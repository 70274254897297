import { NextRouter } from "next/router";

import { MAX_UPLOAD_PROGRESS_VALUE } from "~/components/core/FileUpload/constants";
import {
  MessagingConversation,
  MessagingConversationGroup
} from "~/components/messaging/declarations/conversations";
import {
  MessagingConversationMessage,
  MessagingConversationMessageFromSystem
} from "~/components/messaging/declarations/messages";
import { MILLISECONDS_IN_SECOND } from "~/constants/date";
import { CONVERSATION_ID_QUERY_PARAMETER } from "~/constants/messaging";
import ROUTES from "~/constants/routes";
import { ConversationDetails_conversation_DetailedOneToOneConversation_draftMessage } from "~/declarations/apollo/ConversationDetails";
import {
  AttachmentRequest,
  AttachmentType
} from "~/declarations/apollo/globalTypes";
import { MyConversation_GroupConversation_picture_GroupCustomPicture } from "~/declarations/apollo/MyConversation";
import {
  PublishDraftMessage_publishDraftMessage,
  PublishDraftMessage_publishDraftMessage_ConversationMessageWithQuote_author
} from "~/declarations/apollo/PublishDraftMessage";
import { SaveDraftMessageVariables } from "~/declarations/apollo/SaveDraftMessage";
import { isEmpty, isNotNull } from "~/utils/common";
import { richTextToString, stringToRichText } from "~/utils/richText";

import {
  DEFAULT_MESSAGE,
  DEFAULT_MESSAGING_FILE_MIME_TYPE,
  MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME,
  MESSAGING_GROUP_CONVERSATION_TYPENAME,
  MESSAGING_GROUP_CUSTOM_PICTURE_TYPENAME,
  MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME,
  MESSAGING_SYSTEM_MESSAGES_TYPES
} from "../constants";
import {
  MessageFieldFileState,
  MessageFieldQuoteState,
  MessageFieldState,
  MessagingConversationMessageAttachments,
  MessagingConversationMessageAuthor,
  MessagingConversationQuote
} from "../declarations/common";

const isEveryImageLoaded = (images: MessageFieldFileState[]): boolean =>
  images.every(image => image.loadingProgress === MAX_UPLOAD_PROGRESS_VALUE);

const areAllAttachmentsLoaded = ({
  file,
  images
}: MessageFieldState): boolean =>
  (!file || file.loadingProgress === MAX_UPLOAD_PROGRESS_VALUE) &&
  (images.length === 0 || isEveryImageLoaded(images));

export const isMessageHasAttachment = ({
  gif,
  file,
  images,

  nft,
  post
}: MessageFieldState): boolean =>
  isNotNull(gif) ||
  isNotNull(file) ||
  images.length > 0 ||
  isNotNull(nft) ||
  isNotNull(post);

export const isMessageValid = (state: MessageFieldState): boolean =>
  (!isEmpty(state.text) || isMessageHasAttachment(state)) &&
  areAllAttachmentsLoaded(state);

export const draftMessageToMessageFieldState = (
  draftMessage: ConversationDetails_conversation_DetailedOneToOneConversation_draftMessage
): MessageFieldState => {
  const fieldState: MessageFieldState = {
    ...DEFAULT_MESSAGE,
    text: draftMessage.richText
      ? richTextToString(draftMessage.richText)
      : DEFAULT_MESSAGE.text,
    originalId: draftMessage.originalId,
    quote:
      draftMessage.quote && draftMessage.quoteType
        ? {
            id: draftMessage.quote.id,
            type: draftMessage.quoteType,
            user: draftMessage.quote.author,
            attachments: draftMessage.quote.attachments,
            richText: draftMessage.richText
          }
        : null
  };
  const draftImages: MessageFieldState["images"] = [];

  draftMessage.attachments?.forEach(attachment => {
    switch (attachment.__typename) {
      case AttachmentType.AttachmentFile: {
        fieldState.file = {
          id: attachment.id,
          file: new File([], attachment.name, {
            type: attachment.mimeType
          }),
          url: attachment.url,
          loadingProgress: MAX_UPLOAD_PROGRESS_VALUE
        };
        break;
      }
      case AttachmentType.AttachmentGif: {
        fieldState.gif = {
          id: attachment.id,
          thumbnail: attachment.thumbnail.url
        };
        break;
      }
      case AttachmentType.AttachmentImage: {
        draftImages.push({
          id: attachment.id,
          url: attachment.thumbnails.w1200.url,
          loadingProgress: MAX_UPLOAD_PROGRESS_VALUE
        });
        break;
      }
      case AttachmentType.AttachmentVideo: {
        break;
      }
      case AttachmentType.AttachmentPost: {
        fieldState.post = { ...attachment };
        break;
      }
      case "AttachmentNft": {
        fieldState.nft = { ...attachment };
      }
    }
  });

  fieldState.images = draftImages;

  return fieldState;
};

export const needToChangeMessageDraft = (
  oldDraft?: MessageFieldState,
  newDraft?: MessageFieldState
): boolean =>
  isNotNull(newDraft) &&
  (oldDraft?.gif?.id !== newDraft.gif?.id ||
    oldDraft?.text !== newDraft.text ||
    oldDraft?.images !== newDraft.images ||
    oldDraft?.file !== newDraft.file ||
    oldDraft?.quote !== newDraft.quote ||
    oldDraft?.post?.id !== newDraft.post?.id ||
    oldDraft?.originalId !== newDraft.originalId ||
    oldDraft?.nft?.id !== newDraft.nft?.id);

const messageFieldStateAttachmentsToSaveDraftRequestForm = (
  state: MessageFieldState
): SaveDraftMessageVariables["attachments"] => {
  if (state.gif) {
    return [
      {
        id: state.gif.id,
        type: AttachmentType.AttachmentGif
      }
    ];
  }

  if (state.file?.id) {
    return [
      {
        id: state.file.id,
        type: AttachmentType.AttachmentFile
      }
    ];
  }

  if (state.images.length) {
    return state.images.reduce<AttachmentRequest[]>((images, image) => {
      if (image.id) {
        images.push({
          id: image.id,
          type: AttachmentType.AttachmentImage
        });
      }

      return images;
    }, []);
  }

  if (state.post) {
    return [
      {
        type: AttachmentType.AttachmentPost,
        id: state.post.id
      }
    ];
  }

  if (state.nft) {
    return [
      {
        type: AttachmentType.AttachmentNft,
        id: state.nft.id
      }
    ];
  }
};

export const messageFieldStateToSaveDraftRequestForm = (
  conversationId: string,
  state: MessageFieldState
): SaveDraftMessageVariables => ({
  text: state.text,
  conversationId,
  attachments: messageFieldStateAttachmentsToSaveDraftRequestForm(state),
  quote: state.quote
    ? {
        id: state.quote.id,
        type: state.quote.type
      }
    : undefined
});

const messageFieldStateAttachmentsToDraftResponse = (
  state: MessageFieldState
): MessagingConversationMessageAttachments[] => {
  if (state.file) {
    return [
      {
        __typename: AttachmentType.AttachmentFile,
        id: state.file.id,
        url: state.file.url ?? "",
        name: state.file.file?.name ?? "",
        size: state.file.file?.size ?? 0,
        mimeType: state.file.file?.type ?? DEFAULT_MESSAGING_FILE_MIME_TYPE
      }
    ];
  }

  if (state.gif) {
    return [
      {
        __typename: AttachmentType.AttachmentGif,
        id: state.gif.id,
        thumbnail: {
          url: state.gif.thumbnail
        }
      }
    ];
  }

  if (state.images.length) {
    return state.images.map(image => ({
      __typename: AttachmentType.AttachmentImage,
      id: image.id,
      url: image.url ?? "",
      sizes: {
        height: null,
        width: null
      },
      thumbnails: {
        m600: {
          url: image.url ?? ""
        },
        w1200: {
          url: image.url ?? ""
        }
      }
    }));
  }

  if (state.post) {
    return [state.post];
  }

  if (state.nft) {
    return [state.nft];
  }

  return [];
};

const messageFieldStateQuoteToDraftResponse = (
  quote: MessageFieldQuoteState
): MessagingConversationQuote => ({
  __typename: MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME,
  id: quote.id,
  attachments: quote.attachments ?? [],
  richText: quote.richText,
  author: {
    __typename: "Person",
    id: quote.user?.id ?? "",
    name: quote.user?.name ?? "",
    nickname: quote.user?.nickname ?? ""
  }
});

export const messageFieldStateToDraftResponse = async (
  state: MessageFieldState,
  author: MessagingConversationMessageAuthor,
  id: string
): Promise<PublishDraftMessage_publishDraftMessage> => {
  const authorToPublish: PublishDraftMessage_publishDraftMessage_ConversationMessageWithQuote_author =
    {
      __typename: "Person",
      id: author.id,
      name: author.name,
      nickname: author.nickname,
      avatar: author.avatar,
      isFounder: author.isFounder,
      isCharity: author.isCharity,
      validation: author.validation
    };
  const currentTime = String(Number(new Date()) / MILLISECONDS_IN_SECOND);

  const commonFields = {
    id,
    richText: stringToRichText(state.text),
    author: authorToPublish,
    createdAt: currentTime,
    updatedAt: currentTime,
    reactions: [],
    attachments: messageFieldStateAttachmentsToDraftResponse(state)
  };

  if (state.quote) {
    return {
      ...commonFields,
      __typename: MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME,
      quote: messageFieldStateQuoteToDraftResponse(state.quote),
      quoteType: state.quote.type
    };
  }

  return {
    __typename: MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME,
    ...commonFields
  };
};

export const getUniqueFileId = (file: File): string =>
  `${new Date().getTime()}${file.name}`;

export const goToConversationPage = (
  router: NextRouter,
  channelId: string,
  replace = false
): Promise<boolean> => {
  const url = {
    pathname: ROUTES.messaging,
    query: {
      [CONVERSATION_ID_QUERY_PARAMETER]: channelId
    }
  };

  if (replace) {
    return router.replace(url);
  }

  return router.push(url);
};

export const getMessageQuoteImage = (
  attachments?: MessagingConversationQuote["attachments"] | null
): string | null => {
  const firstAttachment = attachments?.[0];

  if (!firstAttachment) {
    return null;
  }

  if (firstAttachment.__typename === AttachmentType.AttachmentGif) {
    return firstAttachment.thumbnail.url;
  }

  if (firstAttachment.__typename === AttachmentType.AttachmentImage) {
    return firstAttachment.thumbnails.w1200.url;
  }

  return null;
};

export const isGroupConversation = (
  conversation: MessagingConversation
): conversation is MessagingConversationGroup =>
  conversation.__typename === MESSAGING_GROUP_CONVERSATION_TYPENAME;

export const isMessageFromSystem = (
  message: MessagingConversationMessage
): message is MessagingConversationMessageFromSystem =>
  MESSAGING_SYSTEM_MESSAGES_TYPES.includes(message.__typename);

export const isCustomGroupPicture = (
  picture: MessagingConversationGroup["picture"]
): picture is MyConversation_GroupConversation_picture_GroupCustomPicture =>
  picture.__typename === MESSAGING_GROUP_CUSTOM_PICTURE_TYPENAME;

export const goToConversationWithPerson = (
  authorId: string,
  router: NextRouter
): void => {
  router.push({
    pathname: ROUTES.messaging,
    query: {
      receiver: authorId
    }
  });
};
