import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { LONG_POST_VIDEO_UNIT_FRAGMENT } from "~/components/hypeTV/HypeTV/graphql";
import {
  NFT_COMMON_FRAGMENT,
  NFT_EDITION_FRAGMENT
} from "~/components/nft/graphql";
import {
  LONG_STREAM_VIDEO_UNIT_FRAGMENT,
  SHORT_POST_NODE_FRAGMENT,
  SHORT_STREAM_VIDEO_UNIT_FRAGMENT
} from "~/components/post/graphql";

export const MESSAGE_ATTACHMENT_POST_FRAGMENT = gql`
  fragment MessageAttachmentPost on AttachmentPost {
    __typename
    id
    post {
      ... on ShortPost {
        ...ShortPostNode
      }
      ... on LongPost {
        ...LongPostVideoUnitFragment
      }
      ... on LongStream {
        ...LongStreamVideoUnit
      }
      ... on ShortStream {
        ...ShortStreamVideoUnit
      }
    }
  }

  ${LONG_POST_VIDEO_UNIT_FRAGMENT}
  ${LONG_STREAM_VIDEO_UNIT_FRAGMENT}
  ${SHORT_STREAM_VIDEO_UNIT_FRAGMENT}
  ${SHORT_POST_NODE_FRAGMENT}
`;

export const MESSAGE_ATTACHMENT_SHALLOW_FRAGMENT = gql`
  fragment shallowMessageAttachment on Attachment {
    __typename

    ... on AttachmentImage {
      id
    }

    ... on AttachmentGif {
      id
    }

    ... on AttachmentFile {
      id
    }

    ... on AttachmentPost {
      id
    }

    ... on AttachmentMoney {
      id
    }

    ... on AttachmentNft {
      id
    }
  }
`;

export const MESSAGE_ATTACHMENT_FRAGMENT = gql`
  fragment messageAttachment on Attachment {
    __typename

    ... on AttachmentImage {
      id
      url
      sizes {
        height
        width
      }
      thumbnails {
        m600 {
          url
        }
        w1200 {
          url
        }
      }
    }

    ... on AttachmentGif {
      id
      thumbnail {
        url
      }
    }

    ... on AttachmentFile {
      id
      url
      name
      size
      mimeType
    }

    ... on AttachmentPost {
      ...MessageAttachmentPost
    }

    ... on AttachmentMoney {
      id
      amount
      status
    }

    ... on AttachmentNft {
      id
      nft {
        defaultEdition {
          ...NftEditionFragment
        }
        ...NftCommonFragment
      }
    }
  }

  ${NFT_COMMON_FRAGMENT}
  ${NFT_EDITION_FRAGMENT}
  ${MESSAGE_ATTACHMENT_POST_FRAGMENT}
`;

export const CONVERSATION_MESSAGE_REACTION_FRAGMENT = gql`
  fragment ConversationMessageReaction on ConversationMessageReaction {
    __typename
    type
    score
    ownId
  }
`;

export const CONVERSATION_MESSAGE_QUOTE_FRAGMENT = gql`
  fragment ConversationMessageQuote on SimpleConversationMessage {
    __typename
    id
    author {
      __typename
      id
      name
      nickname
    }
    attachments {
      ...messageAttachment
    }
    richText {
      ...richText
    }
  }
  ${MESSAGE_ATTACHMENT_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
`;

export const CONVERSATION_MESSAGE_FRAGMENT_NAME = "MyConversationMessage";
export const CONVERSATION_MESSAGE_FRAGMENT = gql`
  fragment MyConversationMessage on CommonConversationMessage {
    id
    __typename
    createdAt

    ... on SimpleConversationMessage {
      updatedAt
      author {
        __typename
        id
        name
        nickname
        isFounder
        validation
        isCharity
        avatar {
          little
          middle
          big
        }
      }
      reactions {
        ...ConversationMessageReaction
      }
      attachments {
        ...messageAttachment
      }
      richText {
        ...richText
      }
    }

    ... on ConversationMessageWithQuote {
      updatedAt
      author {
        __typename
        id
        name
        nickname
        isFounder
        isCharity
        validation
        avatar {
          little
          middle
          big
        }
      }
      reactions {
        ...ConversationMessageReaction
      }
      attachments {
        ...messageAttachment
      }
      richText {
        ...richText
      }

      quoteType
      quote {
        ...ConversationMessageQuote
      }
    }
    ... on GroupParticipantInvited {
      inviter {
        name
        nickname
        avatar {
          little
        }
      }
      person {
        name
        nickname
      }
    }
    ... on GroupTopicChanged {
      performer {
        name
        nickname
        avatar {
          little
        }
      }
      topic
    }
    ... on GroupParticipantJoined {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupParticipantLeft {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupPictureChanged {
      performer {
        name
        nickname
        avatar {
          little
        }
      }
      picture {
        little
      }
    }
    ... on GroupParticipantJoinRequestSent {
      conversationId
      person {
        id
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupParticipantKicked {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupParticipantBanned {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupParticipantUnbanned {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupOwnershipTransferred {
      performer {
        name
        nickname
        avatar {
          little
        }
      }
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupParticipantPromoted {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
    ... on GroupParticipantDemoted {
      person {
        name
        nickname
        avatar {
          little
        }
      }
    }
  }

  ${RICH_TEXT_FRAGMENT}
  ${MESSAGE_ATTACHMENT_FRAGMENT}
  ${CONVERSATION_MESSAGE_REACTION_FRAGMENT}
  ${CONVERSATION_MESSAGE_QUOTE_FRAGMENT}
`;

export const CONVERSATION_MESSAGE_SHALLOW_ATTACHMENT_FRAGMENT = gql`
  fragment MyConversationMessageWithShallowAttachment on GeneralConversationMessage {
    __typename
    id
    createdAt
    updatedAt
    author {
      __typename
      id
      name
      nickname
      avatar {
        little
        middle
        big
      }
    }
    reactions {
      ...ConversationMessageReaction
    }
    attachments {
      ...shallowMessageAttachment
    }
    richText {
      ...richText
    }
    ... on ConversationMessageWithQuote {
      quoteType
      quote {
        __typename
        id
        author {
          __typename
          id
          name
          nickname
        }
        attachments {
          ...shallowMessageAttachment
        }
        richText {
          ...richText
        }
      }
    }
  }
  ${RICH_TEXT_FRAGMENT}
  ${MESSAGE_ATTACHMENT_SHALLOW_FRAGMENT}
  ${CONVERSATION_MESSAGE_REACTION_FRAGMENT}
`;
export const CONVERSATION_MESSAGES_QUERY_KEY = "conversationMessages";

export const CONVERSATION_MESSAGES_QUERY = gql`
  query ConversationMessages(
    $conversationId: String!
    $first: Int
    $after: String
  ) {
    conversationMessages(
      conversationId: $conversationId
      first: $first
      after: $after
    ) @connection(key: "conversationMessages", filter: ["conversationId"]) {
      edges {
        node {
          ...MyConversationMessage
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${CONVERSATION_MESSAGE_FRAGMENT}
`;

export const TYPENAME_CONVERSATION_MESSAGE_FRAGMENT = gql`
  fragment typenameConversationMessageFragment on ConversationMessage {
    __typename
  }
`;

export const CONVERSATION_MESSAGE_EDIT_FRAGMENT_NAME =
  "ConversationMessageEditFragment";

export const CONVERSATION_MESSAGE_EDIT_FRAGMENT = gql`
  fragment ConversationMessageEditFragment on GeneralConversationMessage {
    richText {
      ...richText
    }
    attachments {
      ...messageAttachment
    }
  }
  ${RICH_TEXT_FRAGMENT}
  ${MESSAGE_ATTACHMENT_FRAGMENT}
`;

export const CONVERSATION_MESSAGE_QUOTE_EDIT_FRAGMENT = gql`
  fragment ConversationMessageQuoteEditFragment on ConversationMessageWithQuote {
    quoteType
    quote {
      ...ConversationMessageQuote
    }
  }
  ${RICH_TEXT_FRAGMENT}
  ${MESSAGE_ATTACHMENT_FRAGMENT}
  ${CONVERSATION_MESSAGE_QUOTE_FRAGMENT}
`;

export const REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT_NAME =
  "ConverationMessageReactions";

export const REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT = gql`
  fragment ConverationMessageReactions on GeneralConversationMessage {
    reactions {
      ...ConversationMessageReaction
    }
  }
  ${CONVERSATION_MESSAGE_REACTION_FRAGMENT}
`;

export const CONVERSATION_MESSAGE_QUOTE_EDIT_FRAGMENT_NAME =
  "ConversationMessageQuoteEditFragment";
