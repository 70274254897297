import { useRouter } from "next/router";
import { useEffect } from "react";

import ROUTES from "~/constants/routes";
import { getSocialSignupRecordFromLocalStorage } from "~/utils/authentication/localStorage";

const useRestoreSignupDialog = (openSignupDialog: () => void): void => {
  const router = useRouter();

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_USERNAME_RESERVATION_INSTEAD_OF_SIGNUP === "true"
    ) {
      return;
    }

    if (
      router.pathname !== ROUTES.index &&
      getSocialSignupRecordFromLocalStorage()
    ) {
      openSignupDialog();
    }
  }, [openSignupDialog, router.pathname]);
};

export default useRestoreSignupDialog;
