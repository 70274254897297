import { WalletTransactionContentTypename } from "./declarations";

export const NUMBER_OF_WALLET_PIN_DIGITS = 4;
export const NUMBER_OF_VERIFICATION_CODE_DIGITS = 6;
export const WORDS_IN_WALLET_MNEMONIC = 12;

export const WALLET_MNEMONIC_SEPARATOR = " ";

export enum WALLET_ERRORS {
  WALLET_KEY_DOES_NOT_EXIST = "wallet.exception.wallet.walletKeyIsNotExists",
  WALLET_DOES_NOT_EXIST = "wallet.exception.wallet.walletIsNotExists",
  WALLET_ALREADY_EXISTS = "wallet.exception.wallet.walletAlreadyExists",
  WALLET_OWNED_BY_OTHER = "wallet.exception.wallet.walletOwnedByOther",
  RECEIVER_HAS_NO_WALLET = "wallet.exception.transferMoney.receiverWalletIsAbsent",
  NOT_ENOUGH_MONEY = "wallet.exception.transferMoney.notEnoughMoney",
  INCORRECT_PIN = "wallet.exception.wallet.gettingKeyError",
  WALLET_WAS_DROPPED = "wallet.exception.wallet.keyWasDropped",
  WALLET_INVALID_PUBLIC_KEY = "wallet.exception.blockChain.broadcastError.invalidPubKey",
  WALLET_UNAUTHORIZED = "wallet.exception.blockChain.broadcastError.unauthorized",
  LINK_NOT_FOUND = "wallet.exception.linkNotFound.common",
  LIMITS_REACHED = "wallet.exception.cantRequestPayout.limitsReached",
  ANOTHER_PAYOUT_IS_PENDING = "wallet.exception.cantRequestPayout.anotherPayoutIsPending"
}

export enum PAYMENT_OPERATION {
  purchase,
  tip,
  donate,
  send,
  mint,
  importXdbNft,
  exportNftToXdb
}

export enum WALLET_TRANSACTION_CONTENT_TYPENAME {
  nftCollection = "TransactionContentNftCollection",
  payout = "TransactionContentPayout",
  unknown = "TransactionContentUnknown",
  nftEdition = "TransactionContentNftEdition",
  longPost = "TransactionContentLongPost",
  longStream = "TransactionContentLongStream",
  shortPost = "TransactionContentShortPost",
  shortStream = "TransactionContentShortStream",
  nft = "TransactionContentNft"
}

export const TRANSACTION_CONTENT_TYPE_TO_TRANSLATION_KEY: {
  [key in WalletTransactionContentTypename]: string | null;
} = {
  [WALLET_TRANSACTION_CONTENT_TYPENAME.unknown]: null,
  [WALLET_TRANSACTION_CONTENT_TYPENAME.nftCollection]: null,
  [WALLET_TRANSACTION_CONTENT_TYPENAME.payout]: null,
  [WALLET_TRANSACTION_CONTENT_TYPENAME.nftEdition]:
    "wallet:transactions_details_preview_nft",
  [WALLET_TRANSACTION_CONTENT_TYPENAME.longPost]:
    "wallet:transactions_details_preview_hypetv",
  [WALLET_TRANSACTION_CONTENT_TYPENAME.longStream]:
    "wallet:transactions_details_preview_stream",
  [WALLET_TRANSACTION_CONTENT_TYPENAME.shortPost]:
    "wallet:transactions_details_preview_post",
  [WALLET_TRANSACTION_CONTENT_TYPENAME.shortStream]:
    "wallet:transactions_details_preview_stream",
  [WALLET_TRANSACTION_CONTENT_TYPENAME.nft]:
    "wallet:transactions_details_preview_nft"
};

export enum WALLET_DIALOG_ERROR_CODE {
  receiverHasNoWallet = "receiverHasNoWallet"
}

export const WALLET_ERRORS_ABOUT_INCORRECT_KEY: string[] = [
  WALLET_ERRORS.WALLET_KEY_DOES_NOT_EXIST,
  WALLET_ERRORS.WALLET_INVALID_PUBLIC_KEY,
  WALLET_ERRORS.WALLET_UNAUTHORIZED
];
