import { SnackbarKey } from "notistack";

import PaymentResultSnackEditionUpdated from "~/components/core/PaymentSnack/PaymentResultSnack/PaymentResultSnackEditionUpdated";
import PaymentResultSnackMessage from "~/components/core/PaymentSnack/PaymentResultSnack/PaymentResultSnackMessage";
import PaymentResultSnackPost from "~/components/core/PaymentSnack/PaymentResultSnack/PaymentResultSnackPost";
import { PostPurchaseLinkClickHandler } from "~/components/wallet/providers/WalletProvider/declarations";
import { ProgressOfTransaction, PURCHASE_TYPE } from "~/declarations/purchases";
import { ObserverNotifyFunction } from "~/utils/observer/useObserver";

interface Props {
  id: SnackbarKey;
  progress: ProgressOfTransaction;
  notifyPostPurchaseLinkClickHandler: ObserverNotifyFunction<PostPurchaseLinkClickHandler>;
  hidden: boolean;
  className?: string;
}

const PaymentResultSnack = ({
  id,
  progress,
  notifyPostPurchaseLinkClickHandler,
  hidden,
  className
}: Props): JSX.Element | null => {
  if (hidden) {
    return null;
  }

  if (
    progress.type === PURCHASE_TYPE.postPurchase ||
    progress.type === PURCHASE_TYPE.postTipping
  ) {
    return (
      <PaymentResultSnackPost
        id={id}
        className={className}
        purchaseType={progress.type}
        postType={progress.postType}
        postId={progress.postId}
        notifyPostPurchaseLinkClickHandler={notifyPostPurchaseLinkClickHandler}
      />
    );
  }

  if (
    progress.type === PURCHASE_TYPE.sendOwnMoney ||
    progress.type === PURCHASE_TYPE.sendMoneyByMessage
  ) {
    return (
      <PaymentResultSnackMessage
        id={id}
        className={className}
        cost={progress.balance}
      />
    );
  }

  if (
    progress.type === PURCHASE_TYPE.nftStartSellingEditions ||
    progress.type === PURCHASE_TYPE.nftStopSellingEditions
  ) {
    return <PaymentResultSnackEditionUpdated id={id} className={className} />;
  }

  return null;
};

export default PaymentResultSnack;
