import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { SentryLink } from "apollo-link-sentry";
import { createUploadLink } from "apollo-upload-client";
import { NextPageContext } from "next";
import nookies from "nookies";
import { useEffect, useMemo } from "react";

import { MessagingConversationMessage } from "~/components/messaging/declarations/messages";
import { WebSocketLink } from "~/components/providers/ApolloProvider/webSocketLink";
import { MILLISECONDS_IN_SECOND } from "~/constants/date";
import possibleTypes from "~/declarations/apollo/possibleTypes.json";
import AuthService from "~/services/AuthService";
import { ACCESS_TOKEN_NAME } from "~/services/AuthService/constants";
import {
  CustomNode,
  customStylePagination
} from "~/utils/apollo/customStylePagination";
import { isServerSide } from "~/utils/common";

import tokenRefreshLink from "./tokenRefreshLink";

let apolloClient: ApolloClient<NormalizedCacheObject>;
const isServer = typeof window === "undefined";

type AuthHeader = {
  authorization: string;
};

type ApolloLinkWithSubscriptionClient = ApolloLink & {
  closeSubscriptionClient: () => void;
};

const WEB_SOCKET_KEEP_ALIVE_TIMEOUT = 10 * MILLISECONDS_IN_SECOND;

const getPolymorphicAuthHeader = (
  headers: Record<string, string>,
  ctx?: NextPageContext
): AuthHeader => {
  if (isServerSide()) {
    const accessToken = nookies.get(ctx)[ACCESS_TOKEN_NAME];

    return AuthService.getAuthorizationHeader(accessToken);
  }

  return AuthService.getAuthorizationHeader();
};

const createTerminatingLink = ({
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: {
  onSubscriptionReconnected?: () => void;
  onSubscriptionDisconnected?: () => void;
}): {
  terminatingLink: ApolloLink;
  webSocketLink: WebSocketLink | null;
} => {
  const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_API,
    fetch,
    credentials: "include"
  }) as unknown as ApolloLink;

  if (isServerSide()) {
    return {
      terminatingLink: uploadLink,
      webSocketLink: null
    };
  }

  const webSocketLink = new WebSocketLink({
    url: process.env.NEXT_PUBLIC_SUBSCRIPTION_API ?? "",
    keepAlive: WEB_SOCKET_KEEP_ALIVE_TIMEOUT,
    lazy: true,
    retryAttempts: Infinity,
    connectionParams: () => ({
      Authorization: AuthService.getAuthorizationHeader().authorization
    }),
    /* Try to reconnect after all possible errors */
    isFatalConnectionProblem: () => false,
    onDisconnected: onSubscriptionDisconnected,
    onReconnected: onSubscriptionReconnected
  });

  const terminatingLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    webSocketLink,
    uploadLink as unknown as ApolloLink
  );

  return {
    terminatingLink,
    webSocketLink
  };
};

const authLink = (ctx?: NextPageContext): ApolloLink =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...getPolymorphicAuthHeader(headers, ctx)
    }
  }));

const createApolloLink = ({
  ctx,
  onSubscriptionDisconnected,
  onSubscriptionReconnected
}: {
  ctx?: NextPageContext;
  onSubscriptionReconnected?: () => void;
  onSubscriptionDisconnected?: () => void;
}): ApolloLink => {
  const { terminatingLink, webSocketLink } = createTerminatingLink({
    onSubscriptionDisconnected,
    onSubscriptionReconnected
  });

  const link = ApolloLink.from([
    new SentryLink({
      uri: process.env.NEXT_PUBLIC_API,
      attachBreadcrumbs: {
        includeError: true,
        /* Warning: this option increases the size of the transmitted data */
        includeQuery:
          process.env.NEXT_PUBLIC_SENTRY_GRAPHQL_QUERY_ENABLED === "true",
        /* Warning: some requests require sensitive information, so enabling this option may result in token leakage */
        includeVariables:
          process.env.NEXT_PUBLIC_SENTRY_GRAPHQL_QUERY_VARIABLES_ENABLED ===
          "true"
      }
    }),
    tokenRefreshLink(),
    authLink(ctx),
    terminatingLink
  ]);

  /* Hack to reconnect webSocketLink and change token */
  (link as ApolloLinkWithSubscriptionClient).closeSubscriptionClient = () => {
    webSocketLink?.client?.restart();
  };

  return link;
};

const createApolloClient = ({
  ctx,
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: {
  ctx?: NextPageContext;
  onSubscriptionReconnected?: () => void;
  onSubscriptionDisconnected?: () => void;
}): ApolloClient<NormalizedCacheObject> => {
  const link = createApolloLink({
    ctx,
    onSubscriptionDisconnected,
    onSubscriptionReconnected
  });

  const apolloClient = new ApolloClient({
    link,
    ssrMode: isServer,
    cache: new InMemoryCache({
      addTypename: false,
      possibleTypes: possibleTypes,
      typePolicies: {
        AttachmentImage: {
          fields: {
            thumbnails: {
              merge: true
            }
          }
        },
        Nft: {
          fields: {
            stats: {
              merge: true
            }
          }
        },
        NftEdition: {
          fields: {
            status: {
              merge: true
            }
          }
        },
        ShortPost: {
          fields: {
            sensitiveShown: {
              read(value): boolean {
                return Boolean(value);
              }
            },
            richText: {
              merge: false
            },
            settings: {
              merge: true
            },
            paywall: {
              merge: true
            },
            counters: {
              merge: true
            }
          }
        },
        PersonCurrentUserReference: {
          merge: true
        },
        CurrentUserReferenceForChannel: {
          merge: true
        },
        GroupConversation: {
          fields: {
            picture: {
              merge: false
            }
          }
        },
        GroupConversationSettings: {
          merge: true
        },
        OneToOneConversationSettings: {
          merge: true
        },
        Person: {
          merge: true,
          fields: {
            stats: {
              merge: true
            },
            background: {
              merge: true
            },
            avatar: {
              merge: true
            },
            nftSettings: {
              merge: true
            }
          }
        },
        LongPost: {
          fields: {
            sensitiveShown: {
              read(value): boolean {
                return Boolean(value);
              }
            },
            settings: {
              merge: true
            },
            video: {
              merge: true
            },
            richText: {
              merge: false
            },
            previewInfo: {
              merge: true
            },
            paywall: {
              merge: true
            }
          }
        },
        LongStream: {
          fields: {
            sensitiveShown: {
              read(value): boolean {
                return Boolean(value);
              }
            },
            settings: {
              merge: true
            },
            stream: {
              merge: true
            },
            richText: {
              merge: false
            }
          }
        },
        GeneralConversationMessage: {
          fields: {
            richText: {
              merge: false
            }
          }
        },
        AttachmentVideo: {
          fields: {
            cover: {
              merge: true
            }
          }
        },
        Cover: {
          fields: {
            thumbnails: {
              merge: true
            }
          }
        },
        PersonSettings: {
          merge: true
        },

        Wallet: {
          keyFields: ["address"]
        },
        Query: {
          fields: {
            threads: customStylePagination([
              "commentableId",
              "commentableType"
            ]),
            replies: customStylePagination(["threadId"]),
            shortContentFromFollowedHubs: customStylePagination(),
            simpleSearch: customStylePagination(["type", "search"]),
            searchCharities: customStylePagination(["search"]),
            searchTags: customStylePagination(["search"]),
            myTransactionsNew: customStylePagination(["filter"]),
            getMoneyRecipients: customStylePagination(["search"]),
            discoverFastFoodFeed: customStylePagination(["categories"]),
            searchShortContent: customStylePagination(["search"]),
            searchLongContent: customStylePagination(["search"]),
            pinnedPosts: customStylePagination(["personId"]),
            hubShortContent: customStylePagination(["hubId"]),
            hubLongContent: customStylePagination(["hubId"]),
            hubStreams: customStylePagination(["hubId", "streamStatus"]),
            createdChannels: customStylePagination(["personId", "search"]),
            notifications: customStylePagination(["activityType"]),
            nearbyLocations: customStylePagination(),
            donation: customStylePagination(["streamId"]),
            longContentFromFollowedHubs: customStylePagination([
              "streamStatuses"
            ]),
            streamsFromFollowedHubs: customStylePagination([
              "categories",
              "status"
            ]),
            discoverHubsStreams: customStylePagination([
              "categories",
              "streamStatus"
            ]),
            channelFeed: customStylePagination(),
            // TODO replace by real query name when its will be ready by backend
            followingsByLongContentActivity: customStylePagination(["first"]),
            discoverLongContent: customStylePagination(["categories"]),
            discoverNft: customStylePagination(),
            discoverHotNft: customStylePagination(),
            discoverSoldOutNft: customStylePagination(),
            getEditions: customStylePagination(["nftId"]),
            getEditionOwnershipHistory: customStylePagination(["editionId"]),
            ownedNfts: customStylePagination(["ownerId"]),
            createdNfts: customStylePagination(["creatorId"]),
            channelPosts: customStylePagination(["channelId"]),
            reactors: customStylePagination([
              "reactableId",
              "reactableType",
              "search"
            ]),
            blockedPersons: customStylePagination(["search"]),
            conversationMembers: customStylePagination(["type", "search"]),
            sharedFiles: customStylePagination(["conversationId"]),
            sharedImages: customStylePagination(["conversationId"]),
            conversationMessages: customStylePagination<
              MessagingConversationMessage & CustomNode
            >(["conversationId"]),
            myConversations: customStylePagination(),
            participants: customStylePagination(["conversationId"]),
            followings: customStylePagination(["search", "personId"]),
            premiumPosts: customStylePagination(["personId"]),
            lastActiveFollowings: customStylePagination([]),
            myTransactionsWithDetails: customStylePagination(["filter"]),
            myPremiumPosts: customStylePagination(["filter"]),
            myPurchasedPosts: customStylePagination(["filter"]),
            myReceivedTips: customStylePagination(["filter"]),
            mySentTips: customStylePagination(["filter"]),
            myInvoicesList: customStylePagination(["filter"]),
            importedXdbNfts: customStylePagination(["importerId"]),
            getXdbNftList: customStylePagination(["xdbAddress"]),
            getCharitiesAsPersonByEditionId: customStylePagination([
              "editionId"
            ]),
            discoverTrailblazerNft: customStylePagination(),
            profileNfts: customStylePagination(["filter, order"]),
            profileNftStats: {
              merge: true
            }
          }
        }
      }
    })
  });

  apolloClient.onResetStore(async (): Promise<void> => {
    (
      apolloClient.link as ApolloLinkWithSubscriptionClient
    ).closeSubscriptionClient();
  });

  return apolloClient;
};

export const initializeApollo = ({
  initialState,
  ctx,
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: {
  initialState?: NormalizedCacheObject;
  ctx?: NextPageContext;
  onSubscriptionReconnected?: () => void;
  onSubscriptionDisconnected?: () => void;
}): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient =
    apolloClient ??
    createApolloClient({
      ctx,
      onSubscriptionReconnected,
      onSubscriptionDisconnected
    });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({
      ...existingCache,
      ...initialState
    });
  }

  AuthService.setApolloClient(_apolloClient);

  // For SSG and SSR always create a new Apollo Client
  if (isServerSide()) {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export const useApollo = ({
  initialState,
  onSubscriptionReconnected,
  onSubscriptionDisconnected
}: {
  initialState: NormalizedCacheObject;
  onSubscriptionReconnected?: () => void;
  onSubscriptionDisconnected?: () => void;
}): ApolloClient<NormalizedCacheObject> => {
  const apolloClient = useMemo(
    () =>
      initializeApollo({
        initialState,
        onSubscriptionReconnected,
        onSubscriptionDisconnected
      }),
    [initialState, onSubscriptionDisconnected, onSubscriptionReconnected]
  );

  useEffect(
    () => () => {
      (
        apolloClient.link as ApolloLinkWithSubscriptionClient
      ).closeSubscriptionClient();
    },
    [apolloClient.link]
  );

  return apolloClient;
};
