import {
  PersonConfidentiality,
  PersonFollowingStatus,
  PersonStatus
} from "~/declarations/apollo/globalTypes";

export interface Person {
  id: string;
  name: string;
  nickname: string;
  email?: string;
  founder?: boolean;
  verified?: boolean;
  isCharity: boolean;
  avatar: {
    little: string;
    middle?: string;
    big?: string;
  } | null;
  stats?: {
    followers?: number;
    posts?: number;
    following?: number;
    tipped?: number;
  } | null;
  confidentiality: PersonConfidentiality;
  followingReference: {
    me: PersonFollowingStatus;
  };
  currentUserReference: {
    canMessage?: boolean;
  };
}

export interface PersonWithAccountInfo {
  id: string;
  name: string;
  nickname: string;
  avatar: { little: string } | null;
  isFounder: boolean;
  isCharity: boolean;
  validation: boolean;
  isAnonymous?: boolean;
  status: PersonStatus;
}

export const PERSON_TYPENAME = "Person";

export const enum ACCOUNT_TYPE {
  common,
  anonymous,
  system,
  deleted
}
