import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const XDB_CAN_NOT_EXPORT_NFT_WARNING_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.canNotExportNftToXdbWarning> =
  {
    Component: safeDynamicImport(
      () => import("~/components/xdb/export/XdbCanNotExportNftWarning"),
      { ssr: false }
    )
  };
