import { createGlobalStyle } from "styled-components";

import {
  RE_CAPTCHA_BADGE_SELECTOR,
  RE_CAPTCHA_ERROR_SELECTOR
} from "~/components/providers/ReCaptchaProvider/constants";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  * {
    scrollbar-width: 7px;
    scrollbar-color: ${({ theme }): string =>
      theme.newTheme.background.senary} transparent;
  }

  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 3px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }): string =>
      theme.newTheme.background.senary};
    border-radius: 3px;
  }

  body {
    overflow-x: hidden;
    overflow-anchor: none;
    margin-right: calc(-1 * (100vw - 100%));

    /* Prevent material to break scroll hack */
    padding: 0 !important;

    scrollbar-width: 5px;
    scrollbar-color: ${({ theme }): string =>
      theme.newTheme.background.senary} transparent;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }): string =>
        theme.newTheme.background.senary};
      border-radius: 5px;
    }
  }

  svg {
    fill: currentColor;
  }

  a {
    cursor: pointer;
  }

  ${RE_CAPTCHA_ERROR_SELECTOR} {
    display: none;
  }

  ${RE_CAPTCHA_BADGE_SELECTOR} {
    visibility: hidden;
  }
`;

export default GlobalStyle;
