import { boolean, mixed, object, SchemaOf, string } from "yup";

import { VERIFICATION_CODE_VALIDATION_SCHEMA } from "~/components/payment/validation";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { WalletDropForm } from "~/components/wallet/WalletDropDialog/declarations";
import { REQUIRED_SCHEMA } from "~/constants/validation/common";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const DROPPING_OLD_DIALOG_FORM_ID = "dropping-old-dialog-form";

export const INITIAL_VALUES = {
  agree: false,
  password: "",
  verificationCode: ""
};

export const VALIDATION_SCHEMA = object().shape({
  agree: REQUIRED_SCHEMA.concat(boolean().oneOf([true])),
  password: REQUIRED_SCHEMA.concat(string())
});

export const generateValidationSchema = (
  hasPassword: boolean
): SchemaOf<WalletDropForm> =>
  object({
    agree: REQUIRED_SCHEMA.concat(boolean().oneOf([true])),
    password: mixed().when([], {
      is: () => hasPassword,
      then: REQUIRED_SCHEMA.concat(string()),
      otherwise: object()
    }),
    verificationCode: mixed().when([], {
      is: () => !hasPassword,
      then: REQUIRED_SCHEMA.concat(VERIFICATION_CODE_VALIDATION_SCHEMA),
      otherwise: object()
    })
  });

export const WALLET_DROP_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.dropWallet> = {
  Component: safeDynamicImport(
    () => import("~/components/wallet/WalletDropDialog"),
    {
      ssr: false
    }
  )
};
