import { ProgressOfTransaction, PURCHASE_TYPE } from "~/declarations/purchases";

export const getTransactionDictionaryKeyByType = (
  type: PURCHASE_TYPE,
  instanceId: string
): string => `${type}:${instanceId}`;

export const getTransactionDictionaryKeyByProgress = (
  progress: ProgressOfTransaction
): string => {
  switch (progress.type) {
    case PURCHASE_TYPE.postPurchase:
    case PURCHASE_TYPE.postTipping: {
      return getTransactionDictionaryKeyByType(progress.type, progress.postId);
    }
    case PURCHASE_TYPE.sendMoneyByMessage: {
      /* TODO: choose a more reliable identifier */
      return getTransactionDictionaryKeyByType(progress.type, "");
    }
    case PURCHASE_TYPE.sendOwnMoney: {
      /* TODO: choose a more reliable identifier */
      return getTransactionDictionaryKeyByType(progress.type, "");
    }
    case PURCHASE_TYPE.nftMinting:
    case PURCHASE_TYPE.xdbNftExport:
    case PURCHASE_TYPE.xdbNftImport:
    case PURCHASE_TYPE.nftStopSellingEditions:
    case PURCHASE_TYPE.nftStartSellingEditions: {
      return getTransactionDictionaryKeyByType(progress.type, progress.nftId);
    }
    case PURCHASE_TYPE.nftPurchase: {
      return getTransactionDictionaryKeyByType(
        progress.type,
        progress.editionId
      );
    }
  }
};
