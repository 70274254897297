import {
  EventConversationFrozen,
  EventConversationUnfrozen,
  EventConversationWasRead,
  EventGroupCreated,
  EventGroupInvitePolicyChanged,
  EventGroupKindChanged,
  EventGroupOwnershipTransferred,
  EventGroupPictureChanged,
  EventGroupPrivacyChanged,
  EventGroupTopicChanged,
  EventMessageCreated,
  EventMessageDeleted,
  EventMessageEdited,
  EventMessagePublished,
  EventMessageQuoteChanged,
  EventOneToOneConversationCreated,
  EventParticipantBanned,
  EventParticipantBlockingMessagesChanged,
  EventParticipantDemoted,
  EventParticipantIgnoringMessagesChanged,
  EventParticipantInvited,
  EventParticipantJoined,
  EventParticipantJoinRequestSent,
  EventParticipantKicked,
  EventParticipantLeft,
  EventParticipantPromoted,
  EventParticipantUnbanned,
  EventReactionCreated,
  EventReactionDeleted
} from "./declarations";

export const EVENT_MESSAGE_CREATED_TYPENAME: EventMessageCreated["__typename"] =
  "EventMessageCreated";
export const EVENT_MESSAGE_DELETED_TYPENAME: EventMessageDeleted["__typename"] =
  "EventMessageDeleted";
export const EVENT_MESSAGE_EDITED_TYPENAME: EventMessageEdited["__typename"] =
  "EventMessageEdited";
export const EVENT_MESSAGE_QUOTE_CHANGED_TYPENAME: EventMessageQuoteChanged["__typename"] =
  "EventMessageQuoteChanged";
export const EVENT_PARTICIPANT_JOINED_TYPENAME: EventParticipantJoined["__typename"] =
  "EventParticipantJoined";
export const EVENT_PARTICIPANT_KICKED_TYPENAME: EventParticipantKicked["__typename"] =
  "EventParticipantKicked";
export const EVENT_PARTICIPANT_LEFT_TYPENAME: EventParticipantLeft["__typename"] =
  "EventParticipantLeft";
export const EVENT_PARTICIPANT_INVITED_TYPENAME: EventParticipantInvited["__typename"] =
  "EventParticipantInvited";
export const EVENT_PARTICIPANT_DEMOTED_TYPENAME: EventParticipantDemoted["__typename"] =
  "EventParticipantDemoted";
export const EVENT_PARTICIPANT_PROMOTED_TYPENAME: EventParticipantPromoted["__typename"] =
  "EventParticipantPromoted";
export const EVENT_PARTICIPANT_UNBANNED_TYPENAME: EventParticipantUnbanned["__typename"] =
  "EventParticipantUnbanned";
export const EVENT_PARTICIPANT_BANNED_TYPENAME: EventParticipantBanned["__typename"] =
  "EventParticipantBanned";
export const EVENT_PARTICIPANT_JOIN_REQUEST_SENT_TYPENAME: EventParticipantJoinRequestSent["__typename"] =
  "EventParticipantJoinRequestSent";
export const EVENT_GROUP_OWNERSHIP_TRANSFERRED_TYPENAME: EventGroupOwnershipTransferred["__typename"] =
  "EventGroupOwnershipTransferred";
export const EVENT_GROUP_PICTURE_CHANGED_TYPENAME: EventGroupPictureChanged["__typename"] =
  "EventGroupPictureChanged";
export const EVENT_GROUP_CREATED_TYPENAME: EventGroupCreated["__typename"] =
  "EventGroupCreated";
export const EVENT_GROUP_TOPIC_CHANGED_TYPENAME: EventGroupTopicChanged["__typename"] =
  "EventGroupTopicChanged";
export const EVENT_CONVERSATION_WAS_READ_TYPENAME: EventConversationWasRead["__typename"] =
  "EventConversationWasRead";
export const EVENT_CONVERSATION_FROZEN_TYPENAME: EventConversationFrozen["__typename"] =
  "EventConversationFrozen";
export const EVENT_CONVERSATION_UNFROZEN_TYPENAME: EventConversationUnfrozen["__typename"] =
  "EventConversationUnfrozen";
export const EVENT_ONE_TO_ONE_CONVERSATION_CREATED_TYPENAME: EventOneToOneConversationCreated["__typename"] =
  "EventOneToOneConversationCreated";
export const EVENT_PARTICIPANT_BLOCKING_MESSAGES_CHANGED_TYPENAME: EventParticipantBlockingMessagesChanged["__typename"] =
  "EventParticipantBlockingMessagesChanged";
export const EVENT_PARTICIPANT_IGNORING_MESSAGES_CHANGED_TYPENAME: EventParticipantIgnoringMessagesChanged["__typename"] =
  "EventParticipantIgnoringMessagesChanged";
export const EVENT_GROUP_INVITE_POLICY_CHANGED_TYPENAME: EventGroupInvitePolicyChanged["__typename"] =
  "EventGroupInvitePolicyChanged";
export const EVENT_GROUP_KIND_CHANGED_TYPENAME: EventGroupKindChanged["__typename"] =
  "EventGroupKindChanged";
export const EVENT_GROUP_PRIVACY_CHANGED_TYPENAME: EventGroupPrivacyChanged["__typename"] =
  "EventGroupPrivacyChanged";
export const EVENT_REACTION_CREATED_TYPENAME: EventReactionCreated["__typename"] =
  "EventReactionCreated";
export const EVENT_REACTION_DELETED_TYPENAME: EventReactionDeleted["__typename"] =
  "EventReactionDeleted";
export const EVENT_MESSAGE_PUBLISHED_TYPENAME: EventMessagePublished["__typename"] =
  "EventMessagePublished";

export const MESSAGING_EVENT_TYPE = {
  messageCreated: EVENT_MESSAGE_CREATED_TYPENAME,
  messageDeleted: EVENT_MESSAGE_DELETED_TYPENAME,
  messageEdited: EVENT_MESSAGE_EDITED_TYPENAME,
  messageQuoteChanged: EVENT_MESSAGE_QUOTE_CHANGED_TYPENAME,
  participantJoined: EVENT_PARTICIPANT_JOINED_TYPENAME,
  participantKicked: EVENT_PARTICIPANT_KICKED_TYPENAME,
  participantLeft: EVENT_PARTICIPANT_LEFT_TYPENAME,
  participantInvited: EVENT_PARTICIPANT_INVITED_TYPENAME,
  participantDemoted: EVENT_PARTICIPANT_DEMOTED_TYPENAME,
  participantPromoted: EVENT_PARTICIPANT_PROMOTED_TYPENAME,
  participantUnbanned: EVENT_PARTICIPANT_UNBANNED_TYPENAME,
  participantBanned: EVENT_PARTICIPANT_BANNED_TYPENAME,
  participantJoinRequestSent: EVENT_PARTICIPANT_JOIN_REQUEST_SENT_TYPENAME,
  groupOwnershipTransferred: EVENT_GROUP_OWNERSHIP_TRANSFERRED_TYPENAME,
  groupPictureChanged: EVENT_GROUP_PICTURE_CHANGED_TYPENAME,
  groupCreated: EVENT_GROUP_CREATED_TYPENAME,
  groupTopicChanged: EVENT_GROUP_TOPIC_CHANGED_TYPENAME,
  conversationWasRead: EVENT_CONVERSATION_WAS_READ_TYPENAME,
  conversationFrozen: EVENT_CONVERSATION_FROZEN_TYPENAME,
  conversationUnfrozen: EVENT_CONVERSATION_UNFROZEN_TYPENAME,
  oneToOneConversationCreated: EVENT_ONE_TO_ONE_CONVERSATION_CREATED_TYPENAME,
  participantBlockingMessagesChanged:
    EVENT_PARTICIPANT_BLOCKING_MESSAGES_CHANGED_TYPENAME,
  participantIgnoringMessagesChanged:
    EVENT_PARTICIPANT_IGNORING_MESSAGES_CHANGED_TYPENAME,
  groupInvitePolicyChanged: EVENT_GROUP_INVITE_POLICY_CHANGED_TYPENAME,
  groupKindChanged: EVENT_GROUP_KIND_CHANGED_TYPENAME,
  groupPrivacyChanged: EVENT_GROUP_PRIVACY_CHANGED_TYPENAME,
  reactionCreated: EVENT_REACTION_CREATED_TYPENAME,
  reactionDeleted: EVENT_REACTION_DELETED_TYPENAME,
  messagePublished: EVENT_MESSAGE_PUBLISHED_TYPENAME
};
