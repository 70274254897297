import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_SYNCHRONIZATION_SUCCESS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.walletSynchronizationSuccess> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletSynchronizationSuccessDialog"),
      { ssr: false }
    )
  };
