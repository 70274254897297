import useTranslation from "next-translate/useTranslation";
import { useCallback, useEffect, useState } from "react";

import { MessageCreatedHandler } from "~/components/messaging/providers/MessagingProvider/useRealTimeMessagingUpdateViaSubscription/declarations";

interface UseMessagingNotificationsResult {
  onMessageCreated: MessageCreatedHandler;
  clearNotifications: () => void;
  notifications: string[];
}

const NEW_MESSAGE_NOTIFICATION_TRANSLATION_KEY =
  "messaging_notification_new-message";
const DEFAULT_NOTIFICATIONS_VALUE: string[] = [];

export const useMessagingNotifications = (
  activeConversationId: string | null
): UseMessagingNotificationsResult => {
  const { t } = useTranslation("messaging");
  const [notifications, setNotifications] = useState<string[]>(
    DEFAULT_NOTIFICATIONS_VALUE
  );

  const handleMessageCreated = useCallback(
    conversationId => {
      if (!activeConversationId || conversationId !== activeConversationId) {
        return;
      }

      setNotifications(notifications => {
        if (notifications.length) {
          return notifications;
        } else {
          return [t(NEW_MESSAGE_NOTIFICATION_TRANSLATION_KEY)];
        }
      });
    },
    [activeConversationId, t]
  );

  const clearNotifications = useCallback(() => {
    setNotifications(DEFAULT_NOTIFICATIONS_VALUE);
  }, []);

  useEffect(() => {
    clearNotifications();
  }, [activeConversationId, clearNotifications]);

  return {
    notifications,
    clearNotifications,
    onMessageCreated: handleMessageCreated
  };
};
