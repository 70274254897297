import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export enum WALLET_CREATE_KEY_DIALOG_ERROR_CODE {
  noUserOrWalletAddress = "noUserOrWalletAddress"
}

export const WALLET_CREATE_KEY_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.createWalletKey> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletCreateKeyDialog"),
      { ssr: false }
    )
  };
