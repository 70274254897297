import { object } from "yup";

import { PIN_VALIDATION_SCHEMA } from "~/components/payment/validation";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { XdbNftImportPinForm } from "~/components/xdb/import/XdbNftImportPinDialog/declaration";
import { REQUIRED_SCHEMA } from "~/constants/validation/common";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const XDB_NFT_IMPORT_PIN_FORM_INITIAL_VALUES: XdbNftImportPinForm = {
  pin: ""
};

export const XDB_NFT_IMPORT_PIN_FORM_VALIDATION_SCHEMA = object({
  pin: REQUIRED_SCHEMA.concat(PIN_VALIDATION_SCHEMA)
});

export const XDB_NFT_IMPORT_PIN_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.xdbNftImportPin> =
  {
    Component: safeDynamicImport(
      () => import("~/components/xdb/import/XdbNftImportPinDialog"),
      { ssr: false }
    )
  };
