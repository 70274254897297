import MuiLink, { LinkProps as MuiLinkProps } from "@material-ui/core/Link";
import { forwardRef, useMemo } from "react";
import styled from "styled-components";

const LINK_VARIANTS = ["primary", "secondary", "tertiary"] as const;

export type LinkProps = Omit<MuiLinkProps, "variant" | "ref"> & {
  variant?: typeof LINK_VARIANTS[number];
};

const Link = forwardRef<HTMLLinkElement, LinkProps>(
  (
    { variant, className, underline = "none", ...otherProps }: LinkProps,
    ref
  ): JSX.Element => {
    const extendedClassName = useMemo(() => {
      const classNames = className?.split(" ") ?? [];

      if (LINK_VARIANTS.some(linkVariant => linkVariant === variant)) {
        classNames.push(`MuiLink-${variant}`);
      }

      return classNames.join(" ");
    }, [className, variant]);

    return (
      <StyledMuiLink
        ref={ref}
        variant="inherit"
        color="inherit"
        underline={underline}
        className={extendedClassName}
        {...otherProps}
      />
    );
  }
);

Link.displayName = "Link";

export default Link;

const StyledMuiLink = styled(MuiLink)`
  font-family: "Aeonik", "Roboto", "Helvetica", "Arial", sans-serif;

  &.MuiLink-primary {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.08px;
  }
  &.MuiLink-secondary {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  &.MuiLink-tertiary {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.08px;
    line-height: 24px;
  }
`;
