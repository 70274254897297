import { gql } from "@apollo/client";

export const POST_AUTHOR_FRAGMENT = gql`
  fragment shortPostAuthor on Person {
    __typename
    id
    name
    avatar {
      little
      middle
    }
    confidentiality
    nickname
    validation
    isFounder
    isCharity
    followingReference {
      me
    }
    currentUserReference {
      __typename
      canMessage
      canEdit
    }
  }
`;
