import amplitude, { AmplitudeClient } from "amplitude-js";
import { useRouter } from "next/router";
import Script from "next/script";
import nookies from "nookies";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { ROUTER_EVENTS } from "~/constants/events";
import { isEmpty } from "~/utils/common";

import { AnalyticsContext } from "./AnalyticsContext";
import {
  ANALYTICS_ERRORS,
  ANALYTICS_EVENT,
  CUSTOM_USER_PROPERTY,
  FB_PIXEL_ID,
  GTM_ID,
  OPT_OUT_COOKIE_NAME
} from "./constants";
import { EventWithOptionalPayload, SendEventCallback } from "./declarations";

type Props = {
  children: ReactNode;
};

const fbPageView = (): void => {
  window.fbq?.("track", "PageView");
};

export const gtmPageView = (): void => {
  const pageUrl = window.location.href.substring(window.location.origin.length);

  window.dataLayer?.push({
    event: "pageview",
    page: pageUrl
  });
};

const AnalyticsProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const [client, setClient] = useState<AmplitudeClient | null>(null);

  const setUserId = (userId: string): void => {
    client?.setUserId(userId);
  };

  const setCustomUserProperty = (
    property: CUSTOM_USER_PROPERTY,
    value: string | number
  ): void => {
    if (client) {
      const identity = new amplitude.Identify().set(property, value);
      client.identify(identity);
    }
  };

  const sendEvent = useCallback<SendEventCallback>(
    (eventWithPayload): void => {
      if (!client) {
        return;
      }

      const url = window.location.href;
      const eventName = eventWithPayload.event;
      const eventData =
        (eventWithPayload as EventWithOptionalPayload).data || {};

      client.logEvent(eventName, {
        ...eventData,
        url
      });
    },
    [client]
  );

  useEffect(() => {
    if (client !== null) {
      return;
    }

    const API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;

    if (isEmpty(API_KEY) || !API_KEY) {
      console.error(ANALYTICS_ERRORS.apiKey);
      return;
    }

    const amplitudeClient = amplitude.getInstance();

    const optOutCookie = nookies.get(null)[OPT_OUT_COOKIE_NAME];
    const optedOut = !isEmpty(optOutCookie);

    amplitudeClient.init(API_KEY);
    amplitudeClient.setOptOut(optedOut);

    setClient(amplitudeClient);
  }, [client]);

  useEffect(() => {
    if (client) {
      sendEvent({ event: ANALYTICS_EVENT.websiteVisit });
    }
  }, [client, sendEvent]);

  useEffect(() => {
    if (!FB_PIXEL_ID) {
      return;
    }

    // This pageView only triggers the first time (it's important for Pixel to have real information)
    fbPageView();

    const handleRouteChange = (): void => {
      fbPageView();
    };

    router.events.on(ROUTER_EVENTS.routeChangeComplete, handleRouteChange);
    return () => {
      router.events.off(ROUTER_EVENTS.routeChangeComplete, handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (!GTM_ID) {
      return;
    }

    gtmPageView();

    const handleRouteChange = (): void => {
      gtmPageView();
    };

    router.events.on(ROUTER_EVENTS.routeChangeComplete, handleRouteChange);
    return () => {
      router.events.off(ROUTER_EVENTS.routeChangeComplete, handleRouteChange);
    };
  }, [router.events]);

  return (
    <AnalyticsContext.Provider
      value={{ setUserId, setCustomUserProperty, sendEvent }}
    >
      {FB_PIXEL_ID && (
        <Script
          id="fb-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${FB_PIXEL_ID});
            `
          }}
        />
      )}

      {/* Google Tag Manager - Global base code */}
      {GTM_ID && (
        <Script
          id="gtm-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${GTM_ID}');
            `
          }}
        />
      )}

      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
