import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { NextPageContext } from "next";
import nookies from "nookies";
import qs from "query-string";

import { NETWORK_CODES } from "~/constants/apiInteraction";
import { EVENTS } from "~/constants/events";
import { RefreshToken } from "~/declarations/apollo/RefreshToken";
import {
  ACCESS_TOKEN_NAME,
  COOKIE_OPTIONS,
  FOREVER_COOKIE_LIFETIME_MILLISECONDS,
  LOCAL_STORAGE_LOGIN_AT_KEY,
  LOCAL_STORAGE_LOGOUT_AT_KEY,
  LOCAL_STORAGE_RELOAD_AFTER_LOGOUT_DELAY
} from "~/services/AuthService/constants";
import {
  LOGOUT_MUTATION,
  TOKEN_REFRESH_MUTATION
} from "~/services/AuthService/graphql";
import { isEmpty, isServerSide } from "~/utils/common";
import { getCustomGraphQLErrorRequestStatus } from "~/utils/errors";
import { redirect } from "~/utils/redirect";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
let accessToken: string | null = null;
let logoutAt: string | null = null;
let loginAt: string | null = null;
let logoutAttempts = 0;

const hasLogoutMark = (): boolean =>
  Boolean(logoutAt && localStorage.getItem(LOCAL_STORAGE_LOGOUT_AT_KEY));

const hasLoginMark = (): boolean =>
  Boolean(loginAt && localStorage.getItem(LOCAL_STORAGE_LOGIN_AT_KEY));

const clearLogoutMark = (): void => {
  if (!hasLogoutMark()) {
    return;
  }

  logoutAt = null;
  localStorage.removeItem(LOCAL_STORAGE_LOGOUT_AT_KEY);
};

const setLogoutMark = (): void => {
  if (hasLogoutMark()) {
    return;
  }

  logoutAt = Date.now().toString();
  localStorage.setItem(LOCAL_STORAGE_LOGOUT_AT_KEY, logoutAt);
};

const clearLoginMark = (): void => {
  if (hasLoginMark()) {
    return;
  }

  loginAt = null;
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_AT_KEY);
};

const clearToken = (): void => {
  nookies.destroy(null, ACCESS_TOKEN_NAME, COOKIE_OPTIONS);
  accessToken = null;
};

const readToken = (): void => {
  try {
    const cookies = nookies.get(null);
    accessToken = cookies[ACCESS_TOKEN_NAME];
  } catch (err) {
    console.error("JWT reading error: " + err);
  }
};

const setLoginMark = (): void => {
  if (isServerSide() || hasLoginMark()) {
    return;
  }
  loginAt = Date.now().toString();
  localStorage.setItem(LOCAL_STORAGE_LOGIN_AT_KEY, loginAt);
};

const setToken = (newAccessToken: string | null, initial?: boolean): void => {
  accessToken = newAccessToken;
  clearLogoutMark();

  if (!isServerSide() && newAccessToken) {
    const expires = new Date(Date.now() + FOREVER_COOKIE_LIFETIME_MILLISECONDS);

    nookies.set(null, ACCESS_TOKEN_NAME, newAccessToken, {
      ...COOKIE_OPTIONS,
      expires
    });

    if (initial && apolloClient) {
      apolloClient.resetStore();
    }
  }
};

const getAuthorizationHeader = (
  accessTokeForRequest: string | null = accessToken
): { authorization: string } => ({
  authorization: accessTokeForRequest ? `Bearer ${accessTokeForRequest}` : ""
});

const refreshToken = async (): Promise<boolean> => {
  let refreshed = false;

  try {
    const result = await apolloClient?.mutate<RefreshToken>({
      mutation: TOKEN_REFRESH_MUTATION
    });

    if (result?.data?.refreshToken) {
      setToken(result.data.refreshToken.accessToken.value);
      refreshed = true;
    }
  } catch (e) {
    // Handle fail in place of using of refreshToken() using return value
  }

  return refreshed;
};

const isAuthorizedClient = (): boolean => Boolean(accessToken);

const isAuthorizedServer = (context: NextPageContext): boolean => {
  const cookies = nookies.get(context);

  return !isEmpty(cookies) && !isEmpty(cookies[ACCESS_TOKEN_NAME]);
};

const authorizePage = async ({
  context,
  redirectForAuthorized,
  redirectForUnauthorized
}: {
  context: NextPageContext;
  redirectForAuthorized?: string | null;
  redirectForUnauthorized?: string | null;
}): Promise<boolean> => {
  const authorized = isServerSide()
    ? await isAuthorizedServer(context)
    : await isAuthorizedClient();

  if (authorized) {
    if (redirectForAuthorized) {
      redirect(context, redirectForAuthorized, NETWORK_CODES.found);
    }
    return true;
  }

  if (redirectForUnauthorized) {
    const parsedURL = qs.parseUrl(redirectForUnauthorized, {
      parseFragmentIdentifier: true
    });

    const nextURL = context.asPath ?? context.pathname;
    if (nextURL) {
      parsedURL.query.next = nextURL;
    }

    redirect(context, qs.stringifyUrl(parsedURL), NETWORK_CODES.found);
  }

  return false;
};

const logout = async (): Promise<void> => {
  if (!isAuthorizedClient() || logoutAttempts) {
    return;
  }

  const clearResourcesOnLogout = (): void => {
    clearToken();
    apolloClient?.cache.reset();
    setLogoutMark();
    window.location.reload();
  };

  try {
    await apolloClient?.mutate({
      mutation: LOGOUT_MUTATION
    });

    clearResourcesOnLogout();
  } catch (error) {
    if (
      getCustomGraphQLErrorRequestStatus(error) ===
        NETWORK_CODES.unauthorized ||
      getCustomGraphQLErrorRequestStatus(error) === NETWORK_CODES.forbidden
    ) {
      clearResourcesOnLogout();
    }

    console.error(error);
  } finally {
    logoutAttempts++;
  }
};

const setApolloClient = (
  newApolloClient: ApolloClient<NormalizedCacheObject>
): void => {
  apolloClient = newApolloClient;
};

const init = (): void => {
  readToken();

  const handleChangeLocalStorage = ({ key, newValue }: StorageEvent): void => {
    if (!newValue) {
      return;
    }

    const logoutInAnotherTab =
      key === LOCAL_STORAGE_LOGOUT_AT_KEY && logoutAt !== newValue;

    const loginInAnotherTab =
      key === LOCAL_STORAGE_LOGIN_AT_KEY && loginAt !== newValue;

    if (logoutInAnotherTab) {
      clearToken();
      clearLoginMark();
    }

    if (loginInAnotherTab) {
      clearLogoutMark();
    }

    if (logoutInAnotherTab || loginInAnotherTab) {
      window.setTimeout(() => {
        window.location.reload();
      }, LOCAL_STORAGE_RELOAD_AFTER_LOGOUT_DELAY);
    }
  };

  if (!isServerSide()) {
    window.addEventListener(
      EVENTS.localStorageChange,
      handleChangeLocalStorage,
      false
    );
  }
};

const getAccessToken = (): string => accessToken ?? "";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AuthService = () => {
  init();

  return {
    getAuthorizationHeader,
    getAccessToken,
    setApolloClient,
    setToken,
    setLoginMark,
    isAuthorizedServer,
    isAuthorizedClient,
    refreshToken,
    logout,
    authorizePage
  };
};

export default AuthService();
