import useTranslation from "next-translate/useTranslation";
import { forwardRef, KeyboardEvent } from "react";
import styled from "styled-components";

import Button from "~/components/core/Button/Button";
import IconButton from "~/components/core/IconButton";
import { TextFieldProps } from "~/components/core/TextField/TextField";
import { KEY_PHYSICAL_CODE } from "~/constants/keyboard";
import Search from "~/public/assets/icons/Search.svg?sprite";

import TextField from "../TextField";

export type SearchFieldProps = TextFieldProps & {
  onApply?: () => void;
  onClear?: () => void;
};

const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  function SearchField(
    {
      value,
      placeholder,
      inputProps,
      InputProps,
      onClear,
      onApply,
      ...props
    }: SearchFieldProps,
    ref
  ): JSX.Element {
    const { t } = useTranslation("common");
    const searchText = t("search");

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      if (onApply && event.keyCode === KEY_PHYSICAL_CODE.enter) {
        onApply();
      }
    };

    return (
      <SearchTextField
        {...props}
        value={value}
        variant="outlined"
        onKeyDown={handleKeyDown}
        placeholder={placeholder ?? searchText}
        inputProps={{ ...inputProps, ref, "aria-label": searchText }}
        InputProps={{
          ...InputProps,
          notched: false,
          startAdornment: <SearchIcon />,
          endAdornment: value ? (
            <>
              <ClearButton icon="closeFilled" onClick={onClear} />
              {onApply && (
                <Button onClick={onApply} size="small">
                  {searchText}
                </Button>
              )}
            </>
          ) : undefined
        }}
      />
    );
  }
);

export default SearchField;

const SearchTextField = styled(TextField)`
  .MuiInputBase-root {
    padding-right: 8px;
  }
  .MuiInputBase-input {
    font-size: 13px;
    padding: 17px 12px;

    &::placeholder {
      color: ${({ theme }) => theme.newTheme.text.tertiary};
      font-size: 13px;
      font-weight: 500;
    }
  }
`;

const SearchIcon = styled(Search)`
  flex-shrink: 0;
  height: 24px;
  width: 24px;
`;

const ClearButton = styled(IconButton).attrs({
  tabIndex: -1,
  iconSize: 16,
  icon: "closeFilled"
})`
  color: ${({ theme }) => theme.newTheme.background.quaternary};
  height: 24px;
  width: 24px;
  padding: 4px;
  margin-right: 8px;
`;
