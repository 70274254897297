/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "VideoContent-89363c44--sprite",
  "use": "VideoContent-89363c44--sprite-usage",
  "viewBox": "0 0 24 24",
  "content": "<symbol xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" id=\"VideoContent-89363c44--sprite\"><path d=\"M2.57 5.52c.846-.82 2.018-1.27 3.342-1.27h5.51c1.323 0 2.495.45 3.341 1.27.847.82 1.32 1.967 1.32 3.27v6.42c0 1.302-.473 2.45-1.32 3.27-.846.82-2.018 1.27-3.342 1.27H5.912c-1.324 0-2.496-.45-3.342-1.27-.847-.82-1.32-1.968-1.32-3.27V8.79c0-1.303.473-2.45 1.32-3.27zm1.044 1.077c-.533.516-.864 1.263-.864 2.193v6.42c0 .93.331 1.677.864 2.193.535.518 1.318.847 2.298.847h5.51c.98 0 1.763-.329 2.297-.847.533-.516.864-1.264.864-2.193V8.79c0-.93-.33-1.677-.864-2.193-.534-.518-1.318-.847-2.298-.847H5.912c-.98 0-1.763.329-2.298.847zm16.251-.234a1.829 1.829 0 0 1 1.99.102c.583.412.895 1.098.895 1.799v7.472c0 .702-.312 1.387-.894 1.799h-.002a1.859 1.859 0 0 1-1.073.348 1.83 1.83 0 0 1-.917-.248h-.002l-1.276-.726-.001-.001c-.736-.421-1.145-1.232-1.145-2.07V9.16c0-.837.408-1.65 1.146-2.069l1.278-.727h.001m1.123 1.325a.33.33 0 0 0-.378-.024l-.002.002-1.28.728c-.208.118-.388.393-.388.765v5.679c0 .37.179.646.39.766 0 0-.001 0 0 0l1.28.729a.34.34 0 0 0 .17.05c.06 0 .131-.019.21-.074.14-.1.26-.302.26-.574V8.264c0-.272-.12-.475-.26-.575h-.002z\" clip-rule=\"evenodd\" fill-rule=\"evenodd\" /></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
