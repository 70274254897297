export enum THEME_MODE {
  light = "light",
  dark = "dark"
}

export enum LAYOUT_SIZE {
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
  full = "full"
}

export const SHADES_GRADE = [4, 8, 16, 24, 48, 64, 72, 84, 92] as const;
