import {
  default as MuiTypography,
  TypographyProps as MuiTypographyProps
} from "@material-ui/core/Typography";
import { CSSProperties, forwardRef, useMemo } from "react";
import styled from "styled-components";

export const HEADINGS = ["h1", "h2", "h3", "h4"] as const;
export const TITLES = ["title1", "title2", "title3", "title4"] as const;
export const PARAGRAPHS = ["p1", "p2", "p3", "p4"] as const;
export const BUTTONS = ["button2", "button3"] as const;

type Headings = typeof HEADINGS[number];
type Titles = typeof TITLES[number];
type Paragraphs = typeof PARAGRAPHS[number];
type Buttons = typeof BUTTONS[number];

export type TypographyProps = Omit<MuiTypographyProps, "variant" | "color"> & {
  variant?: Headings | Titles | Paragraphs | Buttons;
  color?: MuiTypographyProps["color"] | "textTertiary";
};

const VARIANT_MAP: {
  [key in NonNullable<
    TypographyProps["variant"]
  >]: MuiTypographyProps["variant"];
} = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  title1: "caption",
  title2: "caption",
  title3: "caption",
  title4: "caption",
  p1: "body1",
  p2: "body1",
  p3: "body1",
  p4: "body1",
  button2: "body1",
  button3: "body1"
};

export const TYPOGRAPHY_BY_VARIANT: Record<
  NonNullable<TypographyProps["variant"]>,
  CSSProperties
> = {
  h1: {
    fontSize: "34px",
    letterSpacing: "0.2px",
    lineHeight: "40px",
    fontWeight: "bold"
  },
  h2: {
    fontSize: "25px",
    letterSpacing: "-0.4px",
    lineHeight: "30px",
    fontWeight: "bold"
  },
  h3: {
    fontSize: "20px",
    letterSpacing: "-0.4px",
    lineHeight: "28px",
    fontWeight: "bold"
  },
  h4: {
    fontSize: "17px",
    letterSpacing: "-0.4px",
    lineHeight: "24px",
    fontWeight: "bold"
  },
  title1: {
    fontSize: "17px",
    letterSpacing: "0",
    lineHeight: "26px",
    fontWeight: "700"
  },
  title2: {
    fontSize: "15px",
    letterSpacing: "-0.08px",
    lineHeight: "21px",
    fontWeight: "500"
  },
  title3: {
    fontSize: "13px",
    letterSpacing: "0",
    lineHeight: "20px",
    fontWeight: "500"
  },
  title4: {
    fontSize: "10px",
    letterSpacing: "0.12px",
    lineHeight: "16px",
    fontWeight: "500"
  },
  p1: {
    fontSize: "17px",
    letterSpacing: "0.2px",
    lineHeight: "26px"
  },
  p2: {
    fontSize: "15px",
    letterSpacing: "0.2px",
    lineHeight: "21px"
  },
  p3: {
    fontSize: "13px",
    letterSpacing: "0",
    lineHeight: "20px"
  },
  p4: {
    fontSize: "10px",
    letterSpacing: "0.12px",
    lineHeight: "16px"
  },
  button2: {
    fontSize: "15px",
    letterSpacing: "0",
    lineHeight: "24px",
    fontWeight: "500"
  },
  button3: {
    fontSize: "13px",
    letterSpacing: "-0.08px",
    lineHeight: "24px",
    fontWeight: "500"
  }
};

const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    { className, variant, color, ...props }: TypographyProps,
    ref
  ): JSX.Element => {
    className = useMemo(() => {
      const classNames = className?.split(" ") ?? [];

      if (
        TITLES.some(titleVariant => titleVariant === variant) ||
        PARAGRAPHS.some(paragraphVariant => paragraphVariant === variant) ||
        BUTTONS.some(buttonVariant => buttonVariant === variant)
      ) {
        classNames.push(`MuiTypography-${variant}`);
      }

      if (color === "textTertiary") {
        classNames.push(`MuiTypography-${color}`);
      }

      return classNames.join(" ");
    }, [className, color, variant]);

    return (
      <StyledMuiTypography
        ref={ref}
        variant={variant ? VARIANT_MAP[variant] : undefined}
        className={className}
        color={color !== "textTertiary" ? color : undefined}
        {...props}
      />
    );
  }
);

Typography.displayName = "Typography";

export default Typography;

const StyledMuiTypography = styled(MuiTypography)`
  font-family: "Aeonik", "Roboto", "Helvetica", "Arial", sans-serif;

  &.MuiTypography-h1 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.h1 }}
  }
  &.MuiTypography-h2 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.h2 }}
  }
  &.MuiTypography-h3 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.h3 }}
  }
  &.MuiTypography-h4 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.h4 }}
  }

  &.MuiTypography-title1 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.title1 }}
  }
  &.MuiTypography-title2 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.title2 }}
  }
  &.MuiTypography-title3 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.title3 }}
  }
  &.MuiTypography-title4 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.title4 }}
  }

  &.MuiTypography-p1 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.p1 }}
  }
  &.MuiTypography-p2 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.p2 }}
  }
  &.MuiTypography-p3 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.p3 }}
  }
  &.MuiTypography-p4 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.p4 }}
  }
  &.MuiTypography-button2 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.button2 }}
  }
  &.MuiTypography-button3 {
    ${{ ...TYPOGRAPHY_BY_VARIANT.button3 }}
  }

  &.MuiTypography-colorTextPrimary {
    color: ${({ theme }) => theme.newTheme.text.primary};
  }

  &.MuiTypography-colorTextSecondary {
    color: ${({ theme }) => theme.newTheme.text.secondary};
  }

  /* TODO: replace after mui@5.0.0-beta.1 */
  &.MuiTypography-textTertiary {
    color: ${({ theme }) => theme.newTheme.text.tertiary};
  }
`;
