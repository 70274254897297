export const RE_CAPTCHA_CONTAINER_CLASS_NAME = "recaptcha-container";
export const RE_CAPTCHA_ERROR_SELECTOR = `.${RE_CAPTCHA_CONTAINER_CLASS_NAME} div`;
export const RE_CAPTCHA_BADGE_CLASS_NAME = "grecaptcha-badge";
export const RE_CAPTCHA_BADGE_SELECTOR = `.${RE_CAPTCHA_BADGE_CLASS_NAME}`;
export const DEFAULT_RE_CAPTCHA_DYNAMIC_SCRIPT_ID = "google-recaptcha-v3";
export const RE_CAPTCHA_CAN_NOT_CONNECT_TO_SERVICES_ERROR =
  "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge.";

export const RE_CAPTCHA_STATIC_SCRIPT_SELECTOR =
  "script[src^='https://www.gstatic.com/recaptcha/releases']";

export enum RE_CAPTCHA_DYNAMIC_SCRIPT_HOST_NAME {
  google = "google.com",
  reCaptchaNet = "recaptcha.net"
}

export enum RE_CAPTCHA_DYNAMIC_SCRIPT_FILE {
  public = "api.js",
  enterprise = "enterprise.js"
}
