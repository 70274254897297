import { useCallback } from "react";

import useCustomSnackbar from "~/components/providers/CustomSnackbarProvider/useCustomSnackbar";
import {
  CustomApolloError,
  CustomGraphQLError,
  TranslatedError
} from "~/declarations/errors";

import { isEmpty, isObject, isString } from "./common";

// TODO: use translated message here when backend errors translation system will be implemented
export const NETWORK_ERROR_MESSAGE = "Network error";

export const getCustomGraphQLErrorRequestStatus = (
  error: CustomApolloError
): number | null => {
  if (!isEmpty(error.graphQLErrors)) {
    const errorMessageObject = error.graphQLErrors[0];

    return errorMessageObject?.extensions?.errorCode ?? null;
  }

  return null;
};

export const getCustomGraphQLError = (
  error: CustomApolloError
): CustomGraphQLError | null => {
  if (!isEmpty(error.graphQLErrors)) {
    const errorMessageObject = error.graphQLErrors[0];

    return errorMessageObject;
  }

  return null;
};

export const getCustomApolloErrorMessage = (
  error: CustomApolloError
): string => {
  const graphQLError = getCustomGraphQLError(error);

  if (graphQLError) {
    return graphQLError.message;
  }

  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  return NETWORK_ERROR_MESSAGE;
};

export const isTranslatedError = (error: unknown): error is TranslatedError => {
  const translatedError = error as TranslatedError;

  return (
    isObject(translatedError) &&
    translatedError !== null &&
    isString(translatedError.key) &&
    ["object", "undefined"].includes(typeof translatedError.options)
  );
};

type UseErrorSnackbarResult = (error: CustomApolloError) => void;

export const useApolloErrorSnackbar = (): UseErrorSnackbarResult => {
  const { enqueueCustomSnackbar } = useCustomSnackbar();

  return useCallback(
    (error: CustomApolloError): void => {
      const errorMessage = getCustomApolloErrorMessage(error);
      enqueueCustomSnackbar(errorMessage, {
        variant: "error"
      });
    },
    [enqueueCustomSnackbar]
  );
};
