import MuiMenu from "@material-ui/core/Menu";
import styled from "styled-components";

const Menu = styled(MuiMenu)`
  .MuiList-root {
    padding: 16px;
  }
`;

export default Menu;
