import {
  ApolloQueryResult,
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  QueryLazyOptions
} from "@apollo/client";
import { useLazyQuery } from "@apollo/client/react/hooks";
import { useCallback, useMemo } from "react";

import {
  PaginationQuery,
  PaginationQueryNode,
  PaginationVariables
} from "~/declarations/pagination";

export interface UsePaginationResult<
  Query,
  QueryVariables extends PaginationVariables,
  Node
> {
  fetch: (options?: QueryLazyOptions<QueryVariables> | undefined) => void;
  result: LazyQueryResult<Query, QueryVariables>;
  fetchMore: (variables?: QueryVariables) => Promise<boolean>;
  items: Node[];
  refetch?: (variables?: QueryVariables) => Promise<ApolloQueryResult<Query>>;
  hasMore: boolean;
  loading: boolean;
  called: boolean;
  totalCount: number;
}

export const usePagination = <
  Query extends PaginationQuery<Key>,
  Key extends keyof Query,
  QueryVariables extends PaginationVariables
>(
  query: DocumentNode,
  queryKey: Key,
  options: LazyQueryHookOptions<Query, QueryVariables> = {}
): UsePaginationResult<
  Query,
  QueryVariables,
  PaginationQueryNode<Query, Key>
> => {
  const [fetch, result] = useLazyQuery<Query, QueryVariables>(query, options);
  const { data, fetchMore, refetch, loading, called } = result;
  const resultData = data?.[queryKey];

  const handleFetchMore = useCallback(
    async (variables?: QueryVariables): Promise<boolean> => {
      const hasNextPage = resultData?.pageInfo.hasNextPage;

      if (!hasNextPage) {
        return false;
      }

      const response = await fetchMore<Query, QueryVariables>({
        query,
        variables: {
          ...options.variables,
          after: resultData?.pageInfo.endCursor,
          first: options.variables?.first,
          count: options.variables?.count,
          ...(variables ?? {})
        } as Partial<QueryVariables>
      });

      return response.data?.[queryKey]?.pageInfo.hasNextPage === true;
    },
    [
      resultData?.pageInfo.endCursor,
      resultData?.pageInfo.hasNextPage,
      fetchMore,
      options.variables,
      query,
      queryKey
    ]
  );

  const items = useMemo(
    () =>
      resultData?.edges.map<NonNullable<Query[Key]>["edges"][number]["node"]>(
        edge => edge.node
      ) ?? [],
    [resultData?.edges]
  );

  const hasMore = resultData?.pageInfo?.hasNextPage ?? true;
  const totalCount = resultData?.totalCount ?? 0;

  return {
    fetch,
    items,
    hasMore,
    totalCount,
    fetchMore: handleFetchMore,
    result,
    refetch,
    loading,
    called
  };
};
