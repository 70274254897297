import { JsonParam, useQueryParam } from "next-query-params";
import { useCallback, useMemo } from "react";

import {
  DIALOG_CONFIG_MAP,
  DIALOGS_STATE_QUERY_PARAMETER
} from "~/components/providers/DialogProvider/constants";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  OpenDialogArguments,
  UpdateDialogRoutingStateHandler
} from "~/components/providers/DialogProvider/declarations/context";
import {
  DialogState,
  RoutingDialogsState
} from "~/components/providers/DialogProvider/declarations/state";
import {
  getRawDialogRoutingStateFromLocation,
  getValidRoutingDialogsState
} from "~/components/providers/DialogProvider/utils";

type UseDialogProviderRouterStateResult = {
  state: RoutingDialogsState<DIALOG_NAME>;
  resetState: () => void;
  setDialogClosed: (dialogName: DIALOG_NAME) => void;
  addDialog: (dialogState: DialogState<DIALOG_NAME>) => void;
  deleteDialog: (dialogName: DIALOG_NAME) => void;
  updateDialog: <Name extends DIALOG_NAME>(
    ...openDialogArguments: OpenDialogArguments<Name>
  ) => void;
};

const useDialogProviderRouterState = (): UseDialogProviderRouterStateResult => {
  const [dialogsQueryParameter, setDialogsQueryParameter] = useQueryParam<
    RoutingDialogsState<DIALOG_NAME> | undefined
  >(DIALOGS_STATE_QUERY_PARAMETER, JsonParam);

  const state = useMemo(
    () => getValidRoutingDialogsState(dialogsQueryParameter),
    [dialogsQueryParameter]
  );

  const setDialogClosed = useCallback(
    (dialogName: DIALOG_NAME) => {
      const indexOfDialogToClose = state.findIndex(
        ({ name }) => name === dialogName
      );

      if (indexOfDialogToClose === -1) {
        return;
      }

      const newState = [...state];

      newState[indexOfDialogToClose] = {
        ...state[indexOfDialogToClose],
        open: false
      };

      setDialogsQueryParameter(newState, "replaceIn");
    },
    [state, setDialogsQueryParameter]
  );

  const addDialog = useCallback(
    (dialogState: DialogState<DIALOG_NAME>) => {
      const newState = [...state, dialogState];

      setDialogsQueryParameter(newState, "replaceIn");
    },
    [state, setDialogsQueryParameter]
  );

  const deleteDialog = useCallback(
    (dialogName: DIALOG_NAME) => {
      const stateWithDeletedDialog = state.filter(
        ({ name }) => name !== dialogName
      );

      const newQueryParameter = stateWithDeletedDialog.length
        ? stateWithDeletedDialog
        : undefined;

      setDialogsQueryParameter(newQueryParameter, "replaceIn");
    },
    [state, setDialogsQueryParameter]
  );

  const updateDialog: UpdateDialogRoutingStateHandler = useCallback(
    <Name extends DIALOG_NAME>(
      ...openDialogArguments: OpenDialogArguments<Name>
    ) => {
      const dialogName = openDialogArguments[0];
      const dialogOptions = openDialogArguments[1];

      const config = DIALOG_CONFIG_MAP[dialogName];

      if (!config.saveInUrl) {
        return;
      }
      const rawDialogRoutingState = getRawDialogRoutingStateFromLocation();
      const routingDialogsState = getValidRoutingDialogsState(
        rawDialogRoutingState
      );

      const index = routingDialogsState.findIndex(
        ({ name }) => name === dialogName
      );

      if (index === -1 || !routingDialogsState[index].open) {
        return;
      }

      const newRoutingDialogsState = [...routingDialogsState];
      const newDialogState: DialogState<DIALOG_NAME> = {
        open: routingDialogsState[index].open,
        name: dialogName,
        options: dialogOptions
      };

      newRoutingDialogsState[index] = newDialogState;

      setDialogsQueryParameter(newRoutingDialogsState, "replaceIn");
    },
    [setDialogsQueryParameter]
  );

  const resetState = useCallback(() => {
    setDialogsQueryParameter(undefined);
  }, [setDialogsQueryParameter]);

  return {
    state,
    resetState,
    setDialogClosed,
    addDialog,
    deleteDialog,
    updateDialog
  };
};

export default useDialogProviderRouterState;
