import { gql } from "@apollo/client";

import {
  NFT_EDITION_FRAGMENT,
  NFT_EDITION_OWNER_FRAGMENT,
  NFT_EDITION_PROFIT_SHARE
} from "~/components/nft/graphql";
import { MY_WALLET_FRAGMENT } from "~/components/wallet/graphql";

export const NFT_INSTANCE_STATISTICS_FRAGMENT = gql`
  fragment NftInstanceStatistics on Nft {
    __typename
    id

    stats {
      minPrice
      maxPrice
      availableForSaleCount
      ownedByMe
    }
  }
`;

export const NFT_EDITION_UPDATED_FRAGMENT_NAME = "NftEditionUpdated";
export const NFT_EDITION_UPDATED_FRAGMENT = gql`
  fragment NftEditionUpdated on NftEdition {
    __typename
    id
    status
    cost
    charitable
    owner {
      ...NftEditionOwner
    }
    profitShares {
      ...NftEditionProfitShare
    }
  }

  ${NFT_EDITION_OWNER_FRAGMENT}
  ${NFT_EDITION_PROFIT_SHARE}
`;

export const NFT_INSTANCE_AND_EDITION_UPDATED_DATA_QUERY = gql`
  query NftInstanceAndEditionUpdatedData($nftId: String!, $editionId: String) {
    getNft(nftId: $nftId) {
      ...NftInstanceStatistics

      edition(id: $editionId) {
        ...NftEditionUpdated
        number
        cost
        profitShares {
          ...NftEditionProfitShare
        }
      }

      defaultEdition {
        ...NftEditionFragment
      }
    }
  }

  ${NFT_INSTANCE_STATISTICS_FRAGMENT}
  ${NFT_EDITION_FRAGMENT}
  ${NFT_EDITION_UPDATED_FRAGMENT}
  ${NFT_EDITION_PROFIT_SHARE}
`;

export const WALLET_EVENTS_SUBSCRIPTION = gql`
  subscription WalletEvents($offset: String) {
    myWalletEvents(offset: $offset) {
      offset
      events {
        __typename

        ... on EventNftMinted {
          nftId
        }

        ... on EventPostWasBought {
          postId
          postType
          cost
        }

        ... on EventTransactionConfirmed {
          wallet {
            ...MyWalletFragment
          }
          transaction {
            reason
            reasonId
            balance
            content {
              __typename

              ... on TransactionContentShortStream {
                postId
              }

              ... on TransactionContentLongStream {
                postId
              }
            }
          }
          nft {
            id
            editionId
          }
        }
      }
    }
  }

  ${MY_WALLET_FRAGMENT}
`;
