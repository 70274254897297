import styled from "styled-components";

import AccountHeaderMessaging from "../AccountHeaderMessaging";
import AccountHeaderNotifications from "../common/AccountHeaderNotifications";

export interface Props {
  hasUnreadNotifications: boolean;
  className?: string;
}

const AccountHeaderMobileActivity = ({
  hasUnreadNotifications,
  className
}: Props): JSX.Element => (
  <Wrapper className={className}>
    <AccountHeaderMessaging />
    <NotificationsWrapper>
      <AccountHeaderNotifications
        hasUnreadNotifications={hasUnreadNotifications}
      />
    </NotificationsWrapper>
  </Wrapper>
);

export default AccountHeaderMobileActivity;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
`;

const NotificationsWrapper = styled.div`
  margin-left: 8px;
`;
