import { gql } from "@apollo/client";

export const SAVE_WALLET_KEY_MUTATION = gql`
  mutation SaveWalletKey($key: String!) {
    saveWalletKey(key: $key)
  }
`;
export const SEND_SIGNED_TRANSACTION_MUTATION = gql`
  mutation SendSignedTransaction($transaction: String!) {
    sendSignedTransaction(transaction: $transaction)
  }
`;
export const WALLET_KEY_QUERY = gql`
  query WalletKey($id: String!, $pin: String) {
    getWalletKey(id: $id, pin: $pin)
  }
`;

export const MY_WALLET_FRAGMENT = gql`
  fragment MyWalletFragment on Wallet {
    __typename
    address
    balanceInUdoo
  }
`;

export const MY_WALLET_QUERY = gql`
  query MyWallet {
    myWallet {
      ...MyWalletFragment
    }
  }

  ${MY_WALLET_FRAGMENT}
`;

export const SAVE_PUBLIC_KEY_MUTATION = gql`
  mutation SavePublicKey($address: String!, $publicKey: String!) {
    savePublicKey(address: $address, publicKey: $publicKey)
  }
`;

export const GET_WALLET_STATUS_QUERY = gql`
  query GetWalletStatus($personId: String!) {
    getWalletStatus(personId: $personId)
  }
`;

export const SEND_VERIFICATION_CODE_MUTATION = gql`
  mutation SendVerificationCode($action: VerificationAction!, $email: String) {
    sendVerificationCode(action: $action, email: $email) {
      id
      timeout
    }
  }
`;

const TRANSACTION_FRAGMENT = gql`
  fragment Transaction on Transaction {
    __typename
    id
    balance
    content {
      __typename

      ... on TransactionContentNft {
        nftId
      }

      ... on TransactionContentNftEdition {
        nftId
        editionId
      }

      ... on TransactionContentShortPost {
        postId
      }

      ... on TransactionContentLongPost {
        postId
      }

      ... on TransactionContentShortStream {
        postId
      }

      ... on TransactionContentLongStream {
        postId
      }
    }
    date
    type
    category
    sender {
      id
      name
      nickname
      isAnonymous
      isFounder
      isCharity
      validation
      status
      avatar {
        little
        middle
      }
    }
    receiver {
      id
      name
      nickname
      isAnonymous
      isFounder
      isCharity
      validation
      status
      avatar {
        little
        middle
      }
    }
  }
`;

export const LAST_TRANSACTIONS_QUERY = gql`
  query LastTransactions(
    $filter: TransactionFilter!
    $order: TransactionsOrder
    $direction: OrderDirection
    $first: Int
    $after: String
  ) {
    myTransactionsNew(
      filter: $filter
      order: $order
      direction: $direction
      first: $first
      after: $after
    ) @connection(key: "myTransactionsNew", filter: ["filter"]) {
      edges {
        node {
          ...Transaction
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }

  ${TRANSACTION_FRAGMENT}
`;

export const MY_TRANSACTIONS_WITH_DETAILS_QUERY = gql`
  query MyTransactionsWithDetails(
    $filter: TransactionFilter!
    $order: TransactionsOrder
    $direction: OrderDirection
    $first: Int
    $after: String
  ) {
    myTransactionsWithDetails: myTransactionsNew(
      filter: $filter
      order: $order
      direction: $direction
      first: $first
      after: $after
    ) @connection(key: "myTransactionsWithDetails", filter: ["filter"]) {
      edges {
        node {
          ...Transaction
          balanceDetails {
            type
            rate
            amount
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }

  ${TRANSACTION_FRAGMENT}
`;

export const SAVE_WALLET_MUTATION = gql`
  mutation SaveWallet(
    $address: String!
    $publicKey: String!
    $key: String!
    $pin: String!
  ) {
    saveWallet(address: $address, publicKey: $publicKey, key: $key, pin: $pin)
  }
`;

export const RESTORE_WALLET_MUTATION = gql`
  mutation RestoreWallet(
    $address: String!
    $publicKey: String!
    $key: String!
    $pin: String!
  ) {
    restoreWallet(
      address: $address
      publicKey: $publicKey
      key: $key
      pin: $pin
    )
  }
`;
