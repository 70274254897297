import { AvatarProps, default as MuiAvatar } from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import NextLink from "next/link";
import { useMemo } from "react";
import styled from "styled-components";

import { ACCOUNT_TYPE } from "~/declarations/person";
import Anonymous from "~/public/assets/icons/placeholder/Anonymous.svg?sprite";
import Profile from "~/public/assets/icons/ProfileGradient.svg?sprite";
import LogoGradient from "~/public/assets/images/LogoGradient.svg?sprite";
import UserDeletedRounded from "~/public/assets/images/UserDeletedRounded.svg?sprite";
import { BLACK, WHITE } from "~/theme/colors";
import { omitStyleProps } from "~/utils/styled-components";

const AVATAR_SIZE = {
  small: 24,
  medium: 32,
  large: 40,
  xLarge: 48
};

export type AvatarSize = keyof typeof AVATAR_SIZE;

type Props = Omit<AvatarProps, "children"> & {
  size?: AvatarSize;
  loading?: boolean;
  fallback?: React.ReactNode;
  href?: string;
  accountType?: ACCOUNT_TYPE;
};

const Avatar = ({
  src,
  href,
  size = "medium",
  loading = false,
  fallback,
  accountType = ACCOUNT_TYPE.common,
  ...props
}: Props): JSX.Element => {
  const avatarFallback = useMemo(() => {
    switch (accountType) {
      case ACCOUNT_TYPE.deleted:
        return (
          <LightIconWrapper>
            <UserDeletedRounded />
          </LightIconWrapper>
        );
      case ACCOUNT_TYPE.system:
        return (
          <SystemAccountWrapper avatarSize={AVATAR_SIZE[size]}>
            <LogoGradient />
          </SystemAccountWrapper>
        );
      case ACCOUNT_TYPE.anonymous:
        return (
          <LightIconWrapper>
            <Anonymous />
          </LightIconWrapper>
        );
      case ACCOUNT_TYPE.common:
        return (
          fallback || (
            <DarkIconWrapper>
              <Profile />
            </DarkIconWrapper>
          )
        );
    }
  }, [accountType, size, fallback]);

  const content = (
    <StyledMuiAvatar
      {...props}
      color="primary"
      src={src}
      size={size}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={href ? "a" : "div"}
    >
      {avatarFallback}
    </StyledMuiAvatar>
  );

  if (loading) {
    return (
      <Skeleton
        variant="circle"
        animation={false}
        width={AVATAR_SIZE[size]}
        height={AVATAR_SIZE[size]}
      />
    );
  }

  if (href) {
    return (
      <NextLink href={href} passHref>
        {content}
      </NextLink>
    );
  }

  return content;
};

export default Avatar;

const StyledMuiAvatar = styled(MuiAvatar).withConfig<{ size: AvatarSize }>(
  omitStyleProps(["size"])
)`
  display: inline-flex;
  flex-shrink: 0;
  height: ${({ size }) => `${AVATAR_SIZE[size]}px`};
  position: relative;
  width: ${({ size }) => `${AVATAR_SIZE[size]}px`};

  &.MuiAvatar-colorDefault {
    background-color: ${({ theme }) => theme.newTheme.background.primary};
  }

  svg {
    width: 100%;
  }
`;

const SystemAccountWrapper = styled.div<{ avatarSize: number }>`
  background-color: ${BLACK};
  border-radius: 50%;
  display: flex;

  svg {
    height: ${({ avatarSize }) => avatarSize * 0.7}px;
    margin: auto;
  }
`;

const DarkIconWrapper = styled.div`
  background-color: ${BLACK};
  display: flex;
`;

const LightIconWrapper = styled.div`
  background-color: ${WHITE};
  display: flex;
`;
