import { mixed, object, SchemaOf, string } from "yup";

import {
  AUTHENTICATION_METHOD,
  SocialSignupRecord
} from "~/declarations/authentication";

const SOCIAL_SIGNUP_RECORD_LOCAL_STORAGE_KEY = "social-signup-record";

const SOCIAL_SIGNUP_RECORD_LOCAL_STORAGE_SCHEMA: SchemaOf<SocialSignupRecord> =
  object({
    id: string().required(),
    firstName: string().defined(),
    lastName: string().defined(),
    email: string().required(),
    method: mixed()
      .oneOf([AUTHENTICATION_METHOD.apple, AUTHENTICATION_METHOD.google])
      .required()
  })
    .required()
    .strict(true);

export const getSocialSignupRecordFromLocalStorage =
  (): SocialSignupRecord | null => {
    try {
      const recordAsString = localStorage.getItem(
        SOCIAL_SIGNUP_RECORD_LOCAL_STORAGE_KEY
      );

      const record = recordAsString ? JSON.parse(recordAsString) : null;

      if (
        !record ||
        !SOCIAL_SIGNUP_RECORD_LOCAL_STORAGE_SCHEMA.isValidSync(record)
      ) {
        return null;
      }

      return record;
    } catch {
      return null;
    }
  };

export const setSocialSignupRecordToLocalStore = (
  record: SocialSignupRecord
): void => {
  const recordAsJSON = JSON.stringify(record);

  localStorage.setItem(SOCIAL_SIGNUP_RECORD_LOCAL_STORAGE_KEY, recordAsJSON);
};

export const removeSocialSignupRecordFromLocalStore = (): void => {
  try {
    localStorage.removeItem(SOCIAL_SIGNUP_RECORD_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error(error);
  }
};
