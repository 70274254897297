import {
  ApolloClient,
  DataProxy,
  defaultDataIdFromObject
} from "@apollo/client";
import { useCallback } from "react";

import { Post_getPost } from "~/declarations/apollo/Post";

import { fetchPostById } from "../posts";
import { POST_FRAGMENT, POST_FRAGMENT_NAME } from "../posts/graphql";

type UpdatePostHandler = (postId: string) => Promise<boolean>;

export const updatePostInCache = async (
  store: DataProxy,
  post: Post_getPost
): Promise<void> => {
  const cacheId = defaultDataIdFromObject({
    id: post.id,
    __typename: post.__typename
  });

  try {
    store.writeFragment({
      fragment: POST_FRAGMENT,
      fragmentName: POST_FRAGMENT_NAME,
      id: cacheId,
      data: post
    });
  } catch (error) {
    console.error(error);
  }
};

const usePostUpdate = (client: ApolloClient<unknown>): UpdatePostHandler => {
  const handlePostUpdate = useCallback(
    async (postId: string): Promise<boolean> => {
      let post;

      try {
        post = await fetchPostById(client, postId, "network-only");
      } catch (error) {
        console.error(error);

        return false;
      }

      if (post) {
        updatePostInCache(client, post);
        return true;
      }

      return false;
    },
    [client]
  );

  return handlePostUpdate;
};

export default usePostUpdate;
