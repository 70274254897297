import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { forwardRef, ReactNode } from "react";

import Link, { LinkProps } from "~/components/core/Link/Link";

export type TextLinkProps = Omit<LinkProps, "href"> & {
  href: NextLinkProps["href"];
  children?: ReactNode;
};

const TextLink = forwardRef<HTMLLinkElement, TextLinkProps>(
  ({ children, href, ...otherProps }: TextLinkProps, ref): JSX.Element => (
    <NextLink passHref href={href}>
      <Link ref={ref} {...otherProps}>
        {children}
      </Link>
    </NextLink>
  )
);

TextLink.displayName = "TextLink";

export default TextLink;
