import { COMMON_DIALOG_STATUS } from "~/components/providers/DialogProvider/declarations/common";
import { PostType } from "~/declarations/apollo/globalTypes";
import { Post } from "~/declarations/post";
import { ProgressOfTransaction } from "~/declarations/purchases";

type RunAfterWalletAvailableGuardPayloadSuccessHandlerProps = {
  newWalletHasBeenCreated: boolean;
};

export type RunAfterWalletAvailableGuardPayloadSuccessHandler =
  | ((
      props: RunAfterWalletAvailableGuardPayloadSuccessHandlerProps
    ) => Promise<void>)
  | ((props: RunAfterWalletAvailableGuardPayloadSuccessHandlerProps) => void);

export type RunAfterWalletAvailableGuardPayload = {
  onSuccess: RunAfterWalletAvailableGuardPayloadSuccessHandler;
  reason: WALLET_DIALOG_CALL_REASON;
  ignoreWalletKeyCheck?: boolean;
  redirectUrlAfterBuyingCoins?: string;
};

export type RunAfterWalletAvailableGuard = (
  payload: RunAfterWalletAvailableGuardPayload
) => Promise<void>;

type WalletCommonResolveData = {
  status: COMMON_DIALOG_STATUS;
};

export type StartCreateWalletFlowResolveData = WalletCommonResolveData & {
  newWalletHasBeenCreated: boolean;
};
export type StartCreateWalletKeyFlowResolveData = WalletCommonResolveData & {
  newWalletHasBeenCreated: boolean;
};
export type WarnAboutWalletKeyDropResolveData = WalletCommonResolveData & {
  newWalletHasBeenCreated: boolean;
};

export type WalletCreateFlowHandler = (
  reason: WALLET_DIALOG_CALL_REASON
) => Promise<StartCreateWalletFlowResolveData>;
export type WalletCreateKeyFlowHandler = (
  reason: WALLET_DIALOG_CALL_REASON
) => Promise<StartCreateWalletKeyFlowResolveData>;

type WarnAboutWalletKeyDropPayload = {
  reason: WALLET_DIALOG_CALL_REASON;
};

export type WarnAboutWalletKeyDropHandler = (
  payload?: WarnAboutWalletKeyDropPayload
) => Promise<WarnAboutWalletKeyDropResolveData>;

export enum WALLET_DIALOG_CALL_REASON {
  createWallet,
  walletKeyDropping,
  postTipping,
  postPurchase,
  postCreation,
  nftCreation,
  nftPurchase,
  nftSetForSale,
  coinsSendingViaWidget,
  streamDonation,
  pinResetting,
  payout,
  coinsPurchase,
  showMnemonic,
  walletSynchronization,
  xdbNftImport,
  xdbNftExport
}

export interface WalletBuyCoinsDialogPayloadPost {
  id: Post["id"];
  type: PostType;
}
export interface WalletBuyCoinsDialogPayload {
  post?: WalletBuyCoinsDialogPayloadPost;
  redirectUrlAfterBuyingCoins?: string;
}

export enum TRANSACTION_DONE_REASON {
  timeout,
  success
}

export type TransactionDoneHandler = (
  progress: ProgressOfTransaction,
  reason: TRANSACTION_DONE_REASON,
  remove: () => void
) => boolean;

export type PostPurchaseLinkClickHandler = (postId: string) => boolean;
