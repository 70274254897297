import { useEffect, useState } from "react";

import { EVENTS } from "~/constants/events";

const SCROLL_OVERFLOW_THRESHOLD = 0;

export function useScrollDetect(): boolean {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = (): void => {
      const pageYOffset = window.pageYOffset;
      const thresholdPassed = pageYOffset > SCROLL_OVERFLOW_THRESHOLD;

      if (thresholdPassed && !scrolled) {
        setScrolled(true);
      } else if (scrolled && pageYOffset <= SCROLL_OVERFLOW_THRESHOLD) {
        setScrolled(false);
      }
    };

    window.addEventListener(EVENTS.scroll, handleScroll);

    return (): void => {
      window.removeEventListener(EVENTS.scroll, handleScroll);
    };
  }, [scrolled]);

  return scrolled;
}
