export const ANALYTICS_ACTION_IDS = {
  login: "login",
  signup: "signup",
  usernameReservation: "username-reservation",
  selectLoginMethod: "select-login-method",
  selectSignupMethod: "select-signup-method",
  exploreApp: "explore-app",
  startPostCreation: "start-post-creation",
  publishPost: "publish-post",
  startCoinsPurchasing: "start-coins-purchasing",
  coinsPurchasing: "coins-purchasing",
  startCashOut: "start-cash-out",
  startLogin: "start-login",
  startSignup: "start-signup"
};
