export enum WalletSynchronizationPinDialogSuccessResult {
  walletCreated = "walletCreated",
  mnemonicErrorWithRetry = "mnemonicErrorWithRetry",
  pinErrorWithRetry = "pinErrorWithRetry",
  fatalError = "fatalError",
  walletNotExists = "walletNotExists",
  walletKeyNotExists = "walletKeyNotExists",
  walletAlreadyExistsButKeyNotFound = "walletAlreadyExistsButKeyNotFound",
  walletAlreadySynched = "walletAlreadySynched"
}

export type WalletSynchronizationPinDialogSuccessData =
  | {
      reason: WalletSynchronizationPinDialogSuccessResult.walletCreated;
    }
  | {
      reason: Exclude<
        WalletSynchronizationPinDialogSuccessResult,
        WalletSynchronizationPinDialogSuccessResult.walletCreated
      >;
      message: string;
    };

export type SpecificWalletSynchronizationPinDialogOptions = {
  mnemonic: string;
};
