import { THEME_MODE } from "~/constants/theme";

import { createMuiThemeFromStyledComponentsTheme } from "./createMuiThemeFromStyledComponentsTheme";
import { DARK_STYLED_COMPONENTS_THEME as DARK_STYLED_COMPONENTS_THEME } from "./dark";
import { LIGHT_STYLED_COMPONENTS_THEME as LIGHT_STYLED_COMPONENTS_THEME } from "./light";

export const THEME_BY_TYPE = {
  [THEME_MODE.light]: {
    styledComponentsTheme: LIGHT_STYLED_COMPONENTS_THEME,
    muiTheme: createMuiThemeFromStyledComponentsTheme(
      LIGHT_STYLED_COMPONENTS_THEME
    )
  },
  [THEME_MODE.dark]: {
    styledComponentsTheme: DARK_STYLED_COMPONENTS_THEME,
    muiTheme: createMuiThemeFromStyledComponentsTheme(
      DARK_STYLED_COMPONENTS_THEME
    )
  }
};
