export enum KEY {
  arrowUp = "ArrowUp",
  arrowDown = "ArrowDown"
}

export enum KEY_CODES {
  enter = "Enter",
  arrowRight = "ArrowRight",
  arrowLeft = "ArrowLeft"
}

export enum KEY_PHYSICAL_CODE {
  esc = 27,
  up = 38,
  down = 40,
  left = 37,
  right = 39,
  enter = 13,
  backspace = 8,
  tab = 9,
  letterE = 69,
  dash = 189,
  period = 190
}
