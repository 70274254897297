import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

import {
  UserInterestsDialogCloseHandler,
  UserInterestsOpenDialogHandler
} from "./declarations";

export interface GlobalContextProps {
  greeting: {
    needToShow: boolean;
    setNeedToShow?: React.Dispatch<React.SetStateAction<boolean>>;
  };
  userInterestsDialog: {
    open: boolean;
    needToShow: boolean;
    cancelLabel?: string;
    submitLabel?: string;
    setNeedToShow: (needToShow: boolean) => void;
    openDialog: UserInterestsOpenDialogHandler;
    closeDialog: UserInterestsDialogCloseHandler;
  };
  cookiePolicy: {
    needToShow: boolean;
    accepted: boolean;
    showPopup: (personId?: string) => void;
    closePopup: () => void;
    onAccept: (personId?: string) => void;
  };
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

const useGlobalContext = createUseContext(GlobalContext, "GlobalContext");

export default GlobalContext.Provider;
export { useGlobalContext };
