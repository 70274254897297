import { ImageSize } from "~/components/meta/declarations";

export const POST_SHARING_IMAGES_COUNT = 3;

export const AVATAR_IMAGE_SIZE: ImageSize = {
  width: 900,
  height: 900
};

export const DEFAULT_PROFILE_SHARING_IMAGE = "";

const DEFAULT_META_IMG_SIZE = 600;
const DEFAULT_META_IMG_PATH = "/assets/images/LogoMeta.png";

export const DEFAULT_META_IMAGES = [
  {
    url: `${process.env.NEXT_PUBLIC_HOST_URL}${DEFAULT_META_IMG_PATH}`,
    width: DEFAULT_META_IMG_SIZE,
    height: DEFAULT_META_IMG_SIZE
  }
];

const META_LOCALE_BY_NEXT_LOCALE: { [key: string]: string } = {
  en: "en_US"
};

export const getMetaLocaleByNextLocale = (locale?: string): string => {
  if (locale && META_LOCALE_BY_NEXT_LOCALE.hasOwnProperty(locale)) {
    return META_LOCALE_BY_NEXT_LOCALE[locale];
  }
  return META_LOCALE_BY_NEXT_LOCALE.en;
};
