import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ButtonHTMLAttributes, useCallback } from "react";
import styled from "styled-components";

import AppPatternBackgroundForDialog from "~/components/core/AppPatternBackground/AppPatternBackgroundForDialog";
import Avatar from "~/components/core/Avatar";
import { AvatarSize } from "~/components/core/Avatar/Avatar";
import Button from "~/components/core/Button";
import { ButtonProps } from "~/components/core/Button/Button";
import { ConfirmationDialogWrapper } from "~/components/core/ConfirmationDialog/styled";
import Divider from "~/components/core/Divider";
import { IconVariant } from "~/components/core/Icon/declarations";
import IconButton from "~/components/core/IconButton";
import {
  COMMON_DIALOG_CLOSE_REASON,
  COMMON_DIALOG_ERROR_CODE
} from "~/components/providers/DialogProvider/declarations/common";
import { CommonDialogCloseReasonWithError } from "~/components/providers/DialogProvider/declarations/results";

import IconAvatar from "../IconAvatar";
import Typography from "../Typography";

type TransitionDuration =
  | number
  | { appear?: number; enter?: number; exit?: number };

const ICON_AVATAR_HEIGHT = 56;

interface Props {
  className?: string;
  open: boolean;
  icon?: IconVariant;
  avatar?: string;
  avatarSize?: AvatarSize;
  caption?: React.ReactNode;
  description?: React.ReactNode;
  confirmText: string;
  confirmDisabled?: boolean;
  confirmButtonType?: ButtonHTMLAttributes<unknown>["type"];
  confirmTabIndex?: number;
  cancelText?: string;
  cancelButtonVariant?: ButtonProps["variant"];
  cancelTabIndex?: number;
  formId?: string;
  confirmId?: string;
  cancelId?: string;
  onClose?: (
    closeReason: CommonDialogCloseReasonWithError<COMMON_DIALOG_ERROR_CODE>
  ) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  onEnter?: () => void;
  onExited?: () => void;
  children?: React.ReactNode;
  autoFocusConfirmButton?: boolean;
  transitionDuration?: TransitionDuration;
  hideFooter?: boolean;
  order?: number;
}

const ConfirmationDialog = ({
  className,
  open,
  icon,
  avatar,
  avatarSize,
  caption,
  description,
  confirmText,
  confirmDisabled = false,
  confirmButtonType = "submit",
  confirmTabIndex,
  cancelText,
  cancelTabIndex,
  cancelButtonVariant = "outlined",
  formId,
  confirmId,
  cancelId,
  children,
  autoFocusConfirmButton,
  hideFooter,
  transitionDuration,
  order = 0,
  onClose,
  onConfirm,
  onEnter,
  onExited,
  onCancel = () =>
    onClose?.({
      closeReason: COMMON_DIALOG_CLOSE_REASON.cancelButton
    })
}: Props): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickCloseButton = useCallback((): void => {
    onClose?.({ closeReason: COMMON_DIALOG_CLOSE_REASON.closeButton });
  }, [onClose]);

  return (
    <ConfirmationDialogWrapper
      open={open}
      order={order}
      onClose={onClose}
      TransitionProps={{
        onEnter,
        onExited
      }}
      transitionDuration={transitionDuration}
      fullScreen={fullScreen}
      className={className}
    >
      <AppPatternBackgroundForDialog />
      <CloseButton onClick={handleClickCloseButton} />
      <CenteredDialogContent>
        {icon && <StyledIconAvatar icon={icon} size={ICON_AVATAR_HEIGHT} />}
        {avatar !== undefined && <Avatar src={avatar} size={avatarSize} />}
        {caption && <Caption>{caption}</Caption>}
        {description && <Description>{description}</Description>}
        {children}
      </CenteredDialogContent>
      {!hideFooter && (
        <Footer>
          <FooterDivider />
          <DialogFooter>
            {cancelText && (
              <CancelButton
                analyticsId={cancelId}
                variant={cancelButtonVariant}
                onClick={onCancel}
                tabIndex={cancelTabIndex}
              >
                {cancelText}
              </CancelButton>
            )}
            <ConfirmButton
              analyticsId={confirmId}
              form={formId}
              tabIndex={confirmTabIndex}
              type={confirmButtonType}
              disabled={confirmDisabled}
              variant="contained"
              onClick={onConfirm}
              autoFocus={autoFocusConfirmButton}
            >
              {confirmText}
            </ConfirmButton>
          </DialogFooter>
        </Footer>
      )}
    </ConfirmationDialogWrapper>
  );
};

export default ConfirmationDialog;

export const CenteredDialogContent = styled(DialogContent)`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: auto;
  padding: 0 40px;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    justify-content: center;
  }
`;

export const DialogFooter = styled(DialogActions)`
  align-items: center;
  justify-content: center;
  padding: 0;

  & > * {
    flex: 1;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export const Caption = styled(Typography).attrs({
  variant: "h2",
  color: "textPrimary"
})`
  margin-bottom: 8px;
`;

export const Description = styled(Typography).attrs({
  variant: "p2",
  color: "textPrimary"
})`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow-x: hidden;
  max-width: 100%;
`;

const CancelButton = styled(Button).attrs({
  size: "x-large"
})`
  max-width: 233px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: unset;
  }
`;

const ConfirmButton = styled(Button).attrs({
  size: "x-large"
})`
  max-width: 233px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: unset;
  }
`;

export const CloseButton = styled(IconButton).attrs({
  icon: "close"
})`
  position: absolute;
  z-index: 2;
  top: 24px;
  right: 24px;

  @media (max-height: 499px) {
    top: 12px;
    right: 12px;
    padding: 5px;

    & svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const FooterDivider = styled(Divider)`
  margin: 16px 0;
`;

const StyledIconAvatar = styled(IconAvatar)`
  color: ${({ theme }) => theme.newTheme.background.primary};
  margin: 0 auto 24px;
  min-height: ${ICON_AVATAR_HEIGHT}px;
`;

export const Footer = styled.div`
  min-height: 129px;
  padding: 0 40px 40px;
`;
