import { ApolloLink } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";

import { NETWORK_CODES } from "~/constants/apiInteraction";
import AuthService from "~/services/AuthService";
import { isServerSide } from "~/utils/common";

import { OperationQueuing } from "./queue";

const REFRESH_TOKEN_MISSED_ERROR_STATUS = "refreshTokenIsAbsent";

/*
  This link doesn't refresh apollo subscription after the second refresh token error (after network error and ws closing).
  This is because the errors from the new response from retrying the request does not get passed into the error handler again.

  TODO: find a way to call this link after a retry
*/
const tokenRefreshLink = (): ApolloLink => {
  const pendingRequests = new OperationQueuing();
  let isRefreshing = false;

  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (isServerSide()) {
      return forward(operation);
    }

    /* Will be handled in catch block */
    if (operation.operationName === "Logout") {
      return;
    }

    const accessError = graphQLErrors?.some(
      gqlError => gqlError.extensions?.errorCode === NETWORK_CODES.unauthorized
    );

    const refreshError = graphQLErrors?.some(
      gqlError =>
        gqlError.extensions?.status === REFRESH_TOKEN_MISSED_ERROR_STATUS
    );

    if (!accessError) {
      if (networkError && !refreshError) {
        console.error(`[Network error]: ${networkError}`);
        // if we need automatically retrying of request we can try
        // apollo-link-retry
      }

      return;
    }

    if (!isRefreshing) {
      isRefreshing = true;

      AuthService.refreshToken().then(refreshed => {
        if (!refreshed) {
          AuthService.logout();
        }

        pendingRequests.consumeQueue();

        isRefreshing = false;
      });
    }

    return pendingRequests.enqueueRequest({
      operation,
      forward
    });
  });
};

export default tokenRefreshLink;
