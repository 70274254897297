/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "Logo-3b4db009--sprite",
  "use": "Logo-3b4db009--sprite-usage",
  "viewBox": "0 0 24 24",
  "content": "<symbol xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" id=\"Logo-3b4db009--sprite\"><path clip-rule=\"evenodd\" d=\"m13.796 1.076-4.216 3.1v11.163c0 .327.363.516.623.325l4.216-3.1V1.402a.392.392 0 0 0-.623-.325zm2.4 18.031 3.373-2.478c.27-.199.43-.518.431-.857V5.159a.393.393 0 0 0-.623-.324l-3.18 2.337v11.935zM4 22.451l3.373-2.479c.27-.199.43-.517.43-.857V6.27a.393.393 0 0 0-.622-.324L4 8.282V22.45zm5.58-4.102 4.216-3.099a.392.392 0 0 1 .623.325v4.837l-4.216 3.1a.392.392 0 0 1-.623-.325v-4.838z\" /></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
