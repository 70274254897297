import { ApolloClient, defaultDataIdFromObject } from "@apollo/client";

import {
  PEOPLE_TO_SHOW_IN_NOTIFICATION_HEADER,
  RAW_NOTIFICATION_TYPE
} from "~/components/notifications/constants";
import {
  NOTIFICATION_FRAGMENT,
  NOTIFICATION_FRAGMENT_NAME,
  NOTIFICATIONS_QUERY
} from "~/components/notifications/graphql";
import { APOLLO_PAGINATION_CLIENT_CURSOR } from "~/constants/pagination";
import { ActionType, ActivityType } from "~/declarations/apollo/globalTypes";
import { Notification as NotificationFragment } from "~/declarations/apollo/Notification";
import { NotificationEvents_activityEvents_events_EventActivityUnstacked_otherPerson } from "~/declarations/apollo/NotificationEvents";
import {
  Notifications,
  Notifications_notifications_edges_node
} from "~/declarations/apollo/Notifications";
import {
  NotificationQuery,
  NotificationsQueryVariables
} from "~/declarations/notifications";
import {
  deleteNodesFromListInCache,
  updateNodeInCache
} from "~/utils/apollo/pagination";

const EMPTY_NOTIFICATION_QUERY_DATA: Notifications["notifications"] = {
  edges: [],
  pageInfo: {
    endCursor: "",
    hasNextPage: true
  },
  totalCount: 0
};

export const addNotificationToCache = <
  Query extends NotificationQuery<QueryKey>,
  QueryKey extends keyof Query
>(
  client: ApolloClient<unknown>,
  notification: Notifications_notifications_edges_node,
  activityType: ActivityType
): void => {
  let notificationsQueryResult: Notifications | null = null;
  const variables: NotificationsQueryVariables = {
    activityType
  };

  try {
    notificationsQueryResult = client.readQuery<
      Notifications,
      NotificationsQueryVariables
    >({
      query: NOTIFICATIONS_QUERY,
      variables
    });
  } catch (error) {
    console.error(error);
    return;
  }

  const queryData: Notifications["notifications"] = notificationsQueryResult
    ? notificationsQueryResult.notifications
    : EMPTY_NOTIFICATION_QUERY_DATA;

  try {
    client.writeQuery<Notifications, NotificationsQueryVariables>({
      query: NOTIFICATIONS_QUERY,
      variables,
      data: {
        notifications: {
          ...queryData,
          edges: [
            {
              cursor: APOLLO_PAGINATION_CLIENT_CURSOR,
              node: notification
            },
            ...queryData.edges
          ],
          totalCount: queryData ? queryData.totalCount + 1 : 1
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateOrAddNotificationToCache = (
  client: ApolloClient<unknown>,
  notification: Notifications_notifications_edges_node,
  activityType = ActivityType.notifications
): void => {
  try {
    const notificationsQueryResult = client.readQuery<
      Notifications,
      NotificationsQueryVariables
    >({
      query: NOTIFICATIONS_QUERY,
      variables: {
        activityType
      }
    });

    if (!notificationsQueryResult) {
      return;
    }

    const edges = notificationsQueryResult.notifications.edges ?? [];
    const index = edges.findIndex(edge => edge.node.id === notification.id);
    const edgeToReplace = edges[index];

    if (index === -1) {
      addNotificationToCache(client, notification, activityType);
      return;
    }

    client.writeQuery<Notifications, NotificationsQueryVariables>({
      query: NOTIFICATIONS_QUERY,
      variables: {
        activityType
      },
      data: {
        notifications: {
          ...notificationsQueryResult.notifications,
          edges: [
            ...edges.slice(0, index),
            {
              ...edgeToReplace,
              node: notification
            },
            ...edges.slice(index + 1)
          ]
        }
      }
    });
  } catch (error) {
    console.error(error);
    return;
  }
};

export const updateNotificationActionTypeInCache = (
  client: ApolloClient<unknown>,
  notificationId: NotificationFragment["id"],
  actionType: ActionType
): void => {
  const queryKey = "notifications";
  const variables: NotificationsQueryVariables = {
    activityType: ActivityType.notifications
  };

  try {
    updateNodeInCache<
      Notifications,
      "notifications",
      NotificationsQueryVariables
    >(
      {
        ids: notificationId,
        query: NOTIFICATIONS_QUERY,
        queryKey,
        queryVariables: variables,
        store: client
      },
      (
        node: Notifications_notifications_edges_node
      ): Notifications_notifications_edges_node => {
        if (node.__typename !== RAW_NOTIFICATION_TYPE.PersonFollowRequest) {
          return node;
        }

        return {
          ...node,
          actionType
        };
      }
    );
  } catch (error) {
    console.error(error);
    return;
  }
};

export const deleteNotificationFromCache = (
  client: ApolloClient<unknown>,
  notificationId: string
): void => {
  deleteNodesFromListInCache<
    Notifications,
    "notifications",
    NotificationsQueryVariables
  >({
    ids: notificationId,
    query: NOTIFICATIONS_QUERY,
    queryKey: "notifications",
    queryVariables: {
      activityType: ActivityType.notifications
    },
    store: client
  });
};

export const unstackNotificationInCache = (
  client: ApolloClient<unknown>,
  notificationId: string,
  unstackedPersonId: string,
  otherPerson: NotificationEvents_activityEvents_events_EventActivityUnstacked_otherPerson | null
): void => {
  const cacheId = defaultDataIdFromObject({
    id: notificationId,
    __typename: "Notification"
  });
  const notification = client.readFragment<NotificationFragment>({
    id: cacheId,
    fragment: NOTIFICATION_FRAGMENT,
    fragmentName: NOTIFICATION_FRAGMENT_NAME
  });

  if (
    !notification ||
    (notification.__typename !== RAW_NOTIFICATION_TYPE.PostLiked &&
      notification.__typename !== RAW_NOTIFICATION_TYPE.PostCommented &&
      notification.__typename !== RAW_NOTIFICATION_TYPE.PersonFollowed)
  ) {
    return;
  }

  let persons = notification.persons.filter(
    person => person.id !== unstackedPersonId
  );

  if (otherPerson) {
    persons.push(otherPerson);
    persons = persons.slice(0, PEOPLE_TO_SHOW_IN_NOTIFICATION_HEADER);
  }

  const otherPersons = Math.max(notification.otherPersons - 1, 0);
  const data = {
    ...notification,
    persons,
    otherPersons
  } as NotificationFragment;

  client.writeFragment<NotificationFragment>({
    id: cacheId,
    fragment: NOTIFICATION_FRAGMENT,
    fragmentName: NOTIFICATION_FRAGMENT_NAME,
    data
  });
};
