import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { MY_CONVERSATIONS_QUERY } from "~/components/messaging/graphql/conversations";
import {
  MyConversations,
  MyConversationsVariables
} from "~/declarations/apollo/MyConversations";

export const useUnreadChannels = (initialUnreadChannels = 0): number => {
  const { data } = useQuery<MyConversations, MyConversationsVariables>(
    MY_CONVERSATIONS_QUERY,
    {
      fetchPolicy: "cache-only",
      nextFetchPolicy: "cache-only"
    }
  );
  const unreadConversations = useMemo(() => {
    if (!data) {
      return initialUnreadChannels;
    }

    return data.myConversations.edges.filter(
      channel => channel.node.read?.unreadMessages > 0
    ).length;
  }, [data, initialUnreadChannels]);

  return unreadConversations;
};
