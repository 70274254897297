import { IconVariant } from "~/components/core/Icon/declarations";
import { PERSON_TYPE } from "~/components/persons/constants";
import { MEDIA_KIT_LINK } from "~/constants/externalLinks";
import ROUTES from "~/constants/routes";

export enum MENU_LINK {
  profile,
  wallet,
  followers,
  followings,
  settings,
  posts,
  hypetv,
  postsDiscover,
  hypetvDiscover,
  problemReport,
  terms,
  privacyPolicy,
  contentPolicy,
  nftTerms,
  logout,
  shortContentCreate,
  longContentCreate,
  streamCreate,
  nftCreate,
  aboutUs,
  helpCenter,
  contactUs,
  darkMode,
  home,
  discover,
  leaveFeedback,
  blog,
  mediaKit
}

export interface MenuLinkItem {
  translationKey: string;
  icon?: IconVariant;
  href?: string;
  onClick?: () => void;
}

export const MENU_LINKS: { [key in MENU_LINK]: MenuLinkItem } = {
  [MENU_LINK.profile]: {
    translationKey: "user:navigation-menu_profile",
    icon: "user",
    href: ROUTES.profile.index
  },
  [MENU_LINK.wallet]: {
    icon: "wallet",
    translationKey: "user:navigation_wallet",
    href: ROUTES.wallet.index
  },
  [MENU_LINK.followers]: {
    icon: "userHeart",
    translationKey: "user:navigation_followers",
    href: `${ROUTES.persons}?variant=${PERSON_TYPE.follower}`
  },
  [MENU_LINK.followings]: {
    icon: "userTick",
    translationKey: "user:navigation_followings",
    href: `${ROUTES.persons}?variant=${PERSON_TYPE.following}`
  },
  [MENU_LINK.settings]: {
    translationKey: "user:navigation_settings-and-privacy",
    href: ROUTES.settings.index,
    icon: "gear"
  },
  [MENU_LINK.logout]: {
    translationKey: "user:navigation_log-out",
    href: "",
    icon: "logout"
  },
  [MENU_LINK.posts]: {
    translationKey: "user:navigation-menu_short-posts_feed",
    href: ROUTES.posts.index
  },
  [MENU_LINK.hypetv]: {
    translationKey: "user:navigation-menu_hypetv_feed",
    href: ROUTES.hypetv.index
  },
  [MENU_LINK.postsDiscover]: {
    translationKey: "user:navigation-menu_discover_short-content_caption",
    href: ROUTES.posts.discover
  },
  [MENU_LINK.hypetvDiscover]: {
    translationKey: "user:navigation-menu_discover_videos_caption",
    href: ROUTES.hypetv.discover
  },
  [MENU_LINK.shortContentCreate]: {
    translationKey: "user:navigation-menu_add-content_short-content_caption",
    href: ROUTES.posts.create,
    icon: "addPost"
  },
  [MENU_LINK.longContentCreate]: {
    translationKey: "user:navigation-menu_add-content_video_caption",
    href: ROUTES.hypetv.create,
    icon: "addHypeTV"
  },
  [MENU_LINK.streamCreate]: {
    translationKey: "user:navigation-menu_add-content_start-stream_caption",
    href: ROUTES.live.create,
    icon: "addStream"
  },
  [MENU_LINK.nftCreate]: {
    translationKey: "user:navigation-menu_add-content_nft_caption",
    href: ROUTES.nft.create,
    icon: "addNFT"
  },
  [MENU_LINK.problemReport]: {
    translationKey: "user:navigation_report-problem",
    icon: "flag"
  },
  [MENU_LINK.terms]: {
    icon: "document",
    translationKey: "user:navigation_terms-of-service",
    href: ROUTES.legal.termsOfService
  },
  [MENU_LINK.privacyPolicy]: {
    icon: "document",
    translationKey: "user:navigation_privacy-policy",
    href: ROUTES.legal.privacyPolicy
  },
  [MENU_LINK.contentPolicy]: {
    icon: "document",
    translationKey: "user:navigation_content-policy",
    href: ROUTES.legal.contentPolicy
  },
  [MENU_LINK.nftTerms]: {
    icon: "document",
    translationKey: "user:navigation_nft-terms",
    href: ROUTES.legal.nftTerms
  },
  [MENU_LINK.aboutUs]: {
    icon: "logo",
    translationKey: "user:navigation_about-us",
    href: ROUTES.settings.about
  },
  [MENU_LINK.helpCenter]: {
    icon: "book",
    translationKey: "user:navigation_help-center",
    href: ROUTES.helpCenter.web.index
  },
  [MENU_LINK.contactUs]: {
    icon: "contactUs",
    translationKey: "user:navigation_contact-us"
  },
  [MENU_LINK.darkMode]: {
    icon: "paintBrush",
    translationKey: "user:navigation_dark-mode"
  },
  [MENU_LINK.home]: {
    icon: "home",
    translationKey: "user:navigation_home",
    href: ROUTES.index
  },
  [MENU_LINK.discover]: {
    icon: "compass",
    translationKey: "user:navigation_discover",
    href: ROUTES.posts.discover
  },
  [MENU_LINK.leaveFeedback]: {
    icon: "feedback",
    translationKey: "user:navigation_leave-feedback"
  },
  [MENU_LINK.blog]: {
    icon: "blog",
    translationKey: "user:navigation_blog",
    href: ROUTES.blog.index
  },
  [MENU_LINK.mediaKit]: {
    icon: "mediaKit",
    translationKey: "user:navigation_media-kit",
    href: MEDIA_KIT_LINK
  }
};
