import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { XDB_NFT_IMPORT_PROCESS_STEP } from "~/components/xdb/import/XdbNftImportProcessDialog/declarations";
import { safeDynamicImport } from "~/utils/import/dynamic";

type XdbNftImportProcessStepDescription = {
  step: XDB_NFT_IMPORT_PROCESS_STEP;
  translationKey: string;
  finalStep?: boolean;
};

export const XDB_NFT_IMPORT_PROCESS_STEP_DESCRIPTIONS: XdbNftImportProcessStepDescription[] =
  [
    {
      step: XDB_NFT_IMPORT_PROCESS_STEP.confirmationFromAstraX,
      translationKey:
        "xdb-nft-import-process_confirmation-from-astrax-step_description"
    },
    {
      step: XDB_NFT_IMPORT_PROCESS_STEP.importingNftFromXdb,
      translationKey: "xdb-nft-import-process_importing-step_description"
    },
    {
      step: XDB_NFT_IMPORT_PROCESS_STEP.completed,
      translationKey: "xdb-nft-import-process_complete-step_description",
      finalStep: true
    }
  ];

export enum XDB_NFT_IMPORT_PROCESS_DIALOG_ERROR_CODE {
  noUserOrWalletAddress = "noUserOrWalletAddress",
  noWalletDecryptionRecord = "noWalletDecryptionRecord"
}

export const XDB_NFT_IMPORT_PROCESS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.xdbNftImportProcess> =
  {
    Component: safeDynamicImport(
      () => import("~/components/xdb/import/XdbNftImportProcessDialog"),
      { ssr: false }
    )
  };
