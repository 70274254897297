import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

import { WhatsInsideDialogPayload } from "./declarations";

export interface WhatsInsideContextProps {
  openDialog: (props: WhatsInsideDialogPayload) => void;
}

const WhatsInsideContext = createContext<WhatsInsideContextProps | undefined>(
  undefined
);

const useWhatsInsideContext = createUseContext(
  WhatsInsideContext,
  "WhatsInsideContext"
);

export default WhatsInsideContext.Provider;
export { useWhatsInsideContext };
