import { MILLISECONDS_IN_SECOND } from "~/constants/date";

export const RE_CAPTCHA_CLIENT_ERROR_NAME = "ClientReCaptchaError";

export const TIME_TO_WAIT_RE_CAPTCHA_SCRIPT_LOADING =
  7 * MILLISECONDS_IN_SECOND;

export enum RE_CAPTCHA_ERROR_MESSAGE {
  WAITING_SCRIPT_TOO_LONG = "It takes too long to load the reCAPTCHA script. Perhaps the NEXT_PUBLIC_RECAPTCHA_SITE_KEY variable is not specified.",
  PREVIOUS_EXECUTION_ON_RUN = "Previous execution is on run"
}
