import { ApolloClient, defaultDataIdFromObject } from "@apollo/client";

import {
  NFT_EDITION_FRAGMENT,
  NFT_EDITION_FRAGMENT_NAME,
  NFT_EDITIONS_QUERY
} from "~/components/nft/graphql";
import {
  NFT_EDITION_UPDATED_FRAGMENT,
  NFT_EDITION_UPDATED_FRAGMENT_NAME,
  NFT_INSTANCE_AND_EDITION_UPDATED_DATA_QUERY,
  NFT_INSTANCE_STATISTICS_FRAGMENT
} from "~/components/wallet/providers/WalletProvider/useRealTimeTransactionsUpdate/graphql";
import { NFT_EDITION_TYPENAME, NFT_TYPENAME } from "~/constants/nft";
import {
  EditionStatus,
  TransferReason
} from "~/declarations/apollo/globalTypes";
import { NftEditionFragment } from "~/declarations/apollo/NftEditionFragment";
import { NftEditionUpdated } from "~/declarations/apollo/NftEditionUpdated";
import {
  NftInstanceAndEditionUpdatedData,
  NftInstanceAndEditionUpdatedDataVariables
} from "~/declarations/apollo/NftInstanceAndEditionUpdatedData";
import { NftInstanceStatistics } from "~/declarations/apollo/NftInstanceStatistics";
import { ProgressOfTransaction, PURCHASE_TYPE } from "~/declarations/purchases";

type UpdateNftAndEditionInCacheOptions = {
  nftId: string;
  editionId: string | null;
  apolloClient: ApolloClient<unknown>;
  reason?: TransferReason;
  onTransactionDone?: (transactionProgress: ProgressOfTransaction) => void;
};

export const updateNftAndEditionInCache = async ({
  nftId,
  editionId,
  onTransactionDone,
  apolloClient,
  reason
}: UpdateNftAndEditionInCacheOptions): Promise<void> => {
  try {
    const response = await apolloClient.query<
      NftInstanceAndEditionUpdatedData,
      NftInstanceAndEditionUpdatedDataVariables
    >({
      query: NFT_INSTANCE_AND_EDITION_UPDATED_DATA_QUERY,
      variables: {
        nftId,
        editionId
      },
      fetchPolicy: "network-only"
    });

    const nft = response.data.getNft;

    if (nft) {
      apolloClient.writeFragment<NftInstanceStatistics>({
        id: defaultDataIdFromObject({
          id: nft.id,
          __typename: NFT_TYPENAME
        }),
        fragment: NFT_INSTANCE_STATISTICS_FRAGMENT,
        data: {
          __typename: nft.__typename,
          id: nft.id,
          stats: nft.stats
        }
      });
    }

    if (nft.edition) {
      apolloClient.writeFragment<NftEditionUpdated>({
        id: defaultDataIdFromObject({
          id: nft.edition.id,
          __typename: NFT_EDITION_TYPENAME
        }),
        fragment: NFT_EDITION_UPDATED_FRAGMENT,
        fragmentName: NFT_EDITION_UPDATED_FRAGMENT_NAME,
        data: {
          __typename: nft.edition.__typename,
          id: nft.edition.id,
          owner: nft.edition.owner,
          status: nft.edition.status,
          cost: nft.edition.cost,
          charitable: nft.edition.charitable,
          profitShares: nft.edition.profitShares
        }
      });
    }

    if (nft.defaultEdition) {
      apolloClient.writeFragment<NftEditionFragment>({
        id: defaultDataIdFromObject({
          id: nft.defaultEdition.id,
          __typename: NFT_EDITION_TYPENAME
        }),
        fragment: NFT_EDITION_FRAGMENT,
        fragmentName: NFT_EDITION_FRAGMENT_NAME,
        data: {
          ...nft.defaultEdition
        }
      });
    }

    if (
      reason === TransferReason.startSellingEditions ||
      reason === TransferReason.stopSellingEditions
    ) {
      const multipleEditions = !editionId;
      if (multipleEditions) {
        apolloClient.refetchQueries({ include: [NFT_EDITIONS_QUERY] });
      }

      const type =
        reason === TransferReason.startSellingEditions
          ? PURCHASE_TYPE.nftStartSellingEditions
          : PURCHASE_TYPE.nftStopSellingEditions;

      onTransactionDone?.({
        nftId,
        type,
        cost: nft.edition?.cost ?? 0,
        status:
          reason === TransferReason.startSellingEditions
            ? EditionStatus.selling
            : EditionStatus.minted
      });
    }
  } catch (error) {
    console.error(error);
  }
};
