import { DocumentNode } from "@apollo/client";
import { DataProxy } from "@apollo/client/cache";

import { APOLLO_PAGINATION_CLIENT_CURSOR } from "~/constants/pagination";
import {
  PaginationQuery,
  PaginationVariables
} from "~/declarations/pagination";

interface ReadNodeFromCacheProps<Query, Key> {
  data: Query;
  ids: string[];
  queryKey: Key;
}

interface ReadNodeFromCacheResult<
  Query extends PaginationQuery<Key>,
  Key extends keyof Query
> {
  node: NonNullable<Query[Key]>["edges"][number]["node"];
  index: number;
}

const readNodesFromCache = <
  Query extends PaginationQuery<Key>,
  Key extends keyof Query
>({
  data,
  ids,
  queryKey
}: ReadNodeFromCacheProps<Query, Key>):
  | ReadNodeFromCacheResult<Query, Key>[]
  | null => {
  const idSet = new Set(ids);
  const edgeNodes = data?.[queryKey]?.edges?.reduce<
    ReadNodeFromCacheResult<Query, Key>[]
  >((readRecords, edge, index) => {
    if (idSet.has(edge.node.id)) {
      readRecords.push({
        index,
        node: edge.node
      });
    }

    return readRecords;
  }, []);

  if (!edgeNodes) {
    return null;
  }

  return edgeNodes;
};

interface UpdateNodeInCacheProps<Key, QueryVariables> {
  query: DocumentNode;
  store: DataProxy;
  ids: string | string[];
  queryVariables?: QueryVariables;
  queryKey: Key;
}

export const updateNodeInCache = <
  Query extends PaginationQuery<Key>,
  Key extends keyof Query,
  QueryVariables extends PaginationVariables
>(
  {
    query,
    store,
    ids,
    queryVariables,
    queryKey
  }: UpdateNodeInCacheProps<Key, QueryVariables>,
  updateNode: (
    node: NonNullable<Query[Key]>["edges"][number]["node"]
  ) => NonNullable<Query[Key]>["edges"][number]["node"]
): boolean => {
  const data = store.readQuery<Query, QueryVariables>({
    query,
    variables: queryVariables
  });

  if (!data) {
    return false;
  }

  const listOfIds = Array.isArray(ids) ? ids : [ids];

  const readResults = readNodesFromCache<Query, Key>({
    data,
    ids: listOfIds,
    queryKey
  });

  if (!readResults) {
    return false;
  }

  const newEdges = data[queryKey]?.edges.slice() ?? [];

  readResults.forEach(({ index, node }) => {
    newEdges[index] = {
      ...newEdges[index],
      node: updateNode(node)
    };
  });

  const newData: Query = {
    ...data,
    [queryKey]: {
      ...data[queryKey],
      edges: newEdges
    }
  };

  store.writeQuery<Query, QueryVariables>({
    query,
    data: newData,
    variables: queryVariables
  });

  return true;
};

export const addNodesToListInCache = <
  Query extends PaginationQuery<QueryKey>,
  QueryKey extends keyof Query,
  QueryVariables extends PaginationVariables
>({
  store,
  query,
  queryKey,
  queryVariables,
  nodes
}: {
  store: DataProxy;
  query: DocumentNode;
  queryKey: QueryKey;
  queryVariables: QueryVariables;
  nodes:
    | NonNullable<Query[QueryKey]>["edges"][number]["node"]
    | NonNullable<Query[QueryKey]>["edges"][number]["node"][];
}): Query | null => {
  let data: Query | null;

  try {
    data = store.readQuery<Query, QueryVariables>({
      query,
      variables: queryVariables
    });
  } catch {
    const edges: NonNullable<Query[QueryKey]>["edges"] = [];

    data = {
      [queryKey]: {
        edges,
        pageInfo: {
          startCursor: null,
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false
        },
        totalCount: 0
      }
    } as Query;
  }

  if (!data) {
    return null;
  }

  const edges = data?.[queryKey]?.edges ?? [];
  const totalCount = data?.[queryKey]?.totalCount ?? 0;
  const nodeList = Array.isArray(nodes) ? nodes : [nodes];
  const edgesFromNodes: NonNullable<Query[QueryKey]>["edges"] = nodeList.map(
    node => ({
      cursor: APOLLO_PAGINATION_CLIENT_CURSOR,
      node
    })
  );
  const newEdges = [...edgesFromNodes, ...edges];
  const newData: Query = {
    ...data,
    [queryKey]: {
      ...data[queryKey],
      edges: newEdges,
      totalCount: totalCount + edgesFromNodes.length
    }
  };

  store.writeQuery<Query, QueryVariables>({
    query,
    data: newData,
    variables: queryVariables
  });

  return newData;
};

export const deleteNodesFromListInCache = <
  Query extends PaginationQuery<QueryKey>,
  QueryKey extends keyof Query,
  QueryVariables extends PaginationVariables
>({
  store,
  query,
  queryKey,
  queryVariables,
  ids
}: {
  store: DataProxy;
  query: DocumentNode;
  queryKey: QueryKey;
  queryVariables: QueryVariables;
  ids: string | string[];
}): void => {
  try {
    const data = store.readQuery<Query, QueryVariables>({
      query,
      variables: queryVariables
    });

    if (!data) {
      return;
    }

    const edges = data?.[queryKey]?.edges ?? [];
    const totalCount = data?.[queryKey]?.totalCount ?? 0;
    const idList = Array.isArray(ids) ? ids : [ids];
    const idSet = new Set(idList);
    const newEdges = edges.filter(edge => !idSet.has(edge.node.id));
    const newData: Query = {
      ...data,
      [queryKey]: {
        ...data[queryKey],
        edges: newEdges,
        totalCount: totalCount - (edges.length - newEdges.length)
      }
    };

    store.writeQuery<Query, QueryVariables>({
      query,
      data: newData,
      variables: queryVariables,
      overwrite: true
    });
  } catch (error) {
    console.error(error);
  }
};

export const moveNodeInListCache = <
  Query extends PaginationQuery<QueryKey>,
  QueryKey extends keyof Query,
  QueryVariables extends PaginationVariables
>({
  store,
  query,
  queryKey,
  queryVariables,
  id,
  newIndex
}: {
  store: DataProxy;
  query: DocumentNode;
  queryKey: QueryKey;
  queryVariables: QueryVariables;
  id: string;
  newIndex: number;
}): void => {
  try {
    const data = store.readQuery<Query, QueryVariables>({
      query,
      variables: queryVariables
    });

    if (!data) {
      return;
    }

    const edges = data?.[queryKey]?.edges ?? [];

    const oldIndex = edges.findIndex(edge => edge.node.id === id);

    if (oldIndex === -1) {
      return;
    }

    const newEdges = [...edges];
    const movedEdges = newEdges.splice(oldIndex, 1);
    newEdges.splice(newIndex, 0, ...movedEdges);

    const newData: Query = {
      ...data,
      [queryKey]: {
        ...data[queryKey],
        edges: newEdges
      }
    };

    store.writeQuery<Query, QueryVariables>({
      query,
      data: newData,
      variables: queryVariables,
      overwrite: true
    });
  } catch (error) {
    console.error(error);
  }
};
