import useTranslation from "next-translate/useTranslation";
import { useRef } from "react";
import styled from "styled-components";

import SearchField from "~/components/core/SearchField";
import { useSearchPopup } from "~/components/providers/SearchPopupProvider/SearchPopupContext";
import { MAX_SEARCH_STRING_LENGTH } from "~/constants/apiSearch";

const SEARCH_DESKTOP_INPUT_WIDTH = 380;
const SEARCH_TABLET_INPUT_WIDTH = 331;

const AccountHeaderDesktopSearch = (): JSX.Element => {
  const { t } = useTranslation("common");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { appliedSearchText, searchPopup, setAppliedSearchText } =
    useSearchPopup();

  const handleChangeSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setAppliedSearchText(event.target.value);
  };

  const handleApplySearch = (): void => {
    if (inputRef.current) {
      searchPopup.openDialog(inputRef.current);
    }
  };

  const handleClearSearch = (): void => {
    searchPopup.closeDialog();
    setAppliedSearchText("");
  };

  return (
    <StyledSearchField
      ref={inputRef}
      placeholder={t("search-input_placeholder")}
      inputProps={{ maxLength: MAX_SEARCH_STRING_LENGTH }}
      value={appliedSearchText}
      onApply={handleApplySearch}
      onClear={handleClearSearch}
      onChange={handleChangeSearch}
    />
  );
};

export default AccountHeaderDesktopSearch;

const StyledSearchField = styled(SearchField)`
  margin-right: 24px;
  width: ${SEARCH_TABLET_INPUT_WIDTH}px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: ${SEARCH_DESKTOP_INPUT_WIDTH}px;
  }
`;
