import { ButtonProps } from "~/components/core/Button/Button";

export enum WALLET_CREATE_DIALOG_STEP {
  mnemonic,
  mnemonicTest,
  setPin
}

export type WalletCreateDialogStepDescription = {
  captionTranslationKey: string;
  descriptionTranslationKey: string;
  cancelButtonTranslationKey: string;
  cancelButtonVariant: ButtonProps["variant"];
  confirmButtonTranslationKey: string;
};

export type SpecificWalletCreateDialogOptions = {
  userId: string;
};
