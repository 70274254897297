import gql from "graphql-tag";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT } from "~/components/messaging/graphql/conversations";
import {
  CONVERSATION_MESSAGE_FRAGMENT,
  CONVERSATION_MESSAGE_QUOTE_FRAGMENT,
  MESSAGE_ATTACHMENT_FRAGMENT
} from "~/components/messaging/graphql/messages";

export const MESSENGER_EVENTS_SUBSCRIPTION = gql`
  subscription MessengerEvents($offset: String) {
    messengerEvents(offset: $offset) {
      offset
      events {
        __typename

        ... on EventConversationFrozen {
          conversationId
        }

        ... on EventConversationUnfrozen {
          conversationId
        }

        ... on EventConversationWasRead {
          conversationId
          readerId
          lastReadAt
        }

        ... on EventOneToOneConversationCreated {
          conversation {
            ...MyConversationWithShallowAttachment
          }
        }

        ... on EventGroupCreated {
          conversation {
            ...MyConversationWithShallowAttachment
          }
        }

        ... on EventMessageCreated {
          occurredOn
          conversationId
          message {
            ...MyConversationMessage
          }
        }

        ... on EventGroupTopicChanged {
          eventId
          conversationId
          occurredOn
          performer {
            name
            nickname
            avatar {
              little
            }
          }
          topic
        }

        ... on EventGroupPictureChanged {
          eventId
          conversationId
          occurredOn
          performer {
            name
            nickname
            avatar {
              little
            }
          }
          picture {
            __typename

            ... on GroupCustomPicture {
              little
              big
            }

            ... on GroupMembersPicture {
              avatars {
                little
              }
            }
          }
        }

        ... on EventParticipantInvited {
          eventId
          conversationId
          occurredOn
          inviter {
            name
            nickname
            avatar {
              little
            }
          }
          person {
            name
            nickname
          }
        }

        ... on EventParticipantJoined {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantLeft {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantJoinRequestSent {
          eventId
          conversationId
          occurredOn
          person {
            id
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantKicked {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantBanned {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantUnbanned {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantPromoted {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventParticipantDemoted {
          eventId
          conversationId
          occurredOn
          person {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventGroupOwnershipTransferred {
          eventId
          conversationId
          occurredOn
          newOwner {
            name
            nickname
            avatar {
              little
            }
          }
          oldOwner {
            name
            nickname
            avatar {
              little
            }
          }
        }

        ... on EventMessageDeleted {
          conversationId
          messageId
        }

        ... on EventMessageEdited {
          messageId
          richText {
            ...richText
          }
          attachments {
            ...messageAttachment
          }
        }

        ... on EventMessageQuoteChanged {
          messageId
          quoteType
          quote {
            ...ConversationMessageQuote
          }
        }

        ... on EventParticipantIgnoringMessagesChanged {
          conversationId
          value
        }

        ... on EventParticipantBlockingMessagesChanged {
          conversationId
          value
        }

        ... on EventGroupInvitePolicyChanged {
          conversationId
          invitePolicy
        }

        ... on EventGroupPrivacyChanged {
          conversationId
          privacy
        }

        ... on EventGroupKindChanged {
          conversationId
          kind
        }

        ... on EventReactionCreated {
          reaction {
            id
            type
            reactableId
            reactorId
          }
        }

        ... on EventReactionDeleted {
          reaction {
            id
            type
            reactableId
            reactorId
          }
        }

        ... on EventMessagePublished {
          messageId
        }
      }
    }
  }
  ${CONVERSATION_MESSAGE_QUOTE_FRAGMENT}
  ${CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT}
  ${CONVERSATION_MESSAGE_FRAGMENT}
  ${MESSAGE_ATTACHMENT_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
`;
