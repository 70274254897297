import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar,
  VariantType
} from "notistack";
import { ReactNode, useCallback } from "react";

export type CustomSnackbarAction = {
  text: ReactNode;
  onClick: () => void;
};

export type ExtraSnackbarOptions = {
  description?: SnackbarMessage;
  firstAction?: CustomSnackbarAction;
  secondAction?: CustomSnackbarAction;
};

export type MessageConfig = {
  title: SnackbarMessage;
  variant?: VariantType;
} & ExtraSnackbarOptions;

type CustomSnackbarOptions = OptionsObject & ExtraSnackbarOptions;

interface CustomSnackbarContext {
  enqueueCustomSnackbar: (
    config: SnackbarMessage,
    options?: CustomSnackbarOptions
  ) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

const useCustomSnackbar = (): CustomSnackbarContext => {
  const { enqueueSnackbar, ...otherProps } = useSnackbar();

  const enqueueCustomSnackbar = useCallback(
    (title: SnackbarMessage, options?: CustomSnackbarOptions): SnackbarKey => {
      const config: MessageConfig = {
        title,
        description: options?.description,
        variant: options?.variant,
        firstAction: options?.firstAction,
        secondAction: options?.secondAction
      };

      return enqueueSnackbar(config, { ...options });
    },
    [enqueueSnackbar]
  );

  return { enqueueCustomSnackbar, ...otherProps };
};

export default useCustomSnackbar;
