import zIndex from "@material-ui/core/styles/zIndex";
import { DefaultTheme } from "styled-components";

import createBreakpoints from "~/theme/createBreakpoints";
import { isServerSide } from "~/utils/common";
import { isIOS } from "~/utils/userAgent";

export const REGULAR_FONT_FAMILY =
  '"Aeonik", "Roboto", "Helvetica", "Arial", sans-serif';

export const COMMON_STYLED_COMPONENTS_THEME: Omit<
  DefaultTheme,
  "type" | "newTheme"
> = {
  palette: {
    common: {
      darkGrey: "#1C1D25",
      lightGrey: "#F7F7F9",
      yellow: "#FFC700",
      orange: "#FA9856",
      green: "#27DC8F",
      red: "#F64D4D",
      purple: "#C173FF"
    },
    blue: {
      900: "#0E1025",
      700: "#131529",
      100: "#243B50"
    }
  },
  isIOS: !isServerSide() && isIOS(),
  layouts: {
    account: {
      headerHeight: 72,
      footerHeight: 40,
      sizes: {
        // TODO: Replace with breakpoints or remove
        md: "965px",
        lg: "1200px",
        xl: "1300px",
        xxl: "2560px",
        full: "100%"
      }
    }
  },
  // MUI-related below
  breakpoints: createBreakpoints({}),
  typography: {
    fontFamily: REGULAR_FONT_FAMILY,
    button: {
      textTransform: "none"
    }
  },
  zIndex: {
    ...zIndex,
    appMenu: zIndex.appBar - 1,
    playerOverlayButton: 100
  }
};
