import { useMemo } from "react";
import { useTheme } from "styled-components";

import { ICON_VARIANT_MAP } from "~/components/core/Icon/constants";
import { IconSize, IconVariant } from "~/components/core/Icon/declarations";

type IconProps = {
  className?: string;
  variant: IconVariant;
  size?: IconSize;
  onClick?: () => void;
};

const Icon = ({
  className,
  variant,
  onClick,
  size = 24
}: IconProps): JSX.Element => {
  const { type } = useTheme();
  const IconComponent = useMemo(
    () => ICON_VARIANT_MAP[type][variant],
    [type, variant]
  );

  return (
    <IconComponent
      onClick={onClick}
      className={className}
      width={size}
      height={size}
    />
  );
};

export default Icon;
