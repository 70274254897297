import createEmojiRegexp from "emoji-regex";

import {
  RICH_TEXT_PART_TYPENAME,
  RICH_TEXT_TYPENAME
} from "~/constants/richText";
import { RichText } from "~/declarations/richText";

import { isNotNull } from "./common";

const EMOJI_REGEXP = createEmojiRegexp();

export const getNormalizedRichText = (richText: RichText): RichText => {
  // It is possible that some texts were left unprocessed on the backend.
  if (!richText.parts.length && richText.text && richText.text.length) {
    return {
      __typename: RICH_TEXT_TYPENAME,
      text: richText.text,
      parts: [
        {
          __typename: RICH_TEXT_PART_TYPENAME.plain,
          text: richText.text
        }
      ]
    };
  }

  return richText;
};

export const isTextStandaloneEmoji = (text: string): boolean => {
  const emojiMatch = text.match(EMOJI_REGEXP);

  return (
    isNotNull(emojiMatch) && emojiMatch.length === 1 && emojiMatch[0] === text
  );
};

export const stringToRichText = (text: string): RichText => ({
  __typename: RICH_TEXT_TYPENAME,
  parts: [
    {
      __typename: RICH_TEXT_PART_TYPENAME.plain,
      text
    }
  ],
  text
});

export const richTextToString = (richText: RichText): string =>
  richText.text ?? "";

export const isRichTextEmpty = (
  richText: RichText | null
): richText is RichText => richText === null || !richText.text;
