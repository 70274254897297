import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import {
  CONVERSATION_MESSAGE_FRAGMENT,
  CONVERSATION_MESSAGE_SHALLOW_ATTACHMENT_FRAGMENT,
  MESSAGE_ATTACHMENT_FRAGMENT
} from "~/components/messaging/graphql/messages";

export const CONVERSATION_REFERENCE_FRAGMENT = gql`
  fragment conversationReferenceFragment on Conversation {
    ... on GroupConversation {
      __typename
      reference {
        canMessage
        ... on GroupConversationReference {
          __typename
          role
        }
      }
    }
    ... on DetailedGroupConversation {
      __typename
      reference {
        canMessage
        ... on GroupConversationReference {
          __typename
          role
        }
      }
    }
    ... on OneToOneConversation {
      __typename
      reference {
        canMessage
      }
    }
    ... on DetailedOneToOneConversation {
      __typename
      reference {
        canMessage
      }
    }
  }
`;

export const CONVERSATION_FRAGMENT = gql`
  fragment MyConversation on Conversation {
    __typename
    id
    muted
    pinned
    read {
      lastReadAt
      unreadMessages
    }
    participantRead {
      lastReadAt
    }
    lastMessage {
      ...MyConversationMessage
    }

    updatedAt
    startedAt

    ...conversationReferenceFragment

    ... on OneToOneConversation {
      participant {
        __typename
        id
        name
        nickname
        isFounder
        validation
        isCharity
        avatar {
          little
          middle
          big
        }
        followingReference {
          me
        }
      }
    }

    ... on DetailedOneToOneConversation {
      participant {
        __typename
        id
        name
        nickname
        isFounder
        validation
        isCharity
        avatar {
          little
          middle
          big
        }
        followingReference {
          me
        }
      }
    }

    ... on GroupConversation {
      topic
      topicFallback
      picture {
        __typename
        ... on GroupCustomPicture {
          little
          big
        }
        ... on GroupMembersPicture {
          avatars {
            little
          }
        }
      }
    }

    ... on DetailedGroupConversation {
      topic
      topicFallback
      picture {
        __typename
        ... on GroupCustomPicture {
          little
          big
        }
        ... on GroupMembersPicture {
          avatars {
            little
          }
        }
      }
    }
  }
  ${CONVERSATION_MESSAGE_FRAGMENT}
  ${CONVERSATION_REFERENCE_FRAGMENT}
`;

export const CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT_NAME =
  "MyConversationWithShallowAttachment";

export const CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT = gql`
  fragment MyConversationWithShallowAttachment on Conversation {
    __typename
    id
    muted
    pinned
    read {
      lastReadAt
      unreadMessages
    }
    participantRead {
      lastReadAt
    }

    lastMessage {
      ...MyConversationMessageWithShallowAttachment
    }

    ...conversationReferenceFragment

    updatedAt
    startedAt

    ... on OneToOneConversation {
      participant {
        __typename
        id
        name
        nickname
        validation
        isFounder
        isCharity
        avatar {
          little
          middle
          big
        }
        followingReference {
          me
        }
      }
    }

    ... on DetailedOneToOneConversation {
      participantRead {
        lastReadAt
      }
      participant {
        __typename
        id
        name
        nickname
        validation
        isFounder
        isCharity
        avatar {
          little
          middle
          big
        }
        followingReference {
          me
        }
      }
    }

    ... on GroupConversation {
      topic
      topicFallback
      picture {
        __typename
        ... on GroupCustomPicture {
          little
          big
        }
        ... on GroupMembersPicture {
          avatars {
            little
          }
        }
      }
    }

    ... on DetailedGroupConversation {
      topic
      topicFallback
      picture {
        __typename
        ... on GroupCustomPicture {
          little
          big
        }
        ... on GroupMembersPicture {
          avatars {
            little
          }
        }
      }
    }
  }
  ${CONVERSATION_MESSAGE_SHALLOW_ATTACHMENT_FRAGMENT}
  ${CONVERSATION_REFERENCE_FRAGMENT}
`;

export const CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT_NAME =
  "ConversationLastMessageAndRead";

export const CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT = gql`
  fragment ConversationLastMessageAndRead on Conversation {
    lastMessage {
      ...MyConversationMessage
    }
    read {
      lastReadAt
      unreadMessages
    }
  }
  ${CONVERSATION_MESSAGE_FRAGMENT}
`;

export const MY_CONVERSATIONS_QUERY_KEY = "myConversations";
export const MY_CONVERSATIONS_QUERY = gql`
  query MyConversations($first: Int, $after: String) {
    myConversations(first: $first, after: $after)
      @connection(key: "myConversations", filter: []) {
      edges {
        cursor
        node {
          ...MyConversationWithShallowAttachment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT}
`;

export const MESSAGING_CONVERSATION_ONE_TO_ONE_SETTINGS_FRAGMENT = gql`
  fragment conversationSettingsFragment on DetailedOneToOneConversation {
    settings {
      __typename
      blockingMessages
      ignoringMessages
    }
  }
`;

export const MESSAGING_CONVERSATION_GROUP_SETTINGS_FRAGMENT = gql`
  fragment groupConversationSettingsFragment on DetailedGroupConversation {
    settings {
      __typename
      ignoringMessages
      invitePolicy
      kind
      privacy
    }
  }
`;

export const READ_ALL_CONVERSATIONS_MUTATIONS = gql`
  mutation ReadAllConversations {
    readAllConversations
  }
`;

export const CONVERSATION_QUERY = gql`
  query Conversation($conversationId: String!) {
    conversation(conversationId: $conversationId) {
      __typename
      ...MyConversation
    }
  }
  ${CONVERSATION_FRAGMENT}
`;
export const CONVERSATION_DETAILS_QUERY = gql`
  query ConversationDetails($conversationId: String!) {
    conversation(conversationId: $conversationId) {
      __typename

      ... on DetailedOneToOneConversation {
        id
        reference {
          __typename
          canMessage
        }
        participant {
          __typename
          id
          followingReference {
            me
          }
        }
        draftMessage {
          originalId
          quoteType
          quote {
            id
            author {
              id
              name
              nickname
            }
            attachments {
              ...messageAttachment
            }
            richText {
              ...richText
            }
          }
          richText {
            ...richText
          }
          attachments {
            ...messageAttachment
          }
        }
        ...conversationSettingsFragment
        stats {
          __typename
          sharedImages
          sharedFiles
        }
      }
      ... on DetailedGroupConversation {
        id
        topic
        stats {
          __typename
          participants
          sharedFiles
          sharedImages
        }
        ...groupConversationSettingsFragment
      }
    }
  }
  ${MESSAGE_ATTACHMENT_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
  ${MESSAGING_CONVERSATION_ONE_TO_ONE_SETTINGS_FRAGMENT}
  ${MESSAGING_CONVERSATION_GROUP_SETTINGS_FRAGMENT}
`;
