import { ReactNode } from "react";

import {
  AppThemeModeContext,
  ThemeModeToggleHandler
} from "~/components/providers/AppThemeModeProvider/AppThemeModeContext";

interface Props {
  toggleTheme: ThemeModeToggleHandler;
  children: ReactNode;
}

const AppThemeModeProvider = ({
  children,
  toggleTheme
}: Props): JSX.Element => (
  <AppThemeModeContext.Provider value={toggleTheme}>
    {children}
  </AppThemeModeContext.Provider>
);

export default AppThemeModeProvider;
