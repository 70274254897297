import useTranslation from "next-translate/useTranslation";

import ConfirmationDialog from "~/components/core/ConfirmationDialog";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  price: number;
  className?: string;
  children?: React.ReactNode;
};

const WhatsInsideDialog = ({
  open,
  onClose,
  onSubmit,
  price,
  className,
  children
}: Props): JSX.Element => {
  const { t } = useTranslation("post");

  return (
    <ConfirmationDialog
      icon="gemFilled"
      open={open}
      onClose={onClose}
      className={className}
      caption={t("post:paywall-preview_label")}
      description={children}
      cancelText={t("common:got-it")}
      confirmText={t("payments:pay-hypes", {
        value: price,
        coinName: t("common:coin-name", { count: price })
      })}
      onConfirm={onSubmit}
    />
  );
};

export default WhatsInsideDialog;
