import { useAccountLayoutContext } from "../AccountLayoutProvider";
import AccountHeaderDesktop from "./AccountHeaderDesktop";
import AccountHeaderMobile from "./AccountHeaderMobile";

const AccountHeader = (): JSX.Element => {
  const { mobileView } = useAccountLayoutContext();

  return mobileView ? <AccountHeaderMobile /> : <AccountHeaderDesktop />;
};

export default AccountHeader;
