import styled from "styled-components";

import Icon from "~/components/core/Icon/Icon";
import { omitStyleProps } from "~/utils/styled-components";

import { IconSize, IconVariant } from "../Icon/declarations";

const DEFAULT_BORDER_SIZE = 2;
const DEFAULT_SIZE = 56;

type Props = {
  icon: IconVariant;
  size?: number;
  iconSize?: IconSize;
  className?: string;
  borderSize?: number;
};

const IconAvatar = ({
  icon,
  iconSize,
  className,
  size = DEFAULT_SIZE,
  borderSize = DEFAULT_BORDER_SIZE
}: Props): JSX.Element => (
  <OuterWrapper className={className} size={size} borderSize={borderSize}>
    <InnerWrapper>
      <Icon variant={icon} size={iconSize} />
    </InnerWrapper>
  </OuterWrapper>
);

export default IconAvatar;

const OuterWrapper = styled.div.withConfig<{
  borderSize: number;
  size: number;
}>(omitStyleProps(["borderSize", "size"]))`
  border-radius: 50%;
  background: ${({ theme }) => theme.newTheme.gradient.secondary};
  padding: ${({ borderSize }) => borderSize}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const InnerWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.newTheme.background.octonary};
  border-radius: inherit;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
