import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const XDB_CONNECT_TO_ASTRAX_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.xdbConnectToAstraX> =
  {
    Component: safeDynamicImport(
      () => import("~/components/xdb/import/XdbConnectToAstraX"),
      { ssr: false }
    )
  };
