export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_DOTTED = "DD.MM.YYYY";
export const DATE_FORMAT_MASK = "__.__.____";
export const DATE_FORMAT_REQUEST = "YYYY-MM-DD";

export const GUID_REGEXP_BASE =
  "[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}";
export const GUID_REGEXP = new RegExp(`^${GUID_REGEXP_BASE}$`, "i");

export const ELLIPSIS_STUB = "...";

export const CONTENT_VIEWED_DELAY = 2000;

export const MAX_PERCENTAGE = 100;
