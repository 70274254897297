import useTranslation from "next-translate/useTranslation";
import { MouseEvent, useCallback } from "react";
import styled from "styled-components";

import Avatar from "~/components/core/Avatar";
import Divider from "~/components/core/Divider";
import Menu from "~/components/core/Menu";
import MenuItem from "~/components/core/MenuItem";
import AuthService from "~/services/AuthService";
import useDialog from "~/utils/useDialog";
import { useRouteChangeCallback } from "~/utils/useRouteChangeCallback";

import { MENU_LINK, MENU_LINKS } from "../constants";

type Props = {
  avatar?: string;
  name?: string;
  loading: boolean;
};

const PROFILE_LINK = MENU_LINKS[MENU_LINK.profile];
const WALLET_LINK = MENU_LINKS[MENU_LINK.wallet];
const FOLLOWERS_LINK = MENU_LINKS[MENU_LINK.followers];
const SETTINGS_LINK = MENU_LINKS[MENU_LINK.settings];
const LOGOUT_LINK = MENU_LINKS[MENU_LINK.logout];

const AccountHeaderDesktopUserMenu = ({
  avatar,
  name,
  loading
}: Props): JSX.Element => {
  const { t } = useTranslation("user");
  const menu = useDialog<HTMLButtonElement>();

  const handleClose = useCallback(() => {
    menu.closeDialog();
  }, [menu]);
  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      menu.openDialog(event.currentTarget);
    },
    [menu]
  );
  const handleClickLogout = (): void => {
    AuthService.logout();
  };

  useRouteChangeCallback(handleClose);

  return (
    <>
      <StyledButton onClick={handleOpen} aria-label={t("common:user-menu")}>
        <Avatar
          loading={loading}
          src={avatar}
          size="xLarge"
          title={name}
          alt={name}
        />
      </StyledButton>
      <StyledMenu
        open={menu.open}
        onClose={handleClose}
        anchorEl={menu.payload}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom"
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: -18
        }}
      >
        <MenuItem href={PROFILE_LINK.href} icon={PROFILE_LINK.icon}>
          {t(PROFILE_LINK.translationKey)}
        </MenuItem>
        <MenuItem href={WALLET_LINK.href} icon={WALLET_LINK.icon}>
          {t(WALLET_LINK.translationKey)}
        </MenuItem>
        <MenuItem href={FOLLOWERS_LINK.href} icon={FOLLOWERS_LINK.icon}>
          {t(FOLLOWERS_LINK.translationKey)}
        </MenuItem>
        <MenuItem href={SETTINGS_LINK.href} icon={SETTINGS_LINK.icon}>
          {t(SETTINGS_LINK.translationKey)}
        </MenuItem>
        <StyledDivider />
        <MenuItem
          href={LOGOUT_LINK.href}
          icon={LOGOUT_LINK.icon}
          onClick={handleClickLogout}
        >
          {t(LOGOUT_LINK.translationKey)}
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AccountHeaderDesktopUserMenu;

const StyledMenu = styled(Menu)`
  .MuiList-root {
    width: 276px;
  }
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;
