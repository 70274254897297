import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { SHORT_POST_NODE_FILE_FRAGMENT } from "~/components/post/graphql";
import { POST_AUTHOR_FRAGMENT } from "~/components/post/postAuthorFragment";

export const NFT_COMMON_FRAGMENT = gql`
  fragment NftCommonFragment on Nft {
    __typename
    id
    status
    charitable
    rarity
    file {
      ... on AttachmentVideo {
        fileSize
      }
      ... on AttachmentImage {
        fileSize
      }
      ...ShortPostNodeFile
    }
    creator {
      ...shortPostAuthor
      stats {
        nftCreated
      }
    }
    numberOfEditions
    title
    stats {
      availableForSaleCount
      ownedByMe
      numberOfSoldEditions
    }
    royalty
    uri
    publishedAt
    platform
    richText {
      ...richText
    }
  }
  ${SHORT_POST_NODE_FILE_FRAGMENT}
  ${POST_AUTHOR_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
`;

export const NFT_EDITION_OWNER_FRAGMENT = gql`
  fragment NftEditionOwner on Person {
    __typename
    id
    name
    nickname
    status
    isAnonymous
    isFounder
    isCharity
    validation
    avatar {
      little
      middle
    }
  }
`;

export const NFT_EDITION_FRAGMENT_NAME = "NftEditionFragment";
export const NFT_EDITION_FRAGMENT = gql`
  fragment NftEditionFragment on NftEdition {
    __typename
    id
    cost
    number
    status
    txHash
    charitable
    owner {
      ...NftEditionOwner
    }
  }

  ${NFT_EDITION_OWNER_FRAGMENT}
`;

export const NFT_OWNED_QUERY = gql`
  query OwnedNfts($first: Int, $after: String, $ownerId: String!) {
    ownedNfts(first: $first, after: $after, ownerId: $ownerId)
      @connection(key: "ownedNfts", filter: ["ownerId"]) {
      edges {
        cursor
        node {
          defaultEdition {
            ... on NftEdition {
              ...NftEditionFragment
            }
          }
          ... on Nft {
            ...NftCommonFragment
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${NFT_COMMON_FRAGMENT}
  ${NFT_EDITION_FRAGMENT}
`;

export const NFT_CREATED_QUERY = gql`
  query CreatedNfts($first: Int, $after: String, $creatorId: String!) {
    createdNfts(first: $first, after: $after, creatorId: $creatorId)
      @connection(key: "createdNfts", filter: ["creatorId"]) {
      edges {
        cursor
        node {
          defaultEdition {
            ... on NftEdition {
              ...NftEditionFragment
            }
          }
          ... on Nft {
            ...NftCommonFragment
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${NFT_COMMON_FRAGMENT}
  ${NFT_EDITION_FRAGMENT}
`;

export const NFT_EDITION_PROFIT_SHARE = gql`
  fragment NftEditionProfitShare on Share {
    __typename
    receiver {
      __typename
      id
      name
      nickname
      avatar {
        little
        middle
      }
      validation
      isCharity
      isFounder
    }
    rate
  }
`;

export const NFT_INSTANCE_QUERY = gql`
  query NftInstance($nftId: String!, $editionId: String) {
    getNft(nftId: $nftId) {
      edition(id: $editionId) {
        ... on NftEdition {
          ...NftEditionFragment
          profitShares {
            ...NftEditionProfitShare
          }
        }
      }
      defaultEdition {
        ... on NftEdition {
          ...NftEditionFragment
        }
      }
      ... on Nft {
        ...NftCommonFragment
      }
      reactions {
        likes
        myReaction {
          id
          type
        }
      }
    }
  }
  ${NFT_COMMON_FRAGMENT}
  ${NFT_EDITION_FRAGMENT}
  ${NFT_EDITION_PROFIT_SHARE}
`;

export const NFT_EDITIONS_QUERY = gql`
  query NftEditionsList(
    $nftId: String!
    $first: Int
    $after: String
    $direction: OrderDirection
    $order: EditionsOrder
    $filter: EditionFilter
  ) {
    getEditions(
      nftId: $nftId
      first: $first
      after: $after
      direction: $direction
      order: $order
      filter: $filter
    ) @connection(key: "getEditions", filter: ["nftId"]) {
      edges {
        cursor
        node {
          ...NftEditionFragment
          profitShares {
            ...NftEditionProfitShare
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${NFT_EDITION_FRAGMENT}
  ${NFT_EDITION_PROFIT_SHARE}
`;
