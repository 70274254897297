import Snackbar from "@material-ui/core/Snackbar";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import styled from "styled-components";

import Typography from "~/components/core/Typography";
import { useGlobalContext } from "~/components/providers/GlobalProvider/GlobalProvider";

const GREETING_HIDE_DURATION = 3500;
const GREETINGS_VARIANTS_COUNT = 11;

type Props = {
  userName: string;
};

const throwDiceForGreetingsIndex = (): number =>
  Math.ceil(Math.random() * GREETINGS_VARIANTS_COUNT);

const GreetingSnackbar = ({ userName }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [greetingsText, setGreetingsText] = useState("");
  const { greeting } = useGlobalContext();
  const { t } = useTranslation("auth");

  useEffect(() => {
    if (greeting.needToShow) {
      setOpen(true);
      setGreetingsText(
        t(`auth_login-greetings_${throwDiceForGreetingsIndex()}`)
      );
      greeting.setNeedToShow?.(false);
    }
  }, [greeting, t]);

  const handleCloseGreeting = (): void => {
    setOpen(false);
  };

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={GREETING_HIDE_DURATION}
      onClose={handleCloseGreeting}
    >
      <Greeting>
        <Typography variant="title2">
          {greetingsText}, <GreetingName>{userName}!</GreetingName>
        </Typography>
      </Greeting>
    </StyledSnackbar>
  );
};

export default GreetingSnackbar;

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  right: 48px;
  top: 80px;
`;

const Greeting = styled.div`
  background-color: ${({ theme }): string =>
    theme.newTheme.background.octonary};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.newTheme.boxShadow.popover};
  color: ${({ theme }): string => theme.newTheme.text.secondary};
  max-width: 250px;
  padding: 20px 15px;
`;

const GreetingName = styled.span`
  color: ${({ theme }): string => theme.newTheme.text.primary};
  overflow-wrap: break-word;
`;
