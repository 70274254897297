import { DefaultTheme } from "styled-components";

import { THEME_MODE } from "~/constants/theme";
import {
  BACKGROUNDS,
  PALE_COLORS,
  TEXT_COLOR,
  THIRD_PARTY_BACKGROUND,
  TRANSPARENCY_BLACK
} from "~/theme/colors";

import { COMMON_STYLED_COMPONENTS_THEME } from "./commonTheme";

export const LIGHT_STYLED_COMPONENTS_THEME: DefaultTheme = {
  ...COMMON_STYLED_COMPONENTS_THEME,
  type: THEME_MODE.light,
  // Colors for use in new components. Will be moved to palette gradually
  newTheme: {
    boxShadow: {
      popover: "0px 56px 112px rgba(11, 11, 11, 0.12)"
    },
    gradient: {
      primary:
        "linear-gradient(108.87deg, #39EAC9 -12.91%, #D3F1A9 43.92%, #D89EEC 98.02%)",
      secondary:
        "linear-gradient(108.87deg, #46FEDC -12.91%, #EAFFCD 43.92%, #F3D4FE 98.02%)"
    },
    text: {
      primary: TEXT_COLOR.lightPrimary,
      secondary: TEXT_COLOR.lightSecondary,
      tertiary: TEXT_COLOR.lightTertiary,
      quaternary: TEXT_COLOR.lightQuaternary,
      primaryInverse: TEXT_COLOR.darkPrimary,
      secondaryInverse: TEXT_COLOR.darkSecondary,
      tertiaryInverse: TEXT_COLOR.darkTertiary,
      quaternaryInverse: TEXT_COLOR.darkQuaternary
    },
    background: {
      primary: BACKGROUNDS.lightPrimary,
      secondary: BACKGROUNDS.lightSecondary,
      tertiary: BACKGROUNDS.lightTertiary,
      quaternary: BACKGROUNDS.lightQuaternary,
      senary: BACKGROUNDS.lightSenary,
      septenary: BACKGROUNDS.lightSeptenary,
      octonary: BACKGROUNDS.lightOctonary,
      primaryInverse: BACKGROUNDS.darkPrimary,
      secondaryInverse: BACKGROUNDS.darkSecondary,
      tertiaryInverse: BACKGROUNDS.darkTertiary,
      quaternaryInverse: BACKGROUNDS.darkQuaternary,
      senaryInverse: BACKGROUNDS.darkSenary,
      septenaryInverse: BACKGROUNDS.darkSeptenary,
      octonaryInverse: BACKGROUNDS.darkOctonary
    },
    thirdPartyColor: {
      xdb: THIRD_PARTY_BACKGROUND.xdb
    },
    pale: {
      pink: PALE_COLORS.lightPalePink,
      purple: PALE_COLORS.lightPalePurple,
      yellow: PALE_COLORS.lightPaleYellow,
      green: PALE_COLORS.lightPaleGreen,
      red: PALE_COLORS.lightPaleRed
    },
    transparent: TRANSPARENCY_BLACK
  }
};
