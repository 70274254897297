import { Translate, TranslationQuery } from "next-translate";

import { DialogProps } from "~/components/core/Dialog/Dialog";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";

export enum WALLET_CONFIRMATION_VARIANT {
  notEnoughFunds,
  pinCreated,
  pinIncorrect,
  noWalletOnClient,
  noWallet,
  walletDropped,
  receiverHasNoWallet,
  buyCoinsFiatSuccess,
  buyCoinsCryptoSuccess,
  buyCoinsError
}

export type WalletConfirmationDialogVariantProps = {
  specific?: number | string;
};

export type SpecificWalletConfirmationDialogOptions = {
  variant: WALLET_CONFIRMATION_VARIANT;
} & WalletConfirmationDialogVariantProps;

type DialogComponentProps = DialogProps<DIALOG_NAME.walletConfirmation>;

export type WalletConfirmationDialogVariant = {
  confirmTextKey: string | ((specific?: number | string) => string);
  confirmId?: string;
  getConfirmTextKeyParameters?: (t: Translate) => TranslationQuery;
  cancelTextKey?: string;
  cancelButtonVariant?: DialogComponentProps["cancelButtonVariant"];
  Content: React.FC<WalletConfirmationDialogVariantProps>;
  icon?: DialogComponentProps["icon"];
};
