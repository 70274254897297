import PostReportResultDialog from "~/components/providers/PostReportProvider/PostReportDialog/PostReportResultDialog";
import { PostAuthor } from "~/declarations/post";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

import {
  PostReportDialogPayload,
  PostReportResultDialogPayload
} from "./declarations";
import PostReportContextProvider from "./PostReportContextProvider";

const PostReportDialog = safeDynamicImport(() => import("./PostReportDialog"));

type Props = {
  children: React.ReactNode;
};

const PostReportProvider = ({ children }: Props): JSX.Element => {
  const postReportDialog = useDialog<PostReportDialogPayload>({
    options: {
      forceResetPayloadOnClose: true
    }
  });

  const postReportResultDialog = useDialog<PostReportResultDialogPayload>();

  const handleReportSuccess = (postAuthor: PostAuthor): void => {
    postReportResultDialog.openDialog({ postAuthor });
  };

  return (
    <PostReportContextProvider
      value={{
        openDialog: postReportDialog.openDialog
      }}
    >
      {children}
      {postReportDialog.hasBeenOpened && postReportDialog.payload && (
        <PostReportDialog
          open={postReportDialog.open}
          postId={postReportDialog.payload.postId}
          postAuthor={postReportDialog.payload.postAuthor}
          onClose={postReportDialog.closeDialog}
          onSuccess={handleReportSuccess}
        />
      )}

      {postReportResultDialog.hasBeenOpened &&
        postReportResultDialog.payload && (
          <PostReportResultDialog
            open={postReportResultDialog.open}
            authorId={postReportResultDialog.payload.postAuthor.id}
            onClose={postReportResultDialog.closeDialog}
          />
        )}
    </PostReportContextProvider>
  );
};

export default PostReportProvider;
