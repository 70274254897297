import styled from "styled-components";

import {
  PatternBackgroundImage,
  PatternBackgroundWrapper
} from "~/components/core/AppPatternBackground/styled";
import LeftLG from "~/public/assets/images/patterns/dialog/LeftLG.svg?url";
import LeftSM from "~/public/assets/images/patterns/dialog/LeftSM.svg?url";
import LeftXS from "~/public/assets/images/patterns/dialog/LeftXS.svg?url";
import RightLG from "~/public/assets/images/patterns/dialog/RightLG.svg?url";
import RightSM from "~/public/assets/images/patterns/dialog/RightSM.svg?url";
import RightXS from "~/public/assets/images/patterns/dialog/RightXS.svg?url";

type Props = {
  className?: string;
};

const AppPatternBackgroundForDialog = ({ className }: Props): JSX.Element => (
  <PatternBackgroundWrapper className={className}>
    <LeftBackgroundImage />
    <RightBackgroundImage />
  </PatternBackgroundWrapper>
);

export default AppPatternBackgroundForDialog;

const LeftBackgroundImage = styled(PatternBackgroundImage)`
  background: url(${LeftLG}) right top / cover no-repeat;
  left: 0;
  width: 114px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    background-image: url(${LeftSM});
    width: 354px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    background-image: url(${LeftSM});
    width: 266px;
  }

  ${({ theme }) => theme.breakpoints.down("xxs")} {
    background-image: url(${LeftXS});
    width: 114px;
  }
`;

const RightBackgroundImage = styled(PatternBackgroundImage)`
  background: url(${RightLG}) left top / cover no-repeat;
  right: 0;
  width: 215px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    background-image: url(${RightSM});
    width: 610px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    background-image: url(${RightSM});
    width: 457px;
  }

  ${({ theme }) => theme.breakpoints.down("xxs")} {
    background-image: url(${RightXS});
    width: 215px;
  }
`;
