import styled from "styled-components";

import IconButton from "~/components/core/IconButton";

export const AddContentButton = styled(IconButton).attrs({
  icon: "addContent"
})`
  padding: 4px;

  svg {
    height: 36px;
    width: 36px;
  }
`;
