import { ReactNode } from "react";

import useDialogProviderState from "~/components/providers/DialogProvider/useDialogProviderState";

import { DialogContext } from "./DialogProviderContext";

type Props = {
  children: ReactNode;
};

const DialogProvider = ({ children }: Props): JSX.Element => {
  const state = useDialogProviderState();

  return (
    <DialogContext.Provider value={state}>{children}</DialogContext.Provider>
  );
};

export default DialogProvider;
