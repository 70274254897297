import Router from "next/router";
import { useEffect, useState } from "react";

import { ROUTER_EVENTS } from "~/constants/events";

import LoadingIndicator from "./LoadingIndicator";

const routerEvents = Router.events;

interface IndicatorMap {
  [key: string]: number;
}

const PageLoadingIndicator = (): JSX.Element => {
  const [indicators, updateIndicators] = useState<IndicatorMap>({});

  const removeIndicator = (path: string): void => {
    updateIndicators((prevIndicators: { [key: string]: number }) => {
      delete prevIndicators[path];

      return { ...prevIndicators };
    });
  };

  useEffect(() => {
    const addIndicator = (path: string): void => {
      const level: number = Object.keys(indicators).length;
      const freshIndicators = {
        ...indicators,
        [path]: level
      };
      updateIndicators(freshIndicators);
    };

    const handleRouteChangeStart = (path: string): void => {
      addIndicator(path);
    };

    routerEvents.on(ROUTER_EVENTS.routeChangeStart, handleRouteChangeStart);

    return (): void => {
      routerEvents.off(ROUTER_EVENTS.routeChangeStart, handleRouteChangeStart);
    };
  }, [indicators]);

  return (
    <>
      {Object.entries(indicators).map(([path, level]) => (
        <LoadingIndicator
          key={path}
          path={path}
          level={level}
          onComplete={removeIndicator}
        />
      ))}
    </>
  );
};

export default PageLoadingIndicator;
