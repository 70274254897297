import { useApolloClient } from "@apollo/client";
import { StringParam, useQueryParam } from "next-query-params";
import { useCallback, useEffect, useState } from "react";

import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { useDialogContext } from "~/components/providers/DialogProvider/DialogProviderContext";
import { hasBankAccountAfterStripeSetup } from "~/components/wallet/Wallet/payout/utils";
import { WALLET_PAYOUT_SETUP_PENDING_DIALOG_VARIANT } from "~/components/wallet/WalletPayoutFlow/WalletPayoutSetupPending/declarations";
import { GetStripeAccountCountry } from "~/declarations/apollo/GetStripeAccountCountry";
import { useApolloErrorSnackbar } from "~/utils/errors";

import { GET_STRIPE_ACCOUNT_COUNTRY_QUERY } from "./graphql";

const RESULT_QUERY_PARAMETER = "payout-accounting-result";

enum BANK_ACCOUNT_SETUP_RESULT {
  success = "success",
  failure = "failure"
}

type Props = {
  readyToCheck: boolean;
};

const useBankAccountSetupResult = ({ readyToCheck }: Props): void => {
  const [initialized, setInitialized] = useState(false);
  const { currentUser } = useAccountLayoutContext();
  const currentUserId = currentUser.data?.id;
  const [resultQueryParameter, setResultQueryParameter] = useQueryParam(
    RESULT_QUERY_PARAMETER,
    StringParam
  );
  const apolloClient = useApolloClient();
  const { openDialog } = useDialogContext();
  const apolloErrorSnackbar = useApolloErrorSnackbar();

  const removeQueryParameter = useCallback(() => {
    setResultQueryParameter(undefined, "replaceIn");
  }, [setResultQueryParameter]);

  useEffect(() => {
    if (
      !currentUserId ||
      !readyToCheck ||
      initialized ||
      (resultQueryParameter !== BANK_ACCOUNT_SETUP_RESULT.success &&
        resultQueryParameter !== BANK_ACCOUNT_SETUP_RESULT.failure)
    ) {
      return;
    }

    const initialize = async (): Promise<void> => {
      try {
        if (resultQueryParameter === BANK_ACCOUNT_SETUP_RESULT.success) {
          const response = await apolloClient.query<GetStripeAccountCountry>({
            query: GET_STRIPE_ACCOUNT_COUNTRY_QUERY,
            fetchPolicy: "no-cache"
          });
          const countryCode = response.data.getStripeAccount?.country;

          const variant =
            countryCode && hasBankAccountAfterStripeSetup(countryCode)
              ? WALLET_PAYOUT_SETUP_PENDING_DIALOG_VARIANT.bankAccount
              : WALLET_PAYOUT_SETUP_PENDING_DIALOG_VARIANT.stripeAccount;

          await openDialog(DIALOG_NAME.payoutSetupPending, {
            variant
          });
        } else if (resultQueryParameter === BANK_ACCOUNT_SETUP_RESULT.failure) {
          await openDialog(DIALOG_NAME.payoutAccountSetupFailed);
        }
      } catch (error) {
        apolloErrorSnackbar(error);
      } finally {
        removeQueryParameter();
      }
    };

    /* Should run only after redirect */
    initialize();
    setInitialized(true);
  }, [
    apolloClient,
    apolloErrorSnackbar,
    currentUserId,
    initialized,
    openDialog,
    readyToCheck,
    removeQueryParameter,
    resultQueryParameter,
    setResultQueryParameter
  ]);
};

export default useBankAccountSetupResult;
