import { number, object, SchemaOf, string } from "yup";

import { SpecificTipStreamDialogOptions } from "~/components/payment/TipStreamDialog/declarations";
import {
  MAX_DONATION_MESSAGE_LENGTH,
  MAX_TIPPING_AMOUNT,
  MAX_TIPPING_AMOUNT_DECIMAL_DIGITS,
  MIN_TIPPING_AMOUNT
} from "~/components/payment/validation";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogConfig,
  DialogOptionsValidationFunction
} from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";
import { isNumberWithDecimalPoint } from "~/utils/validation/decimal";

export const SLIDE_ANIMATION_DURATION_IN_SECONDS = 0.5;

export const INITIAL_AMOUNT_VALIDATION_SCHEMA = number()
  .min(MIN_TIPPING_AMOUNT)
  .max(MAX_TIPPING_AMOUNT)
  .test(
    "MaxDigitsAfterDecimal",
    "Incorrect format",
    isNumberWithDecimalPoint({
      maximumDigitsAfterDecimalPoint: MAX_TIPPING_AMOUNT_DECIMAL_DIGITS,
      optional: true
    })
  );

const VALIDATION_SCHEMA: SchemaOf<SpecificTipStreamDialogOptions> = object({
  postId: string().required(),
  initialAmount: INITIAL_AMOUNT_VALIDATION_SCHEMA,
  initialMessage: string().max(MAX_DONATION_MESSAGE_LENGTH)
})
  .strict()
  .required();

const validateOptions: DialogOptionsValidationFunction = rawOptions =>
  VALIDATION_SCHEMA.isValidSync(rawOptions);

export const TIP_STREAM_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.tipStream> = {
  Component: safeDynamicImport(
    () => import("~/components/payment/TipStreamDialog"),
    {
      ssr: false
    }
  ),
  saveInUrl: true,
  validateOptions
};
