import { SVGProps } from "react";

import { IconVariant } from "~/components/core/Icon/declarations";
import { THEME_MODE } from "~/constants/theme";
import AddFilled from "~/public/assets/icons/AddFilled.svg?sprite";
import AddGradientDark from "~/public/assets/icons/AddGradientDark.svg?sprite";
import AddGradientLight from "~/public/assets/icons/AddGradientLight.svg?sprite";
import AddHypeTVGradientLight from "~/public/assets/icons/AddHypeTVGradientLight.svg?sprite";
import AddNFTGradientLight from "~/public/assets/icons/AddNFTGradientLight.svg?sprite";
import AddOutlined from "~/public/assets/icons/AddOutlined.svg?sprite";
import AddPostGradientLight from "~/public/assets/icons/AddPostGradientLight.svg?sprite";
import AddStreamGradientLight from "~/public/assets/icons/AddStreamGradientLight.svg?sprite";
import AndroidFilled from "~/public/assets/icons/AndroidFilled.svg?sprite";
import AppleFilled from "~/public/assets/icons/AppleFilled.svg?sprite";
import ArrowUp from "~/public/assets/icons/ArrowUp.svg?sprite";
import AstraX from "~/public/assets/icons/AstraX.svg?sprite";
import AttentionFilled from "~/public/assets/icons/AttentionFilled.svg?sprite";
import Balloon from "~/public/assets/icons/Balloon.svg?sprite";
import BalloonFilled from "~/public/assets/icons/BalloonFilled.svg?sprite";
import BankGradient from "~/public/assets/icons/BankGradient.svg?sprite";
import BellFilled from "~/public/assets/icons/BellFilled.svg?sprite";
import BellOutlined from "~/public/assets/icons/BellOutlined.svg?sprite";
import BlogFilled from "~/public/assets/icons/BlogFilled.svg?sprite";
import BlogOutlined from "~/public/assets/icons/BlogOutlined.svg?sprite";
import BookFilled from "~/public/assets/icons/BookFilled.svg?sprite";
import BookOutlined from "~/public/assets/icons/BookOutlined.svg?sprite";
import Burger from "~/public/assets/icons/Burger.svg?sprite";
import Calendar from "~/public/assets/icons/Calendar.svg?sprite";
import Cancel from "~/public/assets/icons/Cancel.svg?sprite";
import Charity from "~/public/assets/icons/Charity.svg?sprite";
import CharityGradient from "~/public/assets/icons/CharityGradient.svg?sprite";
import CharityGradientFilled from "~/public/assets/icons/CharityGradientFilled.svg?sprite";
import CheckMark from "~/public/assets/icons/CheckMark.svg?sprite";
import CheckRounded from "~/public/assets/icons/CheckOutlined.svg?sprite";
import ChevronUp from "~/public/assets/icons/ChevronUp.svg?sprite";
import Clock from "~/public/assets/icons/Clock.svg?sprite";
import ClockFilled from "~/public/assets/icons/ClockFilled.svg?sprite";
import Close from "~/public/assets/icons/Close.svg?sprite";
import CloseFilled from "~/public/assets/icons/CloseFilled.svg?sprite";
import CoinFilled from "~/public/assets/icons/CoinFilled.svg?sprite";
import CoinGradient from "~/public/assets/icons/CoinGradient.svg?sprite";
import Compass from "~/public/assets/icons/Compass.svg?sprite";
import ContactUsFilled from "~/public/assets/icons/ContactUsFilled.svg?sprite";
import ContactUsGradient from "~/public/assets/icons/ContactUsGradient.svg?sprite";
import ContactUsOutlined from "~/public/assets/icons/ContactUsOutlined.svg?sprite";
import Copy from "~/public/assets/icons/Copy.svg?sprite";
import Discover from "~/public/assets/icons/Discover.svg?sprite";
import DiscoverFilled from "~/public/assets/icons/DiscoverFilled.svg?sprite";
import Divider from "~/public/assets/icons/Divider.svg?sprite";
import DocumentFilled from "~/public/assets/icons/DocumentFilled.svg?sprite";
import DocumentOutlined from "~/public/assets/icons/DocumentOutlined.svg?sprite";
import EmailFilled from "~/public/assets/icons/EmailFilled.svg?sprite";
import ExclamationPointFilled from "~/public/assets/icons/ExclamationPointFilled.svg?sprite";
import Expand from "~/public/assets/icons/Expand.svg?sprite";
import Export from "~/public/assets/icons/Export.svg?sprite";
import EyeOutlined from "~/public/assets/icons/EyeOutlined.svg?sprite";
import EyeStrikeout from "~/public/assets/icons/EyeStrikeout.svg?sprite";
import Feedback from "~/public/assets/icons/Feedback.svg?sprite";
import FeedbackFilled from "~/public/assets/icons/FeedbackFilled.svg?sprite";
import FeesFilled from "~/public/assets/icons/FeesFilled.svg?sprite";
import FeesGradient from "~/public/assets/icons/FeesGradient.svg?sprite";
import FeesOutlined from "~/public/assets/icons/FeesOutlined.svg?sprite";
import FilterFilled from "~/public/assets/icons/FilterFilled.svg?sprite";
import FilterOutlined from "~/public/assets/icons/FilterOutlined.svg?sprite";
import FlagFilled from "~/public/assets/icons/FlagFilled.svg?sprite";
import FlagOutlined from "~/public/assets/icons/FlagOutlined.svg?sprite";
import Folder from "~/public/assets/icons/Folder.svg?sprite";
import FolderFilled from "~/public/assets/icons/FolderFilled.svg?sprite";
import Followings from "~/public/assets/icons/Followings.svg?sprite";
import FounderDark from "~/public/assets/icons/FounderDark.svg?sprite";
import FounderLight from "~/public/assets/icons/FounderLight.svg?sprite";
import GearFilled from "~/public/assets/icons/GearFilled.svg?sprite";
import GearOutlined from "~/public/assets/icons/GearOutlined.svg?sprite";
import Gem from "~/public/assets/icons/Gem.svg?sprite";
import GemFilled from "~/public/assets/icons/GemFilled.svg?sprite";
import GifFilled from "~/public/assets/icons/GifFilled.svg?sprite";
import GoogleFilled from "~/public/assets/icons/GoogleFilled.svg?sprite";
import Heart from "~/public/assets/icons/Heart.svg?sprite";
import HeartFilled from "~/public/assets/icons/HeartFilled.svg?sprite";
import Home from "~/public/assets/icons/Home.svg?sprite";
import HomeFilled from "~/public/assets/icons/HomeFilled.svg?sprite";
import HypesFilled from "~/public/assets/icons/HypesFilled.svg?sprite";
import HypesFilledWithGradientLogo from "~/public/assets/icons/HypesFilledWithGradientLogo.svg?sprite";
import HypesGradient from "~/public/assets/icons/HypesGradient.svg?sprite";
import Hypes from "~/public/assets/icons/HypesOutlined.svg?sprite";
import HypeTV from "~/public/assets/icons/HypeTV.svg?sprite";
import HypeTVFilled from "~/public/assets/icons/HypeTVFilled.svg?sprite";
import Image from "~/public/assets/icons/ImageAttachmentOutlined.svg?sprite";
import SquareImageFilled from "~/public/assets/icons/ImageFilled.svg?sprite";
import ImageUploadFilled from "~/public/assets/icons/ImageUploadFilled.svg?sprite";
import InfoCircle from "~/public/assets/icons/InfoCircle.svg?sprite";
import InfoCircleFilled from "~/public/assets/icons/InfoCircleFilled.svg?sprite";
import LayoutHorizontal from "~/public/assets/icons/LayoutHorizontal.svg?sprite";
import LayoutSquare from "~/public/assets/icons/LayoutSquare.svg?sprite";
import LayoutVertical from "~/public/assets/icons/LayoutVertical.svg?sprite";
import LimitFilled from "~/public/assets/icons/LimitFilled.svg?sprite";
import Live from "~/public/assets/icons/Live.svg?sprite";
import LiveFilled from "~/public/assets/icons/LiveFilled.svg?sprite";
import LocationOutlined from "~/public/assets/icons/LocationOutlined.svg?sprite";
import Lock from "~/public/assets/icons/Lock.svg?sprite";
import LockFilled from "~/public/assets/icons/LockFilled.svg?sprite";
import LockWithDots from "~/public/assets/icons/LockWithDots.svg?sprite";
import LockWithDotsFilled from "~/public/assets/icons/LockWithDotsFilled.svg?sprite";
import LogoutOutlined from "~/public/assets/icons/LogoutOutlined.svg?sprite";
import LyingBook from "~/public/assets/icons/LyingBook.svg?sprite";
import MailFilled from "~/public/assets/icons/MailFilled.svg?sprite";
import Manage from "~/public/assets/icons/Manage.svg?sprite";
import ManageFilled from "~/public/assets/icons/ManageFilled.svg?sprite";
import MaximizedOutlined from "~/public/assets/icons/MaximizedOutlined.svg?sprite";
import MediaKit from "~/public/assets/icons/MediaKit.svg?sprite";
import MediaKitFilled from "~/public/assets/icons/MediaKitFilled.svg?sprite";
import MessageWithCrossFilled from "~/public/assets/icons/MessageWithCrossFilled.svg?sprite";
import MinimizedOutlined from "~/public/assets/icons/MinimizedOutlined.svg?sprite";
import More from "~/public/assets/icons/More.svg?sprite";
import MultiContent from "~/public/assets/icons/MultiContent.svg?sprite";
import NFT from "~/public/assets/icons/NFT.svg?sprite";
import NFTFilled from "~/public/assets/icons/NFTFilled.svg?sprite";
import NFTGradient from "~/public/assets/icons/NFTGradient.svg?sprite";
import NFTTransaction from "~/public/assets/icons/NFTTransaction.svg?sprite";
import NFTTransactionFilled from "~/public/assets/icons/NFTTransactionFilled.svg?sprite";
import PaintBrushOutlined from "~/public/assets/icons/PaintBrushOutlined.svg?sprite";
import PencilFilled from "~/public/assets/icons/PencilFilled.svg?sprite";
import PencilOutlined from "~/public/assets/icons/PencilOutlined.svg?sprite";
import PercentageRoundedFilled from "~/public/assets/icons/PercentageRoundedFilled.svg?sprite";
import PercentageRoundedOutlined from "~/public/assets/icons/PercentageRoundedOutlined.svg?sprite";
import PersonWithCrossFilled from "~/public/assets/icons/PersonWithCrossFilled.svg?sprite";
import PlaylistFilled from "~/public/assets/icons/PlaylistFilled.svg?sprite";
import PlayOutlined from "~/public/assets/icons/PlayOutlined.svg?sprite";
import Posts from "~/public/assets/icons/Posts.svg?sprite";
import PostsFilled from "~/public/assets/icons/PostsFilled.svg?sprite";
import Privacy from "~/public/assets/icons/Privacy.svg?sprite";
import PrivacyFilled from "~/public/assets/icons/PrivacyFilled.svg?sprite";
import Processing from "~/public/assets/icons/Processing.svg?sprite";
import QRFilled from "~/public/assets/icons/QRFilled.svg?sprite";
import ReportFilled from "~/public/assets/icons/ReportFilled.svg?sprite";
import ReportGradient from "~/public/assets/icons/ReportGradient.svg?sprite";
import ReportOutlined from "~/public/assets/icons/ReportOutlined.svg?sprite";
import RocketFilled from "~/public/assets/icons/RocketFilled.svg?sprite";
import RocketGradient from "~/public/assets/icons/RocketGradient.svg?sprite";
import RocketOutlined from "~/public/assets/icons/RocketOutlined.svg?sprite";
import Search from "~/public/assets/icons/Search.svg?sprite";
import SendFilled from "~/public/assets/icons/SendFilled.svg?sprite";
import SendOutlined from "~/public/assets/icons/SendOutlined.svg?sprite";
import ShareOutlined from "~/public/assets/icons/ShareOutlined.svg?sprite";
import SharpLight from "~/public/assets/icons/SharpLight.svg?sprite";
import ShieldFilled from "~/public/assets/icons/ShieldFilled.svg?sprite";
import ShieldGradient from "~/public/assets/icons/ShieldGradient.svg?sprite";
import ShieldOutlined from "~/public/assets/icons/ShieldOutlined.svg?sprite";
import Facebook from "~/public/assets/icons/social/Facebook.svg?sprite";
import Telegram from "~/public/assets/icons/social/Telegram.svg?sprite";
import Twitter from "~/public/assets/icons/social/Twitter.svg?sprite";
import WhatsApp from "~/public/assets/icons/social/WhatsApp.svg?sprite";
import SpoilerIcon from "~/public/assets/icons/SpoilerIcon.svg?sprite";
import StarFilled from "~/public/assets/icons/StarFilled.svg?sprite";
import StarOutlined from "~/public/assets/icons/StarOutlined.svg?sprite";
import SuccessFilled from "~/public/assets/icons/SuccessFilled.svg?sprite";
import TeacherFilled from "~/public/assets/icons/TeacherFilled.svg?sprite";
import TeacherGradient from "~/public/assets/icons/TeacherGradient.svg?sprite";
import TeacherOutlined from "~/public/assets/icons/TeacherOutlined.svg?sprite";
import Tips from "~/public/assets/icons/Tips.svg?sprite";
import WalletTransactionIncomeFilled from "~/public/assets/icons/TransactionIncomeFilled.svg?sprite";
import WalletTransactionOutcomeFilled from "~/public/assets/icons/TransactionOutcomeFilled.svg?sprite";
import TrashFilled from "~/public/assets/icons/TrashFilled.svg?sprite";
import Trash from "~/public/assets/icons/TrashOutlined.svg?sprite";
import UserFilled from "~/public/assets/icons/UserFilled.svg?sprite";
import UserGradient from "~/public/assets/icons/UserGradient.svg?sprite";
import UserOutlined from "~/public/assets/icons/UserOutlined.svg?sprite";
import UserRounded from "~/public/assets/icons/UserRounded.svg?sprite";
import UserRoundedFilled from "~/public/assets/icons/UserRoundedFilled.svg?sprite";
import UsersOutlined from "~/public/assets/icons/UsersOutlined.svg?sprite";
import UserWithClear from "~/public/assets/icons/UserWithClear.svg?sprite";
import UserWithClearFilled from "~/public/assets/icons/UserWithClearFilled.svg?sprite";
import UserWithHearth from "~/public/assets/icons/UserWithHeart.svg?sprite";
import UserWithTick from "~/public/assets/icons/UserWithTick.svg?sprite";
import VerifiedDark from "~/public/assets/icons/VerifiedDark.svg?sprite";
import VerifiedLight from "~/public/assets/icons/VerifiedLight.svg?sprite";
import VideoContent from "~/public/assets/icons/VideoContent.svg?sprite";
import VideoContentFilled from "~/public/assets/icons/VideoContentFilled.svg?sprite";
import WalletFilled from "~/public/assets/icons/WalletFilled.svg?sprite";
import WalletGradient from "~/public/assets/icons/WalletGradient.svg?sprite";
import WalletOutlined from "~/public/assets/icons/WalletOutlined.svg?sprite";
import WalletSynchronizationFilled from "~/public/assets/icons/WalletSynchronizationFilled.svg?sprite";
import WalletSynchronizationOutlined from "~/public/assets/icons/WalletSynchronizationOutlined.svg?sprite";
import WalletTransaction from "~/public/assets/icons/WalletTransaction.svg?sprite";
import WalletTransactionFilled from "~/public/assets/icons/WalletTransactionFilled.svg?sprite";
import WalletWithLogoFilled from "~/public/assets/icons/WalletWithLogoFilled.svg?sprite";
import XdbExport from "~/public/assets/icons/XdbExport.svg?sprite";
import XdbWalletFilled from "~/public/assets/icons/XDBWalletFilled.svg?sprite";
import Logo from "~/public/assets/images/Logo.svg?sprite";
import LogoGradient from "~/public/assets/images/LogoGradient.svg?sprite";

type ThemeIcons = { [key in IconVariant]: React.FC<SVGProps<SVGElement>> };

const COMMON_ICONS = {
  /* Add variant for dark theme */
  add: AddOutlined,
  addFilled: AddFilled,
  addHypeTV: AddHypeTVGradientLight,
  addNFT: AddNFTGradientLight,
  addPost: AddPostGradientLight,
  addStream: AddStreamGradientLight,
  androidFilled: AndroidFilled,
  appleFilled: AppleFilled,
  arrowUp: ArrowUp,
  astraX: AstraX,
  attentionFilled: AttentionFilled,
  balloon: Balloon,
  balloonFilled: BalloonFilled,
  bankGradient: BankGradient,
  bell: BellOutlined,
  bellFilled: BellFilled,
  blog: BlogOutlined,
  blogFilled: BlogFilled,
  book: BookOutlined,
  bookFilled: BookFilled,
  burger: Burger,
  calendar: Calendar,
  cancel: Cancel,
  charity: Charity,
  checkMark: CheckMark,
  checkRounded: CheckRounded,
  chevronUp: ChevronUp,
  clock: Clock,
  clockFilled: ClockFilled,
  close: Close,
  closeFilled: CloseFilled,
  coinFilled: CoinFilled,
  coinGradient: CoinGradient,
  compass: Compass,
  contactUs: ContactUsOutlined,
  contactUsFilled: ContactUsFilled,
  contactUsGradient: ContactUsGradient,
  copy: Copy,
  discover: Discover,
  discoverFilled: DiscoverFilled,
  divider: Divider,
  document: DocumentOutlined,
  documentFilled: DocumentFilled,
  emailFilled: EmailFilled,
  exclamationPointFilled: ExclamationPointFilled,
  expand: Expand,
  export: Export,
  eye: EyeOutlined,
  eyeStrikeout: EyeStrikeout,
  facebook: Facebook,
  feedback: Feedback,
  feedbackFilled: FeedbackFilled,
  feesFilled: FeesFilled,
  feesGradient: FeesGradient,
  feesOutlined: FeesOutlined,
  filterOutlined: FilterOutlined,
  filterFilled: FilterFilled,
  flag: FlagOutlined,
  flagFilled: FlagFilled,
  folder: Folder,
  folderFilled: FolderFilled,
  followings: Followings,
  gear: GearOutlined,
  gem: Gem,
  gemFilled: GemFilled,
  gifFilled: GifFilled,
  googleFilled: GoogleFilled,
  heart: Heart,
  heartFilled: HeartFilled,
  home: Home,
  homeFilled: HomeFilled,
  hypeTV: HypeTV,
  hypeTVFilled: HypeTVFilled,
  hypes: Hypes,
  hypesFilled: HypesFilled,
  hypesFilledWithGradientLogo: HypesFilledWithGradientLogo,
  hypesGradient: HypesGradient,
  image: Image,
  imageUploadFilled: ImageUploadFilled,
  infoCircle: InfoCircle,
  infoCircleFilled: InfoCircleFilled,
  layoutHorizontal: LayoutHorizontal,
  layoutSquare: LayoutSquare,
  layoutVertical: LayoutVertical,
  limitFilled: LimitFilled,
  live: Live,
  liveFilled: LiveFilled,
  location: LocationOutlined,
  lock: Lock,
  lockFilled: LockFilled,
  lockWithDots: LockWithDots,
  lockWithDotsFilled: LockWithDotsFilled,
  logout: LogoutOutlined,
  lyingBook: LyingBook,
  mailFilled: MailFilled,
  manage: Manage,
  manageFilled: ManageFilled,
  maximized: MaximizedOutlined,
  mediaKit: MediaKit,
  mediaKitFilled: MediaKitFilled,
  messageWithCrossFilled: MessageWithCrossFilled,
  minimized: MinimizedOutlined,
  more: More,
  multiContent: MultiContent,
  nft: NFT,
  nftFilled: NFTFilled,
  nftGradient: NFTGradient,
  nftTransaction: NFTTransaction,
  nftTransactionFilled: NFTTransactionFilled,
  paintBrush: PaintBrushOutlined,
  pencil: PencilOutlined,
  pencilFilled: PencilFilled,
  percentageRoundedFilled: PercentageRoundedFilled,
  percentageRoundedOutlined: PercentageRoundedOutlined,
  personWithCrossFilled: PersonWithCrossFilled,
  pin: GearOutlined,
  playOutlined: PlayOutlined,
  posts: Posts,
  postsFilled: PostsFilled,
  privacy: Privacy,
  privacyFilled: PrivacyFilled,
  processing: Processing,
  qrFilled: QRFilled,
  report: ReportOutlined,
  reportFilled: ReportFilled,
  reportGradient: ReportGradient,
  rocket: RocketOutlined,
  rocketFilled: RocketFilled,
  rocketGradient: RocketGradient,
  search: Search,
  send: SendOutlined,
  sendFilled: SendFilled,
  share: ShareOutlined,
  sharp: SharpLight,
  shield: ShieldOutlined,
  shieldFilled: ShieldFilled,
  shieldGradient: ShieldGradient,
  spoiler: SpoilerIcon,
  squareImageFilled: SquareImageFilled,
  star: StarOutlined,
  starFilled: StarFilled,
  successFilled: SuccessFilled,
  teacher: TeacherOutlined,
  teacherFilled: TeacherFilled,
  teacherGradient: TeacherGradient,
  telegram: Telegram,
  tips: Tips,
  trash: Trash,
  trashFilled: TrashFilled,
  twitter: Twitter,
  unpin: GearFilled,
  user: UserOutlined,
  userFilled: UserFilled,
  userGradient: UserGradient,
  userHeart: UserWithHearth,
  userRounded: UserRounded,
  userRoundedFilled: UserRoundedFilled,
  userTick: UserWithTick,
  userWithClear: UserWithClear,
  userWithClearFilled: UserWithClearFilled,
  users: UsersOutlined,
  video: VideoContent,
  videoFilled: VideoContentFilled,
  wallet: WalletOutlined,
  walletFilled: WalletFilled,
  walletGradient: WalletGradient,
  whatsapp: WhatsApp,
  walletSynchronizationFilled: WalletSynchronizationFilled,
  walletSynchronizationOutlined: WalletSynchronizationOutlined,
  walletTransaction: WalletTransaction,
  walletTransactionFilled: WalletTransactionFilled,
  walletWithLogoFilled: WalletWithLogoFilled,
  walletTransactionIncomeFilled: WalletTransactionIncomeFilled,
  walletTransactionOutcomeFilled: WalletTransactionOutcomeFilled,
  xdbExport: XdbExport,
  xdbWalletFilled: XdbWalletFilled,
  playlistFilled: PlaylistFilled
};

export const ICON_VARIANT_MAP: {
  [key in THEME_MODE]: ThemeIcons;
} = {
  [THEME_MODE.light]: {
    ...COMMON_ICONS,
    addContent: AddGradientLight,
    logo: Logo,
    logoInverse: LogoGradient,
    verified: VerifiedLight,
    founder: FounderLight,
    charityGradient: CharityGradientFilled
  },
  [THEME_MODE.dark]: {
    ...COMMON_ICONS,
    addContent: AddGradientDark,
    logo: LogoGradient,
    logoInverse: Logo,
    verified: VerifiedDark,
    founder: FounderDark,
    charityGradient: CharityGradient
  }
};
