import { number, object, SchemaOf, string } from "yup";

import {
  MAX_TIPPING_AMOUNT,
  MAX_TIPPING_AMOUNT_DECIMAL_DIGITS,
  MIN_TIPPING_AMOUNT
} from "~/components/payment/validation";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogConfig,
  DialogOptionsValidationFunction
} from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";
import { isNumberWithDecimalPoint } from "~/utils/validation/decimal";

import { SpecificTipPostDialogOptions } from "./declarations";

export const SLIDE_ANIMATION_DURATION_IN_SECONDS = 0.5;

export const INITIAL_AMOUNT_VALIDATION_SCHEMA = number()
  .min(MIN_TIPPING_AMOUNT)
  .max(MAX_TIPPING_AMOUNT)
  .test(
    "MaxDigitsAfterDecimal",
    "Incorrect format",
    isNumberWithDecimalPoint({
      maximumDigitsAfterDecimalPoint: MAX_TIPPING_AMOUNT_DECIMAL_DIGITS,
      optional: true
    })
  );

const VALIDATION_SCHEMA: SchemaOf<SpecificTipPostDialogOptions> = object({
  postId: string().required(),
  initialAmount: INITIAL_AMOUNT_VALIDATION_SCHEMA
})
  .strict()
  .required();

const validateOptions: DialogOptionsValidationFunction = rawOptions =>
  VALIDATION_SCHEMA.isValidSync(rawOptions);

export const TIP_POST_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.tipPost> = {
  Component: safeDynamicImport(
    () => import("~/components/payment/TipPostDialog"),
    {
      ssr: false
    }
  ),
  saveInUrl: true,
  validateOptions
};
