import { useCallback, useState } from "react";

import {
  ANONYMOUS_USER,
  COOKIE_POLICY_ACCEPTED_LOCAL_STORAGE_KEY
} from "~/components/providers/GlobalProvider/constants";
import {
  isPersonAcceptedLS,
  setAcceptedPersonToLS
} from "~/components/providers/GlobalProvider/utils";

import {
  InterestsDialogPayload,
  UserInterestsDialogCloseHandler
} from "./declarations";
import GlobalProvider from "./GlobalProvider";

export interface Props {
  children: React.ReactNode;
}

interface AcceptableDialogState {
  accepted: boolean;
  needToShow: boolean;
}

const GlobalContextProvider = ({ children }: Props): JSX.Element | null => {
  const [greetingNeedToShow, setGreetingNeedToShow] = useState(false);
  const [userInterestsDialog, setUserInterestsDialog] = useState<{
    open: boolean;
    needToShow: boolean;
    cancelLabel?: string;
    submitLabel?: string;
    onClose?: UserInterestsDialogCloseHandler;
  }>({
    open: false,
    needToShow: false
  });
  const [cookiePolicy, setCookiePolicy] = useState<AcceptableDialogState>({
    accepted: false,
    needToShow: false
  });

  const handleAcceptCookiePolicy = useCallback((): void => {
    setAcceptedPersonToLS(
      ANONYMOUS_USER,
      COOKIE_POLICY_ACCEPTED_LOCAL_STORAGE_KEY
    );
    setCookiePolicy({
      accepted: true,
      needToShow: false
    });
  }, []);

  const handleShowCookiePolicyPopup = useCallback((): void => {
    if (
      isPersonAcceptedLS(
        ANONYMOUS_USER,
        COOKIE_POLICY_ACCEPTED_LOCAL_STORAGE_KEY
      )
    ) {
      setCookiePolicy({
        accepted: true,
        needToShow: false
      });
    } else {
      setCookiePolicy({
        accepted: false,
        needToShow: true
      });
    }
  }, []);

  const handleCloseCookiePolicyPopup = useCallback(() => {
    setCookiePolicy(({ accepted }) => ({
      accepted,
      needToShow: false
    }));
  }, []);

  const handleShowUserInterestsPopup = useCallback(
    (payload?: InterestsDialogPayload): void => {
      setUserInterestsDialog({
        open: true,
        needToShow: false,
        ...payload
      });
    },
    []
  );

  const handleCloseUserInterestsPopup = useCallback((): void => {
    userInterestsDialog.onClose?.();

    setUserInterestsDialog({
      needToShow: false,
      open: false
    });
  }, [userInterestsDialog]);

  const setNeedToShowUserInterestsPopup = useCallback(
    (needToShow: boolean): void => {
      setUserInterestsDialog(state => ({
        ...state,
        needToShow
      }));
    },
    []
  );

  return (
    <GlobalProvider
      value={{
        greeting: {
          needToShow: greetingNeedToShow,
          setNeedToShow: setGreetingNeedToShow
        },
        cookiePolicy: {
          showPopup: handleShowCookiePolicyPopup,
          closePopup: handleCloseCookiePolicyPopup,
          accepted: cookiePolicy.accepted,
          needToShow: cookiePolicy.needToShow,
          onAccept: handleAcceptCookiePolicy
        },
        userInterestsDialog: {
          open: userInterestsDialog.open,
          needToShow: userInterestsDialog.needToShow,
          cancelLabel: userInterestsDialog.cancelLabel,
          submitLabel: userInterestsDialog.submitLabel,
          setNeedToShow: setNeedToShowUserInterestsPopup,
          openDialog: handleShowUserInterestsPopup,
          closeDialog: handleCloseUserInterestsPopup
        }
      }}
    >
      {children}
    </GlobalProvider>
  );
};

export default GlobalContextProvider;
