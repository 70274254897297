import { gql } from "@apollo/client";
import { useCallback } from "react";

import { GENERAL_POST_VIDEO_FRAGMENT } from "~/components/post/graphql";
import { PostType } from "~/declarations/apollo/globalTypes";
import {
  MyPostsEvents,
  MyPostsEvents_myPostsEvents_events_EventPostPublished_videos,
  MyPostsEventsVariables
} from "~/declarations/apollo/MyPostsEvents";
import useSubscriptionWithRecovery, {
  OnSubscriptionDataHandler
} from "~/utils/apollo/useSubscriptionWithRecovery";

type PostPublishedHandlerOptions = {
  postId: string;
  postType: PostType;
  videos: MyPostsEvents_myPostsEvents_events_EventPostPublished_videos[] | null;
};

enum POST_EVENT_TYPE {
  published = "EventPostPublished"
}

export type UseRealTimePostPublishedProps = {
  currentUserId?: string;
  onPostPublished: (options: PostPublishedHandlerOptions) => void;
};

const MY_POSTS_EVENTS_SUBSCRIPTION = gql`
  subscription MyPostsEvents($offset: String) {
    myPostsEvents(offset: $offset) {
      offset
      events {
        __typename

        ... on EventPostPublished {
          postId
          postType
          videos {
            ...GeneralPostVideoFragment
          }
        }
      }
    }
  }

  ${GENERAL_POST_VIDEO_FRAGMENT}
`;

export const useRealTimePostPublished = ({
  currentUserId,
  onPostPublished
}: UseRealTimePostPublishedProps): void => {
  const handleSubscriptionData: OnSubscriptionDataHandler<
    MyPostsEvents,
    "myPostsEvents"
  > = useCallback(
    ({ data }): void => {
      data.events.forEach(event => {
        if (event.__typename === POST_EVENT_TYPE.published) {
          const { postId, postType, videos } = event;

          onPostPublished({
            postId,
            postType,
            videos
          });
        }
      });
    },
    [onPostPublished]
  );

  useSubscriptionWithRecovery<
    MyPostsEvents,
    "myPostsEvents",
    MyPostsEventsVariables
  >({
    subscription: MY_POSTS_EVENTS_SUBSCRIPTION,
    key: "myPostsEvents",
    skip: !currentUserId,
    onSubscriptionData: handleSubscriptionData
  });
};
