import { sendSentryReport } from "~/components/core/ProblemReportDialog/utils";
import {
  RE_CAPTCHA_BADGE_SELECTOR,
  RE_CAPTCHA_CAN_NOT_CONNECT_TO_SERVICES_ERROR,
  RE_CAPTCHA_CONTAINER_CLASS_NAME,
  RE_CAPTCHA_DYNAMIC_SCRIPT_FILE,
  RE_CAPTCHA_DYNAMIC_SCRIPT_HOST_NAME,
  RE_CAPTCHA_STATIC_SCRIPT_SELECTOR
} from "~/components/providers/ReCaptchaProvider/constants";
import { ReCaptchaScriptProps } from "~/components/providers/ReCaptchaProvider/declarations";
import { isNotNull, isServerSide } from "~/utils/common";

type GetActualReCaptchaInstanceOptions = {
  useEnterprise: boolean;
};

export const getActualReCaptchaInstance = ({
  useEnterprise
}: GetActualReCaptchaInstanceOptions): GoogleReCaptchaInstance | null => {
  if (isServerSide() || !window.grecaptcha) {
    return null;
  }

  if (!useEnterprise) {
    return window.grecaptcha;
  }

  return window.grecaptcha.enterprise ?? null;
};

const cleanBadge = (): void => {
  const nodeBadge = document.querySelector(RE_CAPTCHA_BADGE_SELECTOR);

  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode);
  }
};

const getDynamicReCaptchaScriptSelector = (scriptId: string): string =>
  `#${scriptId}`;

const cleanScript = (selector: string): void => {
  const script = document.querySelector(selector);

  if (script) {
    script.remove();
  }
};

export const cleanGoogleReCaptcha = (scriptId: string): void => {
  cleanBadge();

  const dynamicReCaptchaScriptSelector =
    getDynamicReCaptchaScriptSelector(scriptId);

  cleanScript(dynamicReCaptchaScriptSelector);
  cleanScript(RE_CAPTCHA_STATIC_SCRIPT_SELECTOR);
};

type GetDynamicReCaptchaScriptElementOptions = {
  useEnterprise: boolean;
  useReCaptchaNet: boolean;
  scriptProps: ReCaptchaScriptProps;
  language?: string;
  onLoad: () => void;
  onError: OnErrorEventHandler;
};

const createDynamicReCaptchaScriptElement = ({
  useEnterprise,
  useReCaptchaNet,
  scriptProps: { id, nonce, async, defer },
  language,
  onLoad,
  onError
}: GetDynamicReCaptchaScriptElementOptions): HTMLScriptElement => {
  const script = document.createElement("script");

  script.id = id;
  script.src = getDynamicReCaptchaScriptSource({
    useEnterprise,
    useReCaptchaNet,
    language
  });

  if (isNotNull(nonce)) {
    script.nonce = nonce;
  }

  script.defer = defer === true;
  script.async = async === true;
  script.onload = onLoad;
  script.onerror = onError;

  return script;
};

type InjectGoogleReCaptchaScriptOptions = {
  useReCaptchaNet: boolean;
  useEnterprise: boolean;
  scriptProps: ReCaptchaScriptProps;
  language?: string;
  onLoad: () => void;
  onError: OnErrorEventHandler;
};

export const injectGoogleReCaptchaScript = ({
  language,
  onLoad,
  onError,
  useReCaptchaNet,
  useEnterprise,
  scriptProps
}: InjectGoogleReCaptchaScriptOptions): void => {
  if (isDynamicScriptInjected(scriptProps.id)) {
    onLoad();

    return;
  }

  const script = createDynamicReCaptchaScriptElement({
    language,
    useEnterprise,
    useReCaptchaNet,
    scriptProps,
    onError,
    onLoad
  });

  const elementToInjectScript =
    scriptProps.appendTo === "body"
      ? document.body
      : document.getElementsByTagName("head")[0];

  elementToInjectScript.appendChild(script);
};

export const isDynamicScriptInjected = (scriptId: string): boolean => {
  const selector = getDynamicReCaptchaScriptSelector(scriptId);
  const script = document.querySelector(selector);

  return isNotNull(script);
};

type GetDynamicReCaptchaScriptSourceOptions = {
  useReCaptchaNet: boolean;
  useEnterprise: boolean;
  language?: string;
};

const getDynamicReCaptchaScriptSource = ({
  useReCaptchaNet,
  useEnterprise,
  language
}: GetDynamicReCaptchaScriptSourceOptions): string => {
  const hostName = useReCaptchaNet
    ? RE_CAPTCHA_DYNAMIC_SCRIPT_HOST_NAME.reCaptchaNet
    : RE_CAPTCHA_DYNAMIC_SCRIPT_HOST_NAME.google;
  const script = useEnterprise
    ? RE_CAPTCHA_DYNAMIC_SCRIPT_FILE.enterprise
    : RE_CAPTCHA_DYNAMIC_SCRIPT_FILE.public;

  const pathname = `https://www.${hostName}/recaptcha/${script}`;
  const queryString = `render=explicit${language ? `&hl=${language}` : ""}`;

  const source = `${pathname}?${queryString}`;

  return source;
};

export const logWarningMessage = (message: string): void => {
  const isDevelopmentMode =
    !!process.env && process.env.NODE_ENV !== "production";

  if (isDevelopmentMode) {
    return;
  }

  console.warn(message);
};

/* To prevent reCAPTCHA errors, render it manually to a form element: https://github.com/google/recaptcha/issues/269 */
export const createReCaptchaContainer = (): HTMLElement => {
  const container = document.createElement("form");
  container.className = RE_CAPTCHA_CONTAINER_CLASS_NAME;

  return container;
};

export const mountReCaptchaContainer = (container: HTMLElement): void => {
  document.body.append(container);
};

/* HACK to track reCAPTCHA connection errors */
export const createLayoutErrorObserver = (
  container: HTMLElement
): MutationObserver | null => {
  if (typeof window.MutationObserver === "undefined") {
    return null;
  }

  const observer = new window.MutationObserver((mutations, observer) => {
    for (const mutation of mutations) {
      if (mutation.type === "childList" && mutation.addedNodes.length) {
        const elementWithError = mutation.addedNodes[0];

        if (
          elementWithError.textContent ===
          RE_CAPTCHA_CAN_NOT_CONNECT_TO_SERVICES_ERROR
        ) {
          logReCaptchaError("Could not connect to the reCAPTCHA service.");
          observer.disconnect();
        }
      }
    }
  });

  observer.observe(container, {
    childList: true
  });

  return observer;
};

export const logReCaptchaError = (message: string): void => {
  sendSentryReport({
    email: "",
    message
  });
  console.error(message);
};
