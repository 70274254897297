import { useCallback, useState } from "react";

export type OpenDialogHandler<T> = (newPayload?: T) => void;
export type SetPayloadDialogHandler<T> = (newPayload: T) => void;
export type CloseDialogHandler = (resetState?: unknown) => void;

interface UseDialogHookOptions {
  forceResetPayloadOnClose?: boolean;
}

interface UseDialogHookArguments<T> {
  initialOpen?: boolean;
  initialPayload?: T;
  options?: UseDialogHookOptions;
}

export interface UseDialogHookResult<T> {
  open: boolean;
  hasBeenOpened: boolean;
  payload?: T;
  openDialog: OpenDialogHandler<T>;
  closeDialog: CloseDialogHandler;
  setPayload: SetPayloadDialogHandler<T>;
}

interface UseDialogHookState<T> {
  open: boolean;
  payload?: T;
  options?: UseDialogHookOptions;
}

const useDialog = <T>({
  initialOpen = false,
  initialPayload,
  options = {}
}: UseDialogHookArguments<T> = {}): UseDialogHookResult<T> => {
  const [{ open, payload }, setState] = useState<UseDialogHookState<T>>({
    open: initialOpen,
    payload: initialPayload,
    options
  });

  const [hasBeenOpened, setAlreadyWasOpen] = useState(false);

  const setPayload = useCallback((newPayload: T): void => {
    setState(state => ({ ...state, payload: newPayload }));
  }, []);

  const closeDialog = useCallback((resetState): void => {
    setState(state => ({
      ...state,
      payload:
        state.options?.forceResetPayloadOnClose || resetState === true
          ? undefined
          : state.payload,
      open: false
    }));
  }, []);

  const openDialog = useCallback(
    (newPayload?: T): void => {
      if (!hasBeenOpened) {
        setAlreadyWasOpen(true);
      }

      setState(state => ({
        ...state,
        open: true,
        payload: newPayload ?? state.payload
      }));
    },
    [hasBeenOpened]
  );

  return {
    open,
    hasBeenOpened,
    payload,
    setPayload,
    openDialog,
    closeDialog
  };
};

export default useDialog;
