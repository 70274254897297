import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export enum STRIPE_CREATE_TOKEN_ERROR_CODE {
  routingNumberInvalid = "routing_number_invalid",
  accountNumberInvalid = "account_number_invalid"
}

export enum WALLET_PAYOUT_BANK_ACCOUNT_SETUP_DIALOG_ERROR_CODE {
  impossibleToFetchCountrySpecification = "impossibleToFetchCountrySpecification"
}

export const WALLET_PAYOUT_BANK_ACCOUNT_SETUP_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.payoutBankAccountSetup> =
  {
    Component: safeDynamicImport(
      () =>
        import(
          "~/components/wallet/WalletPayoutFlow/WalletPayoutBankAccountSetupDialog"
        ),
      { ssr: false }
    )
  };
