export enum WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE {
  general,
  fatal,
  walletAlreadySynched
}

export type SpecificWalletSynchronizationErrorDialogOptions = {
  description: string;
  type: WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE;
};
