import { useCallback, useRef } from "react";

import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogResult } from "~/components/providers/DialogProvider/declarations/results";
import {
  DialogPromiseCallbacks,
  PromiseCallbacksState
} from "~/components/providers/DialogProvider/declarations/state";
import { isNotNull } from "~/utils/common";

type UseDialogPromisesResult = {
  setPromiseCallbacks: (
    name: DIALOG_NAME,
    callbacks: DialogPromiseCallbacks<DIALOG_NAME>
  ) => void;
  resolveAndDeletePromise: (
    name: DIALOG_NAME,
    result: DialogResult<DIALOG_NAME>
  ) => void;
  resetPromiseCallbacks: () => void;
  hasPromiseCallbacks: (name: DIALOG_NAME) => boolean;
};

const createPromiseCallbacksState = (): PromiseCallbacksState => ({});

const useDialogPromises = (): UseDialogPromisesResult => {
  const promiseCallbacks = useRef<PromiseCallbacksState>(
    createPromiseCallbacksState()
  );

  const setPromiseCallbacks = useCallback(
    (name: DIALOG_NAME, callbacks: DialogPromiseCallbacks<DIALOG_NAME>) => {
      promiseCallbacks.current[name] = callbacks;
    },
    []
  );

  const resolveAndDeletePromise = useCallback(
    (name: DIALOG_NAME, result: DialogResult<DIALOG_NAME>) => {
      const callbacks = promiseCallbacks.current[name];

      if (callbacks) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore uncomment when ts will support complex unions
        callbacks.resolve(result);

        delete promiseCallbacks.current[name];
      }
    },
    []
  );

  const resetPromiseCallbacks = useCallback(() => {
    promiseCallbacks.current = createPromiseCallbacksState();
  }, []);

  const hasPromiseCallbacks = useCallback(
    (name: DIALOG_NAME) => isNotNull(promiseCallbacks.current[name]),
    []
  );

  return {
    setPromiseCallbacks,
    resolveAndDeletePromise,
    resetPromiseCallbacks,
    hasPromiseCallbacks
  };
};

export default useDialogPromises;
