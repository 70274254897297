import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";

export const ConfirmationDialogWrapper = styled(Dialog).attrs<{
  order?: number;
}>(({ theme, order = 0 }) => ({
  style: { zIndex: theme.zIndex.modal + order }
}))<{
  order?: number;
}>`
  margin: 0;

  .MuiDialog-paper {
    background: ${({ theme }) => theme.newTheme.background.octonary};
    border-radius: ${({ fullScreen }) => (fullScreen ? 0 : 24)}px;
    padding-top: 40px;

    ${({ theme }) => theme.breakpoints.up("md")} {
      max-width: 562px;
      width: 100%;
    }
  }
`;
