import { gql } from "@apollo/client";

export const NFT_EDITION_OWNERSHIP_QUERY = gql`
  query NftEditionOwnershipHistory(
    $editionId: String!
    $first: Int
    $after: String
  ) {
    getEditionOwnershipHistory(
      editionId: $editionId
      first: $first
      after: $after
    ) @connection(key: "getEditionOwnershipHistory", filter: ["editionId"]) {
      edges {
        cursor
        node {
          id
          newOwner {
            id
            name
            nickname
            status
            isAnonymous
            avatar {
              little
            }
            isFounder
            isCharity
            validation
          }
          cost
          changedAt
          minting
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
