import { useRouter } from "next/router";
import { useEffect } from "react";

import { ROUTER_EVENTS } from "~/constants/events";

export function useRouteChangeCallback(callback: (path: string) => void): void {
  const router = useRouter();

  useEffect(() => {
    router.events.on(ROUTER_EVENTS.routeChangeStart, callback);
    return (): void => {
      router.events.off(ROUTER_EVENTS.routeChangeStart, callback);
    };
  }, [callback, router.events]);
}
