import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const XDB_NFT_IMPORT_SUCCESS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.xdbNftImportSuccess> =
  {
    Component: safeDynamicImport(
      () => import("~/components/xdb/import/XdbNftImportSuccessDialog"),
      { ssr: false }
    )
  };
