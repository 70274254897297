import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import {
  ActivityType,
  NotificationStatus
} from "~/declarations/apollo/globalTypes";
import {
  Notifications,
  Notifications_notifications_edges,
  NotificationsVariables
} from "~/declarations/apollo/Notifications";

import { NOTIFICATIONS_QUERY } from "./graphql";

const notificationEdgeUnread = (
  edge: Notifications_notifications_edges
): boolean => edge.node.status !== NotificationStatus.read;

const useUnreadNotifications = (
  initialNumberOfUnreadNotifications?: number
): number => {
  const notificationsQuery = useQuery<Notifications, NotificationsVariables>(
    NOTIFICATIONS_QUERY,
    {
      variables: {
        activityType: ActivityType.notifications
      },
      fetchPolicy: "cache-only"
    }
  );

  const unreadNotifications = useMemo(() => {
    const fetchedNotifications = notificationsQuery.data;

    if (!fetchedNotifications) {
      return initialNumberOfUnreadNotifications ?? 0;
    }

    const hasNotifications = fetchedNotifications.notifications.edges.filter(
      notificationEdgeUnread
    ).length;

    return hasNotifications;
  }, [initialNumberOfUnreadNotifications, notificationsQuery.data]);

  return unreadNotifications;
};

export default useUnreadNotifications;
