import { useQuery } from "@apollo/client/react/hooks";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setUser as setSentryUser } from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { AuthDialogPayload } from "~/components/auth/declarations";
import GreetingSnackbar from "~/components/auth/GreetingSnackbar";
import useRestoreSignupDialog from "~/components/layouts/AccountLayout/useRestoreSignupDialog";
import MessagingProvider from "~/components/messaging/providers/MessagingProvider";
import NftReportProvider from "~/components/nft/NftReportProvider/NftReportProvider";
import useRealTimeNotifications from "~/components/notifications/useRealTimeNotifications";
import usePostPublishedStatusRealTimeUpdateAfter from "~/components/post/usePostRealTimeUpdate";
import { useAnalyticsContext } from "~/components/providers/AnalyticsProvider/AnalyticsContext";
import { CUSTOM_USER_PROPERTY } from "~/components/providers/AnalyticsProvider/constants";
import DialogProvider from "~/components/providers/DialogProvider";
import DialogMountPoint from "~/components/providers/DialogProvider/DialogMountPoint";
import { useGlobalContext } from "~/components/providers/GlobalProvider/GlobalProvider";
import NotificationsPopupProvider from "~/components/providers/NotificationsPopupProvider";
import PostDeletionProvider from "~/components/providers/PostDeletionProvider/PostDeletionProvider";
import PostReportProvider from "~/components/providers/PostReportProvider/PostReportProvider";
import { useProblemReportDialogContext } from "~/components/providers/ProblemReportDialogProvider/ProblemReportDialogContext";
import SearchPopupProvider from "~/components/providers/SearchPopupProvider";
import UnreadNotificationProvider from "~/components/providers/UnreadNotificationsProvider";
import UserInterestsDialogContextProvider from "~/components/providers/UserInterestsDialogProvider/UserInterestsDialogContextProvider";
import WhatsInsideContextProvider from "~/components/providers/WhatsInsideProvider/WhatsInsideContextProvider";
import WalletProvider from "~/components/wallet/providers/WalletProvider";
import ROUTES from "~/constants/routes";
import { LAYOUT_SIZE } from "~/constants/theme";
import { Me } from "~/declarations/apollo/Me";
import AuthService from "~/services/AuthService";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";
import useEmailConfirmationSubmit from "~/utils/useEmailConfirmationSubmit";

import AccountHeader from "./AccountHeader";
import AccountLayoutProvider from "./AccountLayoutProvider";
import { ME_QUERY } from "./graphql";

const UsernameReservationDialog = safeDynamicImport(
  () =>
    import("~/components/auth/usernameReservation/UsernameReservationDialog"),
  { ssr: false }
);

const SignupDialog = safeDynamicImport(
  () => import("~/components/auth/signup/SignupDialog"),
  { ssr: false }
);

const LoginDialog = safeDynamicImport(
  () => import("~/components/auth/login/LoginDialog/LoginDialog"),
  { ssr: false }
);

const ForgotPasswordDialog = safeDynamicImport(
  () => import("~/components/auth/resetPassword/ResetPasswordDialog"),
  { ssr: false }
);

const CookiePolicyPopup = safeDynamicImport(
  () => import("~/components/appTerms/CookiePolicyPopup"),
  { ssr: false }
);

interface Props {
  children: React.ReactNode;
  size?: LAYOUT_SIZE;
  hideHeaderForUnauthorizedUser?: boolean;
}

const AccountLayout = ({
  children,
  size = LAYOUT_SIZE.full,
  hideHeaderForUnauthorizedUser = false
}: Props): JSX.Element => {
  const { pathname } = useRouter();
  const theme = useTheme();
  const { setUserId, setCustomUserProperty } = useAnalyticsContext();
  const { handleSetReportUser } = useProblemReportDialogContext();
  const usernameReservationInsteadOfSignup =
    process.env.NEXT_PUBLIC_USERNAME_RESERVATION_INSTEAD_OF_SIGNUP === "true";

  const {
    greeting,
    cookiePolicy: {
      accepted: cookiePolicyAccepted,
      needToShow: cookiePolicyPopupNeedToShow,
      onAccept: onAcceptCookiePolicy,
      showPopup: showCookiePolicyPopup,
      closePopup: closeCookiePolicyPopup
    },
    userInterestsDialog
  } = useGlobalContext();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const [authorized, setAuthorized] = useState(false);
  const [authorizationStatusFetched, setAuthorizationStatusFetched] =
    useState(false);
  const loginDialog = useDialog<AuthDialogPayload>();
  const signUpDialog = useDialog();
  const forgotPasswordDialog = useDialog();
  useEmailConfirmationSubmit(authorized);

  const meQuery = useQuery<Me>(ME_QUERY);
  const meData = meQuery.data?.me;

  const meId = meData?.id;
  const hideHeader =
    pathname === ROUTES.index &&
    (!authorizationStatusFetched ||
      (hideHeaderForUnauthorizedUser && !authorized));

  const fetchAuthorizationStatus = (): void => {
    const authorized = AuthService.isAuthorizedClient();
    setAuthorized(authorized);
    setAuthorizationStatusFetched(true);
  };

  useRealTimeNotifications(meId);
  usePostPublishedStatusRealTimeUpdateAfter(meId);

  useEffect(() => {
    fetchAuthorizationStatus();
  }, []);

  useEffect(() => {
    const unauthorizedOnMainPage = ROUTES.index === pathname && !authorized;

    if (
      !authorizationStatusFetched ||
      (authorized && !meId) ||
      unauthorizedOnMainPage
    ) {
      if (cookiePolicyPopupNeedToShow) {
        closeCookiePolicyPopup();
      }

      return;
    }

    showCookiePolicyPopup(meId);
  }, [
    authorizationStatusFetched,
    authorized,
    cookiePolicyAccepted,
    closeCookiePolicyPopup,
    cookiePolicyPopupNeedToShow,
    meId,
    pathname,
    showCookiePolicyPopup
  ]);

  useEffect(() => {
    if (meData) {
      handleSetReportUser(meData);
      setSentryUser({
        id: meData.id,
        email: meData.email,
        username: meData.nickname
      });
      setUserId(meData.id);
      setCustomUserProperty(CUSTOM_USER_PROPERTY.email, meData.email);
    }
  }, [meData, setUserId, setCustomUserProperty, handleSetReportUser]);

  const handleLoginSuccess = (): void => {
    loginDialog.closeDialog();
    setAuthorized(true);
    greeting.setNeedToShow?.(true);
    userInterestsDialog.setNeedToShow(true);
  };

  const handleClickSignUp = (): void => {
    loginDialog.closeDialog();
    signUpDialog.openDialog();
  };

  const handleClickForgotPasswordInLoginDialog = (): void => {
    forgotPasswordDialog.openDialog();
  };

  const handleClickLogin = (): void => {
    signUpDialog.closeDialog();
    loginDialog.openDialog();
  };

  const handleSuccessSignupDialog = (): void => {
    fetchAuthorizationStatus();
  };

  const handleAcceptCookiePolicy = (): void => {
    onAcceptCookiePolicy(meQuery.data?.me.id);
  };

  useRestoreSignupDialog(signUpDialog.openDialog);

  return (
    <WhatsInsideContextProvider>
      <AccountLayoutProvider
        value={{
          authorized,
          authorizationStatusFetched,
          loginDialog,
          signUpDialog,
          mobileView,
          fetchAuthorizationStatus,
          currentUser: {
            data: meData,
            refetch: meQuery.refetch,
            loading: meQuery.loading,
            called: meQuery.called
          }
        }}
      >
        <DialogProvider>
          <WalletProvider currentUser={meQuery.data?.me}>
            <NftReportProvider>
              <PostReportProvider>
                <PostDeletionProvider>
                  <NotificationsPopupProvider
                    authorized={authorized}
                    authorizationStatusFetched={authorizationStatusFetched}
                  >
                    <UnreadNotificationProvider
                      initialNumberOfUnreadNotifications={
                        meData?.stats.unreadNotifications
                      }
                    >
                      <UserInterestsDialogContextProvider>
                        {meQuery.data && (
                          <GreetingSnackbar userName={meQuery.data.me.name} />
                        )}
                        <SearchPopupProvider>
                          <MessagingProvider
                            currentUserId={meQuery.data?.me.id}
                            initialUnreadChannels={
                              meData?.stats.unreadConversations
                            }
                          >
                            <DialogMountPoint />

                            <Wrapper>
                              {hideHeader ? (
                                <ContentFullScreen>
                                  {children}
                                </ContentFullScreen>
                              ) : (
                                <>
                                  <AccountHeader />
                                  <Content size={size}>{children}</Content>
                                </>
                              )}
                            </Wrapper>
                            {signUpDialog.hasBeenOpened &&
                              (usernameReservationInsteadOfSignup ? (
                                <UsernameReservationDialog
                                  open={signUpDialog.open}
                                  onClose={signUpDialog.closeDialog}
                                />
                              ) : (
                                <SignupDialog
                                  open={signUpDialog.open}
                                  onClose={signUpDialog.closeDialog}
                                  onClickLogin={handleClickLogin}
                                  onSuccess={handleSuccessSignupDialog}
                                />
                              ))}

                            {!authorized && (
                              <>
                                {loginDialog.hasBeenOpened && (
                                  <LoginDialog
                                    open={loginDialog.open}
                                    reason={loginDialog.payload?.reason}
                                    onSuccess={handleLoginSuccess}
                                    onClose={loginDialog.closeDialog}
                                    onClickSignUp={handleClickSignUp}
                                    onClickForgotPassword={
                                      handleClickForgotPasswordInLoginDialog
                                    }
                                  />
                                )}
                                {forgotPasswordDialog.hasBeenOpened && (
                                  <ForgotPasswordDialog
                                    open={forgotPasswordDialog.open}
                                    onClose={forgotPasswordDialog.closeDialog}
                                    onClickLoginButton={handleClickLogin}
                                  />
                                )}
                              </>
                            )}

                            {cookiePolicyPopupNeedToShow && (
                              <CookiePolicyPopup
                                open={cookiePolicyPopupNeedToShow}
                                onClose={handleAcceptCookiePolicy}
                              />
                            )}
                          </MessagingProvider>
                        </SearchPopupProvider>
                      </UserInterestsDialogContextProvider>
                    </UnreadNotificationProvider>
                  </NotificationsPopupProvider>
                </PostDeletionProvider>
              </PostReportProvider>
            </NftReportProvider>
          </WalletProvider>
        </DialogProvider>
      </AccountLayoutProvider>
    </WhatsInsideContextProvider>
  );
};

export default AccountLayout;

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const Content = styled.main<{
  size?: LAYOUT_SIZE;
}>`
  margin: 0 auto;
  max-width: ${({ theme, size = LAYOUT_SIZE.full }): string =>
    theme.layouts.account.sizes[size]};
  padding-top: ${({ theme }): number => theme.layouts.account.headerHeight}px;
  width: 100%;
`;

const ContentFullScreen = styled.main`
  width: 100%;
`;
