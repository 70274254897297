import { useDialogContext } from "~/components/providers/DialogProvider/DialogProviderContext";

/* Mount point was separated from main hook so that created dialogs can use other contexts, for example: useWalletProvider, useDialogContext, etc.  */
const DialogMountPoint = (): JSX.Element => {
  const { dialogs } = useDialogContext();

  return <>{dialogs}</>;
};

export default DialogMountPoint;
