import { gql } from "@apollo/client";

export const PREVIEW_VIDEO_FRAGMENT = gql`
  fragment VideoPreviewFields on VideoPreview {
    id
    __typename
    videoId
    publicUrl
    width
    height
    duration
    cover {
      thumbnails {
        little {
          url
        }
        middle {
          url
        }
        big {
          url
        }
      }
    }
  }
`;

const LONG_POST_EDITABLE_FRAGMENT = gql`
  fragment LongPostEditFields on LongPost {
    id
    confidentiality
    __typename
    title
    paywall {
      cost
      preview
    }
    previewInfo {
      addToMyPosts
      previewType
      video {
        ...VideoPreviewFields
      }
      videoCustomSegment {
        ...VideoPreviewFields
      }
      videoStartSegment {
        ...VideoPreviewFields
      }
    }
    settings {
      isEnabledComment
      enabledTipping
      isAdult
      location {
        id
        name
      }
    }
    video {
      id
      videoId
      publicUrl
      duration
      cover {
        thumbnails {
          little {
            url
          }
          middle {
            url
          }
        }
      }
    }
    richText {
      __typename
      text
    }
  }
  ${PREVIEW_VIDEO_FRAGMENT}
`;

export const UPDATE_LONG_POST_MUTATION = gql`
  mutation UpdateLongPost($request: UpdateLongPostRequest!) {
    updateLongPost(request: $request) {
      id
    }
  }
`;

export const GET_LONG_POST_DRAFT_MUTATION = gql`
  mutation LongPostDraft {
    createProfileLongPostDraft {
      ...LongPostEditFields
    }
  }
  ${LONG_POST_EDITABLE_FRAGMENT}
`;

export const EDIT_LONG_POST_QUERY = gql`
  query LongPostEdit($postId: String!) {
    getLongPost(postId: $postId) {
      ...LongPostEditFields
    }
  }
  ${LONG_POST_EDITABLE_FRAGMENT}
`;

export const PUBLISH_LONG_POST_DRAFT_MUTATION = gql`
  mutation PublishLongPostDraft($postId: String!) {
    publishLongDraft(postId: $postId) {
      id
      status
    }
  }
`;

export const ATTACH_VIDEO_TO_LONG_POST_MUTATION = gql`
  mutation AttachVideoToLongPost($postId: String!, $videoId: String!) {
    attachLongVideoToPost(postId: $postId, videoId: $videoId) {
      id
    }
  }
`;

export const ADD_PREVIEW_TO_LONG_POST_MUTATION = gql`
  mutation AddLongPostPreview($postId: String!, $videoId: String!) {
    attachPreviewVideoToLongPost(postId: $postId, videoId: $videoId)
  }
`;

export const REMOVE_PREVIEW_FROM_LONG_POST_MUTATION = gql`
  mutation RemoveLongPostPreview($postId: String!) {
    removeVideoPreview(postId: $postId)
  }
`;
