import { ApolloError, QueryLazyOptions } from "@apollo/client";

import {
  MESSAGING_GROUP_CONVERSATION_TYPENAME,
  MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME
} from "~/components/messaging/constants";
import { MessagingConversation } from "~/components/messaging/declarations/conversations";
import { MY_CONVERSATIONS_QUERY } from "~/components/messaging/graphql/conversations";
import {
  MyConversations,
  MyConversations_myConversations_edges_node,
  MyConversationsVariables
} from "~/declarations/apollo/MyConversations";
import { usePagination } from "~/utils/apollo/usePagination";

export interface UseMessagingConversationsResult {
  items: MessagingConversation[];
  called: boolean;
  loading: boolean;
  hasMore: boolean;
  error?: ApolloError;
  totalCount: number;
  fetch: (
    options?: QueryLazyOptions<MyConversationsVariables> | undefined
  ) => void;
  fetchMore: (
    variables?: MyConversationsVariables | undefined
  ) => Promise<boolean>;
}

const useMessagingConversations = (): UseMessagingConversationsResult => {
  const {
    items,
    called,
    loading,
    hasMore,
    fetch,
    fetchMore,
    result,
    totalCount
  } = usePagination<
    MyConversations,
    "myConversations",
    MyConversationsVariables
  >(MY_CONVERSATIONS_QUERY, "myConversations", {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  });

  function isNotDetailedConversation(
    conversation: MyConversations_myConversations_edges_node
  ): conversation is MessagingConversation {
    return (
      conversation.__typename === MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME ||
      conversation.__typename === MESSAGING_GROUP_CONVERSATION_TYPENAME
    );
  }

  const conversations = items.filter<MessagingConversation>(
    isNotDetailedConversation
  );

  return {
    items: conversations,
    called,
    loading,
    hasMore,
    fetch,
    fetchMore,
    totalCount: totalCount ?? 0,
    error: result.error
  };
};

export default useMessagingConversations;
