import {
  FastFoodFeed_discoverFastFoodFeed_edges_node_richText,
  FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_HashtagPart,
  FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_MentionPart,
  FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_PlainPart,
  FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_UrlPart
} from "~/declarations/apollo/FastFoodFeed";
import { UrlPartType } from "~/declarations/apollo/globalTypes";

export const RICH_TEXT_TYPENAME: FastFoodFeed_discoverFastFoodFeed_edges_node_richText["__typename"] =
  "RichText";
export const RICH_TEXT_PART_PLAIN_PART_TYPENAME: FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_PlainPart["__typename"] =
  "PlainPart";
export const RICH_TEXT_PART_URL_PART_TYPENAME: FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_UrlPart["__typename"] =
  "UrlPart";
export const RICH_TEXT_PART_MENTION_PART_TYPENAME: FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_MentionPart["__typename"] =
  "MentionPart";
export const RICH_TEXT_PART_HASHTAG_PART_TYPENAME: FastFoodFeed_discoverFastFoodFeed_edges_node_richText_parts_HashtagPart["__typename"] =
  "HashtagPart";

export const RICH_TEXT_PART_TYPENAME = {
  plain: RICH_TEXT_PART_PLAIN_PART_TYPENAME,
  url: RICH_TEXT_PART_URL_PART_TYPENAME,
  mention: RICH_TEXT_PART_MENTION_PART_TYPENAME,
  hashtag: RICH_TEXT_PART_HASHTAG_PART_TYPENAME
};

export const EXTERNAL_LINK_TYPES = {
  [UrlPartType.email]: true,
  [UrlPartType.external]: true
};

export const INTERNAL_LINK_TYPES = {
  [UrlPartType.internal]: true
};
