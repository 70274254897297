import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps
} from "styled-components";

import { isString } from "~/utils/common";

export const adaptiveWrapperMixinOld = css`
  @media (max-width: ${({ theme }): number => theme.breakpoints.values.lg}px) {
    padding: 0 10px;
  }

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.md}px) {
    padding: 0 15px;
  }

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    padding: 0 10px;
  }
`;

export const adaptiveWrapperMixin = (
  verticalPaddings = "0"
): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  padding: ${verticalPaddings} 48px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${verticalPaddings} 24px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: ${verticalPaddings} 16px;
  }
`;

export const FIXED_HEADER_SCROLL_JUMP_FIX_MIXIN = css`
  /* Hack to prevent jumping when width changes */
  left: 0;
  padding: 0 !important;
  right: calc(-1 * (100vw - 100%));

  /* Prevent material to break scroll hack */
  transition: unset;
  width: unset;
`;

const MINIMAL_AUTOZOOM_IGNORABLE_IOS_INPUT_FONT_SIZE = 16;
const DEFAULT_INPUT_FONT_SIZE = "inherit";

export const adaptiveIOSInputFontSize = (
  theme: DefaultTheme,
  fontSize?: string
): string => {
  if (theme.isIOS) {
    return `${MINIMAL_AUTOZOOM_IGNORABLE_IOS_INPUT_FONT_SIZE}px !important`;
  }

  if (isString(fontSize)) {
    return fontSize;
  }

  return DEFAULT_INPUT_FONT_SIZE;
};
