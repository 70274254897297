import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_INFO_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.walletInfo> = {
  Component: safeDynamicImport(
    () => import("~/components/wallet/WalletInfoDialog/WalletInfoDialog"),
    { ssr: false }
  )
};

export enum WALLET_INFO_DIALOG_ERROR_CODE {
  noWallet = "noWallet",
  limitsExceeded = "limitsExceeded"
}
