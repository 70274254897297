export enum IMAGE_THUMBNAIL_VARIANT {
  s295 = "s295",
  m600 = "m600",
  b960 = "b960",
  w1200 = "w1200",
  f1920 = "f1920"
}

export enum IMAGE_THUMBNAIL_VARIANT_SQUARE {
  w200h200 = "w200h200",
  w300h300 = "w300h300",
  w400h400 = "w400h400",
  w600h600 = "w600h600"
}

export enum VIDEO_THUMBNAIL_VARIANT {
  little = "little",
  middle = "middle",
  big = "big"
}

export const THUMBNAIL_VARIANT = {
  ...IMAGE_THUMBNAIL_VARIANT,
  ...VIDEO_THUMBNAIL_VARIANT
};
