import { useMutation } from "@apollo/client/react/hooks";
import useTranslation from "next-translate/useTranslation";
import { destroyCookie, parseCookies } from "nookies";
import { useEffect } from "react";

import { EMAIL_CONFIRM_TOKEN_COOKIE_NAME } from "~/components/layouts/AccountLayout/constants";
import { CONFIRM_EMAIL_MUTATION } from "~/components/layouts/AccountLayout/graphql";
import useCustomSnackbar from "~/components/providers/CustomSnackbarProvider/useCustomSnackbar";
import {
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
} from "~/declarations/apollo/ConfirmEmailMutation";
import { COOKIE_OPTIONS } from "~/services/AuthService/constants";
import { isEmpty } from "~/utils/common";

const useEmailConfirmationSubmit = (authorized: boolean): void => {
  const { enqueueCustomSnackbar } = useCustomSnackbar();
  const { t } = useTranslation("common");

  const [confirmEmail] = useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(CONFIRM_EMAIL_MUTATION);

  useEffect((): void => {
    if (!authorized) {
      return;
    }

    const confirmEmailAndShowStatus = async (token: string): Promise<void> => {
      try {
        await confirmEmail({
          variables: {
            token
          }
        });

        enqueueCustomSnackbar(t("email-verification_success"), {
          variant: "success"
        });
      } catch (error) {
        enqueueCustomSnackbar(t("email-verification_error"), {
          variant: "error"
        });
      }
    };

    const cookies = parseCookies();
    const token = cookies[EMAIL_CONFIRM_TOKEN_COOKIE_NAME];

    if (!isEmpty(token)) {
      destroyCookie(null, EMAIL_CONFIRM_TOKEN_COOKIE_NAME, COOKIE_OPTIONS);

      confirmEmailAndShowStatus(token);
    }
  }, [authorized, confirmEmail, enqueueCustomSnackbar, t]);
};

export default useEmailConfirmationSubmit;
