import CssBaseline from "@material-ui/core/CssBaseline";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider
} from "@material-ui/core/styles";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

import AppThemeModeProvider from "../AppThemeModeProvider";
import useAppTheme from "./useAppTheme";

interface Props {
  children: React.ReactNode;
}

const AppStylesProvider = ({ children }: Props): JSX.Element => {
  const { muiTheme, styledComponentsTheme, toggleTheme } = useAppTheme();

  return (
    <StylesProvider injectFirst>
      <AppThemeModeProvider toggleTheme={toggleTheme}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <StyledComponentsThemeProvider theme={styledComponentsTheme}>
            {children}
          </StyledComponentsThemeProvider>
        </MuiThemeProvider>
      </AppThemeModeProvider>
    </StylesProvider>
  );
};
export default AppStylesProvider;
