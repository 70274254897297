import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_PAYOUT_ACCOUNT_SETUP_FAILED_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.payoutAccountSetupFailed> =
  {
    Component: safeDynamicImport(
      () =>
        import(
          "~/components/wallet/WalletPayoutFlow/WalletPayoutAccountSetupFailed"
        ),
      {
        ssr: false
      }
    )
  };
