import { createTheme } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { DefaultTheme } from "styled-components";

import { THEME_MODE } from "~/constants/theme";
import { COMMON_STYLED_COMPONENTS_THEME } from "~/theme/commonTheme";

export const createMuiThemeFromStyledComponentsTheme = (
  theme: DefaultTheme
): Theme =>
  createTheme({
    typography: theme.typography,
    breakpoints: theme.breakpoints,
    palette: {
      type: "light",
      text: {
        primary: theme.newTheme.text.primary,
        secondary: theme.newTheme.text.secondary
      },
      background: {
        default: theme.newTheme.background.octonary,
        paper:
          theme.type === THEME_MODE.light
            ? theme.newTheme.background.octonary
            : theme.newTheme.background.septenary
      }
    },
    spacing: 4,
    props: {
      MuiButton: {
        disableFocusRipple: true
      },
      MuiTooltip: {
        // Timeout is needed everytime to prevent scroll bug when tooltip stays visible in a list on quick scroll
        enterNextDelay: 100
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: "unset"
        }
      },
      // TODO: check why some components are not on the types. Probably because they are not in the core
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      MuiAutocomplete: {
        paper: {
          borderRadius: 12,
          boxShadow: `0px 12px 50px ${theme.newTheme.transparent[8]}`
        }
      },
      MuiSkeleton: {
        root: {
          backgroundColor: theme.newTheme.background.septenary,
          transform: "none"
        },
        text: {
          transform: "none"
        },
        circle: {
          backgroundColor: theme.newTheme.background.septenary
        }
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: "5px",
          fontSize: "12px",
          fontWeight: 500,
          padding: "10px",
          backgroundColor: theme.newTheme.background.primary,
          color: theme.newTheme.text.primaryInverse
        },
        arrow: {
          color: theme.newTheme.background.primary
        }
      },
      MuiAvatarGroup: {
        avatar: {
          "&:first-child": {
            marginLeft: "0"
          }
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: "12px"
        }
      },
      MuiMenu: {
        paper: {
          boxShadow: `0px 12px 50px ${theme.newTheme.transparent[8]}`
        }
      },
      MuiDialog: {
        paper: {
          [COMMON_STYLED_COMPONENTS_THEME.breakpoints.down("xs")]: {
            margin: "16px"
          }
        },
        paperWidthXs: {
          width: "335px"
        }
      },
      MuiPopover: {
        paper: {
          boxShadow: `0px 12px 50px ${theme.newTheme.transparent[8]}`
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: `${theme.newTheme.background.primary} !important`,
          border: "none !important",
          color: theme.newTheme.text.primaryInverse,
          "&:hover": {
            backgroundColor: `${theme.newTheme.background.tertiary} !important`
          }
        },
        dayDisabled: {
          color: theme.newTheme.text.primaryInverse
        }
      },
      MuiPickersCalendar: {
        weekDayLabel: {
          color: theme.newTheme.text.secondary
        }
      },
      MuiPickersArrowSwitcher: {
        iconButton: {
          color: theme.newTheme.text.primary
        }
      },
      MuiPickersDateRangeDay: {
        dayInsideRangeInterval: {
          color: theme.newTheme.text.secondary
        },
        rangeIntervalDayHighlight: {
          backgroundColor: theme.newTheme.background.senary,
          color: theme.newTheme.text.primary
        }
      },
      MuiPickersCalendarHeader: {
        switchViewDropdown: {
          color: theme.newTheme.text.primary
        }
      },
      MuiLink: {
        root: {
          transition: "color 0.1s linear",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "none"
          },
          "&.MuiTypography-colorPrimary": {
            color: theme.newTheme.text.primary,

            "&:hover": {
              color: theme.newTheme.text.secondary
            }
          },
          "&.MuiTypography-colorSecondary": {
            color: theme.newTheme.text.secondary,

            "&:hover": {
              color: theme.newTheme.text.tertiary
            }
          }
        }
      },
      MuiBadge: {
        dot: {
          background: theme.palette.common.red
        }
      }
    }
  });
