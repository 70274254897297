import { useRouter } from "next/router";
import qs from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";

import { sendSentryReport } from "~/components/core/ProblemReportDialog/utils";
import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { DIALOG_CONFIG_MAP } from "~/components/providers/DialogProvider/constants";
import {
  COMMON_DIALOG_CLOSE_REASON,
  COMMON_DIALOG_ERROR_CODE,
  COMMON_DIALOG_STATUS,
  DIALOG_NAME
} from "~/components/providers/DialogProvider/declarations/common";
import { DialogComponentConfirmHandlerArguments } from "~/components/providers/DialogProvider/declarations/config";
import {
  CloseDialogArguments,
  DialogContextContent,
  GetRoutingDialogsStateAsQueryStringOptions,
  OpenDialogArguments,
  OpenDialogHandler
} from "~/components/providers/DialogProvider/declarations/context";
import { DialogResult } from "~/components/providers/DialogProvider/declarations/results";
import {
  DialogPromiseResolveCallback,
  DialogState,
  RoutingDialogsState
} from "~/components/providers/DialogProvider/declarations/state";
import useDialogPriorities from "~/components/providers/DialogProvider/useDialogPriorities";
import useDialogPromises from "~/components/providers/DialogProvider/useDialogPromises";
import useDialogProviderInMemoryState from "~/components/providers/DialogProvider/useDialogProviderInMemoryState";
import useDialogProviderRouterState from "~/components/providers/DialogProvider/useDialogProviderRouterState";
import {
  dialogPriorityComparator,
  getAlreadyOpenDialogsFromState,
  getQueryObjectWithDialogParameter,
  getRawDialogRoutingStateFromLocation,
  getRawDialogRoutingStateFromUrl,
  isPathnameEqual,
  renderDialog,
  validateRouterDialogState
} from "~/components/providers/DialogProvider/utils";
import { ROUTER_EVENTS } from "~/constants/events";

type UseDialogProviderStateResult = DialogContextContent;

const useDialogProviderState = (): UseDialogProviderStateResult => {
  const { currentUser } = useAccountLayoutContext();
  const {
    addDialog: addDialogToRoutingState,
    deleteDialog: deleteDialogFromRoutingState,
    setDialogClosed: setDialogClosedInRoutingState,
    updateDialog: updateDialogRoutingState,
    resetState: resetRoutingState,
    state: routingState
  } = useDialogProviderRouterState();
  const {
    addDialog: addDialogToInMemoryState,
    deleteDialog: deleteDialogFromInMemoryState,
    setDialogClosed: setDialogClosedInMemoryState,
    resetState: resetInMemoryState,
    state: inMemoryState
  } = useDialogProviderInMemoryState();
  const {
    setPromiseCallbacks,
    resolveAndDeletePromise,
    resetPromiseCallbacks,
    hasPromiseCallbacks
  } = useDialogPromises();

  const {
    setPriority,
    setPrioritiesByRoutingState,
    deletePriority,
    resetPriorities,
    hasPriority,
    getPriority,
    getDialogNamesSortedByPriority
  } = useDialogPriorities();
  const [initialized, setInitialized] = useState(false);
  const router = useRouter();

  const addDialogToState = useCallback(
    (dialogState: DialogState<DIALOG_NAME>) => {
      const config = DIALOG_CONFIG_MAP[dialogState.name];

      if (config.saveInUrl) {
        addDialogToRoutingState(dialogState);
      } else {
        addDialogToInMemoryState(dialogState);
      }
    },
    [addDialogToInMemoryState, addDialogToRoutingState]
  );

  const openDialog: OpenDialogHandler = useCallback(
    <Name extends DIALOG_NAME>(
      ...openDialogArguments: OpenDialogArguments<Name>
    ) =>
      new Promise((resolve, reject) => {
        const dialogName = openDialogArguments[0];
        const dialogOptions = openDialogArguments[1];
        const dialogAlreadyOpen = hasPriority(dialogName);

        if (dialogAlreadyOpen) {
          const errorMessage = `Dialog ${dialogName} has already been opened`;

          sendSentryReport({
            email: "",
            person: currentUser.data,
            message: errorMessage
          });

          resolve({
            status: COMMON_DIALOG_STATUS.closed,
            closeReason: COMMON_DIALOG_CLOSE_REASON.error,
            errorCode: COMMON_DIALOG_ERROR_CODE.alreadyOpen
          });

          return;
        }

        setPriority(dialogName);
        setPromiseCallbacks(dialogName, {
          resolve: resolve as DialogPromiseResolveCallback<DIALOG_NAME>,
          reject
        });

        const newDialogState: DialogState<DIALOG_NAME> = {
          open: true,
          name: dialogName,
          options: dialogOptions
        };

        addDialogToState(newDialogState);
      }),
    [
      addDialogToState,
      currentUser.data,
      hasPriority,
      setPriority,
      setPromiseCallbacks
    ]
  );

  const setCloseStateForDialog = useCallback(
    (dialogName: DIALOG_NAME) => {
      const config = DIALOG_CONFIG_MAP[dialogName];

      if (config.saveInUrl) {
        setDialogClosedInRoutingState(dialogName);
      } else {
        setDialogClosedInMemoryState(dialogName);
      }
    },
    [setDialogClosedInMemoryState, setDialogClosedInRoutingState]
  );

  const resolvePromiseWithClosedStatus = useCallback(
    <Name extends DIALOG_NAME>(
      ...closeDialogArguments: CloseDialogArguments<Name>
    ) => {
      const dialogName = closeDialogArguments[0];
      const closeReason = closeDialogArguments[1];
      const errorCode = closeDialogArguments[2];

      resolveAndDeletePromise(dialogName, {
        status: COMMON_DIALOG_STATUS.closed,
        closeReason,
        errorCode
      } as DialogResult<Name>);
    },
    [resolveAndDeletePromise]
  );

  const silentlyDeleteDialogState = useCallback(
    (name: DIALOG_NAME) => {
      resolvePromiseWithClosedStatus(
        name,
        COMMON_DIALOG_CLOSE_REASON.noLongerRelevant
      );

      deletePriority(name);
    },
    [deletePriority, resolvePromiseWithClosedStatus]
  );

  const closeDialog = useCallback(
    <Name extends DIALOG_NAME>(
      ...closeDialogArguments: CloseDialogArguments<Name>
    ) => {
      const dialogName = closeDialogArguments[0];

      resolvePromiseWithClosedStatus(...closeDialogArguments);

      setCloseStateForDialog(dialogName);
    },
    [resolvePromiseWithClosedStatus, setCloseStateForDialog]
  );

  const deleteDialogFromState = useCallback(
    (dialogName: DIALOG_NAME) => {
      const config = DIALOG_CONFIG_MAP[dialogName];
      deletePriority(dialogName);

      if (config.saveInUrl) {
        deleteDialogFromRoutingState(dialogName);
      } else {
        deleteDialogFromInMemoryState(dialogName);
      }
    },
    [
      deleteDialogFromInMemoryState,
      deleteDialogFromRoutingState,
      deletePriority
    ]
  );

  const confirmDialog = useCallback(
    <Name extends DIALOG_NAME>(
      dialogNameToClose: Name,
      ...confirmArguments: DialogComponentConfirmHandlerArguments<Name>
    ): void => {
      const successData = confirmArguments[0];

      resolveAndDeletePromise(dialogNameToClose, {
        status: COMMON_DIALOG_STATUS.success,
        ...(successData ? { data: successData } : {})
      } as DialogResult<Name>);

      setCloseStateForDialog(dialogNameToClose);
    },
    [resolveAndDeletePromise, setCloseStateForDialog]
  );

  const dialogs = useMemo(
    () =>
      [...inMemoryState, ...routingState].map(state => {
        const order = getPriority(state.name);

        return renderDialog({
          state,
          closeDialog,
          confirmDialog,
          deleteDialogFromState,
          order
        });
      }),
    [
      inMemoryState,
      routingState,
      getPriority,
      closeDialog,
      confirmDialog,
      deleteDialogFromState
    ]
  );

  const resetDialogState = useCallback(
    ({
      ignoreRoutingState
    }: { ignoreRoutingState?: boolean } | undefined = {}) => {
      const sortedDialogNames = getDialogNamesSortedByPriority();

      sortedDialogNames.forEach(name =>
        resolvePromiseWithClosedStatus(
          name,
          COMMON_DIALOG_CLOSE_REASON.noLongerRelevant
        )
      );

      resetPromiseCallbacks();
      resetPriorities();
      resetInMemoryState();

      if (!ignoreRoutingState) {
        resetRoutingState();
      }
    },
    [
      getDialogNamesSortedByPriority,
      resetInMemoryState,
      resetPriorities,
      resetPromiseCallbacks,
      resetRoutingState,
      resolvePromiseWithClosedStatus
    ]
  );

  const recoverDialogsState = useCallback(
    (state: RoutingDialogsState<DIALOG_NAME>): void => {
      setPrioritiesByRoutingState(state);
    },
    [setPrioritiesByRoutingState]
  );

  useEffect(() => {
    if (initialized) {
      return;
    }

    const initialState = getRawDialogRoutingStateFromLocation();

    if (validateRouterDialogState(initialState, { initial: true })) {
      recoverDialogsState(initialState);
    } else {
      resetDialogState();
    }

    setInitialized(true);
  }, [initialized, recoverDialogsState, resetDialogState]);

  useEffect(() => {
    const handleRouteChangeStart = (newPathname: string): void => {
      if (!isPathnameEqual(newPathname, router.asPath)) {
        resetDialogState({
          ignoreRoutingState: true
        });
        return;
      }

      const newRoutingState = getRawDialogRoutingStateFromUrl(newPathname);

      if (!validateRouterDialogState(newRoutingState)) {
        resetDialogState();
        return;
      }

      const dialogsToSave = new Set();
      const sortedRoutingState = [...routingState].sort((dialog1, dialog2) => {
        const priority1 = getPriority(dialog1.name);
        const priority2 = getPriority(dialog2.name);

        return dialogPriorityComparator(priority1, priority2);
      });

      sortedRoutingState.forEach(state => {
        const dialogState = newRoutingState.find(
          ({ name }) => name === state.name
        );

        if (!dialogState) {
          const dialogWasNotCorrectlyClosed =
            hasPromiseCallbacks(state.name) || hasPriority(state.name);

          if (dialogWasNotCorrectlyClosed) {
            silentlyDeleteDialogState(state.name);
          }

          return;
        }

        if (
          dialogState &&
          !dialogState.open &&
          hasPromiseCallbacks(state.name)
        ) {
          resolvePromiseWithClosedStatus(
            state.name,
            COMMON_DIALOG_CLOSE_REASON.noLongerRelevant
          );
        } else if (
          dialogState &&
          dialogState.open &&
          !hasPriority(state.name)
        ) {
          setPriority(dialogState.name);
        }

        dialogsToSave.add(state.name);
      });

      const newDialogsToOpen = newRoutingState.filter(
        ({ name }) => !dialogsToSave.has(name) && !hasPriority(name)
      );

      newDialogsToOpen.forEach(({ name, options }) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        openDialog<any>(name, options)
      );
    };

    router.events.on(ROUTER_EVENTS.routeChangeStart, handleRouteChangeStart);

    return (): void => {
      router.events.off(ROUTER_EVENTS.routeChangeStart, handleRouteChangeStart);
    };
  }, [
    closeDialog,
    getPriority,
    hasPriority,
    hasPromiseCallbacks,
    inMemoryState,
    openDialog,
    recoverDialogsState,
    resetDialogState,
    resolvePromiseWithClosedStatus,
    router.asPath,
    router.events,
    routingState,
    setPriority,
    silentlyDeleteDialogState
  ]);

  const getRoutingDialogsStateAsQueryString = useCallback(
    ({ exclude }: GetRoutingDialogsStateAsQueryStringOptions) => {
      const state = routingState.filter(({ name }) => !exclude.includes(name));
      const queryStringObject = getQueryObjectWithDialogParameter(state);

      return qs.stringify(queryStringObject);
    },
    [routingState]
  );

  const alreadyOpenDialogs = useMemo(() => {
    if (!initialized) {
      return {};
    }

    return getAlreadyOpenDialogsFromState(routingState, inMemoryState);
  }, [inMemoryState, initialized, routingState]);

  return {
    dialogs,
    initialized,
    alreadyOpenDialogs,
    openDialog,
    updateDialogRoutingState,
    getRoutingDialogsStateAsQueryString
  };
};

export default useDialogProviderState;
