import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { LONG_STREAM_VIDEO_UNIT_FRAGMENT } from "~/components/post/graphql";
import { PREVIEW_VIDEO_FRAGMENT } from "~/components/post/manage/LongContentManage/graphql";
import { POST_AUTHOR_FRAGMENT } from "~/components/post/postAuthorFragment";

const ATTACHMENT_VIDEO_WITHOUT_VIEWS_FRAGMENT = gql`
  fragment AttachmentVideoWithoutViewsFragment on AttachmentVideo {
    __typename
    duration
    previewUrl
    cover {
      url
      blurredUrl
      sizes {
        height
        width
      }
      thumbnails {
        little {
          url
        }
        middle {
          url
        }
        big {
          url
        }
        w200h200 {
          url
        }
        w300h300 {
          url
        }
        w400h400 {
          url
        }
        w600h600 {
          url
        }
      }
    }
  }
`;

const LONG_POST_UNIT_WITHOUT_VIDEO_FRAGMENT = gql`
  fragment LongPostUnitWithoutVideoFragment on LongPost {
    id
    __typename
    sensitiveShown @client
    confidentiality
    owner {
      ...shortPostAuthor
    }
    title
    previewInfo {
      addToMyPosts
      previewType
      video {
        ...VideoPreviewFields
      }
      videoCustomSegment {
        ...VideoPreviewFields
      }
      videoStartSegment {
        ...VideoPreviewFields
      }
    }
    settings {
      isAdult
      enabledTipping
    }
    paywall {
      cost
      preview
      status
    }
    counters {
      comments
      tips {
        sum
      }
    }
    reactions {
      likes
      myReaction {
        id
        type
      }
    }
    type
    status
    publishedAt
    richText {
      ...richText
    }
  }
  ${PREVIEW_VIDEO_FRAGMENT}
  ${POST_AUTHOR_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
`;

// Video Unit, without video.views for pinned posts in Profile
export const PROFILE_PINNED_LONG_POST_VIDEO_UNIT_FRAGMENT = gql`
  fragment ProfilePinnedLongPostVideoUnitFragment on LongPost {
    ...LongPostUnitWithoutVideoFragment
    video {
      ...AttachmentVideoWithoutViewsFragment
    }
  }
  ${LONG_POST_UNIT_WITHOUT_VIDEO_FRAGMENT}
  ${ATTACHMENT_VIDEO_WITHOUT_VIEWS_FRAGMENT}
`;

// Video Unit, with video.views
export const LONG_POST_VIDEO_UNIT_FRAGMENT = gql`
  fragment LongPostVideoUnitFragment on LongPost {
    ...LongPostUnitWithoutVideoFragment
    video {
      ...AttachmentVideoWithoutViewsFragment
      views
    }
  }
  ${LONG_POST_UNIT_WITHOUT_VIDEO_FRAGMENT}
  ${ATTACHMENT_VIDEO_WITHOUT_VIEWS_FRAGMENT}
`;

export const HYPE_TV_FEED_QUERY = gql`
  query LongContentFromFollowedHubs(
    $after: String
    $first: Int
    $streamStatuses: [StreamStatus]
  ) {
    longContentFromFollowedHubs(
      after: $after
      first: $first
      streamStatuses: $streamStatuses
    ) {
      edges {
        cursor
        node {
          ... on LongStream {
            ...LongStreamVideoUnit
          }
          ... on LongPost {
            ...LongPostVideoUnitFragment
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
  ${LONG_POST_VIDEO_UNIT_FRAGMENT}
  ${LONG_STREAM_VIDEO_UNIT_FRAGMENT}
`;
