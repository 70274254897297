import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_SET_UP_SUCCESS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.setUpWalletSuccess> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletSetUpSuccessDialog"),
      {
        ssr: false
      }
    )
  };
