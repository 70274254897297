import NextLink from "next/link";
import useTranslation from "next-translate/useTranslation";
import styled, { useTheme } from "styled-components";

import ROUTES from "~/constants/routes";
import { THEME_MODE } from "~/constants/theme";
import LogoBlack from "~/public/assets/images/Logo.svg";
import LogoGradient from "~/public/assets/images/LogoGradient.svg";

interface Props {
  className?: string;
}

const LOGO_SIZE = 40;

const MenuLogotype = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isDark = theme.type === THEME_MODE.dark;

  return (
    <NextLink href={ROUTES.index} passHref>
      <StyledLink className={className} aria-label={t("to-main-page")}>
        <LogoImage src={isDark ? LogoGradient : LogoBlack} />
      </StyledLink>
    </NextLink>
  );
};

export default MenuLogotype;

const LogoImage = styled.img`
  width: ${LOGO_SIZE}px;
`;

const StyledLink = styled.a`
  height: ${LOGO_SIZE}px;
  margin-right: 24px;
`;
