import { forwardRef } from "react";
import styled from "styled-components";

export interface Props {
  children: React.ReactNode;
  onHover?: () => void;
  className?: string;
  onBlur: (relatedTarget: EventTarget | null) => void;
  ref?: React.Ref<HTMLDivElement>;
}

const AccountHeaderSectionLink = forwardRef<HTMLDivElement, Props>(
  ({ children, className, onHover, onBlur }: Props, ref): JSX.Element => {
    const handleMouseEnter = (): void => {
      onHover?.();
    };

    const handleMouseLeave = (
      event: React.MouseEvent<HTMLDivElement>
    ): void => {
      onBlur(event.relatedTarget);
    };

    return (
      <SectionLink
        ref={ref}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </SectionLink>
    );
  }
);

AccountHeaderSectionLink.displayName = "AccountHeaderSectionLink";

export default AccountHeaderSectionLink;

const SectionLink = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
`;
