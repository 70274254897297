import { ObserverHandlerListModifier } from "~/utils/observer/useObserver";

export type ApolloSubscriptionsConnectionEventsHandler = (
  event: APOLLO_SUBSCRIPTION_CONNECTION_EVENT
) => boolean;

export type ApolloSubscriptionsConnectionEventsContextProps = {
  subscribe: ObserverHandlerListModifier<ApolloSubscriptionsConnectionEventsHandler>;
  unsubscribe: ObserverHandlerListModifier<ApolloSubscriptionsConnectionEventsHandler>;
};

export enum APOLLO_SUBSCRIPTION_CONNECTION_EVENT {
  disconnection,
  reconnection
}
