import Paper from "@material-ui/core/Paper";
import { alpha } from "@material-ui/core/styles";
import {
  IconVariant,
  SnackbarContent,
  SnackbarMessage,
  useSnackbar,
  VariantType
} from "notistack";
import { forwardRef, useCallback } from "react";
import styled from "styled-components";

import Button from "~/components/core/Button";
import Icon from "~/components/core/Icon";
import IconButton from "~/components/core/IconButton";
import Typography from "~/components/core/Typography";
import { ExtraSnackbarOptions } from "~/components/providers/CustomSnackbarProvider/useCustomSnackbar";
import { THEME_MODE } from "~/constants/theme";
import { SNACKBAR_BOX_SHADOW_COLOR } from "~/theme/colors";
import { gradientText } from "~/theme/utils";

import {
  DefaultIconWrapper,
  ErrorIconWrapper,
  SuccessIconWrapper,
  WarningIconWrapper
} from "./styled";

const ICON_VARIANTS: IconVariant = {
  default: (
    <DefaultIconWrapper>
      <Icon variant="logo" />
    </DefaultIconWrapper>
  ),
  info: (
    <WarningIconWrapper>
      <Icon variant="logo" />
    </WarningIconWrapper>
  ),
  warning: (
    <WarningIconWrapper>
      <Icon variant="logo" />
    </WarningIconWrapper>
  ),
  success: (
    <SuccessIconWrapper>
      <Icon variant="checkMark" />
    </SuccessIconWrapper>
  ),
  error: (
    <ErrorIconWrapper>
      <Icon variant="attentionFilled" />
    </ErrorIconWrapper>
  )
};

type SnackbarMessageProps = {
  id: string | number;
  title: SnackbarMessage;
  variant?: VariantType;
  className?: string;
} & ExtraSnackbarOptions;

export const CustomSnackbarMessage = forwardRef<
  HTMLDivElement,
  SnackbarMessageProps
>(
  (
    {
      id,
      title,
      description,
      firstAction,
      secondAction,
      className,
      variant = "default"
    }: SnackbarMessageProps,
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <StyledSnackbarContent className={className} ref={ref}>
        <Wrapper elevation={0}>
          <ContentWrapper>
            {ICON_VARIANTS[variant]}
            <MessageWrapper>
              <MessageTitle>{title}</MessageTitle>
              {description && (
                <MessageDescription>{description}</MessageDescription>
              )}
            </MessageWrapper>
            <IconButton onClick={handleDismiss} icon="close" />
          </ContentWrapper>
          {(firstAction || secondAction) && (
            <ActionsWrapper>
              {firstAction && (
                <FirstAction onClick={firstAction.onClick}>
                  {firstAction.text}
                </FirstAction>
              )}
              {secondAction && (
                <SecondAction onClick={secondAction.onClick}>
                  {secondAction.text}
                </SecondAction>
              )}
            </ActionsWrapper>
          )}
        </Wrapper>
      </StyledSnackbarContent>
    );
  }
);

CustomSnackbarMessage.displayName = "CustomSnackbarMessage";

const StyledSnackbarContent = styled(SnackbarContent)`
  min-width: unset;
`;

const MessageWrapper = styled.div`
  min-width: 0;
  padding: 0 16px;
`;

const MessageTitle = styled(Typography).attrs({
  variant: "title2",
  component: "div"
})`
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const MessageDescription = styled(Typography).attrs({
  variant: "p3",
  component: "div",
  color: "textSecondary"
})`
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin-top: 3px;
`;

const Wrapper = styled(Paper)`
  background: ${({ theme }) => theme.newTheme.background.octonary};
  border-radius: 16px;
  box-shadow: 0 56px 94px
    ${({ theme }) =>
      theme.type === THEME_MODE.light
        ? alpha(SNACKBAR_BOX_SHADOW_COLOR, 0.06)
        : alpha(SNACKBAR_BOX_SHADOW_COLOR, 0.24)};
  max-width: 513px;
  padding: 16px;
`;

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const ActionsWrapper = styled.div`
  margin: 8px 0 0 80px;
`;

const Action = styled(Button)`
  .MuiButton-label {
    padding: 0 8px;
  }
`;

const FirstAction = styled(Action).attrs({
  variant: "text",
  size: "medium"
})`
  &:first-child {
    margin-right: 9px;
  }
`;

const SecondAction = styled(Action).attrs({
  variant: "text",
  size: "medium"
})`
  &,
  &:hover,
  &:focus {
    ${({ theme }) => {
      if (theme.type === THEME_MODE.light) {
        return `color: ${theme.newTheme.text.secondary};`;
      }

      return gradientText(
        theme.newTheme.gradient.primary,
        theme.newTheme.text.secondary
      );
    }}
  }
`;
