import { gql } from "@apollo/client";

export const RICH_TEXT_FRAGMENT = gql`
  fragment richText on RichText {
    __typename
    text
    parts {
      __typename

      ... on PlainPart {
        text
      }

      ... on MentionPart {
        id
        nickname
      }

      ... on UrlPart {
        path
        urlType
        text
      }

      ... on HashtagPart {
        hashtag
      }
    }
  }
`;
