import Link from "@material-ui/core/Link";
import NextLink from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import styled from "styled-components";

import Icon from "~/components/core/Icon";
import {
  MENU_LINK,
  MENU_LINKS
} from "~/components/layouts/AccountLayout/AccountHeader/constants";
import ROUTES from "~/constants/routes";

interface Props {
  authorized: boolean;
  className?: string;
}

const AccountHeaderFeedLinks = ({
  className,
  authorized
}: Props): JSX.Element => {
  const { t } = useTranslation("user");
  const { pathname } = useRouter();

  const discoverPath = MENU_LINKS[MENU_LINK.postsDiscover].href;
  const discoverActive = pathname.includes(ROUTES.discover.index);

  const homePath = MENU_LINKS[MENU_LINK.posts].href;
  const homeActive = [
    ROUTES.posts.index,
    ROUTES.hypetv.index,
    ROUTES.live.index,
    ROUTES.nft.index,
    ROUTES.index
  ].includes(pathname);

  return (
    <Wrapper className={className}>
      {authorized && homePath && (
        <NextLink href={homePath} passHref>
          <FeedLink color="textPrimary">
            <Icon variant={homeActive ? "homeFilled" : "home"} />
            {t("navigation-menu_home_caption")}
          </FeedLink>
        </NextLink>
      )}
      {discoverPath && (
        <NextLink href={discoverPath} passHref>
          <FeedLink color="textPrimary">
            <Icon variant={discoverActive ? "discoverFilled" : "discover"} />
            {t("navigation-menu_discover_caption")}
          </FeedLink>
        </NextLink>
      )}
    </Wrapper>
  );
};

export default AccountHeaderFeedLinks;

const FeedLink = styled(Link).attrs({
  underline: "none"
})`
  font-size: 15px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  font-weight: 500;
  margin-left: 16px;

  ${FeedLink} {
    margin-right: 40px;
  }
`;
