export enum WALLET_SYNCHRONIZATION_METHOD {
  qrCode = "qrCode",
  mnemonic = "mnemonic"
}

export type WalletSynchronizationMethodSelectorDialogSuccessResult = {
  method: WALLET_SYNCHRONIZATION_METHOD;
};

export type WalletsynchronizationMethodMeta = {
  method: WALLET_SYNCHRONIZATION_METHOD;
  labelTranslationKey: string;
};
