import Badge from "@material-ui/core/Badge";
import useTranslation from "next-translate/useTranslation";
import { MouseEvent, useCallback, useRef } from "react";

import IconButton from "~/components/core/IconButton/IconButton";
import { useNotificationPopup } from "~/components/providers/NotificationsPopupProvider/NotificationsPopupContext";

interface Props {
  hasUnreadNotifications: boolean;
}

const AccountHeaderNotifications = ({
  hasUnreadNotifications
}: Props): JSX.Element => {
  const { openDialog, open } = useNotificationPopup();
  const { t } = useTranslation("notifications");

  const bellButtonRef = useRef(null);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      openDialog(event.currentTarget);
    },
    [openDialog]
  );

  return (
    <Badge
      overlap="circular"
      badgeContent=""
      variant="dot"
      invisible={!hasUnreadNotifications}
    >
      <IconButton
        onClick={handleClick}
        aria-label={t("notifications-section")}
        ref={bellButtonRef}
        icon={open ? "bellFilled" : "bell"}
      />
    </Badge>
  );
};

export default AccountHeaderNotifications;
