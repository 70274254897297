export enum WalletSynchronizationMnemonicInputDialogSuccessResult {
  mnemonicChecked = "mnemonicChecked",
  errorWithRetry = "errorWithRetry",
  fatalError = "fatalError"
}

export type WalletSynchronizationMnemonicInputDialogSuccessData =
  | {
      reason: WalletSynchronizationMnemonicInputDialogSuccessResult.mnemonicChecked;
      mnemonic: string;
    }
  | {
      reason: Exclude<
        WalletSynchronizationMnemonicInputDialogSuccessResult,
        WalletSynchronizationMnemonicInputDialogSuccessResult.mnemonicChecked
      >;
      message: string;
    };
