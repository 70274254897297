import { ApolloClient, QueryOptions } from "@apollo/client";

import {
  Post as PostQueryResult,
  Post_getPost,
  PostVariables
} from "~/declarations/apollo/Post";

import { POST_QUERY } from "./graphql";

export const fetchPostById = async (
  client: ApolloClient<unknown>,
  postId: string,
  fetchPolicy?: QueryOptions["fetchPolicy"]
): Promise<Post_getPost | null> => {
  const post = await client.query<PostQueryResult, PostVariables>({
    query: POST_QUERY,
    variables: {
      postId
    },
    fetchPolicy
  });

  const postData = post.data?.getPost;

  if (!postData) {
    return null;
  }

  return {
    ...postData
  };
};
