import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

export type ThemeModeToggleHandler = () => void;

type AppThemeModeContextState = ThemeModeToggleHandler;

export const AppThemeModeContext = createContext<
  AppThemeModeContextState | undefined
>(undefined);

export const useAppThemeModeContext = createUseContext(
  AppThemeModeContext,
  "AppThemeModeContext"
);
