import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const NFT_PURCHASING_FAILURE_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.purchaseNftFailure> =
  {
    Component: safeDynamicImport(
      () =>
        import(
          "~/components/nft/NftAsset/NftPurchaseDialog/NftPurchasingFailureDialog"
        ),
      { ssr: false }
    )
  };

export enum NFT_PURCHASING_FAILURE_DIALOG_ERROR_CODE {
  reportLink = "reportLink"
}
