import { gql, useApolloClient } from "@apollo/client";
import { StringParam, useQueryParams } from "next-query-params";
import { useEffect, useState } from "react";

import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { useAnalyticsContext } from "~/components/providers/AnalyticsProvider/AnalyticsContext";
import {
  ANALYTICS_EVENT,
  FEE_TYPE_TO_PAYMENT_PROVIDER
} from "~/components/providers/AnalyticsProvider/constants";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { useDialogContext } from "~/components/providers/DialogProvider/DialogProviderContext";
import { WALLET_CONFIRMATION_VARIANT } from "~/components/wallet/WalletConfirmationDialog/declarations";
import {
  GetNumberOfCoinsByInvoiceId,
  GetNumberOfCoinsByInvoiceIdVariables
} from "~/declarations/apollo/GetNumberOfCoinsByInvoiceId";
import { BuyHypesFeeType } from "~/declarations/apollo/globalTypes";
import { useApolloErrorSnackbar } from "~/utils/errors";

const RESULT_QUERY_PARAMETER = "buy-coins-result";
const INVOICE_ID_QUERY_PARAMETER = "invoiceId";
const METHOD_QUERY_PARAMETER = "payment-method";

enum BUY_COINS_RESULT {
  success = "success",
  failure = "failure"
}

type Props = {
  readyToCheck: boolean;
};

const GET_NUMBER_OF_COINS_BY_INVOICE_ID = gql`
  query GetNumberOfCoinsByInvoiceId($id: String!) {
    getInvoiceById(id: $id) {
      hypes
    }
  }
`;

const useBuyCoinsResult = ({ readyToCheck }: Props): void => {
  const { sendEvent } = useAnalyticsContext();
  const [initialized, setInitialized] = useState(false);
  const { currentUser } = useAccountLayoutContext();
  const currentUserId = currentUser.data?.id;
  const [query, setQuery] = useQueryParams({
    [RESULT_QUERY_PARAMETER]: StringParam,
    [METHOD_QUERY_PARAMETER]: StringParam,
    [INVOICE_ID_QUERY_PARAMETER]: StringParam
  });
  const resultQueryParameter = query[RESULT_QUERY_PARAMETER];
  const methodQueryParameter = query[METHOD_QUERY_PARAMETER];
  const invoiceIdQueryParameter = query[INVOICE_ID_QUERY_PARAMETER];

  const apolloClient = useApolloClient();
  const { openDialog } = useDialogContext();
  const apolloErrorSnackbar = useApolloErrorSnackbar();

  useEffect(() => {
    if (
      !currentUserId ||
      !readyToCheck ||
      initialized ||
      (resultQueryParameter !== BUY_COINS_RESULT.success &&
        resultQueryParameter !== BUY_COINS_RESULT.failure) ||
      !invoiceIdQueryParameter ||
      (methodQueryParameter !== BuyHypesFeeType.bitpay &&
        methodQueryParameter !== BuyHypesFeeType.stripe)
    ) {
      return;
    }

    const initialize = async (): Promise<void> => {
      try {
        /* Check that this is a real redirect from payment service and fetch number of coins */
        const response = await apolloClient.query<
          GetNumberOfCoinsByInvoiceId,
          GetNumberOfCoinsByInvoiceIdVariables
        >({
          query: GET_NUMBER_OF_COINS_BY_INVOICE_ID,
          fetchPolicy: "no-cache",
          variables: {
            id: invoiceIdQueryParameter
          }
        });

        const invoice = response.data.getInvoiceById;

        if (invoice) {
          if (resultQueryParameter === BUY_COINS_RESULT.success) {
            const variant =
              methodQueryParameter === BuyHypesFeeType.stripe
                ? WALLET_CONFIRMATION_VARIANT.buyCoinsFiatSuccess
                : WALLET_CONFIRMATION_VARIANT.buyCoinsCryptoSuccess;

            sendEvent({
              event: ANALYTICS_EVENT.buyCoinsFinish,
              data: {
                amount: invoice.hypes,
                paymentProvider:
                  FEE_TYPE_TO_PAYMENT_PROVIDER[methodQueryParameter]
              }
            });

            await openDialog(DIALOG_NAME.walletConfirmation, {
              variant,
              specific: invoice.hypes
            });
          } else if (resultQueryParameter === BUY_COINS_RESULT.failure) {
            await openDialog(DIALOG_NAME.walletConfirmation, {
              variant: WALLET_CONFIRMATION_VARIANT.buyCoinsError
            });
          }
        }
      } catch (error) {
        apolloErrorSnackbar(error);
      } finally {
        setQuery(
          {
            [RESULT_QUERY_PARAMETER]: undefined,
            [METHOD_QUERY_PARAMETER]: undefined,
            [INVOICE_ID_QUERY_PARAMETER]: undefined
          },
          "replaceIn"
        );
      }
    };

    /* Should run only after redirect */
    initialize();
    setInitialized(true);
  }, [
    apolloClient,
    apolloErrorSnackbar,
    initialized,
    openDialog,
    readyToCheck,
    currentUserId,
    setQuery,
    resultQueryParameter,
    invoiceIdQueryParameter,
    methodQueryParameter,
    sendEvent
  ]);
};

export default useBuyCoinsResult;
