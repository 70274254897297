import styled from "styled-components";

import { DARK_THEME_PATTERN_OPACITY } from "~/components/core/AppPatternBackground/constants";
import { IMG_CROSS_ORIGIN_FORCE_VALUE } from "~/constants/media";
import { THEME_MODE } from "~/constants/theme";

export const PatternBackgroundImage = styled.div.attrs({
  crossOrigin: IMG_CROSS_ORIGIN_FORCE_VALUE
})`
  position: absolute;
  height: 100%;
  opacity: ${({ theme }) =>
    theme.type === THEME_MODE.dark ? DARK_THEME_PATTERN_OPACITY : 1};
  top: 0;
`;

export const PatternBackgroundWrapper = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`;
