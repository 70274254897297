import { gql } from "@apollo/client";

const NOTIFICATION_PERSON_FRAGMENT = gql`
  fragment NotificationPerson on Person {
    __typename
    id
    nickname
    avatar {
      little
      middle
    }
  }
`;

const NOTIFICATION_IMAGE_PREVIEW_FRAGMENT = gql`
  fragment NotificationImagePreview on AttachmentImage {
    __typename
    id
    thumbnails {
      s295 {
        url
      }
      m600 {
        url
      }
    }
  }
`;

const NOTIFICATION_VIDEO_PREVIEW_FRAGMENT = gql`
  fragment NotificationVideoPreview on AttachmentVideo {
    __typename
    id
    duration
    cover {
      __typename
      url
      thumbnails {
        little {
          url
        }
        middle {
          url
        }
      }
    }
  }
`;

const NOTIFICATION_NFT_FILE_PREVIEW = gql`
  fragment NotificationNFTFilePreview on NotificationNftFile {
    __typename

    ... on AttachmentImage {
      ...NotificationImagePreview
    }

    ... on AttachmentVideo {
      ...NotificationVideoPreview
    }
  }
`;

const NOTIFICATION_POST_PREVIEW = gql`
  fragment NotificationPostPreview on NotificationPost {
    __typename
    id
    type
    attachment {
      __typename

      ... on AttachmentImage {
        ...NotificationImagePreview
      }

      ... on AttachmentVideo {
        ...NotificationVideoPreview
      }
    }
  }
`;

export const NOTIFICATION_FRAGMENT_NAME = "Notification";
export const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on Notification {
    __typename
    id
    status
    updatedAt

    ... on PayoutProcessed {
      createdAt
    }

    ... on PayoutFailed {
      createdAt
    }

    ... on PersonFollowed {
      persons {
        ...NotificationPerson
        name
        followingReference {
          me
        }
      }
      otherPersons
    }

    ... on PostLiked {
      persons {
        ...NotificationPerson
      }
      otherPersons
      post {
        ...NotificationPostPreview
      }
    }

    ... on PostCommented {
      persons {
        ...NotificationPerson
      }
      otherPersons
      post {
        ...NotificationPostPreview
      }
    }

    ... on PersonFollowRequest {
      follower {
        ...NotificationPerson
      }
      actionType
    }

    ... on PersonFollowRequestAccepted {
      target {
        ...NotificationPerson
      }
    }

    ... on StreamStarted {
      streamer {
        ...NotificationPerson
      }
      post {
        type
        id
      }
    }

    ... on NftMinted {
      nft {
        id
        file {
          ...NotificationNFTFilePreview
        }
      }
    }

    ... on NftAvailableForSale {
      owner {
        ...NotificationPerson
      }
      nft {
        id
        file {
          ...NotificationNFTFilePreview
        }
      }
    }

    ... on NftPurchaseCompleted {
      editionId
      nft {
        id
        file {
          ...NotificationNFTFilePreview
        }
      }
    }

    ... on NftPurchased {
      buyer {
        ...NotificationPerson
      }
      editionId
      editionPrice
      nft {
        id
        file {
          ...NotificationNFTFilePreview
        }
      }
    }

    ... on PostSold {
      buyer {
        ...NotificationPerson
      }
      cost
      post {
        ...NotificationPostPreview
      }
    }

    ... on PostTipped {
      donor {
        ...NotificationPerson
      }
      cost
      post {
        ...NotificationPostPreview
      }
    }

    ... on PersonMentioned {
      author {
        ...NotificationPerson
      }
      mentionPlace {
        __typename

        ... on MentionPostPlace {
          post {
            ...NotificationPostPreview
          }
        }

        ... on MentionCommentPlace {
          post {
            ...NotificationPostPreview
          }
          commentId
        }

        ... on MentionMessageInConversationPlace {
          conversationId
          topic
        }
      }
    }

    ... on PersonReplied {
      author {
        ...NotificationPerson
      }
      text
      post {
        ...NotificationPostPreview
      }
      commentId
    }

    ... on StreamCreated {
      __typename
    }

    ... on ReplenishmentDone {
      __typename
      balance
    }

    ... on NftToXdbExportFailed {
      __typename
    }

    ... on NftToXdbExported {
      __typename
    }
  }

  ${NOTIFICATION_POST_PREVIEW}
  ${NOTIFICATION_PERSON_FRAGMENT}
  ${NOTIFICATION_IMAGE_PREVIEW_FRAGMENT}
  ${NOTIFICATION_VIDEO_PREVIEW_FRAGMENT}
  ${NOTIFICATION_NFT_FILE_PREVIEW}
`;

export const NOTIFICATIONS_QUERY = gql`
  query Notifications(
    $activityType: ActivityType!
    $first: Int
    $after: String
  ) {
    notifications(activityType: $activityType, first: $first, after: $after)
      @connection(key: "notifications", filter: ["activityType"]) {
      edges {
        cursor
        node {
          ...Notification
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }

  ${NOTIFICATION_FRAGMENT}
`;

export const READ_NOTIFICATIONS_MUTATION = gql`
  mutation ReadNotifications($notificationIds: [String!]!) {
    readNotifications(notificationIds: $notificationIds)
  }
`;

export const ACCEPT_PROFILE_FOLLOWER_MUTATION = gql`
  mutation AcceptProfileFollowerRequest($personId: String!) {
    confirmPendingFollower(personId: $personId) {
      id
    }
  }
`;

export const DECLINE_PROFILE_FOLLOWER_MUTATION = gql`
  mutation DeclineProfileFollowerRequest($personId: String!) {
    rejectPendingFollower(personId: $personId) {
      id
    }
  }
`;

export const NOTIFICATION_EVENTS_SUBSCRIPTION = gql`
  subscription NotificationEvents($offset: String) {
    activityEvents(offset: $offset) {
      offset
      events {
        __typename

        ... on EventActivityCreated {
          activity {
            ...Notification
          }
        }

        ... on EventActivityStacked {
          activity {
            __typename
            id
            status
            updatedAt

            ... on PersonFollowed {
              persons {
                ...NotificationPerson
                name
                followingReference {
                  me
                }
              }
              otherPersons
            }

            ... on PostLiked {
              persons {
                ...NotificationPerson
              }
              otherPersons
              post {
                ...NotificationPostPreview
              }
            }

            ... on PostCommented {
              persons {
                ...NotificationPerson
              }
              otherPersons
              post {
                ...NotificationPostPreview
              }
            }
          }
        }

        ... on EventActivityUnstacked {
          activityId
          unstackedPersonId
          otherPerson {
            ...NotificationPerson

            name
            followingReference {
              me
            }
          }
        }

        ... on EventActivityActionPerformed {
          activityId
          actionType
        }

        ... on EventActivityDeleted {
          activityId
        }
      }
    }
  }

  ${NOTIFICATION_FRAGMENT}
  ${NOTIFICATION_PERSON_FRAGMENT}
`;
