import { object, SchemaOf, string } from "yup";

import { SpecificNftPurchaseDialogOptions } from "~/components/nft/NftAsset/NftPurchaseDialog/declarations";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogConfig,
  DialogOptionsValidationFunction
} from "~/components/providers/DialogProvider/declarations/config";
import { WALLET_DIALOG_ERROR_CODE } from "~/components/wallet/constants";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const NFT_PURCHASE_DIALOG_FORM_ID = "nft-purchase-dialog-form-id";
export const SLIDE_ANIMATION_DURATION_IN_SECONDS = 0.5;

const VALIDATION_SCHEMA: SchemaOf<SpecificNftPurchaseDialogOptions> = object({
  nftId: string().required(),
  nftEditionId: string().required()
})
  .strict()
  .required();

const validateOptions: DialogOptionsValidationFunction = rawOptions =>
  VALIDATION_SCHEMA.isValidSync(rawOptions);

export enum FREE_NFT_PURCHASE_ERROR_CODE {
  limitExceeded = "limitExceeded"
}

export type NFT_PURCHASE_DIALOG_ERROR_CODE =
  | WALLET_DIALOG_ERROR_CODE
  | FREE_NFT_PURCHASE_ERROR_CODE;

export const NFT_PURCHASE_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.purchaseNft> =
  {
    Component: safeDynamicImport(
      () => import("~/components/nft/NftAsset/NftPurchaseDialog"),
      {
        ssr: false
      }
    ),
    saveInUrl: true,
    validateOptions
  };
