import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import {
  WALLET_CREATE_DIALOG_STEP,
  WalletCreateDialogStepDescription
} from "~/components/wallet/WalletCreateDialog/declarations";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_CREATE_DIALOG_STEP_DESCRIPTIONS: {
  [key in WALLET_CREATE_DIALOG_STEP]: WalletCreateDialogStepDescription;
} = {
  [WALLET_CREATE_DIALOG_STEP.mnemonic]: {
    captionTranslationKey: "wallet-create_mnemonic_caption",
    descriptionTranslationKey: "wallet-create_mnemonic_description",
    cancelButtonVariant: "outlined",
    cancelButtonTranslationKey: "wallet-create_mnemonic_cancel_action",
    confirmButtonTranslationKey: "wallet-create_mnemonic_confirm_action"
  },
  [WALLET_CREATE_DIALOG_STEP.mnemonicTest]: {
    captionTranslationKey: "wallet-create_mnemonic-test_caption",
    descriptionTranslationKey: "wallet-create_mnemonic-test_description",
    cancelButtonVariant: "outlined",
    cancelButtonTranslationKey: "wallet-create_mnemonic-test_cancel_action",
    confirmButtonTranslationKey: "wallet-create_mnemonic-test_confirm_action"
  },
  [WALLET_CREATE_DIALOG_STEP.setPin]: {
    captionTranslationKey: "wallet-create_set-pin_caption",
    descriptionTranslationKey: "wallet-create_set-pin_description",
    cancelButtonVariant: "outlined",
    cancelButtonTranslationKey: "wallet-create_set-pin_cancel_action",
    confirmButtonTranslationKey: "wallet-create_set-pin_confirm_action"
  }
};

export const WALLET_CREATE_DIALOG_INITIAL_STEP =
  WALLET_CREATE_DIALOG_STEP.mnemonic;

export const CREATE_WALLET_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.createWallet> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletCreateDialog"),
      {
        ssr: false
      }
    )
  };
