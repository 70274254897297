import {
  ApolloClient,
  OnSubscriptionDataOptions,
  useSubscription
} from "@apollo/client";
import { DocumentNode } from "graphql";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useApolloSubscriptionsConnectionEventsContext } from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/ApolloSubscriptionsConnectionEventsContext";
import { APOLLO_SUBSCRIPTION_CONNECTION_EVENT } from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/declarations";

type OffsetType = string | null;

type SubscriptionWithOffset<Key extends string | number | symbol> = {
  [key in Key]: {
    offset: OffsetType;
  };
};

export type OnSubscriptionDataHandler<
  Subscription extends SubscriptionWithOffset<SubscriptionKey>,
  SubscriptionKey extends keyof Subscription
> = (options: {
  client: ApolloClient<unknown>;
  data: Subscription[SubscriptionKey];
}) => void;

type VariablesWithOffset = {
  offset?: OffsetType;
};

export type UseSubscriptionWithRecoveryProps<
  Subscription extends SubscriptionWithOffset<SubscriptionKey>,
  SubscriptionKey extends keyof Subscription,
  SubscriptionVariables
> = {
  subscription: DocumentNode;
  key: SubscriptionKey;
  skip?: boolean;
  variables?: SubscriptionVariables;
  onSubscriptionData?: OnSubscriptionDataHandler<Subscription, SubscriptionKey>;
};

const useSubscriptionWithRecovery = <
  Subscription extends SubscriptionWithOffset<SubscriptionKey>,
  SubscriptionKey extends keyof Subscription,
  SubscriptionVariables extends VariablesWithOffset
>({
  subscription,
  key,
  skip,
  variables,
  onSubscriptionData
}: UseSubscriptionWithRecoveryProps<
  Subscription,
  SubscriptionKey,
  SubscriptionVariables
>): void => {
  const lastOffsetRef = useRef<OffsetType | undefined>();
  const [offset, setOffset] = useState<OffsetType | undefined>();
  const subscriptionVariables = useMemo(
    () =>
      ({
        offset,
        ...variables
      } as SubscriptionVariables),
    [offset, variables]
  );

  const handleSubscriptionData = useCallback(
    ({
      client,
      subscriptionData
    }: OnSubscriptionDataOptions<Subscription>): void => {
      const data = subscriptionData.data;

      if (!data) {
        return;
      }

      onSubscriptionData?.({ client, data: data[key] });

      lastOffsetRef.current = data[key].offset;
    },
    [key, onSubscriptionData]
  );

  useSubscription<Subscription, SubscriptionVariables>(subscription, {
    skip,
    variables: subscriptionVariables,
    onSubscriptionData: handleSubscriptionData
  });

  const { subscribe, unsubscribe } =
    useApolloSubscriptionsConnectionEventsContext();

  useEffect(() => {
    const handleSubscribe = (
      event: APOLLO_SUBSCRIPTION_CONNECTION_EVENT
    ): boolean => {
      if (event === APOLLO_SUBSCRIPTION_CONNECTION_EVENT.disconnection) {
        setOffset(lastOffsetRef.current);
      }

      return false;
    };

    subscribe(handleSubscribe);

    return () => {
      unsubscribe(handleSubscribe);
    };
  }, [subscribe, unsubscribe]);
};

export default useSubscriptionWithRecovery;
