import { object, SchemaOf, string } from "yup";

import { GUID_REGEXP } from "~/constants/common";
import { WalletDecryptionRecord } from "~/declarations/wallet";
import { isObject } from "~/utils/common";

const WALLET_LOCAL_STORAGE_KEY = "wallet";

interface WalletKeyMap {
  [type: string]: WalletDecryptionRecord | undefined;
}

const WALLET_KEY_LOCALSTORAGE_SCHEMA: SchemaOf<WalletDecryptionRecord> = object(
  {
    ciphertext: string().required(),
    id: string().required().matches(GUID_REGEXP),
    iv: string().required()
  }
)
  .required()
  .strict(true);

const getWalletKeyMapFromLocalStorage = (): WalletKeyMap => {
  try {
    const walletKeyMapAsString = localStorage.getItem(WALLET_LOCAL_STORAGE_KEY);
    const walletKeyMap = walletKeyMapAsString
      ? JSON.parse(walletKeyMapAsString)
      : {};
    const validWalletKeyMap = isObject(walletKeyMap) ? walletKeyMap : {};

    return validWalletKeyMap as WalletKeyMap;
  } catch {
    return {};
  }
};

export const getWalletDecryptionRecordFromLocalStore = (
  currentUserId: string
): WalletDecryptionRecord | null => {
  try {
    const walletKeyMap = getWalletKeyMapFromLocalStorage();
    const decryptionRecord = walletKeyMap[currentUserId];

    if (
      !decryptionRecord ||
      !WALLET_KEY_LOCALSTORAGE_SCHEMA.isValidSync(decryptionRecord)
    ) {
      return null;
    }

    return {
      iv: decryptionRecord.iv,
      ciphertext: decryptionRecord.ciphertext,
      id: decryptionRecord.id
    };
  } catch {
    return null;
  }
};

const writeWalletKeyMapToLocalStore = (walletKeyMap: WalletKeyMap): void => {
  const walletKeyMapAsString = JSON.stringify(walletKeyMap);

  localStorage.setItem(WALLET_LOCAL_STORAGE_KEY, walletKeyMapAsString);
};

export const setWalletDecryptionRecordToLocalStore = (
  decryptionRecord: WalletDecryptionRecord,
  currentUserId: string
): boolean => {
  try {
    const walletKeyMap = getWalletKeyMapFromLocalStorage();
    const updatedWalletKeyMap = {
      ...walletKeyMap,
      [currentUserId]: decryptionRecord
    };

    writeWalletKeyMapToLocalStore(updatedWalletKeyMap);

    return true;
  } catch {
    return false;
  }
};

export const removeWalletDecryptionRecordFromLocalStore = (
  currentUserId: string
): boolean => {
  try {
    const walletKeyMap = getWalletKeyMapFromLocalStorage();

    delete walletKeyMap[currentUserId];
    writeWalletKeyMapToLocalStore(walletKeyMap);

    return true;
  } catch {
    return false;
  }
};
