import { useEffect } from "react";

const useSetVhUnit = (): void => {
  useEffect(() => {
    const recalculateHeight = (): void => {
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    recalculateHeight();

    window.addEventListener("resize", recalculateHeight);

    return () => {
      window.removeEventListener("resize", recalculateHeight);
    };
  }, []);
};

export default useSetVhUnit;
