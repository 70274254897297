import { Method } from "axios";

export enum SAVE_STATE {
  init = "initialize",
  error = "error",
  saving = "saving",
  saved = "saved"
}

export const REST_METHODS: { [key: string]: Method } = {
  post: "POST",
  get: "GET",
  put: "PUT",
  delete: "DELETE"
};

export const NETWORK_CODES = {
  ok: 200,
  found: 302,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  unprocessableRequest: 422
};
