import { useMemo } from "react";

import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { safeDynamicImport } from "~/utils/import/dynamic";

import { useGlobalContext } from "../GlobalProvider/GlobalProvider";

const UserInterestsDialog = safeDynamicImport(
  () => import("./UserInterestsDialog/UserInterestsDialog"),
  { ssr: false }
);

type Props = {
  children: React.ReactNode;
};

const UserInterestsDialogContextProvider = ({
  children
}: Props): JSX.Element => {
  const { currentUser, authorized } = useAccountLayoutContext();
  const {
    userInterestsDialog: {
      cancelLabel,
      submitLabel,
      open: openUserInterests,
      needToShow: needToShowUserInterests,
      closeDialog: onCloseUserInterests
    }
  } = useGlobalContext();
  const userInterests = currentUser.data?.preferredCategories;
  const initialSelectedInterestIds = useMemo(() => {
    if (!userInterests) {
      return null;
    }

    return userInterests.map(({ id }) => id);
  }, [userInterests]);
  const userInterestsDialogOpen =
    authorized &&
    initialSelectedInterestIds &&
    (openUserInterests ||
      (needToShowUserInterests && !initialSelectedInterestIds.length));

  return (
    <>
      {children}
      {userInterestsDialogOpen && initialSelectedInterestIds && (
        <UserInterestsDialog
          initialSelectedInterestIds={initialSelectedInterestIds}
          cancelLabel={cancelLabel}
          submitLabel={submitLabel}
          open={userInterestsDialogOpen}
          onClose={onCloseUserInterests}
        />
      )}
    </>
  );
};

export default UserInterestsDialogContextProvider;
