import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { VIDEO_POST_VIDEO_FRAGMENT } from "~/components/hypeTV/Video/graphql";
import { POST_AUTHOR_FRAGMENT } from "~/components/post/postAuthorFragment";

const SHORT_POST_VIDEO_FRAGMENT = gql`
  fragment ShortPostVideo on AttachmentVideo {
    __typename
    id
    cover {
      url
      blurredUrl
      thumbnails {
        little {
          url
        }
        middle {
          url
        }
        big {
          url
        }
        w200h200 {
          url
        }
        w300h300 {
          url
        }
        w400h400 {
          url
        }
        w600h600 {
          url
        }
      }
    }
    width
    height
    publicUrl
    views
    videoId
  }
`;

export const GENERAL_POST_VIDEO_FRAGMENT_NAME = "GeneralPostVideoFragment";
export const GENERAL_POST_VIDEO_FRAGMENT = gql`
  fragment GeneralPostVideoFragment on AttachmentVideo {
    ...ShortPostVideo
    ...VideoPostVideo
  }

  ${VIDEO_POST_VIDEO_FRAGMENT}
  ${SHORT_POST_VIDEO_FRAGMENT}
`;

export const SHORT_POST_NODE_FILE_FRAGMENT = gql`
  fragment ShortPostNodeFile on AttachedFile {
    __typename

    ... on AttachmentImage {
      id
      blurredUrl
      url
      thumbnails {
        s295 {
          url
        }
        b960 {
          url
        }
        m600 {
          url
        }
        w1200 {
          url
        }
        f1920 {
          url
        }
        w200h200 {
          url
        }
        w300h300 {
          url
        }
        w400h400 {
          url
        }
        w600h600 {
          url
        }
      }
      sizes {
        width
        height
      }
    }
    ... on AttachmentVideo {
      ...ShortPostVideo
    }
  }
  ${SHORT_POST_VIDEO_FRAGMENT}
`;

export const SHORT_POST_NODE_FRAGMENT = gql`
  fragment ShortPostNode on ShortPost {
    __typename
    parentPost {
      __typename
      id
      type
      title
      richText {
        ...richText
      }
      video {
        duration
        views
      }
      previewInfo {
        previewType
        video {
          publicUrl
          width
          height
        }
        videoStartSegment {
          publicUrl
        }
      }
      paywall {
        cost
        status
        preview
      }
    }
    sensitiveShown @client
    id
    owner {
      ...shortPostAuthor
    }
    settings {
      enabledTipping
      isAdult
      isPreviewBlurred
      location {
        id
        name
      }
    }
    paywall {
      preview
      status
      cost
    }
    files {
      ...ShortPostNodeFile
    }
    type
    confidentiality
    status
    publishedAt
    counters {
      comments
      tips {
        sum
      }
    }
    reactions {
      likes
      myReaction {
        id
        type
      }
    }
    richText {
      ...richText
    }
    imagePreview {
      isCustomPreview
      image {
        url
        blurredUrl
        sizes {
          width
          height
        }
        thumbnails {
          b960 {
            url
          }
          w200h200 {
            url
          }
          w300h300 {
            url
          }
          w400h400 {
            url
          }
          w600h600 {
            url
          }
        }
      }
    }
  }
  ${RICH_TEXT_FRAGMENT}
  ${POST_AUTHOR_FRAGMENT}
  ${SHORT_POST_NODE_FILE_FRAGMENT}
`;

export const SHORT_STREAM_VIDEO_UNIT_FRAGMENT = gql`
  fragment ShortStreamVideoUnit on ShortStream {
    __typename
    id
    name
    owner {
      ...shortPostAuthor
    }
    richText {
      ...richText
    }
  }
  ${POST_AUTHOR_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
`;

export const LONG_STREAM_VIDEO_UNIT_FRAGMENT = gql`
  fragment LongStreamVideoUnit on LongStream {
    __typename
    sensitiveShown @client
    id
    status
    type
    owner {
      ...shortPostAuthor
    }
    name
    settings {
      isAdult
      categories {
        nameI18N
      }
    }
    stream {
      id
      status
      watchers
      finishDate
      startDate
      video {
        duration
        url
      }
      cover {
        url
        blurredUrl
        sizes {
          height
          width
        }
        thumbnails {
          s295 {
            url
          }
          m600 {
            url
          }
          b960 {
            url
          }
          w1200 {
            url
          }
          w200h200 {
            url
          }
          w300h300 {
            url
          }
          w400h400 {
            url
          }
          w600h600 {
            url
          }
        }
      }
    }
    counters {
      comments
      tips {
        sum
      }
    }
    reactions {
      likes
      myReaction {
        id
        type
      }
    }
    confidentiality
    publishedAt
    paywall {
      cost
      preview
      status
    }
    richText {
      ...richText
    }
  }
  ${POST_AUTHOR_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
`;

export const SHORT_POST_SENSITIVE_SHOWN_FRAGMENT_NAME =
  "ShortPostSensitiveShown";
export const SHORT_POST_SENSITIVE_SHOWN_FRAGMENT = gql`
  fragment ShortPostSensitiveShown on ShortPost {
    sensitiveShown
  }
`;

export const LONG_POST_SENSITIVE_SHOWN_FRAGMENT_NAME = "LongPostSensitiveShown";
export const LONG_POST_SENSITIVE_SHOWN_FRAGMENT = gql`
  fragment LongPostSensitiveShown on LongPost {
    sensitiveShown
  }
`;

export const LONG_STREAM_SENSITIVE_SHOWN_FRAGMENT_NAME =
  "LongStreamSensitiveShown";
export const LONG_STREAM_SENSITIVE_SHOWN_FRAGMENT = gql`
  fragment LongStreamSensitiveShown on LongStream {
    sensitiveShown
  }
`;

export const POST_COMMENT_COUNTERS_FRAGMENT = gql`
  fragment PostCounters on Post {
    counters {
      comments
      tips {
        sum
      }
    }
  }
`;

const FULL_BODY_POST_AUTHOR = gql`
  fragment FullBodyPostAuthor on Person {
    __typename
    id
    name
    avatar {
      little
      middle
    }
    nickname
    validation
    isFounder
    isCharity
    followingReference {
      me
    }
    currentUserReference {
      __typename
      canMessage
      canEdit
    }
  }
`;

const FULL_BODY_SHORT_POST_VIDEO_FRAGMENT = gql`
  fragment FullBodyShortPostVideo on AttachmentVideo {
    __typename
    id
    cover {
      url
      blurredUrl
      thumbnails {
        little {
          url
        }
        middle {
          url
        }
        big {
          url
        }
      }
    }
    width
    height
    publicUrl
    views
    videoId
  }
`;

const FULL_BODY_SHORT_POST_IMAGE_FRAGMENT = gql`
  fragment FullBodyShortPostImage on AttachmentImage {
    id
    blurredUrl
    url
    thumbnails {
      s295 {
        url
      }
      m600 {
        url
      }
      b960 {
        url
      }
      f1920 {
        url
      }
    }
    sizes {
      width
      height
    }
  }
`;

const FULL_BODY_SHORT_POST_FILE_FRAGMENT = gql`
  fragment FullBodyShortPostFile on AttachedFile {
    __typename

    ... on AttachmentImage {
      ...FullBodyShortPostImage
    }
    ... on AttachmentVideo {
      ...FullBodyShortPostVideo
    }
  }
  ${FULL_BODY_SHORT_POST_VIDEO_FRAGMENT}
  ${FULL_BODY_SHORT_POST_IMAGE_FRAGMENT}
`;

const FULL_BODY_SHORT_POST_FRAGMENT = gql`
  fragment FullBodyShortPost on ShortPost {
    __typename
    id
    parentPost {
      __typename
      id
      type
      title
      richText {
        ...richText
      }
      video {
        duration
      }
      previewInfo {
        previewType
      }
      paywall {
        cost
        status
        preview
      }
    }
    sensitiveShown @client
    owner {
      ...FullBodyPostAuthor
    }
    settings {
      enabledTipping
      isAdult
      isPreviewBlurred
      location {
        id
        name
      }
    }
    paywall {
      preview
      status
      cost
    }
    files {
      ...FullBodyShortPostFile
    }
    type
    confidentiality
    status
    publishedAt
    counters {
      comments
      tips {
        sum
      }
    }
    reactions {
      likes
      myReaction {
        id
        type
      }
    }
    richText {
      ...richText
    }
    imagePreview {
      image {
        blurredUrl
        sizes {
          width
          height
        }
        thumbnails {
          b960 {
            url
          }
        }
      }
    }
  }
  ${RICH_TEXT_FRAGMENT}
  ${FULL_BODY_POST_AUTHOR}
  ${FULL_BODY_SHORT_POST_FILE_FRAGMENT}
`;

export const SHORT_POST_QUERY = gql`
  query StandalonePost($postId: String!) {
    getShortPost(postId: $postId) {
      ...FullBodyShortPost
    }
  }
  ${FULL_BODY_SHORT_POST_FRAGMENT}
`;

export const POST_STATUS_FRAGMENT_NAME = "PostStatusFragment";
export const POST_STATUS_FRAGMENT = gql`
  fragment PostStatusFragment on Post {
    __typename
    status
  }
`;
