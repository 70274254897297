import { number, object, SchemaOf, string } from "yup";

import { ValidationFunctionsMap } from "~/components/auth/declarations";
import {
  getValidateEmailFunction,
  getValidationFunctionsMap
} from "~/components/auth/utils";
import { MILLISECONDS_IN_SECOND } from "~/constants/date";
import { EMAIL_SCHEMA } from "~/constants/validation/user";

import { ProblemReportForm } from "../ProblemReportDialog/declarations";

export const ROWS_COUNT = 5;
export const MAX_ROWS_COUNT = 5;
export const MAX_MESSAGE_LENGTH = 500;
export const MIN_MESSAGE_LENGTH = 1;
export const SUPPORT_PLATFORM = "Web";

export enum PROBLEM_REPORT_TOPIC {
  generalQuestion = 1,
  feedbackOrSuggestion = 2,
  reportContent = 3,
  technicalIssue = 4,
  other = 5,
  authenticationIssue = 6,
  nftIssue = 7,
  paymentIssue = 8
}

export enum PROBLEM_REPORT_TYPE {
  crash = "crash",
  payout = "payout",
  authenticationIssue = "auth",
  contactSupport = "contact",
  reportProblem = "report",
  leaveFeedback = "feedback",
  nftIssue = "nft",
  paymentIssue = "payment"
}

export const PROBLEM_REPORT_TYPE_TO_TOPIC = {
  [PROBLEM_REPORT_TYPE.crash]: PROBLEM_REPORT_TOPIC.technicalIssue,
  [PROBLEM_REPORT_TYPE.payout]: PROBLEM_REPORT_TOPIC.paymentIssue,
  [PROBLEM_REPORT_TYPE.authenticationIssue]:
    PROBLEM_REPORT_TOPIC.authenticationIssue,
  [PROBLEM_REPORT_TYPE.contactSupport]: PROBLEM_REPORT_TOPIC.generalQuestion,
  [PROBLEM_REPORT_TYPE.reportProblem]: PROBLEM_REPORT_TOPIC.technicalIssue,
  [PROBLEM_REPORT_TYPE.leaveFeedback]:
    PROBLEM_REPORT_TOPIC.feedbackOrSuggestion,
  [PROBLEM_REPORT_TYPE.nftIssue]: PROBLEM_REPORT_TOPIC.nftIssue,
  [PROBLEM_REPORT_TYPE.paymentIssue]: PROBLEM_REPORT_TOPIC.paymentIssue
};

export const TITLE_TRANSLATION_KEYS_BY_TYPE = {
  [PROBLEM_REPORT_TYPE.crash]: "report:problem-report_crash_title",
  [PROBLEM_REPORT_TYPE.payout]: "report:problem-report_payout_title",
  [PROBLEM_REPORT_TYPE.authenticationIssue]:
    "report:problem-report_authentication-issue_title",
  [PROBLEM_REPORT_TYPE.contactSupport]:
    "report:problem-report_contact-support_title",
  [PROBLEM_REPORT_TYPE.reportProblem]:
    "report:problem-report_report-a-problem_title",
  [PROBLEM_REPORT_TYPE.leaveFeedback]: "problem-report_leave-feedback_title",
  [PROBLEM_REPORT_TYPE.nftIssue]: "report:problem-report_contact-support_title",
  [PROBLEM_REPORT_TYPE.paymentIssue]:
    "report:problem-report_contact-support_title"
};

export const SUBTITLE_TRANSLATION_KEYS_BY_TYPE = {
  [PROBLEM_REPORT_TYPE.crash]: "report:problem-report_let-us-know",
  [PROBLEM_REPORT_TYPE.payout]: "report:problem-report_payout_subtitle",
  [PROBLEM_REPORT_TYPE.authenticationIssue]:
    "report:problem-report_authentication-issue_subtitle",
  [PROBLEM_REPORT_TYPE.contactSupport]:
    "report:problem-report_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.reportProblem]:
    "report:problem-report_report-a-problem_subtitle",
  [PROBLEM_REPORT_TYPE.leaveFeedback]: "problem-report_leave-feedback_subtitle",
  [PROBLEM_REPORT_TYPE.nftIssue]:
    "report:problem-report_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.paymentIssue]:
    "report:problem-report_contact-support_subtitle"
};

export const SUCCESS_DIALOG_TITLE_TRANSLATION_KEYS_BY_TYPE = {
  [PROBLEM_REPORT_TYPE.crash]:
    "report:problem-report-success_contact-support_title",
  [PROBLEM_REPORT_TYPE.payout]:
    "report:problem-report-success_contact-support_title",
  [PROBLEM_REPORT_TYPE.authenticationIssue]:
    "report:problem-report-success_contact-support_title",
  [PROBLEM_REPORT_TYPE.contactSupport]:
    "report:problem-report-success_contact-support_title",
  [PROBLEM_REPORT_TYPE.reportProblem]:
    "report:problem-report-success_contact-support_title",
  [PROBLEM_REPORT_TYPE.leaveFeedback]:
    "report:problem-report-success_leave-feedback_title",
  [PROBLEM_REPORT_TYPE.nftIssue]:
    "report:problem-report-success_contact-support_title",
  [PROBLEM_REPORT_TYPE.paymentIssue]:
    "report:problem-report-success_contact-support_title"
};

export const SUCCESS_DIALOG_SUBTITLE_TRANSLATION_KEYS_BY_TYPE = {
  [PROBLEM_REPORT_TYPE.crash]:
    "report:problem-report-success_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.payout]:
    "report:problem-report-success_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.authenticationIssue]:
    "report:problem-report-success_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.contactSupport]:
    "report:problem-report-success_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.reportProblem]:
    "report:problem-report-success_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.leaveFeedback]:
    "problem-report-success_leave-feedback_subtitle",
  [PROBLEM_REPORT_TYPE.nftIssue]:
    "report:problem-report-success_contact-support_subtitle",
  [PROBLEM_REPORT_TYPE.paymentIssue]:
    "report:problem-report-success_contact-support_subtitle"
};

const LONG_DEBOUNCE_TIME_IN_MILLISECONDS = MILLISECONDS_IN_SECOND;
const SHORT_DEBOUNCE_TIME_IN_MILLISECONDS = 300;
const validateEmail = getValidateEmailFunction({
  checkIfExists: false
});

export const INFREQUENT_VALIDATION_FUNCTION_BY_FIELD_NAME: ValidationFunctionsMap<
  Pick<ProblemReportForm, "email">
> = getValidationFunctionsMap(
  {
    email: validateEmail
  },
  LONG_DEBOUNCE_TIME_IN_MILLISECONDS
);

export const FREQUENT_VALIDATION_FUNCTION_BY_FIELD_NAME: ValidationFunctionsMap<
  Pick<ProblemReportForm, "email">
> = getValidationFunctionsMap(
  {
    email: validateEmail
  },
  SHORT_DEBOUNCE_TIME_IN_MILLISECONDS
);

export const CLIENT_VALIDATION_SCHEMA: SchemaOf<
  Omit<ProblemReportForm, "email">
> = object({
  message: string()
    .required(() => ({ key: "report:problem-report_message_error_required" }))
    .min(MIN_MESSAGE_LENGTH, () => ({
      key: "report:problem-report_message_error_min",
      options: { min: MIN_MESSAGE_LENGTH }
    }))
    .max(MAX_MESSAGE_LENGTH, () => ({
      key: "report:problem-report_message_error_max",
      options: { max: MAX_MESSAGE_LENGTH }
    })),
  topicId: number()
    .nullable()
    .required(() => ({
      key: "report:problem-report_topic_error_required"
    }))
});

export const BACKEND_VALIDATION_SCHEMA: SchemaOf<ProblemReportForm> = object({
  email: EMAIL_SCHEMA
}).concat(CLIENT_VALIDATION_SCHEMA);

export const INITIAL_VALUES: ProblemReportForm = {
  email: "",
  message: "",
  topicId: null
};

export const SENTRY_FEEDBACK_API = "/api/sentry-feedback";
export const CUSTOM_USER_FEEDBACK_ERROR_NAME = "CustomUserFeedback";
export const CUSTOM_USER_FEEDBACK_ERROR_TEXT = "USER FEEDBACK";
export const INCOGNITO_USERNAME = "incognito";
