import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export enum WALLET_CONFIRMATION_RECEIVER_HAS_NOT_WALLET_TYPE {
  tipping,
  purchase,
  sendCoins
}

export const WALLET_CONFIRMATION_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.walletConfirmation> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletConfirmationDialog"),
      {
        ssr: false
      }
    )
  };
