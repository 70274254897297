import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

const UnreadNotificationsContext = createContext<number | undefined>(0);

const useUnreadNotificationsContext = createUseContext(
  UnreadNotificationsContext,
  "UnreadNotificationsContext"
);

export default UnreadNotificationsContext.Provider;
export { useUnreadNotificationsContext };
