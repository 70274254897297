import NftReportResultDialog from "~/components/nft/NftReportProvider/NftReportDialog/NftReportResultDialog";
import { PostAuthor } from "~/declarations/post";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

import {
  NftReportDialogPayload,
  NftReportResultDialogPayload
} from "./declarations";
import NftReportContextProvider from "./NftReportContextProvider";

const NftReportDialog = safeDynamicImport(
  () => import("./NftReportDialog/NftReportDialog")
);

type Props = {
  children: React.ReactNode;
};

const NftReportProvider = ({ children }: Props): JSX.Element => {
  const nftReportDialog = useDialog<NftReportDialogPayload>({
    options: {
      forceResetPayloadOnClose: true
    }
  });

  const nftReportResultDialog = useDialog<NftReportResultDialogPayload>();

  const handleReportSuccess = (nftCreator: PostAuthor): void => {
    nftReportResultDialog.openDialog({ nftCreator });
  };

  return (
    <NftReportContextProvider
      value={{
        openDialog: nftReportDialog.openDialog
      }}
    >
      {children}
      {nftReportDialog.hasBeenOpened && nftReportDialog.payload && (
        <NftReportDialog
          open={nftReportDialog.open}
          nftId={nftReportDialog.payload.nftId}
          nftCreator={nftReportDialog.payload.nftCreator}
          onClose={nftReportDialog.closeDialog}
          onSuccess={handleReportSuccess}
        />
      )}

      {nftReportResultDialog.hasBeenOpened && nftReportResultDialog.payload && (
        <NftReportResultDialog
          open={nftReportResultDialog.open}
          authorId={nftReportResultDialog.payload.nftCreator.id}
          onClose={nftReportResultDialog.closeDialog}
        />
      )}
    </NftReportContextProvider>
  );
};

export default NftReportProvider;
