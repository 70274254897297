import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

import { PostReportDialogPayload } from "./declarations";

export interface PostReportProps {
  openDialog: (props: PostReportDialogPayload) => void;
}

const PostReportContext = createContext<PostReportProps | undefined>(undefined);

const usePostReportContext = createUseContext(
  PostReportContext,
  "PostReportContext"
);

export default PostReportContext.Provider;
export { usePostReportContext };
