import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";

import { PURCHASE_TYPE } from "~/declarations/purchases";
import updateTipCounter from "~/utils/apollo/posts/updateTipCounter";
import usePostUpdate from "~/utils/apollo/usePostUpdate";

import {
  TRANSACTION_DONE_REASON,
  TransactionDoneHandler
} from "../declarations";

type UseProgressOfTransactionsHandlersProps = {
  addTransactionDoneHandler: (handler: TransactionDoneHandler) => void;
  removeTransactionDoneHandler: (handler: TransactionDoneHandler) => void;
};

const useProgressOfTransactionsDefaultHandlers = ({
  addTransactionDoneHandler,
  removeTransactionDoneHandler
}: UseProgressOfTransactionsHandlersProps): void => {
  const apollo = useApolloClient();
  const updatePost = usePostUpdate(apollo);

  useEffect(() => {
    const removeProgressOnTimeout: TransactionDoneHandler = (
      progress,
      reason,
      remove
    ) => {
      if (reason === TRANSACTION_DONE_REASON.timeout) {
        remove();
        console.error(`Transaction failed (timeout)`);
      }

      return true;
    };

    addTransactionDoneHandler(removeProgressOnTimeout);

    return () => {
      removeTransactionDoneHandler(removeProgressOnTimeout);
    };
  }, [addTransactionDoneHandler, removeTransactionDoneHandler]);

  useEffect(() => {
    const removeProgressOnSuccess: TransactionDoneHandler = (
      progress,
      reason,
      remove
    ) => {
      if (reason === TRANSACTION_DONE_REASON.success) {
        remove();
        return true;
      }

      return false;
    };

    addTransactionDoneHandler(removeProgressOnSuccess);

    return () => {
      removeTransactionDoneHandler(removeProgressOnSuccess);
    };
  }, [addTransactionDoneHandler, removeTransactionDoneHandler]);

  useEffect(() => {
    const updateTipCounterOnSuccess: TransactionDoneHandler = (
      progress,
      reason,
      remove
    ) => {
      if (
        reason === TRANSACTION_DONE_REASON.success &&
        progress.type === PURCHASE_TYPE.postTipping
      ) {
        remove();
        updateTipCounter(
          apollo,
          progress.postId,
          progress.postType,
          progress.balance
        );
        return true;
      }

      return false;
    };

    addTransactionDoneHandler(updateTipCounterOnSuccess);

    return () => {
      removeTransactionDoneHandler(updateTipCounterOnSuccess);
    };
  }, [addTransactionDoneHandler, apollo, removeTransactionDoneHandler]);

  useEffect(() => {
    const updatePostOnSuccess: TransactionDoneHandler = (
      progress,
      reason,
      remove
    ) => {
      if (
        reason === TRANSACTION_DONE_REASON.success &&
        progress.type === PURCHASE_TYPE.postPurchase
      ) {
        updatePost(progress.postId).then(remove);
        return true;
      }

      return false;
    };

    addTransactionDoneHandler(updatePostOnSuccess);

    return () => {
      removeTransactionDoneHandler(updatePostOnSuccess);
    };
  }, [addTransactionDoneHandler, removeTransactionDoneHandler, updatePost]);

  useEffect(() => {
    const updateNftEditionOnSuccess: TransactionDoneHandler = (
      progress,
      reason,
      remove
    ) => {
      if (
        reason === TRANSACTION_DONE_REASON.success &&
        progress.type === PURCHASE_TYPE.nftStartSellingEditions
      ) {
        remove();
        return true;
      }

      return false;
    };

    addTransactionDoneHandler(updateNftEditionOnSuccess);

    return () => {
      removeTransactionDoneHandler(updateNftEditionOnSuccess);
    };
  }, [addTransactionDoneHandler, apollo, removeTransactionDoneHandler]);
};

export default useProgressOfTransactionsDefaultHandlers;
