export enum WalletCreateKeyDialogSuccessResult {
  keyCreated = "keyCreated",
  errorWithRetry = "errorWithRetry",
  fatalError = "fatalError",
  walletNotExists = "walletNotExists",
  walletKeyNotExists = "walletKeyNotExists"
}

type ResultsWithNoMessage =
  | WalletCreateKeyDialogSuccessResult.keyCreated
  | WalletCreateKeyDialogSuccessResult.walletNotExists;

export type WalletCreateKeyDialogSuccessData =
  | {
      reason: Exclude<WalletCreateKeyDialogSuccessResult, ResultsWithNoMessage>;
      message: string;
    }
  | {
      reason: ResultsWithNoMessage;
    };
