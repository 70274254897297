import { object, SchemaOf, string } from "yup";

import { SpecificPurchaseDialogOptions } from "~/components/payment/PurchaseDialog/declarations";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogConfig,
  DialogOptionsValidationFunction
} from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const SLIDE_ANIMATION_DURATION_IN_SECONDS = 0.5;

const VALIDATION_SCHEMA: SchemaOf<SpecificPurchaseDialogOptions> = object({
  postId: string().required(),
  teaserText: string().required()
})
  .strict()
  .required();

const validateOptions: DialogOptionsValidationFunction = rawOptions =>
  VALIDATION_SCHEMA.isValidSync(rawOptions);

export const PURCHASE_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.postPurchase> = {
  Component: safeDynamicImport(
    () => import("~/components/payment/PurchaseDialog"),
    {
      ssr: false
    }
  ),
  saveInUrl: true,
  validateOptions
};
