import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps
} from "@material-ui/core/MenuItem";
import { LinkProps } from "next/link";
import { forwardRef } from "react";
import styled from "styled-components";

import Icon from "~/components/core/Icon";
import { IconVariant } from "~/components/core/Icon/declarations";
import Typography from "~/components/core/Typography";
import { omitStyleProps } from "~/utils/styled-components";

import TextLink from "../TextLink";

type Props = Omit<MuiMenuItemProps, "button"> & {
  icon?: IconVariant;
  button?: true;
  href?: LinkProps["href"];
  target?: HTMLAnchorElement["target"];
  rel?: HTMLAnchorElement["rel"];
  disablePadding?: boolean;
};

const MenuItem = forwardRef<HTMLLIElement, Props>(
  (
    { children, icon, href, target, rel, disablePadding, ...props }: Props,
    ref
  ): JSX.Element => {
    let content = (
      <MenuInnerWrapper disablePadding={disablePadding}>
        {icon && <StyledIcon variant={icon} />}
        <StyledTypography>{children}</StyledTypography>
      </MenuInnerWrapper>
    );

    content = href ? (
      <StyledTextLink href={href} target={target} rel={rel}>
        {content}
      </StyledTextLink>
    ) : (
      content
    );

    return (
      <Wrapper {...props} ref={ref}>
        {content}
      </Wrapper>
    );
  }
);

MenuItem.displayName = "MenuItem";

export default MenuItem;

const Wrapper = styled(MuiMenuItem)`
  border-radius: 10px;
  padding: 0;
`;

export const MenuInnerWrapper = styled.div.withConfig<{
  disablePadding?: boolean;
}>(omitStyleProps(["disablePadding"]))`
  display: flex;
  padding: ${({ disablePadding }) => (disablePadding ? "0" : "12px 16px")};
  width: 100%;
  align-items: center;
`;

const StyledTextLink = styled(TextLink)`
  flex: 1;
`;

const StyledTypography = styled(Typography).attrs({
  variant: "title2",
  component: "div"
})`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
  margin-right: 12px;
`;
