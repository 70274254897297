export enum AUTHENTICATION_METHOD {
  email = "email",
  google = "google",
  apple = "apple"
}

export type THIRD_PARTY_AUTHENTICATION_METHOD = Exclude<
  AUTHENTICATION_METHOD,
  AUTHENTICATION_METHOD.email
>;

export type SocialSignupCredentials = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type SocialSignupRecord = SocialSignupCredentials & {
  method: THIRD_PARTY_AUTHENTICATION_METHOD;
};
