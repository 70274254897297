import {
  Breakpoint,
  Breakpoints,
  BreakpointsOptions,
  BreakpointValues
} from "@material-ui/core/styles/createBreakpoints";

import { isNumber } from "~/utils/common";

const DEFAULT_UNIT = "px";
const DEFAULT_STEP = 5;

const DEFAULT_KEYS: Breakpoint[] = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"];

const DEFAULT_VALUES: BreakpointValues = {
  xxs: 0,
  xs: 600,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1920,
  xxl: 2560
};

export default function createBreakpoints({
  values = DEFAULT_VALUES,
  keys = DEFAULT_KEYS,
  unit = DEFAULT_UNIT,
  step = DEFAULT_STEP,
  ...otherOptions
}: BreakpointsOptions): Breakpoints {
  function up(key: Breakpoint | number): string {
    const value = !isNumber(key) ? values[key] : key;

    return `@media (min-width:${value}${unit})`;
  }

  function down(key: Breakpoint | number): string {
    const endIndex = !isNumber(key) ? keys.indexOf(key) + 1 : key;
    const upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      // max breakpoint down applies to all sizes
      return up(keys[0]);
    }

    const value =
      isNumber(upperbound) && endIndex > 0 ? upperbound - step / 100 : key;

    return `@media (max-width:${value}${unit})`;
  }

  function between(
    start: Breakpoint | number,
    end: Breakpoint | number
  ): string {
    const endIndex = !isNumber(end) ? keys.indexOf(end) : end;

    if (endIndex === keys.length - 1) {
      return up(start);
    }

    const minWidth = !isNumber(start) ? values[start] : start;
    const maxWidth =
      Boolean(~endIndex) && isNumber(values[keys[endIndex + 1]])
        ? values[keys[endIndex + 1]] - step / 100
        : end;

    return `@media (min-width:${minWidth}${unit}) and (max-width:${maxWidth}${unit})`;
  }

  function only(key: Breakpoint): string {
    return between(key, key);
  }

  function width(key: Breakpoint): number {
    return values[key];
  }

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    width,
    ...otherOptions
  };
}
