import { useQuery } from "@apollo/client";
import useTranslation from "next-translate/useTranslation";
import { SnackbarKey } from "notistack";
import { useCallback, useMemo } from "react";
import styled from "styled-components";

import TextLink from "~/components/core/TextLink";
import { CustomSnackbarMessage } from "~/components/providers/CustomSnackbarProvider/CustomSnackbarMessage";
import { PostPurchaseLinkClickHandler } from "~/components/wallet/providers/WalletProvider/declarations";
import { PATH_TO_POST_BY_TYPE, SHORT_POST_TYPENAME } from "~/constants/posts";
import { PostType } from "~/declarations/apollo/globalTypes";
import { Post, PostVariables } from "~/declarations/apollo/Post";
import { PURCHASE_TYPE } from "~/declarations/purchases";
import { POST_QUERY } from "~/utils/apollo/posts/graphql";
import { ObserverNotifyFunction } from "~/utils/observer/useObserver";

interface Props {
  id: SnackbarKey;
  postId: string;
  postType: PostType;
  purchaseType: PURCHASE_TYPE.postPurchase | PURCHASE_TYPE.postTipping;
  notifyPostPurchaseLinkClickHandler: ObserverNotifyFunction<PostPurchaseLinkClickHandler>;
  className?: string;
}

const getLinkToPost = (post?: Post): string => {
  if (!post) {
    return "";
  }

  const postToGoTo =
    post.getPost.__typename === SHORT_POST_TYPENAME && post.getPost.parentPost
      ? post.getPost.parentPost
      : post.getPost;

  const postId = postToGoTo.id;
  const route = PATH_TO_POST_BY_TYPE[postToGoTo.type];
  const href = route.replace("[id]", postId);

  return href;
};

const PaymentResultSnackPost = ({
  id,
  postId,
  postType,
  purchaseType,
  notifyPostPurchaseLinkClickHandler,
  className
}: Props): JSX.Element => {
  const { t } = useTranslation("payments");
  const { data } = useQuery<Post, PostVariables>(POST_QUERY, {
    variables: {
      postId
    },
    fetchPolicy: "cache-only"
  });
  const href = getLinkToPost(data);

  const handleClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      const { broken } = notifyPostPurchaseLinkClickHandler(true, postId);

      if (broken) {
        event.preventDefault();
      }
    },
    [notifyPostPurchaseLinkClickHandler, postId]
  );

  const postfixKey = useMemo(() => {
    const tippingPostfixKey =
      postType === PostType.longStream
        ? "stream-tipping-is-succeeded-postfix"
        : "post-tipping-is-succeeded-postfix";

    return purchaseType === PURCHASE_TYPE.postPurchase
      ? "post-purchase-is-succeeded-postfix"
      : tippingPostfixKey;
  }, [postType, purchaseType]);

  return (
    <CustomSnackbarMessage
      id={id}
      className={className}
      title={
        <div>
          <PostLink onClick={handleClick} href={href}>
            {postType === PostType.longStream
              ? t("stream-transaction-is-succeeded-prefix")
              : t("post-transaction-is-succeeded-prefix")}
          </PostLink>

          {t(postfixKey)}
        </div>
      }
    />
  );
};

export default PaymentResultSnackPost;

const PostLink = styled(TextLink)`
  font-weight: bold;
`;
