import useTranslation from "next-translate/useTranslation";
import { MouseEvent } from "react";
import styled from "styled-components";

import IconButton from "~/components/core/IconButton";
import MenuLogotype from "~/components/core/MenuLogotype";
import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { useSearchPopup } from "~/components/providers/SearchPopupProvider/SearchPopupContext";
import { useUnreadNotificationsContext } from "~/components/providers/UnreadNotificationsProvider/UnreadNotificationsContextProvider";
import { ANALYTICS_ACTION_IDS } from "~/constants/analytics";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";
import { useScrollDetect } from "~/utils/useScrollDetect";

import AccountHeaderWrapper from "../common/AccountHeaderWrapper";
import { AddContentButton } from "../common/styled";
import AccountHeaderMobileActivity from "./AccountHeaderMobileActivity";
import AccountHeaderMobileMenuWithButton from "./AccountHeaderMobileMenuWithButton";

const AccountHeaderMobileAddContentMenu = safeDynamicImport(
  () => import("./AccountHeaderMobileAddContentMenu"),
  {
    ssr: false
  }
);

const AccountHeaderMobile = (): JSX.Element => {
  const { t } = useTranslation("common");

  const scrolled = useScrollDetect();
  const unreadNotifications = useUnreadNotificationsContext();
  const { authorized, authorizationStatusFetched, currentUser } =
    useAccountLayoutContext();
  const { searchPopup } = useSearchPopup();
  const addContentMenu = useDialog<HTMLButtonElement>();

  const handleClickAddContentButton = (
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    addContentMenu.openDialog(event.currentTarget);
  };

  const handleOpenSearchPopup = (
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    searchPopup.openDialog(event.currentTarget);
  };

  return (
    <>
      <StyledHeaderWrapper withShadow={scrolled}>
        <EdgeWrapper>
          <MenuLogotype />
        </EdgeWrapper>
        <RightEdgeWrapper>
          {authorizationStatusFetched && authorized && (
            <StyledAddContentButton
              analyticsId={ANALYTICS_ACTION_IDS.startPostCreation}
              onClick={handleClickAddContentButton}
              aria-label={t("user:navigation-menu_add-content_caption")}
            />
          )}
          {authorizationStatusFetched && authorized && (
            <StyledAccountHeaderMobileActivity
              hasUnreadNotifications={unreadNotifications > 0}
            />
          )}
          <IconButton icon="search" onClick={handleOpenSearchPopup} />
          <AccountHeaderMobileMenuWithButton />
        </RightEdgeWrapper>
      </StyledHeaderWrapper>

      {authorizationStatusFetched &&
        authorized &&
        addContentMenu.payload &&
        addContentMenu.hasBeenOpened && (
          <AccountHeaderMobileAddContentMenu
            open={addContentMenu.open}
            onClose={addContentMenu.closeDialog}
            allowCreateNFT={currentUser.data?.nftSettings.canCreateNft === true}
          />
        )}
    </>
  );
};

export default AccountHeaderMobile;

const StyledHeaderWrapper = styled(AccountHeaderWrapper)`
  justify-content: space-between;
  z-index: ${({ theme }): number => theme.zIndex.drawer + 1};
`;

const EdgeWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
`;

const RightEdgeWrapper = styled(EdgeWrapper)`
  justify-content: flex-end;
`;

const StyledAccountHeaderMobileActivity = styled(AccountHeaderMobileActivity)`
  margin-right: 8px;
`;

const StyledAddContentButton = styled(AddContentButton)`
  margin-right: 8px;
`;
