import useTranslation from "next-translate/useTranslation";
import { SnackbarKey } from "notistack";

import { CustomSnackbarMessage } from "~/components/providers/CustomSnackbarProvider/CustomSnackbarMessage";

type Props = {
  id: SnackbarKey;
  className?: string;
};

const PaymentProgressSnackLoading = ({ id, className }: Props): JSX.Element => {
  const { t } = useTranslation("payments");

  return (
    <CustomSnackbarMessage
      id={id}
      className={className}
      title={t("transaction-is-processed")}
    />
  );
};

export default PaymentProgressSnackLoading;
