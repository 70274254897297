import { SnackbarProvider } from "notistack";

import { CustomSnackbarMessage } from "./CustomSnackbarMessage";
import { MessageConfig } from "./useCustomSnackbar";

export interface Props {
  children: React.ReactNode;
}

const MAX_SNACK_PER_PAGE = 2;

const CustomSnackbarProvider = ({ children }: Props): JSX.Element => (
  <SnackbarProvider
    maxSnack={MAX_SNACK_PER_PAGE}
    content={(key, config) => (
      <CustomSnackbarMessage
        id={key}
        title={(config as MessageConfig).title}
        description={(config as MessageConfig).description}
        variant={(config as MessageConfig).variant}
        firstAction={(config as MessageConfig).firstAction}
        secondAction={(config as MessageConfig).secondAction}
      />
    )}
    anchorOrigin={{
      horizontal: "center",
      vertical: "bottom"
    }}
  >
    {children}
  </SnackbarProvider>
);

export default CustomSnackbarProvider;
