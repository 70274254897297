import {
  ConversationDetails_conversation_DetailedGroupConversation,
  ConversationDetails_conversation_DetailedGroupConversation_settings,
  ConversationDetails_conversation_DetailedGroupConversation_stats,
  ConversationDetails_conversation_DetailedOneToOneConversation_settings
} from "~/declarations/apollo/ConversationDetails";
import {
  ConversationMessages_conversationMessages_edges_node_ConversationMessageWithQuote,
  ConversationMessages_conversationMessages_edges_node_ConversationMessageWithQuote_quote_author,
  ConversationMessages_conversationMessages_edges_node_GroupOwnershipTransferred,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantBanned,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantDemoted,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantInvited,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantJoined,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantJoinRequestSent,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantKicked,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantLeft,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantPromoted,
  ConversationMessages_conversationMessages_edges_node_GroupParticipantUnbanned,
  ConversationMessages_conversationMessages_edges_node_GroupPictureChanged,
  ConversationMessages_conversationMessages_edges_node_GroupTopicChanged,
  ConversationMessages_conversationMessages_edges_node_SimpleConversationMessage
} from "~/declarations/apollo/ConversationMessages";
import { CreateOneToOneConversation_createOneToOneConversation } from "~/declarations/apollo/CreateOneToOneConversation";
import { QuoteType, ReactionType } from "~/declarations/apollo/globalTypes";
import {
  MyConversations_myConversations_edges_node_GroupConversation,
  MyConversations_myConversations_edges_node_GroupConversation_picture_GroupCustomPicture,
  MyConversations_myConversations_edges_node_OneToOneConversation
} from "~/declarations/apollo/MyConversations";

import { MessageFieldState } from "./declarations/common";

export const DEFAULT_MESSAGE: MessageFieldState = {
  text: "",
  gif: null,
  file: null,
  images: [],
  originalId: null,
  quote: null,
  post: null,
  nft: null
};

export const MESSAGES_PER_PAGE = 25;
export const MESSAGING_MAX_AVATAR_COUNT = 2;
export const CHANNEL_INFO_INTRO_DELAY = 3000;

export const MESSAGE_HEADER_POSTFIX_BY_QUOTE_TYPE = {
  [QuoteType.forward]: "messaging_message_title_forwarded",
  [QuoteType.reply]: "messaging_message_title_replied"
};

export const MESSAGE_HEADER_PREFIX_BY_QUOTE_TYPE = {
  [QuoteType.forward]: "messaging_field_title_forward",
  [QuoteType.reply]: "messaging_field_title_to"
};

export const MESSAGE_HEADER_YOU_TRANSLATION_KEY = "messaging_message_title_you";
export const EMOJI_TO_REACTION_TYPE: {
  [smile: string]: ReactionType | undefined;
} = {
  "😂": ReactionType.haha,
  "😎": ReactionType.glasses,
  "😀": ReactionType.smile,
  "😍": ReactionType.love,
  "😳": ReactionType.embarrassment,
  "🤟": ReactionType.rock
};
export const REACTION_TYPE_TO_EMOJI = Object.keys(
  EMOJI_TO_REACTION_TYPE
).reduce<{
  [reaction: string]: string | undefined;
}>((map, emoji) => {
  const reaction = EMOJI_TO_REACTION_TYPE[emoji];

  if (reaction) {
    map[reaction] = emoji;
  }

  return map;
}, {});

export const MAX_MESSAGING_FILE_SIZE_IN_BYTES = 1024 * 1024 * 1024;
export const MAX_IMAGES_IN_MESSAGE = 10;
export const DEFAULT_MESSAGING_FILE_MIME_TYPE = "application/unknown";

export const MESSAGING_PERSON_TYPENAME: ConversationMessages_conversationMessages_edges_node_ConversationMessageWithQuote_quote_author["__typename"] =
  "Person";

export const MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME: ConversationMessages_conversationMessages_edges_node_SimpleConversationMessage["__typename"] =
  "SimpleConversationMessage";
export const MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME: ConversationMessages_conversationMessages_edges_node_ConversationMessageWithQuote["__typename"] =
  "ConversationMessageWithQuote";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOINED_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantJoined["__typename"] =
  "GroupParticipantJoined";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_LEFT_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantLeft["__typename"] =
  "GroupParticipantLeft";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_PIC_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupPictureChanged["__typename"] =
  "GroupPictureChanged";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_TOPIC_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupTopicChanged["__typename"] =
  "GroupTopicChanged";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_ADDED_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantInvited["__typename"] =
  "GroupParticipantInvited";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOIN_REQUEST_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantJoinRequestSent["__typename"] =
  "GroupParticipantJoinRequestSent";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_BANNED_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantBanned["__typename"] =
  "GroupParticipantBanned";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_UNBANNED_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantUnbanned["__typename"] =
  "GroupParticipantUnbanned";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_KICKED_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantKicked["__typename"] =
  "GroupParticipantKicked";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_DEMOTED_ADMIN_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantDemoted["__typename"] =
  "GroupParticipantDemoted";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_PROMOTED_ADMIN_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupParticipantPromoted["__typename"] =
  "GroupParticipantPromoted";
export const MESSAGING_SYSTEM_CONVERSATION_MESSAGE_OWNERSHIP_TRANSFERRED_TYPENAME: ConversationMessages_conversationMessages_edges_node_GroupOwnershipTransferred["__typename"] =
  "GroupOwnershipTransferred";
export const MESSAGING_SYSTEM_MESSAGES_TYPES: string[] = [
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOINED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_LEFT_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_PIC_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_TOPIC_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOIN_REQUEST_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_ADDED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_BANNED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_UNBANNED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_KICKED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_DEMOTED_ADMIN_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_PROMOTED_ADMIN_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_OWNERSHIP_TRANSFERRED_TYPENAME
];

export const MESSAGING_DETAILED_ONE_TO_ONE_CONVERSATION_TYPENAME: CreateOneToOneConversation_createOneToOneConversation["__typename"] =
  "DetailedOneToOneConversation";
export const MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME: MyConversations_myConversations_edges_node_OneToOneConversation["__typename"] =
  "OneToOneConversation";
export const MESSAGING_ONE_TO_ONE_CONVERSATION_SETTINGS_TYPENAME: ConversationDetails_conversation_DetailedOneToOneConversation_settings["__typename"] =
  "OneToOneConversationSettings";
export const MESSAGING_GROUP_CONVERSATION_SETTINGS_TYPENAME: ConversationDetails_conversation_DetailedGroupConversation_settings["__typename"] =
  "GroupConversationSettings";
export const MESSAGING_DETAILED_GROUP_CONVERSATION_TYPENAME: ConversationDetails_conversation_DetailedGroupConversation["__typename"] =
  "DetailedGroupConversation";
export const MESSAGING_GROUP_CONVERSATION_TYPENAME: MyConversations_myConversations_edges_node_GroupConversation["__typename"] =
  "GroupConversation";
export const MESSAGING_GROUP_CONVERSATION_STATS_TYPENAME: ConversationDetails_conversation_DetailedGroupConversation_stats["__typename"] =
  "GroupConversationStats";
export const OPTIMISTIC_MESSAGE_ID = "OPTIMISTIC_MESSAGE_ID";
export const OPTIMISTIC_REACTION_ID = "OPTIMISTIC_REACTION_ID";
export const MESSAGING_GROUP_CUSTOM_PICTURE_TYPENAME: MyConversations_myConversations_edges_node_GroupConversation_picture_GroupCustomPicture["__typename"] =
  "GroupCustomPicture";
