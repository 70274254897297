import Icon from "~/components/core/Icon";
import { ProfileByIdOrNickname_person } from "~/declarations/apollo/ProfileByIdOrNickname";
import { isNotNull } from "~/utils/common";
import { getUserIdsFromEnv } from "~/utils/environmentVariables";

export enum PROFILE_SECTION {
  featured = "featured",
  posts = "posts",
  hypetv = "hypetv",
  premium = "premium",
  nft = "nft"
}

export const PROFILE_SECTIONS = [
  {
    value: PROFILE_SECTION.featured,
    getCaptionKey: () => "profile:section_featured_caption",
    icon: <Icon variant="star" />,
    iconActive: <Icon variant="starFilled" />
  },
  {
    value: PROFILE_SECTION.posts,
    getCaptionKey: () => "profile:section_posts_caption",
    icon: <Icon variant="posts" />,
    iconActive: <Icon variant="postsFilled" />
  },
  {
    value: PROFILE_SECTION.hypetv,
    getCaptionKey: () => "profile:section_hypetv_caption",
    icon: <Icon variant="hypeTV" />,
    iconActive: <Icon variant="hypeTVFilled" />
  },
  {
    value: PROFILE_SECTION.premium,
    getCaptionKey: () => "profile:section_premium_caption",
    icon: <Icon variant="gem" />,
    iconActive: <Icon variant="gemFilled" />
  },
  {
    value: PROFILE_SECTION.nft,
    getCaptionKey: (
      profile: ProfileByIdOrNickname_person | null | undefined
    ) => {
      const userIdsToReplaceNameOfNftTab = getUserIdsFromEnv(
        process.env.NEXT_PUBLIC_USER_IDS_TO_REPLACE_NAME_OF_NFT_TAB
      );
      const showAlternativeCaption =
        isNotNull(profile) && userIdsToReplaceNameOfNftTab.includes(profile.id);

      return showAlternativeCaption
        ? "profile:section_nft_alternative-caption"
        : "profile:section_nft_caption";
    },
    icon: <Icon variant="nft" />,
    iconActive: <Icon variant="nftFilled" />
  }
];
