import { isNullable } from "~/utils/common";

type IsNumberWithDecimalPointOptions = {
  maximumDigitsAfterDecimalPoint: number;
  optional: boolean;
};

export const isNumberWithDecimalPoint =
  ({
    maximumDigitsAfterDecimalPoint,
    optional
  }: IsNumberWithDecimalPointOptions) =>
  (value: number | undefined): boolean => {
    if (isNullable(value) && optional) {
      return true;
    }

    const regexp = new RegExp(
      `^\\d+\\.?\\d{0,${maximumDigitsAfterDecimalPoint}}$`
    );
    const valueAsString = String(value);

    return regexp.test(valueAsString);
  };
