import AppBar from "@material-ui/core/AppBar";
import styled from "styled-components";

import {
  adaptiveWrapperMixin,
  FIXED_HEADER_SCROLL_JUMP_FIX_MIXIN
} from "~/theme/adaptivity";
import { omitStyleProps } from "~/utils/styled-components";

interface Props {
  children: React.ReactNode;
  withShadow: boolean;
  className?: string;
}

const AccountHeaderWrapper = ({
  children,
  withShadow,
  className
}: Props): JSX.Element => (
  <Header position="fixed" shadow={withShadow}>
    {/* className set on InnerWrapper  to set padding for mobile and still maintain scroll jumping fix*/}
    <InnerWrapper className={className}>{children}</InnerWrapper>
  </Header>
);

export default AccountHeaderWrapper;

const Header = styled(AppBar).withConfig<{
  shadow: boolean;
}>(omitStyleProps(["shadow"]))`
  background: ${({ theme }): string => theme.newTheme.background.octonary};
  box-shadow: ${({ theme, shadow }): string =>
    shadow ? `0px 2px 50px ${theme.newTheme.transparent[8]}` : ""};
  height: ${({ theme }) => theme.layouts.account.headerHeight}px;
  border-bottom: 1px solid
    ${({ theme }) => theme.newTheme.background.tertiaryInverse};

  ${FIXED_HEADER_SCROLL_JUMP_FIX_MIXIN}
`;

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;

  ${adaptiveWrapperMixin()};
`;
