import useUnreadNotifications from "~/components/notifications/useUnreadNotifications";

import UnreadNotificationsContextProvider from "./UnreadNotificationsContextProvider";

export interface Props {
  initialNumberOfUnreadNotifications?: number;
  children: React.ReactNode;
}

const UnreadNotificationsProvider = ({
  initialNumberOfUnreadNotifications,
  children
}: Props): JSX.Element => {
  const unreadNotifications = useUnreadNotifications(
    initialNumberOfUnreadNotifications
  );

  return (
    <UnreadNotificationsContextProvider value={unreadNotifications}>
      {children}
    </UnreadNotificationsContextProvider>
  );
};

export default UnreadNotificationsProvider;
