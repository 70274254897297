import { createContext } from "react";

import { DialogContextContent } from "~/components/providers/DialogProvider/declarations/context";
import createUseContext from "~/utils/createUseContext";

export const DialogContext = createContext<DialogContextContent | undefined>(
  undefined
);

export const useDialogContext = createUseContext(
  DialogContext,
  "DialogContext"
);
