import { FilledInputProps } from "@material-ui/core/FilledInput";
import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps
} from "@material-ui/core/TextField";
import composeRefs from "@seznam/compose-react-refs";
import { ChangeEvent, useMemo, useRef } from "react";
import styled, { css } from "styled-components";

import IconButton from "~/components/core/IconButton";
import { HASH_TAG_SYMBOL } from "~/constants/hashTags";
import { MENTION_SYMBOL } from "~/constants/mentions";
import { isEmpty, isNotNull, isString } from "~/utils/common";
import { asyncSetInputCursorPosition } from "~/utils/input";
import { omitStyleProps } from "~/utils/styled-components";

import Typography from "../Typography";

const TEXT_FIELD_SIZE_LARGE = "large";

type TextFieldSize = MuiTextFieldProps["size"] | typeof TEXT_FIELD_SIZE_LARGE;

// showMaxCharactersLabel and showMentionAndHashtagControls works only for controlled variants
export type TextFieldProps = Omit<MuiTextFieldProps, "size"> & {
  size?: TextFieldSize;
  endAdornment?: React.ReactNode;
  showMaxCharactersLabel?: boolean;
  showMentionAndHashtagControls?: boolean;
};

const CLASS_NAME_SEPARATOR = " ";

const CUSTOM_CLASS_NAME_BY_SIZE = {
  [TEXT_FIELD_SIZE_LARGE]: "MuiInput-sizeLarge"
};

const TextField = ({
  endAdornment,
  InputProps,
  showMaxCharactersLabel,
  showMentionAndHashtagControls,
  className,
  size = TEXT_FIELD_SIZE_LARGE,
  ...props
}: TextFieldProps): JSX.Element => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);

  const customClassName = useMemo(() => {
    const classNames = className?.split(CLASS_NAME_SEPARATOR) ?? [];

    if (size === TEXT_FIELD_SIZE_LARGE) {
      classNames.push(CUSTOM_CLASS_NAME_BY_SIZE[size]);
    }

    return classNames.join(CLASS_NAME_SEPARATOR);
  }, [className, size]);

  const withMaxCharactersLabel =
    showMaxCharactersLabel &&
    props.inputProps &&
    isNotNull(props.inputProps.maxLength);
  const inputValue = isString(props.value)
    ? props.value
    : inputRef.current?.value ?? "";
  const lengthMax = props.inputProps?.maxLength;
  const lengthCurrent = inputValue.length;
  const maxThresholdReached = lengthMax ? lengthCurrent >= lengthMax : false;

  const handleClickAddSymbol = (symbol: string) => (): void => {
    const inputElement = inputRef.current;

    if (!inputElement) {
      return;
    }

    const inputValue = inputElement.value;
    const previousCursorStartPosition =
      inputElement.selectionStart ?? inputValue.length;
    const previousCursorEndPosition =
      inputElement.selectionEnd ?? inputValue.length;

    const prefix = inputValue.slice(0, previousCursorStartPosition);
    const delimiter =
      prefix.length > 0 && prefix[prefix.length - 1] !== " " ? " " : "";
    const postfix = inputValue.slice(previousCursorEndPosition);

    const newValue = `${prefix}${delimiter}${symbol}${postfix}`;
    const newCursorPosition = prefix.length + delimiter.length + symbol.length;

    inputElement.focus();
    props?.onChange?.({
      target: {
        ...inputElement,
        value: newValue
      }
    } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    asyncSetInputCursorPosition(inputElement, newCursorPosition);
  };

  const hasAdornment =
    withMaxCharactersLabel ||
    showMentionAndHashtagControls ||
    !isEmpty(endAdornment) ||
    !isEmpty(InputProps?.endAdornment);

  return (
    <>
      <StyledTextField
        variant="filled"
        {...props}
        className={customClassName}
        size={size === TEXT_FIELD_SIZE_LARGE ? undefined : size}
        withMaxCharactersLabel={withMaxCharactersLabel}
        showMentionAndHashtagControls={showMentionAndHashtagControls}
        inputRef={composeRefs(inputRef, props.inputRef)}
        InputProps={
          {
            ...InputProps,
            endAdornment: hasAdornment ? (
              <>
                {endAdornment ? (
                  <AdornmentWrapper>{endAdornment}</AdornmentWrapper>
                ) : (
                  InputProps?.endAdornment
                )}
                {showMentionAndHashtagControls && (
                  <>
                    <MentionAndHashtag>
                      <ActionButton
                        icon={<ActionIcon>{HASH_TAG_SYMBOL}</ActionIcon>}
                        onClick={handleClickAddSymbol(HASH_TAG_SYMBOL)}
                        disabled={maxThresholdReached}
                      />
                      <ActionButton
                        icon={<ActionIcon>{MENTION_SYMBOL}</ActionIcon>}
                        onClick={handleClickAddSymbol(MENTION_SYMBOL)}
                        disabled={maxThresholdReached}
                      />
                    </MentionAndHashtag>
                  </>
                )}
                {withMaxCharactersLabel && (
                  <MaxCharactersCounter
                    hasError={props.error}
                  >{`${lengthCurrent}/${lengthMax}`}</MaxCharactersCounter>
                )}
              </>
            ) : undefined,
            ...(!props.variant ||
            props.variant === "filled" ||
            props.variant === "standard"
              ? { disableUnderline: true }
              : {})
          } as Partial<FilledInputProps>
        }
      />
    </>
  );
};

TextField.displayName = "TextField";

export default TextField;

const StyledTextField = styled(MuiTextField).withConfig<{
  withMaxCharactersLabel?: boolean;
  showMentionAndHashtagControls?: boolean;
}>(omitStyleProps(["withMaxCharactersLabel", "showMentionAndHashtagControls"]))`
  &.MuiInput-sizeMedium {
    .MuiFilledInput-input {
      padding: 26px 14px 11px;
    }

    .MuiInputLabel-filled {
      transform: translate(16px, 20px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(16px, 8px) scale(1);
      }
    }
  }

  &.${CUSTOM_CLASS_NAME_BY_SIZE[TEXT_FIELD_SIZE_LARGE]} {
    .MuiFilledInput-input {
      padding: 31px 14px 14px;
    }

    .MuiInputLabel-filled {
      transform: translate(16px, 24px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(16px, 12px) scale(1);
      }
    }
  }

  .MuiInputBase-root {
    position: relative;
    margin-bottom: ${({ withMaxCharactersLabel }) =>
      withMaxCharactersLabel ? "28px" : 0};

    background-color: ${({ theme }) =>
      theme.newTheme.background.secondaryInverse};
    border-radius: 12px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.newTheme.transparent[8]};

    .MuiInputBase-input {
      caret-color: ${({ theme }) => theme.newTheme.text.secondary};
    }

    .MuiFilledInput-input {
      &:-webkit-autofill {
        // Don't show autofill background
        transition-delay: 50000s;
        transition-property: background-color;

        // provide theme color for autofilled text
        &,
        &:hover,
        &:focus {
          -webkit-text-fill-color: ${({ theme }) => theme.newTheme.text.primary}
        }
      }


      // Temporary fix for unselectable autofilled input
      &:-internal-input-suggested[style] {
        user-select: all !important;
      }
    }

    &:hover {
      box-shadow: 0 0 0 2px
        ${({ theme }) => theme.newTheme.background.quaternary};
    }

    &.Mui-focused {
      box-shadow: 0 0 0 1px
        ${({ theme }) => theme.newTheme.background.quaternary};
    }

    &.Mui-disabled {
      background-color: ${({ theme }) =>
        theme.newTheme.background.tertiaryInverse};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.newTheme.transparent[16]};
    }

    &.Mui-error {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.red};
      color: ${({ theme }) => theme.palette.common.red};
    }

    &.MuiInputBase-multiline {
      padding: 0;

      .MuiFilledInput-input {
        margin: 31px 0
          ${({ showMentionAndHashtagControls }) =>
            showMentionAndHashtagControls ? 32 : 0}px;
        padding: 0 16px
          ${({ showMentionAndHashtagControls }) =>
            showMentionAndHashtagControls ? 0 : 14}px;
      }

      &.MuiFilledInput-adornedEnd {
        padding-right: 12px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiInputLabel-filled {
    color: ${({ theme }) => theme.newTheme.text.primary};
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;

    &.MuiInputLabel-shrink {
      color: ${({ theme }) => theme.newTheme.text.secondary};
      font-size: 10px;
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.newTheme.text.secondary};
    }

    &.Mui-error {
      color: ${({ theme }) => theme.palette.common.red};
    }
  }

  .MuiFormHelperText-root {
    font-size: 10px;
    font-weight: 500;
    margin: 8px 0 0;

    &.Mui-error {
      color: ${({ theme }) => theme.palette.common.red};
       ${({ withMaxCharactersLabel }) =>
         withMaxCharactersLabel
           ? css`
               bottom: 3px;
               padding-right: 70px;
               position: absolute;
             `
           : null}
`;

const AdornmentWrapper = styled.div`
  height: 100%;
  right: 16px;
  top: 20px;
`;

const ActionButton = styled(IconButton)`
  background: transparent;
  font-size: 12px;
  height: 12px;
  padding: 0;
  width: 12px;
`;

const ActionIcon = styled(Typography).attrs({
  variant: "title3"
})`
  display: flex;
  align-items: center;
`;

const MentionAndHashtag = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.newTheme.background.senary};
  border-radius: 8px;
  bottom: 8px;
  display: flex;
  padding: 6px 10px;
  position: absolute;
  right: 8px;

  ${ActionButton}:first-child {
    margin-right: 11px;
  }
`;

const MaxCharactersCounter = styled.div<{ hasError?: boolean }>`
  background-color: ${({ theme }) => theme.newTheme.background.septenary};
  border-radius: 8px;
  bottom: -28px;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  left: ${({ hasError }) => (hasError ? "unset" : 0)};
  line-height: 16px;
  padding: 2px 8px;
  position: absolute;
  right: ${({ hasError }) => (hasError ? 0 : "unset")};
`;
