/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "LogoGradient-2339d094--sprite",
  "use": "LogoGradient-2339d094--sprite-usage",
  "viewBox": "0 0 80 80",
  "content": "<symbol xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 80 80\" id=\"LogoGradient-2339d094--sprite\"><path d=\"M33.046 48.989V16.368l12.518-9.195a.884.884 0 0 1 1.407.713v32.597L34.454 49.7a.884.884 0 0 1-1.408-.712zM17 68.933V28.161l9.548-6.987a.867.867 0 0 1 1.38.7v37.848a2.29 2.29 0 0 1-.933 1.844L17 68.933zm16.046-11.808v14.43a.884.884 0 0 0 1.408.711l12.517-9.218V48.642a.884.884 0 0 0-1.407-.712l-12.518 9.195zm19.027 2.17v-34.31l9.521-6.982a.884.884 0 0 1 1.406.713V50.04c0 .782-.372 1.517-1.003 1.98l-9.924 7.276z\" clip-rule=\"evenodd\" fill=\"url(#LogoGradient-2339d094--sprite_a)\" fill-rule=\"evenodd\" /><defs><linearGradient id=\"LogoGradient-2339d094--sprite_a\" x1=\"22.458\" x2=\"87.193\" y1=\"-48.869\" y2=\"-33.312\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#46FEDC\" offset=\"0\" /><stop stop-color=\"#EAFFCD\" offset=\".512\" /><stop stop-color=\"#F3D4FE\" offset=\"1\" /></linearGradient></defs></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
