import { NFT_FREE_PURCHASE_LIMIT_EXCEEDED_DIALOG_CONFIG } from "~/components/nft/NftAsset/NftFreePurchaseLimitExceededDialog/constants";
import { NFT_PURCHASE_DIALOG_CONFIG } from "~/components/nft/NftAsset/NftPurchaseDialog/constants";
import { NFT_PURCHASING_FAILURE_DIALOG_CONFIG } from "~/components/nft/NftAsset/NftPurchaseDialog/NftPurchasingFailureDialog/constants";
import { NFT_PURCHASING_SUCCESS_DIALOG_CONFIG } from "~/components/nft/NftAsset/NftPurchaseDialog/NftPurchasingSuccessDialog/constants";
import { NFT_CHARITIES_DIALOG_CONFIG } from "~/components/nft/NftCharitiesDialog/constants";
import { NFT_ROYALTY_DETAILS_DIALOG_CONFIG } from "~/components/nft/NftRoyaltyDetailsDialog/constants";
import { BUY_COINS_BY_XDB_PENDING_DIALOG_CONFIG } from "~/components/payment/BuyCoinsDialog/BuyCoinsByXdbPendingDialog/constants";
import { BUY_COINS_BY_XDB_SUCCESS_DIALOG_CONFIG } from "~/components/payment/BuyCoinsDialog/BuyCoinsByXdbSuccessDialog/constants";
import { BUY_COINS_DIALOG_CONFIG } from "~/components/payment/BuyCoinsDialog/constants";
import { PURCHASE_DIALOG_CONFIG } from "~/components/payment/PurchaseDialog/contants";
import { TIP_POST_DIALOG_CONFIG } from "~/components/payment/TipPostDialog/constants";
import { TIP_STREAM_DIALOG_CONFIG } from "~/components/payment/TipStreamDialog/constants";
import { DialogConfigMap } from "~/components/providers/DialogProvider/declarations/config";
import { RoutingDialogsState } from "~/components/providers/DialogProvider/declarations/state";
import { WALLET_CONFIRMATION_DIALOG_CONFIG } from "~/components/wallet/WalletConfirmationDialog/constants";
import { CREATE_WALLET_DIALOG_CONFIG } from "~/components/wallet/WalletCreateDialog/constants";
import { WALLET_CREATE_KEY_DIALOG_CONFIG } from "~/components/wallet/WalletCreateKeyDialog/constants";
import { WALLET_DROP_DIALOG_CONFIG } from "~/components/wallet/WalletDropDialog/constants";
import { WALLET_INFO_DIALOG_CONFIG } from "~/components/wallet/WalletInfoDialog/constants";
import { WALLET_KEY_DROPPED_WARNING_DIALOG_CONFIG } from "~/components/wallet/WalletKeyDroppedWarningDialog/constants";
import { WALLET_MAINTENANCE_DIALOG_CONFIG } from "~/components/wallet/WalletMaintenanceDialog/constants";
import { WALLET_PAYOUT_DISABLED_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutDisabledDialog/constants";
import { WALLET_PAYOUT_ACCOUNT_SETUP_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutAccountSetup/constants";
import { WALLET_PAYOUT_ACCOUNT_SETUP_FAILED_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutAccountSetupFailed/constants";
import { WALLET_PAYOUT_BANK_ACCOUNT_SETUP_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutBankAccountSetupDialog/constants";
import { WALLET_PAYOUT_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutDialog/constants";
import { WALLET_PAYOUT_ERROR_EMAIL_NOT_VERIFIED_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutErrorEmailNotVerifiedDialog/constants";
import { WALLET_PAYOUT_ERROR_LIMIT_EXCEEDED_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutErrorLimitExceededDialog/constants";
import { WALLET_PAYOUT_ERORR_NOT_ENOUGH_FUNDS_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutErrorNotEnoughFundsDialog/constants";
import { WALLET_PAYOUT_REQUIREMENTS_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutRequirementsDialog/constants";
import { WALLET_PAYOUT_SETUP_PENDING_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutSetupPending/constants";
import { WALLET_PAYOUT_SUCCESS_DIALOG_CONFIG } from "~/components/wallet/WalletPayoutFlow/WalletPayoutSuccessDialog/constants";
import { WALLET_RESET_PIN_DIALOG_CONFIG } from "~/components/wallet/WalletResetPinDialog/constants";
import { WALLET_SET_UP_SUCCESS_DIALOG_CONFIG } from "~/components/wallet/WalletSetUpSuccessDialog/constants";
import { WALLET_SHOW_MNEMONIC_DIALOG_CONFIG } from "~/components/wallet/WalletShowMnemonicDialog/constants";
import { WALLET_SYNCHRONIZATION_ERROR_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationErrorDialog/constants";
import { WALLET_SYNCHRONIZATION_METHOD_SELECTOR_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationMethodSelectorDialog/constants";
import { WALLET_SYNCHRONIZATION_MNEMONIC_INPUT_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationMnemonicInputDialog/constants";
import { WALLET_SYNCHRONIZATION_PIN_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationPinDialog/constants";
import { WALLET_SYNCHRONIZATION_SUCCESS_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationSuccessDialog/constants";
import { WALLET_SYNCHRONIZATION_VIA_QR_CODE_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationViaQRCodeDialog/constants";
import { WALLET_SYNCHRONIZATION_WARNING_DIALOG_CONFIG } from "~/components/wallet/WalletSynchronizationWarningDialog/constants";
import { WALLET_TRANSACTION_IN_PROGRESS_DIALOG_CONFIG } from "~/components/wallet/WalletTransactionInProgressDialog/constants";
import { ASTRAX_INSTALL_INSTRUCTIONS_DIALOG_CONFIG } from "~/components/xdb/AstraXInstallInstructionsDialog/constants";
import { XDB_CAN_NOT_EXPORT_NFT_WARNING_DIALOG_CONFIG } from "~/components/xdb/export/XdbCanNotExportNftWarning/constants";
import { XDB_NFT_EXPORT_ADDRESS_INPUT_DIALOG_CONFIG } from "~/components/xdb/export/XdbNftExportAddressInputDialog/constants";
import { XDB_NFT_EXPORT_CREATE_TRUST_DIALOG_CONFIG } from "~/components/xdb/export/XdbNftExportCreateTrustDialog/constants";
import { XDB_NFT_EXPORT_DIALOG_CONFIG } from "~/components/xdb/export/XdbNftExportDialog/constants";
import { XDB_NFT_EXPORT_SUCCESS_DIALOG_CONFIG } from "~/components/xdb/export/XdbNftExportSuccessDialog/constants";
import { XDB_NFT_EXPORT_WARNING_DIALOG_CONFIG } from "~/components/xdb/export/XdbNftExportWarningDialog/constants";
import { XDB_CONNECT_TO_ASTRAX_DIALOG_CONFIG } from "~/components/xdb/import/XdbConnectToAstraX/constants";
import { XDB_NFT_IMPORT_DIALOG_CONFIG } from "~/components/xdb/import/XdbNftImportDialog/constants";
import { XDB_NFT_IMPORT_ERROR_DIALOG_CONFIG } from "~/components/xdb/import/XdbNftImportErrorDialog/constants";
import { XDB_NFT_IMPORT_PIN_DIALOG_CONFIG } from "~/components/xdb/import/XdbNftImportPinDialog/constants";
import { XDB_NFT_IMPORT_PROCESS_DIALOG_CONFIG } from "~/components/xdb/import/XdbNftImportProcessDialog/constants";
import { XDB_NFT_IMPORT_SUCCESS_DIALOG_CONFIG } from "~/components/xdb/import/XdbNftImportSuccessDialog/constants";
import { XDB_WALLET_CONNECTED_DIALOG_CONFIG } from "~/components/xdb/import/XdbWalletConnectedDialog/constants";

import { DIALOG_NAME } from "./declarations/common";

export const DEFAULT_ROUTER_DIALOGS_STATE: RoutingDialogsState<DIALOG_NAME> =
  [];

export const DIALOG_CONFIG_MAP: DialogConfigMap = {
  [DIALOG_NAME.walletMaintenance]: WALLET_MAINTENANCE_DIALOG_CONFIG,
  [DIALOG_NAME.postPurchase]: PURCHASE_DIALOG_CONFIG,
  [DIALOG_NAME.buyCoins]: BUY_COINS_DIALOG_CONFIG,
  [DIALOG_NAME.buyCoinsByXdbPendingDialog]:
    BUY_COINS_BY_XDB_PENDING_DIALOG_CONFIG,
  [DIALOG_NAME.buyCoinsByXdbSuccessDialog]:
    BUY_COINS_BY_XDB_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.walletTransactionInProgress]:
    WALLET_TRANSACTION_IN_PROGRESS_DIALOG_CONFIG,
  [DIALOG_NAME.walletSynchronizationError]:
    WALLET_SYNCHRONIZATION_ERROR_DIALOG_CONFIG,
  [DIALOG_NAME.walletSynchronizationMethodSelector]:
    WALLET_SYNCHRONIZATION_METHOD_SELECTOR_DIALOG_CONFIG,
  [DIALOG_NAME.walletConfirmation]: WALLET_CONFIRMATION_DIALOG_CONFIG,
  [DIALOG_NAME.resetWalletPin]: WALLET_RESET_PIN_DIALOG_CONFIG,
  [DIALOG_NAME.dropWallet]: WALLET_DROP_DIALOG_CONFIG,
  [DIALOG_NAME.setUpWalletSuccess]: WALLET_SET_UP_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.walletKeyDroppedWarning]:
    WALLET_KEY_DROPPED_WARNING_DIALOG_CONFIG,
  [DIALOG_NAME.createWallet]: CREATE_WALLET_DIALOG_CONFIG,
  [DIALOG_NAME.tipPost]: TIP_POST_DIALOG_CONFIG,
  [DIALOG_NAME.tipStream]: TIP_STREAM_DIALOG_CONFIG,
  [DIALOG_NAME.verifyEmailToCashOut]:
    WALLET_PAYOUT_ERROR_EMAIL_NOT_VERIFIED_DIALOG_CONFIG,
  [DIALOG_NAME.notEnoughFundsToCashOut]:
    WALLET_PAYOUT_ERORR_NOT_ENOUGH_FUNDS_DIALOG_CONFIG,
  [DIALOG_NAME.payoutRequirements]: WALLET_PAYOUT_REQUIREMENTS_DIALOG_CONFIG,
  [DIALOG_NAME.cashOutLimitExceeded]:
    WALLET_PAYOUT_ERROR_LIMIT_EXCEEDED_DIALOG_CONFIG,
  [DIALOG_NAME.payoutSuccess]: WALLET_PAYOUT_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.payout]: WALLET_PAYOUT_DIALOG_CONFIG,
  [DIALOG_NAME.payoutAccountSetup]: WALLET_PAYOUT_ACCOUNT_SETUP_DIALOG_CONFIG,
  [DIALOG_NAME.payoutAccountSetupFailed]:
    WALLET_PAYOUT_ACCOUNT_SETUP_FAILED_DIALOG_CONFIG,
  [DIALOG_NAME.payoutSetupPending]: WALLET_PAYOUT_SETUP_PENDING_DIALOG_CONFIG,
  [DIALOG_NAME.walletQRCode]: WALLET_SYNCHRONIZATION_VIA_QR_CODE_DIALOG_CONFIG,
  [DIALOG_NAME.walletSynchronizationWarning]:
    WALLET_SYNCHRONIZATION_WARNING_DIALOG_CONFIG,
  [DIALOG_NAME.walletMnemonic]: WALLET_SHOW_MNEMONIC_DIALOG_CONFIG,
  [DIALOG_NAME.walletInfo]: WALLET_INFO_DIALOG_CONFIG,
  [DIALOG_NAME.purchaseNft]: NFT_PURCHASE_DIALOG_CONFIG,
  [DIALOG_NAME.purchaseNftSuccess]: NFT_PURCHASING_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.purchaseNftFailure]: NFT_PURCHASING_FAILURE_DIALOG_CONFIG,
  [DIALOG_NAME.createWalletKey]: WALLET_CREATE_KEY_DIALOG_CONFIG,
  [DIALOG_NAME.walletSynchronizationMnemonicInput]:
    WALLET_SYNCHRONIZATION_MNEMONIC_INPUT_DIALOG_CONFIG,
  [DIALOG_NAME.walletSynchronizationPin]:
    WALLET_SYNCHRONIZATION_PIN_DIALOG_CONFIG,
  [DIALOG_NAME.walletSynchronizationSuccess]:
    WALLET_SYNCHRONIZATION_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.nftCharities]: NFT_CHARITIES_DIALOG_CONFIG,
  [DIALOG_NAME.nftRoyaltyDetails]: NFT_ROYALTY_DETAILS_DIALOG_CONFIG,
  [DIALOG_NAME.astraXInstallInstructions]:
    ASTRAX_INSTALL_INSTRUCTIONS_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftImport]: XDB_NFT_IMPORT_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftImportError]: XDB_NFT_IMPORT_ERROR_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftImportPin]: XDB_NFT_IMPORT_PIN_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftImportSuccess]: XDB_NFT_IMPORT_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.xdbWalletConnected]: XDB_WALLET_CONNECTED_DIALOG_CONFIG,
  [DIALOG_NAME.xdbConnectToAstraX]: XDB_CONNECT_TO_ASTRAX_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftImportProcess]: XDB_NFT_IMPORT_PROCESS_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftExport]: XDB_NFT_EXPORT_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftExportWarning]: XDB_NFT_EXPORT_WARNING_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftExportAddressInput]:
    XDB_NFT_EXPORT_ADDRESS_INPUT_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftExportCreateTrust]:
    XDB_NFT_EXPORT_CREATE_TRUST_DIALOG_CONFIG,
  [DIALOG_NAME.xdbNftExportSuccess]: XDB_NFT_EXPORT_SUCCESS_DIALOG_CONFIG,
  [DIALOG_NAME.canNotExportNftToXdbWarning]:
    XDB_CAN_NOT_EXPORT_NFT_WARNING_DIALOG_CONFIG,
  [DIALOG_NAME.freeNftPurchaseLimitExceeded]:
    NFT_FREE_PURCHASE_LIMIT_EXCEEDED_DIALOG_CONFIG,
  [DIALOG_NAME.payoutBankAccountSetup]:
    WALLET_PAYOUT_BANK_ACCOUNT_SETUP_DIALOG_CONFIG,
  [DIALOG_NAME.walletPayoutDisabled]: WALLET_PAYOUT_DISABLED_DIALOG_CONFIG
};

export const DIALOGS_STATE_QUERY_PARAMETER = "dialogs";

export const MAXIMUM_NUMBER_OF_DIALOGS = Object.keys(DIALOG_NAME).length;
