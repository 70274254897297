/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "TrashFilled-df2ceb8d--sprite",
  "use": "TrashFilled-df2ceb8d--sprite-usage",
  "viewBox": "0 0 24 24",
  "content": "<symbol xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" id=\"TrashFilled-df2ceb8d--sprite\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.714 9.171s-.56 7.76-.904 10.914c-.164 1.507-1.077 2.39-2.574 2.418-2.849.052-5.7.055-8.548-.006-1.44-.03-2.338-.924-2.499-2.404-.346-3.183-.968-10.922-.968-10.922M3 6.343a.77.77 0 0 1 .771-.771l16.458.018a.771.771 0 0 1 0 1.543L3.77 7.114A.771.771 0 0 1 3 6.343z\" /><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.194 5.59c-.775 0-1.46-.003-1.594-.533l-.208-.92c-.148-.661-.65-1.119-1.221-1.119L9.943 3c-.572 0-1.073.458-1.22 1.12l-.272.919c-.17.535-.82.533-1.594.533\" /></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
