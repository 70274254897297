import { number, object, SchemaOf, string } from "yup";

import { SpecificBuyCoinsDialogOptions } from "~/components/payment/BuyCoinsDialog/declarations";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogConfig,
  DialogOptionsValidationFunction
} from "~/components/providers/DialogProvider/declarations/config";
import { PostType } from "~/declarations/apollo/globalTypes";
import { safeDynamicImport } from "~/utils/import/dynamic";

export enum BUY_COINS_DIALOG_STEP {
  setAmount,
  selectMethod
}

export enum BUY_COINS_METHOD {
  stripe = "stripe",
  bitpay = "bitpay",
  xdb = "xdb"
}

export const SLIDE_ANIMATION_DURATION_IN_SECONDS = 0.5;

const VALIDATION_SCHEMA: SchemaOf<SpecificBuyCoinsDialogOptions> = object({
  post: object({
    id: string().required(),
    type: string()
      .oneOf([
        PostType.long,
        PostType.longStream,
        PostType.short,
        PostType.shortStream
      ])
      .required()
  })
    .default(undefined)
    .optional(),
  initialAmount: number(),
  redirectUrlAfterBuyingCoins: string()
})
  .strict()
  .required();

const validateOptions: DialogOptionsValidationFunction = rawOptions =>
  VALIDATION_SCHEMA.isValidSync(rawOptions);

export const BUY_COINS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.buyCoins> = {
  Component: safeDynamicImport(
    () => import("~/components/payment/BuyCoinsDialog"),
    {
      ssr: false
    }
  ),
  saveInUrl: true,
  validateOptions
};
