import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import PaymentSnack from "~/components/core/PaymentSnack";
import {
  ProgressOfTransaction,
  ProgressOfTransactionDictionary,
  PURCHASE_TYPE
} from "~/declarations/purchases";
import { isNotNull } from "~/utils/common";
import useObserver, {
  ObserverHandlerListModifier
} from "~/utils/observer/useObserver";
import { Dictionary } from "~/utils/structures/dictionary";

import {
  PostPurchaseLinkClickHandler,
  TRANSACTION_DONE_REASON,
  TransactionDoneHandler
} from "../declarations";
import useProgressOfTransactionsDefaultHandlers from "./useProgressOfTransactionsDefaultHandlers";
import {
  getTransactionDictionaryKeyByProgress,
  getTransactionDictionaryKeyByType
} from "./utils";

interface UseProgressOfTransactionsReturn {
  addTransactionProgress: (progress: ProgressOfTransaction) => void;
  handleTransactionSuccess: (progress: ProgressOfTransaction) => void;
  getTransactionProgress: (
    type: PURCHASE_TYPE,
    instanceId: string
  ) => ProgressOfTransaction | null;
  hasPostTransactionInProgress: (id: string) => boolean;
  addTransactionDoneHandler: (handler: TransactionDoneHandler) => void;
  removeTransactionDoneHandler: (handler: TransactionDoneHandler) => void;
  addPostPurchaseLinkClickHandler: ObserverHandlerListModifier<PostPurchaseLinkClickHandler>;
  removePostPurchaseLinkClickHandler: ObserverHandlerListModifier<PostPurchaseLinkClickHandler>;
  transactionInProgress: boolean;
}

type TimerRecord = {
  timerId: number;
  done: boolean;
};

const RESET_TRANSACTION_BLOCK_FALLBACK_DELAY = 15000;
const TRANSACTION_PROGRESS_SNACKBAR_AUTO_HIDE_DURATION = 20000;

const useProgressOfTransactions = (): UseProgressOfTransactionsReturn => {
  const [progressDictionary, setProgressDictionary] =
    useState<ProgressOfTransactionDictionary>({});
  const timerDictionary = useRef<Dictionary<string, TimerRecord>>({});
  const {
    subscribe: addTransactionDoneHandler,
    unsubscribe: removeTransactionDoneHandler,
    notify: notifyTransactionDoneHandlers
  } = useObserver<TransactionDoneHandler>();
  const {
    subscribe: addPostPurchaseLinkClickHandler,
    unsubscribe: removePostPurchaseLinkClickHandler,
    notify: notifyPostPurchaseLinkClickHandler
  } = useObserver<PostPurchaseLinkClickHandler>();
  const { enqueueSnackbar } = useSnackbar();

  const clearTimerToRemoveTransactionProgress = useCallback((key: string) => {
    const timer = timerDictionary.current[key];

    if (timer) {
      clearTimeout(timer.timerId);
      delete timerDictionary.current[key];
    }
  }, []);

  const removeTransactionProgressByKey = useCallback(
    (key: string) => {
      clearTimerToRemoveTransactionProgress(key);

      setProgressDictionary(dictionary => {
        const newDictionary = { ...dictionary };

        delete newDictionary[key];

        return newDictionary;
      });
    },
    [clearTimerToRemoveTransactionProgress]
  );

  const removeTransactionProgress = useCallback(
    (progress: ProgressOfTransaction): void => {
      const key = getTransactionDictionaryKeyByProgress(progress);

      removeTransactionProgressByKey(key);
    },
    [removeTransactionProgressByKey]
  );

  const addTimerToRemoveTransactionProgress = useCallback(
    (key: string, progress: ProgressOfTransaction) => {
      const timerId = window.setTimeout(() => {
        const removeHandler = (): void => removeTransactionProgressByKey(key);

        if (timerDictionary.current[key]?.done) {
          return;
        }

        notifyTransactionDoneHandlers(
          true,
          progress,
          TRANSACTION_DONE_REASON.timeout,
          removeHandler
        );
      }, RESET_TRANSACTION_BLOCK_FALLBACK_DELAY);

      timerDictionary.current[key] = {
        timerId,
        done: false
      };
    },
    [notifyTransactionDoneHandlers, removeTransactionProgressByKey]
  );

  const postponeTimerToRemoveTransactionProgress = useCallback(
    (key: string, progress: ProgressOfTransaction) => {
      const timer = timerDictionary.current[key];

      if (timer) {
        clearTimerToRemoveTransactionProgress(key);
      }

      addTimerToRemoveTransactionProgress(key, progress);
    },
    [addTimerToRemoveTransactionProgress, clearTimerToRemoveTransactionProgress]
  );

  const addTransactionProgress = useCallback(
    (progress: ProgressOfTransaction): void => {
      const key = getTransactionDictionaryKeyByProgress(progress);

      setProgressDictionary(dictionary => {
        postponeTimerToRemoveTransactionProgress(key, progress);

        return {
          ...dictionary,
          [key]: progress
        };
      });

      if (progress.type !== PURCHASE_TYPE.nftMinting) {
        enqueueSnackbar("", {
          key,
          autoHideDuration: TRANSACTION_PROGRESS_SNACKBAR_AUTO_HIDE_DURATION,
          persist: true,
          preventDuplicate: true,
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          content: function ProgressOfTransactionContent(snackId) {
            return (
              <PaymentSnack
                snackId={snackId}
                progress={progress}
                addDoneHandler={addTransactionDoneHandler}
                removeDoneHandler={removeTransactionDoneHandler}
                notifyPostPurchaseLinkClickHandler={
                  notifyPostPurchaseLinkClickHandler
                }
              />
            );
          }
        });
      }
    },
    [
      addTransactionDoneHandler,
      enqueueSnackbar,
      notifyPostPurchaseLinkClickHandler,
      postponeTimerToRemoveTransactionProgress,
      removeTransactionDoneHandler
    ]
  );

  const getTransactionProgress = useCallback(
    (type: PURCHASE_TYPE, instanceId: string) => {
      const key = getTransactionDictionaryKeyByType(type, instanceId);

      return progressDictionary[key] ?? null;
    },
    [progressDictionary]
  );

  const hasPostTransactionInProgress = useCallback(
    (instanceId: string) =>
      isNotNull(
        getTransactionProgress(PURCHASE_TYPE.postPurchase, instanceId)
      ) ||
      isNotNull(getTransactionProgress(PURCHASE_TYPE.postTipping, instanceId)),
    [getTransactionProgress]
  );

  const handleTransactionSuccess = useCallback(
    (progress: ProgressOfTransaction) => {
      const removeHandler = (): void => removeTransactionProgress(progress);
      const key = getTransactionDictionaryKeyByProgress(progress);

      if (!timerDictionary.current[key]) {
        return;
      }

      notifyTransactionDoneHandlers(
        true,
        progress,
        TRANSACTION_DONE_REASON.success,
        removeHandler
      );
    },
    [notifyTransactionDoneHandlers, removeTransactionProgress]
  );

  const transactionInProgress = useMemo(
    () => Object.keys(progressDictionary).length > 0,
    [progressDictionary]
  );

  useEffect(
    () => (): void => {
      Object.values(timerDictionary.current).forEach(timer => {
        if (timer) {
          clearTimeout(timer.timerId);
        }
      });
    },
    []
  );

  useProgressOfTransactionsDefaultHandlers({
    addTransactionDoneHandler,
    removeTransactionDoneHandler
  });

  return {
    addTransactionProgress,
    handleTransactionSuccess,
    getTransactionProgress,
    transactionInProgress,
    addTransactionDoneHandler,
    removeTransactionDoneHandler,
    hasPostTransactionInProgress,
    addPostPurchaseLinkClickHandler,
    removePostPurchaseLinkClickHandler
  };
};

export default useProgressOfTransactions;
