import { StringParam, useQueryParam } from "next-query-params";
import { createContext, useCallback, useMemo } from "react";

import { MessagingConversation } from "~/components/messaging/declarations/conversations";
import { UseMessagingConversationsResult } from "~/components/messaging/providers/MessagingProvider/useMessagingConversations/useMessagingConversations";
import createUseContext from "~/utils/createUseContext";

import useMessagingConversations from "./useMessagingConversations";
import { useMessagingNotifications } from "./useMessagingNotifications";
import useRealTimeMessagingUpdateViaSubscription from "./useRealTimeMessagingUpdateViaSubscription/useRealTimeMessagingUpdateViaSubscription";
import { useUnreadChannels } from "./useUnreadChannels";

export interface MessagingProviderProps {
  children: React.ReactNode;
  currentUserId?: string;
  initialUnreadChannels?: number;
}

interface MessagingContextState {
  activeConversation: MessagingConversation | null;
  activeConversationId: string | null;
  unsetActiveConversation: () => void;
  messagingConversations: UseMessagingConversationsResult | null;
  notifications: string[];
  clearNotifications: () => void;
  unreadConversations: number;
}

const MessagingContext = createContext<MessagingContextState | undefined>(
  undefined
);

const MessagingProvider = ({
  children,
  currentUserId,
  initialUnreadChannels
}: MessagingProviderProps): JSX.Element => {
  const messagingConversations = useMessagingConversations();

  // Don't use setChannel directly. Use goToConversationPage helper to navigate between conversations
  const [channel, setChannel] = useQueryParam("channel", StringParam);
  const activeConversationId = channel || null;

  const { notifications, clearNotifications, onMessageCreated } =
    useMessagingNotifications(activeConversationId);
  const unreadConversations = useUnreadChannels(initialUnreadChannels);

  const activeConversation = useMemo(() => {
    if (!activeConversationId) {
      return null;
    }

    const activeConversation = messagingConversations.items.find(
      channel => channel.id === activeConversationId
    );

    return activeConversation ?? null;
  }, [activeConversationId, messagingConversations]);

  const unsetActiveConversation = useCallback((): void => {
    setChannel(undefined);
  }, [setChannel]);

  useRealTimeMessagingUpdateViaSubscription(currentUserId, onMessageCreated);

  const value: MessagingContextState = useMemo(
    () => ({
      activeConversation,
      activeConversationId,
      unsetActiveConversation,
      messagingConversations,
      notifications,
      clearNotifications,
      unreadConversations: unreadConversations
    }),
    [
      activeConversation,
      activeConversationId,
      clearNotifications,
      messagingConversations,
      notifications,
      unreadConversations,
      unsetActiveConversation
    ]
  );

  return (
    <MessagingContext.Provider value={value}>
      {children}
    </MessagingContext.Provider>
  );
};

const useMessagingContext = createUseContext(
  MessagingContext,
  "MessagingContext"
);

export default MessagingProvider;
export { useMessagingContext };
