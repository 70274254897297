import { useMemo } from "react";

import {
  ApolloSubscriptionsConnectionEventsContextProps,
  ApolloSubscriptionsConnectionEventsHandler
} from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/declarations";
import useObserver, {
  ObserverNotifyFunction
} from "~/utils/observer/useObserver";

type UseApolloSubscriptionsConnectionEventsResult = {
  notifyApolloSubscriptionsConnectionEventsHandlers: ObserverNotifyFunction<ApolloSubscriptionsConnectionEventsHandler>;
  apolloSubscriptionsConnectionEventsContext: ApolloSubscriptionsConnectionEventsContextProps;
};

const useApolloSubscriptionsConnectionEvents =
  (): UseApolloSubscriptionsConnectionEventsResult => {
    const { subscribe, unsubscribe, notify } =
      useObserver<ApolloSubscriptionsConnectionEventsHandler>();

    const apolloSubscriptionsConnectionEventsContext: ApolloSubscriptionsConnectionEventsContextProps =
      useMemo(
        () => ({
          subscribe,
          unsubscribe
        }),
        [subscribe, unsubscribe]
      );

    return {
      notifyApolloSubscriptionsConnectionEventsHandlers: notify,
      apolloSubscriptionsConnectionEventsContext
    };
  };

export default useApolloSubscriptionsConnectionEvents;
