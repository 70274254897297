import { captureException } from "@sentry/nextjs";

import { Me_me } from "~/declarations/apollo/Me";

import {
  CUSTOM_USER_FEEDBACK_ERROR_NAME,
  CUSTOM_USER_FEEDBACK_ERROR_TEXT,
  INCOGNITO_USERNAME,
  SENTRY_FEEDBACK_API
} from "./constants";
import { ProblemReportForm } from "./declarations";

export type SendSentryReportOptions = Omit<ProblemReportForm, "topicId"> & {
  sentryEventId?: string | null;
  person?: Me_me;
};

export class CustomUserFeedback extends Error {
  constructor(...args: (string | undefined)[]) {
    super(...args);
    this.name = CUSTOM_USER_FEEDBACK_ERROR_NAME;
  }
}

export const sendSentryReport = async ({
  email,
  message,
  sentryEventId,
  person
}: SendSentryReportOptions): Promise<void> => {
  const eventId =
    sentryEventId ||
    captureException(new CustomUserFeedback(CUSTOM_USER_FEEDBACK_ERROR_TEXT), {
      fingerprint: [`Date: ${new Date().getTime()}`]
    });

  const comments = message.trim() + `. Please respond to [${email}]`;

  try {
    await fetch(SENTRY_FEEDBACK_API, {
      method: "POST",
      body: new URLSearchParams({
        comments,
        email: person?.email || email,
        name: person?.name ?? INCOGNITO_USERNAME,
        event_id: eventId
      })
    });
  } catch (err) {
    console.error(err);
  }
};

export const getProblemReportDetails = (): string =>
  JSON.stringify({
    appVersion: process.env.SENTRY_RELEASE,
    userAgent: window.navigator.userAgent,
    platform: window.navigator.platform,
    url: window.location.href,
    screen: {
      width: window.screen.width,
      height: window.screen.height
    },
    viewport: {
      width: window.document.documentElement.clientWidth,
      height: window.document.documentElement.clientHeight
    }
  });
