import {
  ButtonProps as MuiButtonProps,
  default as MuiButton
} from "@material-ui/core/Button";
import { forwardRef } from "react";
import styled from "styled-components";

import { THEME_MODE } from "~/constants/theme";
import { omitStyleProps } from "~/utils/styled-components";

const EXTRA_LARGE_SIZE = "x-large";

type ButtonSize = MuiButtonProps["size"] | typeof EXTRA_LARGE_SIZE;

export type ButtonProps = Omit<MuiButtonProps, "size" | "variant"> & {
  size?: ButtonSize;
  analyticsId?: string;
  variant?:
    | MuiButtonProps["variant"]
    | "tertiary"
    | "colorful"
    | "colorful-inverse";
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { size, variant = "contained", className, ...props }: ButtonProps,
    ref
  ): JSX.Element => {
    const classNames = className?.split(" ") ?? [];

    if (size === EXTRA_LARGE_SIZE) {
      size = undefined;
      classNames.push("MuiButton-sizeExtraLarge");
    }

    if (variant === "tertiary") {
      variant = "text";
      classNames.push("MuiButton-tertiary");
    }

    if (variant === "colorful") {
      variant = "text";
      classNames.push("MuiButton-colorful");
    }

    if (variant === "colorful-inverse") {
      variant = "text";
      classNames.push("MuiButton-colorful-inverse");
    }

    className = classNames.join(" ");

    return (
      <StyledMuiButton
        ref={ref}
        className={className}
        variant={variant}
        size={size}
        id={props.analyticsId ?? props.id}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

const StyledMuiButton = styled(MuiButton).withConfig<{
  analyticsId?: string;
}>(omitStyleProps(["analyticsId"]))`
  border-radius: 12px;
  font-size: 15px;
  line-height: 24px;
  padding: 0;
  font-weight: 500;

  .MuiButton-label {
    padding: 8px 24px;
    pointer-events: ${({ analyticsId }) => (analyticsId ? "none" : "auto")};
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
    }
  }

  &.MuiButton-sizeSmall {
    border-radius: 8px;
    font-size: 13px;

    .MuiButton-label {
      padding: 4px 16px;
    }

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
      }
    }
  }

  &.MuiButton-sizeLarge {
    .MuiButton-label {
      padding: 10px 32px;
    }
  }

  &.MuiButton-sizeExtraLarge {
    .MuiButton-label {
      padding: 16px 32px;
    }
  }
  
  &.MuiButton-outlined, &.MuiButton-colorful, &.MuiButton-colorful-inverse {
    // 1px less than normal because of border
    .MuiButton-label {
      padding: 7px 23px;
    }

    &.MuiButton-sizeSmall {
      .MuiButton-label {
        padding: 3px 15px;
      }
    }

    &.MuiButton-sizeLarge {
      .MuiButton-label {
        padding: 9px 31px;
      }
    }

    &.MuiButton-sizeExtraLarge {
      .MuiButton-label {
        padding: 15px 31px;
      }
    }
  }

  &.MuiButton-contained {
    box-shadow: none;
    background-color: ${({ theme }) => theme.newTheme.background.primary};
    color: ${({ theme }) => theme.newTheme.text.quaternary};

    &:hover {
      box-shadow: none;
      background-color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.background.tertiary
          : theme.newTheme.background.secondary};
      color: ${({ theme }) => theme.newTheme.text.quaternary};
    }
    
    &:active {
      box-shadow: none;
    }

    &:disabled {
      box-shadow: none;
      border: 0;
      background-color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.background.quaternary
          : theme.newTheme.background.senary};
      color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.text.quaternary
          : theme.newTheme.text.tertiary};
    }
  }

  &.MuiButton-outlined {
    background-color: ${({ theme }) => theme.newTheme.background.octonary}};
    border-color: ${({ theme }) => theme.newTheme.background.primary};
    color: ${({ theme }) => theme.newTheme.text.primary};

    &:hover {
      border-color: ${({ theme }) => theme.newTheme.background.tertiary};
      color: ${({ theme }) => theme.newTheme.text.primary};
    }

    &:disabled {
      border-color: ${({ theme }) => theme.newTheme.background.quaternary};
      color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.text.secondary
          : theme.newTheme.text.tertiary};
    }
  }

  &.MuiButton-tertiary {
    border: 0;
    background-color: ${({ theme }) => theme.newTheme.background.septenary};
    color: ${({ theme }) => theme.newTheme.text.primary};

    &:hover {
      background-color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.background.primary
          : theme.newTheme.background.senary};
      color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.text.quaternary
          : theme.newTheme.text.primary};
    }

    &:disabled {
      background-color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.background.quaternary
          : theme.newTheme.background.senary};
      color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.text.quaternary
          : theme.newTheme.text.tertiary};
    }
  }

  &.MuiButton-colorful,
  &.MuiButton-colorful-inverse {
    background: ${({ theme }) => theme.newTheme.gradient.primary} border-box;
    border: 1px solid transparent;

    &.MuiButton-sizeSmall {
      .MuiButton-label {
        border-radius: 7px;
      }
    }

    .MuiButton-label {
      border-radius: 11px;
    }

    &:disabled {
      .MuiButton-label {
        background: ${({ theme }) =>
          theme.type === THEME_MODE.light
            ? theme.newTheme.background.quaternary
            : theme.newTheme.background.senary};
      }
      color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.text.quaternary
          : theme.newTheme.text.tertiary};
    }
  }

  &.MuiButton-colorful {
    color: ${({ theme }) => theme.newTheme.text.primary};
    
    .MuiButton-label {
      background: ${({ theme }) => theme.newTheme.background.octonary};
    }

    &:hover {
      .MuiButton-label {
        background: ${({ theme }) =>
          theme.type === THEME_MODE.light
            ? theme.newTheme.background.primary
            : theme.newTheme.background.octonary};
      }
      color: ${({ theme }) =>
        theme.type === THEME_MODE.light
          ? theme.newTheme.text.quaternary
          : theme.newTheme.text.primary};
    }
  }

  &.MuiButton-colorful-inverse {
    color: ${({ theme }) => theme.newTheme.text.primaryInverse};
    
    .MuiButton-label {
      background: ${({ theme }) => theme.newTheme.background.primary};
    }

    &:hover {
      .MuiButton-label {
        background: ${({ theme }) =>
          theme.type === THEME_MODE.dark
            ? theme.newTheme.background.primary
            : theme.newTheme.background.octonary};
      }
      color: ${({ theme }) =>
        theme.type === THEME_MODE.dark
          ? theme.newTheme.text.quaternary
          : theme.newTheme.text.primary};
    }
  }
`;

export default Button;
