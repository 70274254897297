import {
  MESSAGING_DETAILED_ONE_TO_ONE_CONVERSATION_TYPENAME,
  MESSAGING_GROUP_CONVERSATION_TYPENAME,
  MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME
} from "~/components/messaging/constants";
import { MessagingConversation } from "~/components/messaging/declarations/conversations";
import { Conversation_conversation } from "~/declarations/apollo/Conversation";

export const mapDetailedToSimpleConversation = (
  conversation: Conversation_conversation
): MessagingConversation => {
  if (
    conversation.__typename ===
    MESSAGING_DETAILED_ONE_TO_ONE_CONVERSATION_TYPENAME
  ) {
    return {
      ...conversation,
      __typename: MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME
    };
  } else {
    return {
      ...conversation,
      __typename: MESSAGING_GROUP_CONVERSATION_TYPENAME
    };
  }
};
