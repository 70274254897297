import {
  default as MuiIconButton,
  IconButtonProps as MuiIconButtonProps
} from "@material-ui/core/IconButton";
import { forwardRef, useMemo, useState } from "react";
import styled, { css } from "styled-components";

import Icon from "~/components/core/Icon";
import { IconSize, IconVariant } from "~/components/core/Icon/declarations";
import { THEME_MODE } from "~/constants/theme";
import { isString } from "~/utils/common";
import { omitStyleProps } from "~/utils/styled-components";

export type IconButtonProps = {
  icon: IconVariant | React.ReactElement;
  activeIcon?: IconVariant | React.ReactElement;
  iconSize?: IconSize;
  colorful?: boolean;
  analyticsId?: string;
} & MuiIconButtonProps;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      activeIcon = icon,
      iconSize = 24,
      onTouchStart,
      onTouchEnd,
      onMouseUp,
      onMouseDown,
      onMouseLeave,
      children,
      ...props
    }: IconButtonProps,
    ref
  ): JSX.Element => {
    const [active, setActive] = useState(false);

    const handleMouseDown = (
      event: React.MouseEvent<HTMLButtonElement>
    ): void => {
      setActive(true);
      onMouseDown?.(event);
    };

    const handleMouseUp = (
      event: React.MouseEvent<HTMLButtonElement>
    ): void => {
      setActive(false);
      onMouseUp?.(event);
    };

    const handleMouseLeave = (
      event: React.MouseEvent<HTMLButtonElement>
    ): void => {
      setActive(false);
      onMouseLeave?.(event);
    };

    const handleTouchStart = (
      event: React.TouchEvent<HTMLButtonElement>
    ): void => {
      setActive(true);
      onTouchStart?.(event);
    };

    const handleTouchEnd = (
      event: React.TouchEvent<HTMLButtonElement>
    ): void => {
      setActive(false);
      onTouchEnd?.(event);
    };

    const iconComponent = useMemo(
      () =>
        isString(icon) ? (
          <Icon variant={icon as IconVariant} size={iconSize} />
        ) : (
          icon
        ),
      [icon, iconSize]
    );

    const activeIconComponent = useMemo(
      () =>
        isString(activeIcon) ? (
          <Icon variant={activeIcon as IconVariant} size={iconSize} />
        ) : (
          activeIcon
        ),
      [activeIcon, iconSize]
    );

    return (
      <StyledMuiIconButton
        iconSize={iconSize}
        ref={ref}
        disableRipple
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseUp={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        id={props.analyticsId ?? props.id}
        {...props}
      >
        {active ? activeIconComponent : iconComponent}
        {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
      </StyledMuiIconButton>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;

export const ChildrenWrapper = styled.div`
  display: inline-flex;
  margin-left: 4px;
`;

const StyledMuiIconButton = styled(MuiIconButton).withConfig<{
  colorful?: boolean;
  iconSize?: number;
  analyticsId?: string;
}>(omitStyleProps(["colorful", "iconSize", "analyticsId"]))`
  font-family: "Aeonik", "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: ${({ theme }) => theme.newTheme.background.septenary};
  color: ${({ theme }) => theme.newTheme.text.primary};
  padding: 10px;
  position: relative;
  border-radius: 99px;

  svg {
    height: ${({ iconSize }) => iconSize}px;
    width: ${({ iconSize }) => iconSize}px;
  }

  &.Mui-disabled {
    background-color: ${({ theme }) =>
      theme.newTheme.background.secondaryInverse};
    color: ${({ theme }) => theme.newTheme.transparent[24]};
  }

  .MuiIconButton-label {
    pointer-events: ${({ analyticsId }) => (analyticsId ? "none" : "auto")};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.newTheme.background.tertiaryInverse};
  }

  ${({ colorful, theme }) => {
    if (colorful) {
      return css`
        background-clip: padding-box;
        border: solid 1px transparent;
        box-sizing: border-box;
        padding: 9px;

        &:before {
          background: ${({ theme }) => theme.newTheme.gradient.primary};
          border-radius: inherit;
          bottom: -1px;
          content: "";
          left: -1px;
          position: absolute;
          right: -1px;
          top: -1px;
          z-index: -1;
        }

        ${() =>
          theme.type === THEME_MODE.dark
            ? css`
                &.Mui-disabled {
                  border: none;
                  &:before {
                    display: none;
                  }
                }
              `
            : ""}
      `;
    }
  }}
`;
