import { useRouter } from "next/router";
import { SnackbarKey, useSnackbar } from "notistack";
import { forwardRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  PostPurchaseLinkClickHandler,
  TRANSACTION_DONE_REASON,
  TransactionDoneHandler
} from "~/components/wallet/providers/WalletProvider/declarations";
import { MILLISECONDS_IN_SECOND } from "~/constants/date";
import { QUERY_SIGN } from "~/constants/url";
import { ProgressOfTransaction } from "~/declarations/purchases";
import { ObserverNotifyFunction } from "~/utils/observer/useObserver";
import usePrevious from "~/utils/usePrevious";
import { useRouteChangeCallback } from "~/utils/useRouteChangeCallback";

import PaymentProgressSnack from "./PaymentProgressSnack";
import PaymentResultSnack from "./PaymentResultSnack";

type Props = {
  snackId: SnackbarKey;
  progress: ProgressOfTransaction;
  addDoneHandler: (handler: TransactionDoneHandler) => void;
  removeDoneHandler: (handler: TransactionDoneHandler) => void;
  notifyPostPurchaseLinkClickHandler: ObserverNotifyFunction<PostPurchaseLinkClickHandler>;
  ref?: React.Ref<HTMLDivElement>;
};

const AUTOCLOSE_AFTER_SUCCESS_TIMEOUT = 3 * MILLISECONDS_IN_SECOND;

const PaymentSnack = forwardRef<HTMLDivElement, Props>(
  (
    {
      snackId,
      progress,
      addDoneHandler,
      removeDoneHandler,
      notifyPostPurchaseLinkClickHandler
    }: Props,
    ref
  ): JSX.Element => {
    const router = useRouter();
    const [succeeded, setSucceeded] = useState(false);
    const { closeSnackbar } = useSnackbar();

    useEffect(() => {
      const handler: TransactionDoneHandler = (progress, reason) => {
        if (reason === TRANSACTION_DONE_REASON.timeout) {
          closeSnackbar(snackId);
        } else if (reason === TRANSACTION_DONE_REASON.success) {
          setSucceeded(true);

          setTimeout(() => {
            closeSnackbar(snackId);
          }, AUTOCLOSE_AFTER_SUCCESS_TIMEOUT);
        }

        return false;
      };

      addDoneHandler(handler);

      return () => {
        removeDoneHandler(handler);
      };
    }, [addDoneHandler, closeSnackbar, removeDoneHandler, snackId]);

    const previousPathname = usePrevious(router.asPath.split(QUERY_SIGN)[0]);
    useRouteChangeCallback((url: string) => {
      const currentPathname = url.split(QUERY_SIGN)[0];

      if (currentPathname !== previousPathname) {
        closeSnackbar(snackId);
      }
    });

    const handleClick = useCallback(() => {
      closeSnackbar(snackId);
    }, [closeSnackbar, snackId]);

    return (
      <OuterWrapper ref={ref} onClick={handleClick}>
        <ProgressSnack id={snackId} hidden={succeeded} />
        <ResultSnack
          id={snackId}
          progress={progress}
          hidden={!succeeded}
          notifyPostPurchaseLinkClickHandler={
            notifyPostPurchaseLinkClickHandler
          }
        />
      </OuterWrapper>
    );
  }
);

PaymentSnack.displayName = "PaymentSnack";

export default PaymentSnack;

const OuterWrapper = styled.div`
  position: relative;
`;

const ProgressSnack = styled(PaymentProgressSnack)<{
  hidden: boolean;
}>`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  position: ${({ hidden }) => (hidden ? "absolute" : "static")};
  transition-delay: 0s, 1s;
  transition-duration: 1s, 1s;
  transition-property: opacity, visibility;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

const ResultSnack = styled(PaymentResultSnack)`
  height: 104px;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  position: ${({ hidden }) => (hidden ? "absolute" : "static")};
  transition-delay: 0s, 1s;
  transition-duration: 1s, 1s;
  transition-property: opacity, visibility;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  width: 420px;
`;
