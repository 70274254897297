import { alpha } from "@material-ui/core/styles";
import styled from "styled-components";

const IconWrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 72px;
  justify-content: center;
  width: 72px;
`;

export const DefaultIconWrapper = styled(IconWrapper)`
  background-color: ${({ theme }) =>
    alpha(theme.newTheme.background.primary, 0.05)};
  color: ${({ theme }) => theme.newTheme.background.primary};
`;
export const WarningIconWrapper = styled(IconWrapper)`
  background-color: ${({ theme }) => alpha(theme.palette.common.yellow, 0.05)};
  color: ${({ theme }) => theme.palette.common.yellow};
`;
export const SuccessIconWrapper = styled(IconWrapper)`
  background-color: ${({ theme }) => alpha(theme.palette.common.green, 0.05)};
  color: ${({ theme }) => theme.palette.common.green};
`;
export const ErrorIconWrapper = styled(IconWrapper)`
  background-color: ${({ theme }) => alpha(theme.palette.common.red, 0.05)};
  color: ${({ theme }) => theme.palette.common.red};
`;
