import { ReactNode } from "react";

import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

import { NotificationsPopupContext } from "./NotificationsPopupContext";

const NotificationsPopup = safeDynamicImport(
  () => import("~/components/activityPopups/NotificationsPopup"),
  { ssr: false }
);

type Props = {
  authorized: boolean;
  authorizationStatusFetched: boolean;
  children: ReactNode;
};

const NotificationsPopupProvider = ({
  authorized,
  authorizationStatusFetched,
  children
}: Props): JSX.Element => {
  const notificationsPopup = useDialog<HTMLElement>();

  return (
    <NotificationsPopupContext.Provider value={notificationsPopup}>
      {children}
      {authorized &&
        authorizationStatusFetched &&
        notificationsPopup.payload &&
        notificationsPopup.hasBeenOpened && (
          <NotificationsPopup
            open={notificationsPopup.open}
            onClose={notificationsPopup.closeDialog}
            anchorElement={notificationsPopup.payload}
          />
        )}
    </NotificationsPopupContext.Provider>
  );
};

export default NotificationsPopupProvider;
