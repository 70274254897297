import { string } from "yup";

export const MAX_BIO_LENGTH = 160;
export const MIN_EMAIL_LENGTH = 3;
export const MIN_PASSWORD_LENGTH = 8;
export const MIN_DISPLAY_NAME_LENGTH = 3;
export const MAX_DISPLAY_NAME_LENGTH = 30;
export const MIN_USER_NAME_LENGTH = 3;
export const MAX_USER_NAME_LENGTH = 20;

export const USER_NAME_CHAR_REGEXP_BASE = "[a-zA-Z\\._0-9\\-]";
export const USER_NAME_REGEXP = new RegExp(`^${USER_NAME_CHAR_REGEXP_BASE}+$`);
export const DISPLAY_NAME_CHAR_REGEXP_BASE = "[a-zA-Z\\.\\,\\'\\s]";
export const DISPLAY_NAME_REGEXP = new RegExp(
  `^${DISPLAY_NAME_CHAR_REGEXP_BASE}*$`
);

export const EMAIL_SCHEMA = string().defined().min(MIN_EMAIL_LENGTH);

export const PASSWORD_SCHEMA = string().defined().min(MIN_PASSWORD_LENGTH);

export const SUFFICIENT_NUMBER_OF_USER_INTERESTS = 3;

export const DISPLAY_NAME_SCHEMA = string()
  .defined()
  .min(MIN_DISPLAY_NAME_LENGTH)
  .max(MAX_DISPLAY_NAME_LENGTH);

export const USER_NAME_SCHEMA = string()
  .defined()
  .min(MIN_USER_NAME_LENGTH)
  .max(MAX_USER_NAME_LENGTH);

export const SETTINGS_USER_NAME_SCHEMA = string()
  .max(MAX_USER_NAME_LENGTH, () => ({
    key: "form:validation_string_max",
    options: { value: MAX_USER_NAME_LENGTH }
  }))
  .min(MIN_USER_NAME_LENGTH, () => ({
    key: "form:validation_string_min",
    options: { value: MIN_USER_NAME_LENGTH }
  }))
  .matches(USER_NAME_REGEXP, () => ({
    key: "auth:signup_username_error_match"
  }));

export const SETTINGS_DISPLAY_NAME_SCHEMA = string()
  .max(MAX_DISPLAY_NAME_LENGTH, () => ({
    key: "form:validation_string_max",
    options: { value: MAX_DISPLAY_NAME_LENGTH }
  }))
  .min(MIN_DISPLAY_NAME_LENGTH, () => ({
    key: "form:validation_string_min",
    options: { value: MIN_DISPLAY_NAME_LENGTH }
  }))
  .matches(DISPLAY_NAME_REGEXP, () => ({
    key: "auth:signup_display-name_error_match"
  }));
