import { createContext } from "react";

import { CUSTOM_USER_PROPERTY } from "~/components/providers/AnalyticsProvider/constants";
import { SendEventCallback } from "~/components/providers/AnalyticsProvider/declarations";
import createUseContext from "~/utils/createUseContext";

type AnalyticsContextProps = {
  setUserId: (userId: string) => void;
  setCustomUserProperty: (
    property: CUSTOM_USER_PROPERTY,
    value: string | number
  ) => void;
  sendEvent: SendEventCallback;
};

export const AnalyticsContext = createContext<
  AnalyticsContextProps | undefined
>(undefined);

export const useAnalyticsContext = createUseContext(
  AnalyticsContext,
  "AnalyticsContext"
);
