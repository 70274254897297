import { useMutation } from "@apollo/client/react/hooks";
import useTranslation from "next-translate/useTranslation";

import ConfirmationDialog from "~/components/core/ConfirmationDialog";
import useCustomSnackbar from "~/components/providers/CustomSnackbarProvider/useCustomSnackbar";
import {
  PostBlockPerson,
  PostBlockPersonVariables
} from "~/declarations/apollo/PostBlockPerson";
import { Post } from "~/declarations/post";
import { getCustomApolloErrorMessage } from "~/utils/errors";

import { BLOCK_PERSON_MUTATION } from "./graphql";

interface Props {
  open: boolean;
  authorId: Post["owner"]["id"];
  onClose: () => void;
}
const PostReportResultDialog = ({
  open,
  authorId,
  onClose
}: Props): JSX.Element => {
  const { t } = useTranslation("report");
  const { enqueueCustomSnackbar } = useCustomSnackbar();
  const [blockPerson] = useMutation<PostBlockPerson, PostBlockPersonVariables>(
    BLOCK_PERSON_MUTATION
  );

  const handleBlockClick = async (): Promise<void> => {
    try {
      await blockPerson({
        variables: {
          personToBlockId: authorId
        }
      });

      enqueueCustomSnackbar(t("common:blocked"), {
        variant: "success"
      });

      onClose();
    } catch (error) {
      const errorMessageKey = getCustomApolloErrorMessage(error);

      enqueueCustomSnackbar(errorMessageKey, {
        variant: "error"
      });
    }
  };

  return (
    <ConfirmationDialog
      icon="successFilled"
      open={open}
      caption={t("result-title")}
      description={t("result-description-person")}
      confirmText={t("result-block-action")}
      cancelText={t("common:no-thanks")}
      onClose={onClose}
      onConfirm={handleBlockClick}
    />
  );
};

export default PostReportResultDialog;
