import { gql } from "@apollo/client";

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;
export const TOKEN_REFRESH_MUTATION = gql`
  mutation RefreshToken {
    refreshToken {
      accessToken {
        value
        expiresAt
      }
      getStreamToken {
        value
      }
    }
  }
`;
