import { createContext } from "react";

import { PaymentRecipient } from "~/components/payment/declarations";
import { SpecificPurchaseDialogOptions } from "~/components/payment/PurchaseDialog/declarations";
import { SpecificTipPostDialogOptions } from "~/components/payment/TipPostDialog/declarations";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogResult } from "~/components/providers/DialogProvider/declarations/results";
import { WalletNotEnoughFundsPayload } from "~/components/wallet/providers/WalletProvider/WalletProvider";
import { WALLET_CONFIRMATION_RECEIVER_HAS_NOT_WALLET_TYPE } from "~/components/wallet/WalletConfirmationDialog/constants";
import { WalletStatus } from "~/declarations/apollo/globalTypes";
import { MyWallet_myWallet } from "~/declarations/apollo/MyWallet";
import { ProgressOfTransaction, PURCHASE_TYPE } from "~/declarations/purchases";
import createUseContext from "~/utils/createUseContext";
import { ObserverHandlerListModifier } from "~/utils/observer/useObserver";

import {
  PostPurchaseLinkClickHandler,
  RunAfterWalletAvailableGuard,
  TransactionDoneHandler,
  WalletBuyCoinsDialogPayload,
  WalletCreateFlowHandler,
  WalletCreateKeyFlowHandler,
  WarnAboutWalletKeyDropHandler
} from "./declarations";

export interface WalletContextState {
  runAfterWalletAvailable: RunAfterWalletAvailableGuard;
  warnAboutIncorrectPin: () => Promise<
    DialogResult<DIALOG_NAME.resetWalletPin>
  >;
  warnAboutReceiverHasNoWallet: (
    type: WALLET_CONFIRMATION_RECEIVER_HAS_NOT_WALLET_TYPE,
    recipient?: PaymentRecipient | null
  ) => void;
  warnAboutWalletMaintenance: () => void;
  warnAboutTransactionInProgress: () => void;
  warnAboutNotEnoughMoney: (payload: WalletNotEnoughFundsPayload) => void;
  warnAboutWalletKeyDrop: WarnAboutWalletKeyDropHandler;
  fetchWallet: () => Promise<MyWallet_myWallet | null>;
  fetchWalletStatus: (userId: string) => Promise<WalletStatus | null>;
  resetPin: () => Promise<DialogResult<DIALOG_NAME.resetWalletPin>>;
  startNoWalletFlow: WalletCreateFlowHandler;
  startNoWalletKeyFlow: WalletCreateKeyFlowHandler;
  payForPost: (options: SpecificPurchaseDialogOptions) => Promise<void>;
  tipPost: (options: SpecificTipPostDialogOptions) => void;
  addTransactionProgress: (progress: ProgressOfTransaction) => void;
  getTransactionProgress: (
    type: PURCHASE_TYPE,
    instanceId: string
  ) => ProgressOfTransaction | null;
  hasPostTransactionInProgress: (id: string) => boolean;
  addTransactionDoneHandler: (handler: TransactionDoneHandler) => void;
  removeTransactionDoneHandler: (handler: TransactionDoneHandler) => void;
  addPostPurchaseLinkClickHandler: ObserverHandlerListModifier<PostPurchaseLinkClickHandler>;
  removePostPurchaseLinkClickHandler: ObserverHandlerListModifier<PostPurchaseLinkClickHandler>;
  transactionInProgress: boolean;
  postPaymentInProgress: boolean;
  postTippingInProgress: boolean;
  coinsBuyingInProgress: boolean;
  walletSynchronizationMethodSelectionInProgress: boolean;
  buyCoins: (payload?: WalletBuyCoinsDialogPayload) => void;
  wallet: MyWallet_myWallet | null;
  fetched: boolean;
  showNFTMaintenancePlaceholders: boolean;
  allowedToMintNftFor1Hype: boolean;
  checkWalletInMaintenence: () => boolean;
  selectWalletSynchronizationMethod: () => void;
}

const WalletContext = createContext<WalletContextState | undefined>(undefined);

const useWalletContext = createUseContext(WalletContext, "WalletContext");

export { WalletContext, useWalletContext };
