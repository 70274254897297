import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";
import { UseDialogHookResult } from "~/utils/useDialog";

export type NotificationsPopupContextProps = UseDialogHookResult<HTMLElement>;

export const NotificationsPopupContext = createContext<
  NotificationsPopupContextProps | undefined
>(undefined);

export const useNotificationPopup =
  createUseContext<NotificationsPopupContextProps>(
    NotificationsPopupContext,
    "NotificationsPopup"
  );
