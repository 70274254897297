import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "next-translate/useTranslation";
import { MouseEvent, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import Button from "~/components/core/Button";
import IconButton from "~/components/core/IconButton";
import MenuLogotype from "~/components/core/MenuLogotype";
import AccountHeaderDesktopSearch from "~/components/layouts/AccountLayout/AccountHeader/AccountHeaderDesktop/AccountHeaderDesktopSearch";
import AccountHeaderMessaging from "~/components/layouts/AccountLayout/AccountHeader/AccountHeaderMessaging";
import AccountHeaderNotifications from "~/components/layouts/AccountLayout/AccountHeader/common/AccountHeaderNotifications";
import {
  MENU_LINK,
  MENU_LINKS
} from "~/components/layouts/AccountLayout/AccountHeader/constants";
import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { useSearchPopup } from "~/components/providers/SearchPopupProvider/SearchPopupContext";
import { useUnreadNotificationsContext } from "~/components/providers/UnreadNotificationsProvider/UnreadNotificationsContextProvider";
import { ANALYTICS_ACTION_IDS } from "~/constants/analytics";
import { THUMBNAIL_VARIANT } from "~/declarations/thumbnails";
import { isNotNull } from "~/utils/common";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";
import { useRouteChangeCallback } from "~/utils/useRouteChangeCallback";
import { useScrollDetect } from "~/utils/useScrollDetect";

import AccountHeaderFeedLinks from "../AccountHeaderFeedLinks";
import AccountHeaderMobileMenuWithButton from "../AccountHeaderMobile/AccountHeaderMobileMenuWithButton";
import AccountHeaderWrapper from "../common/AccountHeaderWrapper";
import { AddContentButton } from "../common/styled";
import AccountHeaderDesktopUserMenu from "./AccountHeaderDesktopUserMenu";
import AccountHeaderSectionLink from "./AccountHeaderSectionLink";
import { MENU_SECTION } from "./constants";

const AccountHeaderDesktopAddContentMenu = safeDynamicImport(
  () => import("./AccountHeaderDesktopAddContentMenu"),
  {
    ssr: false
  }
);

const AccountHeaderDesktopMenuWithButton = safeDynamicImport(
  () =>
    import(
      "./AccountHeaderDesktopMenuWithButton/AccountHeaderDesktopMenuWithButton"
    ),
  {
    ssr: false,
    loading: () => <IconButton icon="more" disabled />
  }
);

const PROFILE_LINK = MENU_LINKS[MENU_LINK.profile].href;

const AccountHeaderDesktop = (): JSX.Element => {
  const [activeSection, setActiveSection] = useState<null | MENU_SECTION>();
  const unreadNotifications = useUnreadNotificationsContext();
  const {
    currentUser: { data: userData, loading: userLoading },
    authorized,
    authorizationStatusFetched,
    loginDialog,
    signUpDialog
  } = useAccountLayoutContext();
  const uploadSectionRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation("user");
  const { searchPopup } = useSearchPopup();
  const scrolled = useScrollDetect();
  const addContentMenu = useDialog<HTMLButtonElement>();
  const menuRef = useRef<HTMLElement | null>(null);
  const openingTimeout = useRef<null | number>(null);

  const theme = useTheme();
  const smallView = useMediaQuery(theme.breakpoints.down("sm"));
  const authDialogOpened = loginDialog.open || signUpDialog.open;

  const handleCloseMenu = (): void => {
    setActiveSection(null);
  };

  useRouteChangeCallback(handleCloseMenu);

  const handleClickSignIn = (): void => {
    loginDialog.openDialog();
  };

  const handleClickSignUp = (): void => {
    signUpDialog.openDialog();
  };

  const clearOpeningTimeout = (): void => {
    if (openingTimeout.current) {
      window.clearTimeout(openingTimeout.current);
    }
  };

  const handleCloseDetails = (relatedTarget: EventTarget | null): void => {
    const relatedIsDescendantOfMenu =
      menuRef.current &&
      relatedTarget &&
      relatedTarget instanceof Node &&
      menuRef.current.contains(relatedTarget as Node);

    if (relatedTarget !== menuRef.current && !relatedIsDescendantOfMenu) {
      setActiveSection(null);
    }

    clearOpeningTimeout();
  };

  const handleClickAddContent = (
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    if (authorized) {
      addContentMenu.openDialog(event.currentTarget);
    } else {
      loginDialog.openDialog({
        reason: t("auth:auth_login_reason_upload")
      });
    }
  };

  const handleOpenSearchPopup = (
    event: MouseEvent<HTMLButtonElement>
  ): void => {
    searchPopup.openDialog(event.currentTarget);
  };

  const hasActiveSection = isNotNull(activeSection);
  const uploadText = t("user:navigation-menu_add-content_caption");
  const signupPageLinkTranslationKey =
    process.env.NEXT_PUBLIC_USERNAME_RESERVATION_INSTEAD_OF_SIGNUP === "true"
      ? "auth:auth_reserve-username"
      : "auth:auth_signup";

  return (
    <>
      <AccountHeaderWrapper withShadow={scrolled && !hasActiveSection}>
        <MenuLogotype />
        <AccountHeaderFeedLinks authorized={authorized} />
        {!smallView && <AccountHeaderDesktopSearch />}

        <RightSection>
          {((authorizationStatusFetched && authorized) || !smallView) && (
            <AccountHeaderSectionLink
              onBlur={handleCloseDetails}
              ref={uploadSectionRef}
            >
              <AddContentButton
                analyticsId={ANALYTICS_ACTION_IDS.startPostCreation}
                aria-label={uploadText}
                onClick={handleClickAddContent}
              />
            </AccountHeaderSectionLink>
          )}

          {authorizationStatusFetched &&
            (authorized ? (
              <>
                <AccountHeaderMessaging />
                <AccountHeaderNotifications
                  hasUnreadNotifications={unreadNotifications > 0}
                />
                {smallView && (
                  <IconButton icon="search" onClick={handleOpenSearchPopup} />
                )}
                {PROFILE_LINK && !smallView && (
                  <AccountHeaderDesktopUserMenu
                    avatar={userData?.avatar?.[THUMBNAIL_VARIANT.little]}
                    name={userData?.name}
                    loading={userLoading ?? true}
                  />
                )}
              </>
            ) : (
              <>
                <StyledButton
                  analyticsId={ANALYTICS_ACTION_IDS.startLogin}
                  onClick={handleClickSignIn}
                  variant="contained"
                  size="large"
                  aria-label={t("auth:auth_login")}
                  disabled={authDialogOpened}
                >
                  {t("auth:auth_login")}
                </StyledButton>

                <StyledButton
                  analyticsId={ANALYTICS_ACTION_IDS.startSignup}
                  onClick={handleClickSignUp}
                  variant="colorful"
                  size="large"
                  aria-label={t("auth:auth_signup")}
                  disabled={authDialogOpened}
                >
                  {t(signupPageLinkTranslationKey)}
                </StyledButton>
                {smallView && (
                  <IconButton icon="search" onClick={handleOpenSearchPopup} />
                )}
              </>
            ))}

          {smallView ? (
            <AccountHeaderMobileMenuWithButton />
          ) : (
            <AccountHeaderDesktopMenuWithButton />
          )}

          {addContentMenu.hasBeenOpened && addContentMenu.payload && (
            <AccountHeaderDesktopAddContentMenu
              open={addContentMenu.open}
              onClose={addContentMenu.closeDialog}
              anchorElement={addContentMenu.payload}
              allowCreateNFT={userData?.nftSettings.canCreateNft === true}
            />
          )}
        </RightSection>
      </AccountHeaderWrapper>
    </>
  );
};

export default AccountHeaderDesktop;

const RightSection = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: auto;

  > :not(:last-child) {
    margin-right: 8px;

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-right: 13px;
    }
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;
