import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";
import { UseDialogHookResult } from "~/utils/useDialog";

export type SearchPopupContextProps = {
  searchPopup: UseDialogHookResult<HTMLElement>;
  searchText: string;
  appliedSearchText: string;
  setSearchText: (searchText: string) => void;
  setAppliedSearchText: (searchText: string) => void;
};

export const SearchPopupContext = createContext<
  SearchPopupContextProps | undefined
>(undefined);

export const useSearchPopup = createUseContext(
  SearchPopupContext,
  "SearchPopup"
);
