import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
import Router from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { ROUTER_EVENTS } from "~/constants/events";

const FADE_PROGRESSBAR_TIMEOUT = 1000;
const COMPLETED_PERCENTAGE = 100;
const UPDATE_PROGRESS_INTERVAL = 500;
const routerEvents = Router.events;
const FROM_ZINDEX_BECAME_VISIBLE = 1210;
const STAY_VISIBLE_TIMEOUT = 1000;

interface Props {
  path: string;
  level: number;
  onComplete: (path: string) => void;
}

const LoadingIndicator = ({ path, level, onComplete }: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    function progress(): void {
      setCompleted(oldCompleted => {
        if (oldCompleted === COMPLETED_PERCENTAGE) {
          return COMPLETED_PERCENTAGE;
        }

        const diff =
          Math.random() *
          Math.min((COMPLETED_PERCENTAGE - oldCompleted) / 10, 10);

        return Math.min(oldCompleted + diff, COMPLETED_PERCENTAGE);
      });
    }

    let timer: number | null = window.setInterval(
      progress,
      UPDATE_PROGRESS_INTERVAL
    );
    function stopInterval(): void {
      timer && clearInterval(timer);
      timer = null;
    }

    const handleRouteChangeComplete = (): void => {
      setCompleted(COMPLETED_PERCENTAGE);
      stopInterval();
      setTimeout(() => {
        setLoading(false);
        setTimeout(() => onComplete(path), FADE_PROGRESSBAR_TIMEOUT);
      }, STAY_VISIBLE_TIMEOUT);
    };

    const handleRouteChangeError = (): void => {
      setLoading(false);
      stopInterval();
      setTimeout(() => onComplete(path), FADE_PROGRESSBAR_TIMEOUT);
    };

    routerEvents.on(
      ROUTER_EVENTS.routeChangeComplete,
      handleRouteChangeComplete
    );
    routerEvents.on(ROUTER_EVENTS.routeChangeError, handleRouteChangeError);

    return (): void => {
      stopInterval();
      routerEvents.off(
        ROUTER_EVENTS.routeChangeComplete,
        handleRouteChangeComplete
      );
      routerEvents.off(ROUTER_EVENTS.routeChangeError, handleRouteChangeError);
    };
  }, [onComplete, path]);

  return (
    <Fade in={loading} timeout={{ enter: 0, exit: FADE_PROGRESSBAR_TIMEOUT }}>
      <StyledProgress variant="determinate" value={completed} level={level} />
    </Fade>
  );
};

export default LoadingIndicator;

const StyledProgress = styled(LinearProgress)<{ level: number }>`
  &.MuiLinearProgress-root {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ level }): string =>
      String(FROM_ZINDEX_BECAME_VISIBLE + level)};
    height: 4px;
    background: ${({ theme }): string => theme.newTheme.background.octonary};
  }
  & .MuiLinearProgress-bar {
    background: ${({ theme }): string => theme.newTheme.gradient.primary};
    height: 4px;
  }
`;
