import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_PAYOUT_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.payout> = {
  Component: safeDynamicImport(
    () =>
      import(
        "~/components/wallet/WalletPayoutFlow/WalletPayoutDialog/WalletPayoutDialog"
      ),
    {
      ssr: false
    }
  )
};

export enum WALLET_PAYOUT_DIALOG_ERROR_CODE {
  noWallet = "noWallet",
  limitsExceeded = "limitsExceeded"
}
