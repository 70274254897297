import { DataProxy, defaultDataIdFromObject } from "@apollo/client";

import { POST_COMMENT_COUNTERS_FRAGMENT } from "~/components/post/graphql";
import { POST_TYPE_TO_TYPENAME } from "~/constants/posts";
import { PostType } from "~/declarations/apollo/globalTypes";
import { PostCounters } from "~/declarations/apollo/PostCounters";

const updateTipCounter = (
  cache: DataProxy,
  postId: string,
  postType: PostType,
  balanceDifference: number
): void => {
  const __typename = POST_TYPE_TO_TYPENAME[postType];
  const postCacheId = defaultDataIdFromObject({
    id: postId,
    __typename
  });
  const result = cache.readFragment<PostCounters>({
    id: postCacheId,
    fragment: POST_COMMENT_COUNTERS_FRAGMENT
  });

  if (!result) {
    return;
  }

  const initialSum =
    result.counters.tips && result.counters.tips.sum
      ? result.counters.tips.sum
      : 0;

  cache.writeFragment<PostCounters>({
    id: postCacheId,
    fragment: POST_COMMENT_COUNTERS_FRAGMENT,
    data: {
      counters: {
        ...result.counters,
        tips: {
          ...result.counters.tips,
          sum: initialSum + balanceDifference
        }
      }
    }
  });
};

export default updateTipCounter;
