import { DataProxy, defaultDataIdFromObject } from "@apollo/client";

import {
  MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME,
  MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME,
  MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME,
  OPTIMISTIC_MESSAGE_ID
} from "~/components/messaging/constants";
import {
  MessagingConversationMessage,
  MessagingConversationMessageWithQuote,
  MessagingConversationSimpleMessage
} from "~/components/messaging/declarations/messages";
import {
  CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT,
  CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT_NAME,
  MY_CONVERSATIONS_QUERY,
  MY_CONVERSATIONS_QUERY_KEY
} from "~/components/messaging/graphql/conversations";
import {
  CONVERSATION_MESSAGE_FRAGMENT,
  CONVERSATION_MESSAGE_FRAGMENT_NAME,
  CONVERSATION_MESSAGES_QUERY,
  CONVERSATION_MESSAGES_QUERY_KEY
} from "~/components/messaging/graphql/messages";
import {
  getMessageWithQuoteCacheId,
  getSimpleMessageCacheId
} from "~/components/messaging/providers/MessagingProvider/useRealTimeMessagingUpdateViaSubscription/apollo";
import { isMessageFromSystem } from "~/components/messaging/utils/common";
import {
  ConversationMessages,
  ConversationMessages_conversationMessages_edges,
  ConversationMessagesVariables
} from "~/declarations/apollo/ConversationMessages";
import { MyConversation_OneToOneConversation_read } from "~/declarations/apollo/MyConversation";
import {
  MyConversations,
  MyConversationsVariables
} from "~/declarations/apollo/MyConversations";
import {
  addNodesToListInCache,
  deleteNodesFromListInCache,
  updateNodeInCache
} from "~/utils/apollo/pagination";

export const needToAddMessageCreatedNotification = (
  cache: DataProxy,
  conversationId: string,
  messageId: string
): boolean => {
  let response: ConversationMessages | null = null;

  try {
    response = cache.readQuery<
      ConversationMessages,
      ConversationMessagesVariables
    >(
      {
        query: CONVERSATION_MESSAGES_QUERY,
        variables: {
          conversationId
        }
      },
      true
    );
  } catch {
    return false;
  }

  if (!response) {
    return false;
  }

  const hasOptimisticMessage = response.conversationMessages.edges.some(
    edge => edge.node.id === OPTIMISTIC_MESSAGE_ID
  );

  if (hasOptimisticMessage) {
    return false;
  }

  const alreadyExists = response.conversationMessages.edges.some(
    edge => edge.node.id === messageId
  );

  return !alreadyExists;
};

export const getUnreadFromSortedMessageEdges = (
  sortedMessageEdges: ConversationMessages_conversationMessages_edges[],
  lastReadAtAsSecondsTimestamp: string | null,
  currentUserId: string | undefined
): number => {
  let unreadMessages = 0;
  const lastReadAtAsSeconds = Number(lastReadAtAsSecondsTimestamp);

  for (let i = 0; i < sortedMessageEdges.length; i++) {
    const message = sortedMessageEdges[i].node;
    const createdAtAsSeconds = Number(message.createdAt);

    if (lastReadAtAsSeconds < createdAtAsSeconds) {
      if (
        !isMessageFromSystem(message) &&
        currentUserId !== message.author.id
      ) {
        unreadMessages++;
      }
    } else {
      break;
    }
  }

  return unreadMessages;
};

export const addNewMessageToCache = (
  cache: DataProxy,
  newMessage: MessagingConversationMessage,
  conversationId: string,
  currentUserId: string | undefined,
  updateUnreadMessages = true
): void => {
  let response: ConversationMessages | null = null;

  try {
    cache.readQuery<ConversationMessages, ConversationMessagesVariables>({
      query: CONVERSATION_MESSAGES_QUERY,
      variables: {
        conversationId
      }
    });
  } catch {
    return;
  }

  try {
    response = addNodesToListInCache<
      ConversationMessages,
      "conversationMessages",
      ConversationMessagesVariables
    >({
      query: CONVERSATION_MESSAGES_QUERY,
      queryKey: CONVERSATION_MESSAGES_QUERY_KEY,
      queryVariables: {
        conversationId
      },
      store: cache,
      nodes: newMessage
    });
  } catch (error) {
    console.error(error);
    response = null;
  }

  try {
    updateNodeInCache<
      MyConversations,
      "myConversations",
      MyConversationsVariables
    >(
      {
        query: MY_CONVERSATIONS_QUERY,
        queryKey: MY_CONVERSATIONS_QUERY_KEY,
        ids: conversationId,
        store: cache
      },
      conversation => {
        let unreadMessages: number;

        if (!isMessageFromSystem(newMessage) && updateUnreadMessages) {
          const unreadMessagesDiff =
            newMessage.author.id === currentUserId ? 0 : 1;
          unreadMessages = response
            ? getUnreadFromSortedMessageEdges(
                response.conversationMessages.edges,
                conversation.read.lastReadAt,
                currentUserId
              )
            : conversation.read.unreadMessages + unreadMessagesDiff;
        } else {
          unreadMessages = conversation.read.unreadMessages;
        }

        return {
          ...conversation,
          lastMessage: isMessageFromSystem(newMessage)
            ? conversation.lastMessage
            : {
                ...newMessage
              },
          read: {
            ...conversation.read,
            unreadMessages
          }
        };
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateMessageInCache = (
  cache: DataProxy,
  updatedMessage: MessagingConversationMessage
): void => {
  // TODO: cleanup and optimize code below
  if (!isMessageFromSystem(updatedMessage)) {
    const {
      id,
      attachments,
      author,
      createdAt,
      reactions,
      updatedAt,
      richText
    } = updatedMessage;

    const commonFields = {
      id,
      attachments,
      author,
      createdAt,
      reactions,
      updatedAt,
      richText
    };

    const simpleMessageCacheId = getSimpleMessageCacheId(updatedMessage.id);

    if (simpleMessageCacheId) {
      const simpleMessage: MessagingConversationSimpleMessage = {
        __typename: MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME,
        ...commonFields
      };

      try {
        cache.writeFragment({
          id: simpleMessageCacheId,
          fragment: CONVERSATION_MESSAGE_FRAGMENT,
          fragmentName: CONVERSATION_MESSAGE_FRAGMENT_NAME,
          data: simpleMessage
        });
      } catch (error) {
        console.error(error);
      }
    }

    const messageWithQuoteCacheId = getMessageWithQuoteCacheId(
      updatedMessage.id
    );

    if (messageWithQuoteCacheId) {
      const response =
        cache.readFragment<MessagingConversationMessageWithQuote>({
          id: messageWithQuoteCacheId,
          fragment: CONVERSATION_MESSAGE_FRAGMENT,
          fragmentName: CONVERSATION_MESSAGE_FRAGMENT_NAME
        });
      const conversationWithQuote = response;

      if (!conversationWithQuote || !conversationWithQuote.id) {
        return;
      }

      const quote =
        updatedMessage.__typename ===
        MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME
          ? updatedMessage.quote
          : conversationWithQuote.quote;
      const quoteType =
        updatedMessage.__typename ===
        MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME
          ? updatedMessage.quoteType
          : conversationWithQuote.quoteType;

      const messageWithQuote: MessagingConversationMessageWithQuote = {
        __typename: MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME,
        ...commonFields,
        quote,
        quoteType
      };

      try {
        cache.writeFragment({
          id: messageWithQuoteCacheId,
          fragment: CONVERSATION_MESSAGE_FRAGMENT,
          fragmentName: CONVERSATION_MESSAGE_FRAGMENT_NAME,
          data: messageWithQuote
        });
      } catch (error) {
        console.error(error);
      }
    }
  } else {
    // handle system messages
    const systemMessageCacheId = defaultDataIdFromObject({
      id: updatedMessage.id,
      __typename: updatedMessage.__typename
    });

    if (systemMessageCacheId) {
      try {
        cache.writeFragment({
          id: systemMessageCacheId,
          fragment: CONVERSATION_MESSAGE_FRAGMENT,
          fragmentName: CONVERSATION_MESSAGE_FRAGMENT_NAME,
          data: updatedMessage
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};

export const deleteMessageFromCache = (
  cache: DataProxy,
  messageId: string,
  conversationId: string,
  currentUserId: string | undefined
): void => {
  deleteNodesFromListInCache<
    ConversationMessages,
    "conversationMessages",
    ConversationMessagesVariables
  >({
    query: CONVERSATION_MESSAGES_QUERY,
    queryKey: CONVERSATION_MESSAGES_QUERY_KEY,
    queryVariables: {
      conversationId
    },
    store: cache,
    ids: messageId
  });

  const conversationCacheId = defaultDataIdFromObject({
    __typename: MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME,
    id: conversationId
  });

  if (!conversationCacheId) {
    return;
  }

  const lastMessageResponse = cache.readFragment<{
    lastMessage: MessagingConversationMessage | null;
    read: MyConversation_OneToOneConversation_read;
  }>({
    id: conversationCacheId,
    fragment: CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT,
    fragmentName: CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT_NAME
  });

  if (!lastMessageResponse) {
    return;
  }

  const lastMessage = lastMessageResponse.lastMessage;

  if (!lastMessage || lastMessage.id !== messageId) {
    return;
  }

  let conversationMessagesResponse: ConversationMessages | null;

  try {
    conversationMessagesResponse = cache.readQuery<
      ConversationMessages,
      ConversationMessagesVariables
    >({
      query: CONVERSATION_MESSAGES_QUERY,
      variables: {
        conversationId
      }
    });
  } catch {
    conversationMessagesResponse = null;
  }

  const messages = conversationMessagesResponse?.conversationMessages.edges;
  const lastReadAt = lastMessageResponse.read.lastReadAt;
  const unreadMessages = messages
    ? getUnreadFromSortedMessageEdges(messages, lastReadAt, currentUserId)
    : lastMessageResponse.read.unreadMessages;
  const newLastMessage = messages?.length ? messages[0].node : null;

  cache.writeFragment({
    id: conversationCacheId,
    fragment: CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT,
    fragmentName: CONVERSATION_LAST_MESSAGE_AND_READ_FRAGMENT_NAME,
    data: {
      lastMessage: newLastMessage,
      read: {
        ...lastMessageResponse.read,
        unreadMessages
      }
    }
  });
};
