import {
  ApolloClient,
  DataProxy,
  defaultDataIdFromObject
} from "@apollo/client";

import {
  MESSAGING_GROUP_CONVERSATION_TYPENAME,
  MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME
} from "~/components/messaging/constants";
import {
  MessagingConversation,
  MessagingConversationGroup,
  MessagingConversationOneToOne
} from "~/components/messaging/declarations/conversations";
import {
  CONVERSATION_QUERY,
  CONVERSATION_REFERENCE_FRAGMENT,
  MY_CONVERSATIONS_QUERY,
  MY_CONVERSATIONS_QUERY_KEY
} from "~/components/messaging/graphql/conversations";
import { mapDetailedToSimpleConversation } from "~/components/messaging/utils/adapters";
import { MILLISECONDS_IN_SECOND } from "~/constants/date";
import { APOLLO_PAGINATION_CLIENT_CURSOR } from "~/constants/pagination";
import {
  Conversation,
  ConversationVariables
} from "~/declarations/apollo/Conversation";
import {
  GroupParticipantRole,
  PersonFollowingStatus
} from "~/declarations/apollo/globalTypes";
import {
  MyConversations,
  MyConversationsVariables
} from "~/declarations/apollo/MyConversations";
import {
  deleteNodesFromListInCache,
  updateNodeInCache
} from "~/utils/apollo/pagination";

export const addConversationToMyConversations = (
  cache: DataProxy,
  conversation: MessagingConversation
): void => {
  let myConversationsData: MyConversations | null = null;

  try {
    myConversationsData = cache.readQuery<
      MyConversations,
      MyConversationsVariables
    >({
      query: MY_CONVERSATIONS_QUERY
    });
  } catch {}

  if (!myConversationsData) {
    myConversationsData = {
      myConversations: {
        edges: [
          {
            cursor: APOLLO_PAGINATION_CLIENT_CURSOR,
            node: conversation
          }
        ],
        pageInfo: {
          endCursor: "",
          hasNextPage: true
        },
        totalCount: 1
      }
    };
  } else {
    const existingConversations = myConversationsData.myConversations;
    const firstUnpinnedConversationIndex =
      existingConversations.edges.findIndex(
        conversation => !conversation.node.pinned
      );
    const indexToInsert =
      firstUnpinnedConversationIndex > 0 ? firstUnpinnedConversationIndex : 0;

    myConversationsData = {
      ...myConversationsData,
      myConversations: {
        ...existingConversations,
        edges: [
          ...existingConversations.edges.slice(0, indexToInsert),
          {
            cursor: APOLLO_PAGINATION_CLIENT_CURSOR,
            node: conversation
          },
          ...existingConversations.edges.slice(indexToInsert)
        ],
        totalCount: existingConversations.totalCount + 1
      }
    };
  }

  cache.writeQuery<MyConversations, MyConversationsVariables>({
    query: MY_CONVERSATIONS_QUERY,
    variables: {},
    data: myConversationsData
  });
};

export const fetchAndAddConversationToMyConversations = async (
  apolloClient: ApolloClient<unknown>,
  conversationId: string
): Promise<boolean> => {
  try {
    const response = await apolloClient.query<
      Conversation,
      ConversationVariables
    >({
      query: CONVERSATION_QUERY,
      variables: {
        conversationId
      },
      fetchPolicy: "network-only"
    });

    const fetchedConversation = response.data?.conversation;

    if (!fetchedConversation) {
      return false;
    }

    addConversationToMyConversations(
      apolloClient,
      mapDetailedToSimpleConversation(fetchedConversation)
    );

    return true;
  } catch (error) {
    console.error(error);

    return false;
  }
};

export const makeConversationReadInCache = (
  apollo: DataProxy,
  conversationId: string,
  currentUserId: string,
  readerId: string,
  lastReadAt: string | null
): void => {
  try {
    updateNodeInCache<
      MyConversations,
      "myConversations",
      MyConversationsVariables
    >(
      {
        store: apollo,
        query: MY_CONVERSATIONS_QUERY,
        queryKey: MY_CONVERSATIONS_QUERY_KEY,
        ids: conversationId
      },
      node => {
        const read: MessagingConversationOneToOne["read"] =
          currentUserId === readerId
            ? {
                lastReadAt,
                unreadMessages: 0
              }
            : node.read;
        const participantRead: MessagingConversationOneToOne["participantRead"] =
          node.__typename === MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME &&
          currentUserId === readerId
            ? node.participantRead
            : {
                lastReadAt
              };

        return {
          ...node,
          read,
          participantRead
        };
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const makeAllConversationsRead = (
  apollo: DataProxy,
  userId: string
): void => {
  const conversationResponse = apollo.readQuery<
    MyConversations,
    MyConversationsVariables
  >({
    query: MY_CONVERSATIONS_QUERY
  });

  if (!conversationResponse) {
    return;
  }

  const currentDate = String(Number(new Date()) / MILLISECONDS_IN_SECOND);
  conversationResponse.myConversations.edges.forEach(edge => {
    const conversation = edge.node;
    if (conversation.read.unreadMessages > 0) {
      makeConversationReadInCache(
        apollo,
        conversation.id,
        userId,
        userId,
        currentDate
      );
    }
  });
};

export const updateGroupConversationTopicInCache = (
  apollo: DataProxy,
  conversationId: string,
  topic: string | null
): void => {
  try {
    updateNodeInCache<
      MyConversations,
      "myConversations",
      MyConversationsVariables
    >(
      {
        store: apollo,
        query: MY_CONVERSATIONS_QUERY,
        queryKey: MY_CONVERSATIONS_QUERY_KEY,
        ids: conversationId
      },
      node => ({
        ...node,
        topic
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateGroupConversationPictureInCache = (
  apollo: DataProxy,
  conversationId: string,
  picture?: MessagingConversationGroup["picture"]
): void => {
  if (!picture) {
    return;
  }

  try {
    updateNodeInCache<
      MyConversations,
      "myConversations",
      MyConversationsVariables
    >(
      {
        store: apollo,
        query: MY_CONVERSATIONS_QUERY,
        queryKey: MY_CONVERSATIONS_QUERY_KEY,
        ids: conversationId
      },
      node => ({
        ...node,
        picture
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateReferenceRoleInConversationsCache = ({
  store,
  conversationId,
  role
}: {
  store: DataProxy;
  conversationId: string;
  role: GroupParticipantRole;
}): void => {
  updateNodeInCache<
    MyConversations,
    "myConversations",
    MyConversationsVariables
  >(
    {
      query: MY_CONVERSATIONS_QUERY,
      queryKey: "myConversations",
      queryVariables: {},
      store,
      ids: conversationId
    },
    node => {
      if (node.__typename === MESSAGING_GROUP_CONVERSATION_TYPENAME) {
        return {
          ...node,
          reference: {
            ...node.reference,
            role
          }
        };
      }

      return node;
    }
  );
};

export const deleteConversationFromMyConversations = (
  apollo: DataProxy,
  conversationId: string
): void => {
  try {
    deleteNodesFromListInCache<
      MyConversations,
      "myConversations",
      MyConversationsVariables
    >({
      store: apollo,
      query: MY_CONVERSATIONS_QUERY,
      queryKey: MY_CONVERSATIONS_QUERY_KEY,
      queryVariables: {},
      ids: conversationId
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateConversationCanMessage = (
  apollo: DataProxy,
  conversationId: string,
  canMessage: boolean
): void => {
  try {
    const conversationCacheId = defaultDataIdFromObject({
      id: conversationId,
      __typename: MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME
    });

    const response = apollo.readFragment<{
      reference: MessagingConversation["reference"];
    }>({
      id: conversationCacheId,
      fragment: CONVERSATION_REFERENCE_FRAGMENT
    });

    apollo.writeFragment({
      id: conversationCacheId,
      fragment: CONVERSATION_REFERENCE_FRAGMENT,
      data: {
        ...response,
        reference: {
          ...response?.reference,
          canMessage
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateConversationParticipantBlocked = ({
  store,
  conversationId,
  personFollowingStatus
}: {
  store: DataProxy;
  conversationId: string;
  personFollowingStatus: PersonFollowingStatus;
}): void => {
  updateNodeInCache<
    MyConversations,
    "myConversations",
    MyConversationsVariables
  >(
    {
      query: MY_CONVERSATIONS_QUERY,
      queryKey: "myConversations",
      queryVariables: {},
      store,
      ids: conversationId
    },
    node => {
      if (node.__typename === MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME) {
        return {
          ...node,
          participant: {
            ...node.participant,
            followingReference: {
              me: personFollowingStatus
            }
          }
        };
      }

      return node;
    }
  );
};
