import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { POST_TYPE_TO_TYPENAME } from "~/constants/posts";
import { PostStatus } from "~/declarations/apollo/globalTypes";
import {
  useRealTimePostPublished,
  UseRealTimePostPublishedProps
} from "~/utils/useRealTimePostPublished";

import {
  updatePostStatusInApolloCache,
  updateVideoAttachmentInApolloCache
} from "./utils/publishingPost";

export default function usePostPublishedStatusRealTimeUpdateAfter(
  currentUserId?: string
): void {
  const apolloClient = useApolloClient();

  const handlePostPublished: UseRealTimePostPublishedProps["onPostPublished"] =
    useCallback(
      ({ postId, postType, videos }): void => {
        if (videos) {
          videos.forEach(video => {
            updateVideoAttachmentInApolloCache(apolloClient, video);
          });
        }

        updatePostStatusInApolloCache(
          apolloClient,
          postId,
          POST_TYPE_TO_TYPENAME[postType],
          PostStatus.published
        );
      },
      [apolloClient]
    );

  useRealTimePostPublished({
    currentUserId,
    onPostPublished: handlePostPublished
  });
}
