import { ApolloLink, FetchResult, Observable, Operation } from "@apollo/client";
import { print } from "graphql";

import {
  createRestartableClient,
  RestartableClient,
  RestartableClientOptions
} from "~/components/providers/ApolloProvider/restartableClient";

export class WebSocketLink extends ApolloLink {
  client: RestartableClient;

  constructor(options: RestartableClientOptions) {
    super();
    this.client = createRestartableClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink =>
      this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink)
        }
      )
    );
  }
}
