/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "Divider-1bc474f2--sprite",
  "use": "Divider-1bc474f2--sprite-usage",
  "viewBox": "0 0 6 6",
  "content": "<symbol fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 6 6\" id=\"Divider-1bc474f2--sprite\"><circle cx=\"3\" cy=\"3\" r=\"2.5\" fill=\"#171717\" stroke=\"url(#Divider-1bc474f2--sprite_a)\" /><defs><linearGradient id=\"Divider-1bc474f2--sprite_a\" x1=\"1.475\" y1=\"-2.415\" x2=\"6.806\" y2=\"-.592\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#46FEDC\" /><stop offset=\".512\" stop-color=\"#EAFFCD\" /><stop offset=\"1\" stop-color=\"#F3D4FE\" /></linearGradient></defs></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
