
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
// TODO: investigate why deleting this line with empty import breaks SSR
import "./_app.css";

import { ApolloProvider } from "@apollo/client";
import { AppProps } from "next/app";
import { NextQueryParamProvider } from "next-query-params";
import { Fragment, useCallback, useEffect } from "react";

import PageLoadingIndicator from "~/components/core/PageLoadingIndicator";
import AccountLayout from "~/components/layouts/AccountLayout/AccountLayout";
import { ApolloSubscriptionsConnectionEventsContext } from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/ApolloSubscriptionsConnectionEventsContext";
import { APOLLO_SUBSCRIPTION_CONNECTION_EVENT } from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/declarations";
import useApolloSubscriptionsConnectionEvents from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/useApolloSubscriptionsConnectionEvents";
import CommonMeta from "~/components/meta/CommonMeta";
import AnalyticsProvider from "~/components/providers/AnalyticsProvider";
import { useApollo } from "~/components/providers/ApolloProvider/apolloClient";
import AppStylesProvider from "~/components/providers/AppStylesProvider";
import CustomSnackbarProvider from "~/components/providers/CustomSnackbarProvider";
import GlobalContextProvider from "~/components/providers/GlobalProvider/GlobalContextProvider";
import ProblemReportDialogProvider from "~/components/providers/ProblemReportDialogProvider";
import ReCaptchaProvider from "~/components/providers/ReCaptchaProvider";
import { LAYOUT_SIZE } from "~/constants/theme";
import { PageWithLayout } from "~/declarations/next";
import Fonts from "~/theme/Fonts";
import GlobalStyle from "~/theme/GlobalStyle";
import useSetVhUnit from "~/utils/styles/useSetVhUnit";

const removeServerSideGeneratedCSS = (): void => {
  const jssStyles = document.querySelector("#jss-server-side");
  if (jssStyles && jssStyles.parentElement) {
    jssStyles.parentElement.removeChild(jssStyles);
  }
};

const App = ({
  Component,
  pageProps,
  err // Workaround for https://github.com/vercel/next.js/issues/8592
}: AppProps & { err: unknown }): JSX.Element => {
  const {
    apolloSubscriptionsConnectionEventsContext,
    notifyApolloSubscriptionsConnectionEventsHandlers
  } = useApolloSubscriptionsConnectionEvents();

  const handleApolloSubscriptionsReconnected = useCallback(() => {
    notifyApolloSubscriptionsConnectionEventsHandlers(
      false,
      APOLLO_SUBSCRIPTION_CONNECTION_EVENT.reconnection
    );
  }, [notifyApolloSubscriptionsConnectionEventsHandlers]);

  const handleApolloSubscriptionsDisconnected = useCallback(() => {
    notifyApolloSubscriptionsConnectionEventsHandlers(
      false,
      APOLLO_SUBSCRIPTION_CONNECTION_EVENT.disconnection
    );
  }, [notifyApolloSubscriptionsConnectionEventsHandlers]);

  const apolloClient = useApollo({
    initialState: pageProps.initialApolloState,
    onSubscriptionReconnected: handleApolloSubscriptionsReconnected,
    onSubscriptionDisconnected: handleApolloSubscriptionsDisconnected
  });

  useEffect(() => {
    removeServerSideGeneratedCSS();
  }, []);

  useSetVhUnit();

  const Layout = (Component as PageWithLayout).layout || AccountLayout;
  const SubLayout = (Component as PageWithLayout).subLayout || Fragment;
  const layoutSize = (Component as PageWithLayout).size || LAYOUT_SIZE.full;
  const hideHeader = (Component as PageWithLayout)
    .hideHeaderForUnauthorizedUser;

  return (
    <NextQueryParamProvider>
      <Fonts />
      <AnalyticsProvider>
        <GlobalContextProvider>
          <AppStylesProvider>
            <GlobalStyle />
            <CommonMeta />
            <PageLoadingIndicator />
            <ApolloProvider client={apolloClient}>
              <ApolloSubscriptionsConnectionEventsContext.Provider
                value={apolloSubscriptionsConnectionEventsContext}
              >
                <CustomSnackbarProvider>
                  <ReCaptchaProvider
                    reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                    useEnterprise={
                      process.env.NEXT_PUBLIC_RECAPTCHA_ENTERPRISE_ENABLED ===
                      "true"
                    }
                    useReCaptchaNet={
                      process.env.NEXT_PUBLIC_RECAPTCHA_NET_ENABLED === "true"
                    }
                    scriptProps={{
                      defer: true
                    }}
                  >
                    <ProblemReportDialogProvider>
                      <Layout
                        size={layoutSize}
                        hideHeaderForUnauthorizedUser={hideHeader}
                      >
                        <SubLayout>
                          <Component {...pageProps} err={err} />
                        </SubLayout>
                      </Layout>
                    </ProblemReportDialogProvider>
                  </ReCaptchaProvider>
                </CustomSnackbarProvider>
              </ApolloSubscriptionsConnectionEventsContext.Provider>
            </ApolloProvider>
          </AppStylesProvider>
        </GlobalContextProvider>
      </AnalyticsProvider>
    </NextQueryParamProvider>
  );
};

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  