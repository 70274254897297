import Head from "next/head";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useTheme } from "styled-components";

import { getMetaLocaleByNextLocale } from "~/components/meta/constants";
import AppleTouchIcon from "~/public/assets/meta/apple-touch-icon.png";
import FaviconIco from "~/public/assets/meta/favicon.ico";
import Favicon16 from "~/public/assets/meta/favicon-16x16.png";
import Favicon32 from "~/public/assets/meta/favicon-32x32.png";
import PinnedTabIcon from "~/public/assets/meta/safari-pinned-tab.svg?url";

const HOST_URL = process.env.NEXT_PUBLIC_HOST_URL;

const CommonMeta = (): JSX.Element => {
  const { pathname, asPath, locale } = useRouter();
  const { t } = useTranslation("routes");
  const theme = useTheme();

  const absoluteUrl = HOST_URL + asPath;
  const metaLocale = getMetaLocaleByNextLocale(locale);

  return (
    <Head>
      <title>
        {t(`routes:${pathname}`)}
        {t("common:title-splitter")}
        {t("common:app-name")}
      </title>
      <meta name="description" content={t("meta:common_description")} />
      <meta name="HandheldFriendly" content="true" />
      <meta name="robots" content="noodp,noydir" />
      <meta name="google" content="notranslate" />
      <meta name="og:locale" content={metaLocale} />
      <meta name="og:url" content={absoluteUrl} />
      <meta
        name="google-site-verification"
        content="ekOXju9XVH9cKLfWMvG7RdiK1WdiuZb5QhiG0ii1Mm4"
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="format-detection" content="address=no" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
      <link rel="manifest" href="/manifest.json" />
      <link rel="shortcut icon" href={FaviconIco} />
      <link
        rel="mask-icon"
        href={PinnedTabIcon}
        color={theme.newTheme.background.octonary}
      />
      <meta
        name="msapplication-TileColor"
        content={theme.newTheme.background.octonary}
      />
      <meta
        name="msapplication-config"
        content="/assets/meta/browserconfig.xml"
      />
      <meta name="theme-color" content={theme.newTheme.background.octonary} />
    </Head>
  );
};

export default CommonMeta;
