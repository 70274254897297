import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";

export const ME_PREFERRED_CATEGORY = gql`
  fragment MePreferredCategory on LocalizedEnabledCategory {
    id
    __typename
    nameI18N
    background {
      blurredUrl
      thumbnails {
        s295 {
          url
        }
      }
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me: meAsPerson {
      __typename
      id
      email
      hasPassword
      name
      nickname
      status
      confidentiality
      # TODO: get rid of this in ME_QUERY. Use only in ProfileSettings
      aboutRichText {
        ...richText
      }
      # TODO: get rid of this in ME_QUERY. Use only in ProfileSettings
      locationInfo {
        id
        name
      }
      # TODO: get rid of this in ME_QUERY. Use only in ProfileSettings
      birthday
      stats {
        following
        unreadNotifications
        unreadConversations
      }
      avatar {
        little
        middle
        big
      }
      # TODO: get rid of this in ME_QUERY. Load asynchronously when needed
      preferredCategories {
        ...MePreferredCategory
      }
      validation
      isFounder
      isCharity
      nftSettings {
        canCreateNft
        # TODO: get rid fields below in ME_QUERY. Use only in ProfileSettings
        ownedNftsAreHidden
        createdNftsAreHidden
      }
    }
  }

  ${ME_PREFERRED_CATEGORY}
  ${RICH_TEXT_FRAGMENT}
`;

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation ConfirmEmailMutation($token: String!) {
    confirmEmail(token: $token)
  }
`;
