import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const XDB_NFT_EXPORT_ADDRESS_INPUT_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.xdbNftExportAddressInput> =
  {
    Component: safeDynamicImport(
      () => import("~/components/xdb/export/XdbNftExportAddressInputDialog"),
      { ssr: false }
    )
  };
