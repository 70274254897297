import { ReactNode, useState } from "react";

import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

import { SearchPopupContext } from "./SearchPopupContext";

type Props = {
  children: ReactNode;
};

const AccountHeaderSearchPopup = safeDynamicImport(
  () =>
    import(
      "~/components/layouts/AccountLayout/AccountHeader/AccountHeaderSearchPopup"
    ),
  {
    ssr: false
  }
);

const SearchPopupProvider = ({ children }: Props): JSX.Element => {
  const searchPopup = useDialog<HTMLElement>();
  const [searchText, setSearchText] = useState("");
  const [appliedSearchText, setAppliedSearchText] = useState("");

  return (
    <SearchPopupContext.Provider
      value={{
        searchPopup,
        searchText,
        setSearchText,
        appliedSearchText,
        setAppliedSearchText
      }}
    >
      {children}
      {searchPopup.hasBeenOpened && (
        <AccountHeaderSearchPopup
          open={searchPopup.open}
          onClose={searchPopup.closeDialog}
          anchorElement={searchPopup.payload}
          appliedSearchText={appliedSearchText}
          searchText={searchText}
          setAppliedSearchText={setAppliedSearchText}
          setSearchText={setSearchText}
        />
      )}
    </SearchPopupContext.Provider>
  );
};

export default SearchPopupProvider;
