import { useApolloClient } from "@apollo/client/react/hooks";
import debounce from "lodash.debounce";
import { NextRouter, useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";

import { NFT_EDITION_OWNERSHIP_QUERY } from "~/components/nft/NftAsset/NftAssetInfo/NftAssetInfoTabs/types/NftAssetTabOwnership/graphql";
import { SpecificPurchaseDialogOptions } from "~/components/payment/PurchaseDialog/declarations";
import { SpecificTipPostDialogOptions } from "~/components/payment/TipPostDialog/declarations";
import { useAnalyticsContext } from "~/components/providers/AnalyticsProvider/AnalyticsContext";
import { ANALYTICS_EVENT } from "~/components/providers/AnalyticsProvider/constants";
import { SendEventCallback } from "~/components/providers/AnalyticsProvider/declarations";
import {
  COMMON_DIALOG_CLOSE_REASON,
  COMMON_DIALOG_STATUS,
  DIALOG_NAME
} from "~/components/providers/DialogProvider/declarations/common";
import { OpenDialogHandler } from "~/components/providers/DialogProvider/declarations/context";
import { DialogResult } from "~/components/providers/DialogProvider/declarations/results";
import { useDialogContext } from "~/components/providers/DialogProvider/DialogProviderContext";
import { PAYMENT_OPERATION } from "~/components/wallet/constants";
import {
  GET_WALLET_STATUS_QUERY,
  LAST_TRANSACTIONS_QUERY,
  MY_WALLET_QUERY
} from "~/components/wallet/graphql";
import useBuyCoinsResult from "~/components/wallet/providers/WalletProvider/useBuyCoinsResult";
import useBankAccountSetupResult from "~/components/wallet/Wallet/payout/useBankAccountSetupResult";
import { WALLET_CONFIRMATION_RECEIVER_HAS_NOT_WALLET_TYPE } from "~/components/wallet/WalletConfirmationDialog/constants";
import { WALLET_CONFIRMATION_VARIANT } from "~/components/wallet/WalletConfirmationDialog/declarations";
import { WalletCreateKeyDialogSuccessResult } from "~/components/wallet/WalletCreateKeyDialog/declarations";
import { WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE } from "~/components/wallet/WalletSynchronizationErrorDialog/description";
import { WALLET_SYNCHRONIZATION_METHOD } from "~/components/wallet/WalletSynchronizationMethodSelectorDialog/declarations";
import { WalletSynchronizationMnemonicInputDialogSuccessResult } from "~/components/wallet/WalletSynchronizationMnemonicInputDialog/declarations";
import { WalletSynchronizationPinDialogSuccessResult } from "~/components/wallet/WalletSynchronizationPinDialog/declarations";
import ROUTES from "~/constants/routes";
import {
  GetWalletStatus,
  GetWalletStatusVariables
} from "~/declarations/apollo/GetWalletStatus";
import { WalletStatus } from "~/declarations/apollo/globalTypes";
import { Me_me } from "~/declarations/apollo/Me";
import { MyWallet, MyWallet_myWallet } from "~/declarations/apollo/MyWallet";
import { WalletEvents_myWalletEvents_events_EventTransactionConfirmed_nft } from "~/declarations/apollo/WalletEvents";
import { isNotNull } from "~/utils/common";
import { getUserIdsFromEnv } from "~/utils/environmentVariables";
import { useApolloErrorSnackbar } from "~/utils/errors";
import { getWalletDecryptionRecordFromLocalStore } from "~/utils/wallet/localStorage";

import {
  RunAfterWalletAvailableGuard,
  RunAfterWalletAvailableGuardPayloadSuccessHandler,
  StartCreateWalletFlowResolveData,
  StartCreateWalletKeyFlowResolveData,
  WALLET_DIALOG_CALL_REASON,
  WalletBuyCoinsDialogPayload,
  WalletBuyCoinsDialogPayloadPost,
  WarnAboutWalletKeyDropHandler
} from "./declarations";
import useProgressOfTransactions from "./useProgressOfTransactions";
import useRealTimeTransactionsUpdate from "./useRealTimeTransactionsUpdate";
import { WalletContext } from "./WalletContext";

export interface WalletNotEnoughFundsPayload {
  operation: PAYMENT_OPERATION;
  post?: WalletBuyCoinsDialogPayloadPost;
}

export interface Props {
  currentUser?: Me_me;
  children: React.ReactNode;
}

type StartWalletKeyCreateFlowResult = {
  status: COMMON_DIALOG_STATUS;
  newWalletHasBeenCreated: boolean;
};

type StartWalletCreateFlowResult = {
  status: COMMON_DIALOG_STATUS;
  newWalletHasBeenCreated: boolean;
};

export interface RunAfterWalletSetOptions {
  reason: WALLET_DIALOG_CALL_REASON;
  userId: string;
  ignoreWalletKeyCheck?: boolean;
  onSuccess: RunAfterWalletAvailableGuardPayloadSuccessHandler;
}

const UPDATE_RECENT_TRANSACTIONS_DEBOUNCE = 500;

type StartNoWalletKeyFlowHandler = (
  reason: WALLET_DIALOG_CALL_REASON
) => Promise<StartCreateWalletKeyFlowResolveData>;

type StartCreateNewWalletFlowHandler = (
  reason: WALLET_DIALOG_CALL_REASON,
  redirectUrlAfterBuyingCoins?: string
) => Promise<StartCreateWalletFlowResolveData>;

type StartNoWalletFlowProps = {
  reason: WALLET_DIALOG_CALL_REASON;
  router: NextRouter;
  sendEvent: SendEventCallback;
  openDialog: OpenDialogHandler;
  startNoWalletKeyFlow: StartNoWalletKeyFlowHandler;
  startCreateNewWalletFlow: StartCreateNewWalletFlowHandler;
  redirectUrlAfterBuyingCoins?: string;
};

type StartNoWalletFlowHandler = (
  props: StartNoWalletFlowProps
) => Promise<StartCreateWalletFlowResolveData>;

type StartWalletKeyCreateFlowProps = {
  reason: WALLET_DIALOG_CALL_REASON;
  router: NextRouter;
  openDialog: OpenDialogHandler;
  noWalletFlow: StartNoWalletFlowHandler;
  sendEvent: SendEventCallback;
  startNoWalletKeyFlow: StartNoWalletKeyFlowHandler;
  startCreateNewWalletFlow: StartCreateNewWalletFlowHandler;
};

type StartCreateWalletByMnemonicFlowProps = {
  reason: WALLET_DIALOG_CALL_REASON;
  router: NextRouter;
  openDialog: OpenDialogHandler;
  startNoWalletKeyFlow: StartNoWalletKeyFlowHandler;
};

const startWalletKeyCreateFlow = async ({
  openDialog,
  reason,
  router,
  noWalletFlow,
  sendEvent,
  startCreateNewWalletFlow,
  startNoWalletKeyFlow
}: StartWalletKeyCreateFlowProps): Promise<StartWalletKeyCreateFlowResult> => {
  const warningResult = await openDialog(
    DIALOG_NAME.walletSynchronizationWarning
  );

  if (warningResult.status !== COMMON_DIALOG_STATUS.success) {
    return {
      status: warningResult.status,
      newWalletHasBeenCreated: false
    };
  }

  while (1) {
    const createWalletKeyResult = await openDialog(DIALOG_NAME.createWalletKey);

    if (createWalletKeyResult.status !== COMMON_DIALOG_STATUS.success) {
      return {
        status: createWalletKeyResult.status,
        newWalletHasBeenCreated: false
      };
    }

    if (
      createWalletKeyResult.data.reason ===
      WalletCreateKeyDialogSuccessResult.keyCreated
    ) {
      break;
    }

    if (
      createWalletKeyResult.data.reason ===
      WalletCreateKeyDialogSuccessResult.fatalError
    ) {
      await openDialog(DIALOG_NAME.walletSynchronizationError, {
        description: createWalletKeyResult.data.message,
        type: WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.fatal
      });

      return {
        status: COMMON_DIALOG_STATUS.closed,
        newWalletHasBeenCreated: false
      };
    }

    if (
      createWalletKeyResult.data.reason ===
      WalletCreateKeyDialogSuccessResult.walletNotExists
    ) {
      const noWalletFlowResult = await noWalletFlow({
        openDialog,
        reason,
        router,
        sendEvent,
        startCreateNewWalletFlow,
        startNoWalletKeyFlow
      });

      return noWalletFlowResult;
    }

    if (
      createWalletKeyResult.data.reason ===
        WalletCreateKeyDialogSuccessResult.errorWithRetry ||
      createWalletKeyResult.data.reason ===
        WalletCreateKeyDialogSuccessResult.walletKeyNotExists
    ) {
      const { status: errorDialogStatus } = await openDialog(
        DIALOG_NAME.walletSynchronizationError,
        {
          description: createWalletKeyResult.data.message,
          type: WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.general
        }
      );

      if (errorDialogStatus !== COMMON_DIALOG_STATUS.success) {
        return {
          status: COMMON_DIALOG_STATUS.closed,
          newWalletHasBeenCreated: false
        };
      }
    }
  }

  const successDialogResult = await openDialog(
    DIALOG_NAME.walletSynchronizationSuccess
  );

  if (
    router.pathname !== ROUTES.wallet.index &&
    successDialogResult.status === COMMON_DIALOG_STATUS.closed &&
    successDialogResult.closeReason === COMMON_DIALOG_CLOSE_REASON.cancelButton
  ) {
    router.push(ROUTES.wallet.index);
  }

  return {
    status: COMMON_DIALOG_STATUS.success,
    newWalletHasBeenCreated: false
  };
};

const startCreateWalletByMnemonicFlow = async ({
  reason,
  openDialog,
  router,
  startNoWalletKeyFlow
}: StartCreateWalletByMnemonicFlowProps): Promise<StartWalletCreateFlowResult> => {
  let mnemonic: string | undefined = undefined;
  let retryMnemonicInput;

  do {
    retryMnemonicInput = true;

    while (1) {
      const walletMnemonicInputDialogResult = await openDialog(
        DIALOG_NAME.walletSynchronizationMnemonicInput
      );

      if (
        walletMnemonicInputDialogResult.status !== COMMON_DIALOG_STATUS.success
      ) {
        return {
          status: walletMnemonicInputDialogResult.status,
          newWalletHasBeenCreated: false
        };
      }

      if (
        walletMnemonicInputDialogResult.data.reason ===
        WalletSynchronizationMnemonicInputDialogSuccessResult.mnemonicChecked
      ) {
        mnemonic = walletMnemonicInputDialogResult.data.mnemonic;
        break;
      }

      const fatal =
        walletMnemonicInputDialogResult.data.reason ===
        WalletSynchronizationMnemonicInputDialogSuccessResult.fatalError;

      const { status: errorDialogStatus } = await openDialog(
        DIALOG_NAME.walletSynchronizationError,
        {
          description: walletMnemonicInputDialogResult.data.message,
          type: fatal
            ? WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.fatal
            : WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.general
        }
      );

      if (fatal || errorDialogStatus !== COMMON_DIALOG_STATUS.success) {
        return {
          status: COMMON_DIALOG_STATUS.closed,
          newWalletHasBeenCreated: false
        };
      }
    }

    while (1) {
      const pinDialogResult = await openDialog(
        DIALOG_NAME.walletSynchronizationPin,
        {
          mnemonic: mnemonic as string
        }
      );

      if (pinDialogResult.status !== COMMON_DIALOG_STATUS.success) {
        return {
          status: pinDialogResult.status,
          newWalletHasBeenCreated: false
        };
      }

      if (
        pinDialogResult.data.reason ===
        WalletSynchronizationPinDialogSuccessResult.walletAlreadyExistsButKeyNotFound
      ) {
        const noWalletKeyFlowResult = await startNoWalletKeyFlow(reason);

        return noWalletKeyFlowResult;
      }

      if (
        pinDialogResult.data.reason ===
        WalletSynchronizationPinDialogSuccessResult.walletAlreadySynched
      ) {
        const { status: errorDialogStatus } = await openDialog(
          DIALOG_NAME.walletSynchronizationError,
          {
            description: pinDialogResult.data.message,
            type: WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.walletAlreadySynched
          }
        );

        if (errorDialogStatus === COMMON_DIALOG_STATUS.success) {
          router.push(ROUTES.wallet.index);
        }

        return {
          status: COMMON_DIALOG_STATUS.success,
          newWalletHasBeenCreated: true
        };
      }

      if (
        pinDialogResult.data.reason ===
        WalletSynchronizationPinDialogSuccessResult.walletCreated
      ) {
        retryMnemonicInput = false;
        break;
      }

      const fatal =
        pinDialogResult.data.reason ===
        WalletSynchronizationPinDialogSuccessResult.fatalError;

      const { status: errorDialogAction } = await openDialog(
        DIALOG_NAME.walletSynchronizationError,
        {
          description: pinDialogResult.data.message,
          type: fatal
            ? WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.fatal
            : WALLET_SYNCHRONIZATION_ERROR_DIALOG_TYPE.general
        }
      );

      if (fatal || errorDialogAction !== COMMON_DIALOG_STATUS.success) {
        return {
          status: COMMON_DIALOG_STATUS.closed,
          newWalletHasBeenCreated: false
        };
      }

      if (
        pinDialogResult.data.reason ===
          WalletSynchronizationPinDialogSuccessResult.walletKeyNotExists ||
        pinDialogResult.data.reason ===
          WalletSynchronizationPinDialogSuccessResult.mnemonicErrorWithRetry
      ) {
        break;
      }
    }
  } while (retryMnemonicInput);

  const successDialogResult = await openDialog(
    DIALOG_NAME.walletSynchronizationSuccess
  );

  if (
    successDialogResult.status === COMMON_DIALOG_STATUS.closed &&
    successDialogResult.closeReason === COMMON_DIALOG_CLOSE_REASON.cancelButton
  ) {
    router.push(ROUTES.wallet.index);
  }

  return {
    status: COMMON_DIALOG_STATUS.success,
    newWalletHasBeenCreated: true
  };
};

const noWalletFlow = async ({
  reason,
  router,
  sendEvent,
  openDialog,
  startNoWalletKeyFlow,
  startCreateNewWalletFlow,
  redirectUrlAfterBuyingCoins
}: StartNoWalletFlowProps): Promise<StartCreateWalletFlowResolveData> => {
  sendEvent({ event: ANALYTICS_EVENT.walletSetupStart });

  const walletConfirmationResult = await openDialog(
    DIALOG_NAME.walletConfirmation,
    {
      variant: WALLET_CONFIRMATION_VARIANT.noWallet,
      specific: reason
    }
  );

  if (walletConfirmationResult.status === COMMON_DIALOG_STATUS.success) {
    return startCreateNewWalletFlow(reason, redirectUrlAfterBuyingCoins);
  }

  if (
    walletConfirmationResult.status === COMMON_DIALOG_STATUS.closed &&
    walletConfirmationResult.closeReason ===
      COMMON_DIALOG_CLOSE_REASON.cancelButton
  ) {
    const startCreateWalletByMnemonicResult =
      await startCreateWalletByMnemonicFlow({
        reason,
        openDialog,
        router,
        startNoWalletKeyFlow
      });

    return {
      status: startCreateWalletByMnemonicResult.status,
      newWalletHasBeenCreated:
        startCreateWalletByMnemonicResult.newWalletHasBeenCreated
    };
  }

  return {
    status: COMMON_DIALOG_STATUS.closed,
    newWalletHasBeenCreated: false
  };
};

const WalletProvider = ({
  currentUser,
  children
}: Props): JSX.Element | null => {
  const { sendEvent } = useAnalyticsContext();
  const router = useRouter();
  const [{ wallet, fetched }, setWallet] = useState<{
    wallet: MyWallet_myWallet | null;
    fetched: boolean;
  }>({
    wallet: null,
    fetched: false
  });
  const [postPaymentInProgress, setPostPaymentInProgress] = useState(false);
  const [postTippingInProgress, setPostTippingInProgress] = useState(false);
  const [coinsBuyingInProgress, setCoinsBuyingInProgress] = useState(false);
  const [
    walletSynchronizationMethodSelectionInProgress,
    setWalletSynchronizationMethodSelectionInProgress
  ] = useState(false);

  const {
    addTransactionProgress,
    getTransactionProgress,
    handleTransactionSuccess,
    transactionInProgress,
    addTransactionDoneHandler,
    removeTransactionDoneHandler,
    hasPostTransactionInProgress,
    addPostPurchaseLinkClickHandler,
    removePostPurchaseLinkClickHandler
  } = useProgressOfTransactions();

  const apolloErrorSnackbar = useApolloErrorSnackbar();
  const apolloClient = useApolloClient();

  const { openDialog, initialized: dialogProviderInitialized } =
    useDialogContext();

  const allowedToMintNftFor1Hype = useMemo(() => {
    const userIds = getUserIdsFromEnv(
      process.env.NEXT_PUBLIC_ALLOWED_MINT_NFT_FOR_1_HYPE_USERS
    );

    return isNotNull(currentUser) && userIds.includes(currentUser.id);
  }, [currentUser]);

  const showNFTMaintenancePlaceholders = useMemo(() => {
    const userIds = getUserIdsFromEnv(
      process.env.NEXT_PUBLIC_NFT_MAINTENANCE_EXCLUDE_USERS
    );

    return (
      process.env.NEXT_PUBLIC_NFT_MAINTENANCE === "true" &&
      (!currentUser || !userIds.includes(currentUser.id))
    );
  }, [currentUser]);

  const handleUpdateWallet = useCallback(
    (updatedWallet: MyWallet_myWallet): void => {
      setWallet({
        fetched,
        wallet: updatedWallet
      });
    },
    [fetched]
  );

  const handleUpdateRecentTransactions = useMemo(
    () =>
      debounce(() => {
        apolloClient.refetchQueries({
          include: [LAST_TRANSACTIONS_QUERY]
        });
      }, UPDATE_RECENT_TRANSACTIONS_DEBOUNCE),
    [apolloClient]
  );

  const handleBuyNftEdition = useMemo(
    () =>
      debounce(
        (
          nft: WalletEvents_myWalletEvents_events_EventTransactionConfirmed_nft
        ) => {
          apolloClient.refetchQueries({
            include: [NFT_EDITION_OWNERSHIP_QUERY],
            onQueryUpdated: ({ variables }) =>
              variables && variables.editionId === nft.editionId
          });
        }
      ),
    [apolloClient]
  );

  useRealTimeTransactionsUpdate({
    onUpdateWallet: handleUpdateWallet,
    onTransactionDone: handleTransactionSuccess,
    onUpdateRecentTransactions: handleUpdateRecentTransactions,
    onBuyNftEdition: handleBuyNftEdition
  });

  const fetchWallet =
    useCallback(async (): Promise<MyWallet_myWallet | null> => {
      const response = await apolloClient.query<MyWallet>({
        query: MY_WALLET_QUERY,
        fetchPolicy: "network-only"
      });
      const walletOfCurrentUser = response.data?.myWallet || null;

      setWallet({
        wallet: walletOfCurrentUser,
        fetched: true
      });

      return walletOfCurrentUser;
    }, [apolloClient]);

  const fetchWalletStatus = useCallback(
    async (userId: string): Promise<WalletStatus | null> => {
      try {
        const walletStatus = await apolloClient.query<
          GetWalletStatus,
          GetWalletStatusVariables
        >({
          query: GET_WALLET_STATUS_QUERY,
          fetchPolicy: "network-only",
          variables: {
            personId: userId
          }
        });

        return walletStatus.data.getWalletStatus;
      } catch (error) {
        /* It is better not to block flow, because this request may fail due to a third-party error in dts. */
        console.error(error);

        return null;
      }
    },
    [apolloClient]
  );

  const startCreateNewWalletFlow: StartCreateNewWalletFlowHandler = useCallback(
    async (
      reason: WALLET_DIALOG_CALL_REASON,
      redirectUrlAfterBuyingCoins?: string
    ): Promise<StartCreateWalletFlowResolveData> => {
      const currentUserId = currentUser?.id;

      if (!currentUserId) {
        throw new Error(
          "You should provide user id to start wallet creation flow"
        );
      }

      sendEvent({ event: ANALYTICS_EVENT.walletSetupStart });

      const { status: createWalletStatus } = await openDialog(
        DIALOG_NAME.createWallet,
        {
          userId: currentUserId
        }
      );

      if (createWalletStatus !== COMMON_DIALOG_STATUS.success) {
        return {
          status: createWalletStatus,
          newWalletHasBeenCreated: false
        };
      }

      await openDialog(DIALOG_NAME.setUpWalletSuccess, {
        reason,
        redirectUrlAfterBuyingCoins
      });

      return {
        status: createWalletStatus,
        newWalletHasBeenCreated: true
      };
    },
    [currentUser?.id, openDialog, sendEvent]
  );

  const startNoWalletKeyFlow: StartNoWalletKeyFlowHandler = useCallback(
    async (
      reason: WALLET_DIALOG_CALL_REASON
    ): Promise<StartCreateWalletFlowResolveData> => {
      const walletConfirmationResult = await openDialog(
        DIALOG_NAME.walletConfirmation,
        {
          variant: WALLET_CONFIRMATION_VARIANT.noWalletOnClient,
          specific: reason
        }
      );

      if (walletConfirmationResult.status === COMMON_DIALOG_STATUS.success) {
        const { status: walletKeyCreateFlowStatus, newWalletHasBeenCreated } =
          await startWalletKeyCreateFlow({
            reason,
            openDialog,
            router,
            noWalletFlow,
            sendEvent,
            startCreateNewWalletFlow,
            startNoWalletKeyFlow
          });

        return {
          status: walletKeyCreateFlowStatus,
          newWalletHasBeenCreated
        };
      }

      if (
        walletConfirmationResult.status === COMMON_DIALOG_STATUS.closed &&
        walletConfirmationResult.closeReason ===
          COMMON_DIALOG_CLOSE_REASON.cancelButton
      ) {
        const currentUserId = currentUser?.id;

        if (!currentUserId) {
          throw new Error("WalletDropDialog requires userId.");
        }

        const { status: dropWalletStatus } = await openDialog(
          DIALOG_NAME.dropWallet
        );

        if (dropWalletStatus !== COMMON_DIALOG_STATUS.success) {
          return {
            status: dropWalletStatus,
            newWalletHasBeenCreated: false
          };
        }

        fetchWallet();

        return await startCreateNewWalletFlow(reason);
      }

      return {
        status: COMMON_DIALOG_STATUS.closed,
        newWalletHasBeenCreated: false
      };
    },
    [
      currentUser?.id,
      fetchWallet,
      sendEvent,
      openDialog,
      startCreateNewWalletFlow,
      router
    ]
  );

  const warnAboutWalletMaintenance = useCallback(() => {
    openDialog(DIALOG_NAME.walletMaintenance);
  }, [openDialog]);

  const warnAboutTransactionInProgress = useCallback(() => {
    openDialog(DIALOG_NAME.walletTransactionInProgress);
  }, [openDialog]);

  const checkWalletInMaintenence = useCallback(
    () => process.env.NEXT_PUBLIC_WALLET_IN_MAINTENANCE === "true",
    []
  );

  const runAfterWalletSet = useCallback(
    async ({
      reason,
      userId,
      ignoreWalletKeyCheck = false,
      onSuccess
    }: RunAfterWalletSetOptions) => {
      try {
        const walletOfCurrentUser = await fetchWallet();

        if (!walletOfCurrentUser) {
          const { status, newWalletHasBeenCreated } = await noWalletFlow({
            reason,
            openDialog,
            router,
            sendEvent,
            startCreateNewWalletFlow,
            startNoWalletKeyFlow
          });

          if (status === COMMON_DIALOG_STATUS.success) {
            await onSuccess({
              newWalletHasBeenCreated
            });
          }

          return;
        }

        if (!ignoreWalletKeyCheck) {
          const walletDecryptionRecord =
            getWalletDecryptionRecordFromLocalStore(userId);

          if (!walletDecryptionRecord) {
            const { status, newWalletHasBeenCreated } =
              await startNoWalletKeyFlow(reason);

            if (status === COMMON_DIALOG_STATUS.success) {
              await onSuccess({
                newWalletHasBeenCreated
              });
            }

            return;
          }
        }

        await onSuccess({
          newWalletHasBeenCreated: false
        });
      } catch (error) {
        apolloErrorSnackbar(error);
      }
    },
    [
      apolloErrorSnackbar,
      fetchWallet,
      startNoWalletKeyFlow,
      openDialog,
      sendEvent,
      router,
      startCreateNewWalletFlow
    ]
  );

  const runAfterWalletAvailable: RunAfterWalletAvailableGuard = useCallback(
    async ({
      onSuccess,
      reason,
      ignoreWalletKeyCheck = false,
      redirectUrlAfterBuyingCoins
    }): Promise<void> => {
      const currentUserId = currentUser?.id;

      if (!currentUserId) {
        return;
      }

      if (checkWalletInMaintenence()) {
        warnAboutWalletMaintenance();
        return;
      }

      const walletStatus = await fetchWalletStatus(currentUserId);

      if (walletStatus === WalletStatus.busy) {
        warnAboutTransactionInProgress();
        return;
      }

      if (walletStatus === WalletStatus.absent) {
        const { status, newWalletHasBeenCreated } = await noWalletFlow({
          openDialog,
          reason,
          router,
          sendEvent,
          startCreateNewWalletFlow,
          startNoWalletKeyFlow,
          redirectUrlAfterBuyingCoins
        });

        if (status === COMMON_DIALOG_STATUS.success) {
          await onSuccess({
            newWalletHasBeenCreated
          });
        }

        return;
      }

      await runAfterWalletSet({
        reason,
        userId: currentUserId,
        ignoreWalletKeyCheck,
        onSuccess
      });
    },
    [
      currentUser?.id,
      router,
      sendEvent,
      startCreateNewWalletFlow,
      openDialog,
      checkWalletInMaintenence,
      fetchWalletStatus,
      runAfterWalletSet,
      startNoWalletKeyFlow,
      warnAboutTransactionInProgress,
      warnAboutWalletMaintenance
    ]
  );

  const warnAboutReceiverHasNoWallet = useCallback(
    (type: WALLET_CONFIRMATION_RECEIVER_HAS_NOT_WALLET_TYPE) => {
      openDialog(DIALOG_NAME.walletConfirmation, {
        variant: WALLET_CONFIRMATION_VARIANT.receiverHasNoWallet,
        specific: type
      });
    },
    [openDialog]
  );

  const buyCoins = useCallback(
    async (payload?: WalletBuyCoinsDialogPayload) => {
      setCoinsBuyingInProgress(true);

      sendEvent({ event: ANALYTICS_EVENT.buyCoinsStart });

      const result = await runAfterWalletAvailable({
        onSuccess: () =>
          openDialog(DIALOG_NAME.buyCoins, {
            post: payload?.post,
            redirectUrlAfterBuyingCoins: payload?.redirectUrlAfterBuyingCoins
          }),
        reason: WALLET_DIALOG_CALL_REASON.coinsPurchase,
        ignoreWalletKeyCheck: true
      });

      setCoinsBuyingInProgress(false);

      return result;
    },
    [sendEvent, runAfterWalletAvailable, openDialog]
  );

  const warnAboutNotEnoughMoney = useCallback(
    async (payload: WalletNotEnoughFundsPayload): Promise<void> => {
      const result = await openDialog(DIALOG_NAME.walletConfirmation, {
        variant: WALLET_CONFIRMATION_VARIANT.notEnoughFunds,
        specific: payload.operation
      });

      if (result.status === COMMON_DIALOG_STATUS.success) {
        buyCoins({
          post: payload.post
        });
      }
    },
    [buyCoins, openDialog]
  );

  const warnAboutWalletKeyDrop: WarnAboutWalletKeyDropHandler = useCallback(
    async (
      { reason } = { reason: WALLET_DIALOG_CALL_REASON.walletKeyDropping }
    ) => {
      const result = await openDialog(DIALOG_NAME.walletKeyDroppedWarning);

      if (result.status !== COMMON_DIALOG_STATUS.success) {
        return {
          status: result.status,
          newWalletHasBeenCreated: false
        };
      }

      const startNoWalletKeyResult = await startNoWalletKeyFlow(reason);

      return startNoWalletKeyResult;
    },
    [openDialog, startNoWalletKeyFlow]
  );

  const resetPin = useCallback(
    (): Promise<DialogResult<DIALOG_NAME.resetWalletPin>> =>
      openDialog(DIALOG_NAME.resetWalletPin),
    [openDialog]
  );

  const payForPost = useCallback(
    async (options: SpecificPurchaseDialogOptions) => {
      setPostPaymentInProgress(true);

      await runAfterWalletAvailable({
        onSuccess: async () =>
          await openDialog(DIALOG_NAME.postPurchase, {
            postId: options.postId,
            teaserText: options.teaserText
          }),
        reason: WALLET_DIALOG_CALL_REASON.postPurchase
      });

      setPostPaymentInProgress(false);
    },
    [openDialog, runAfterWalletAvailable]
  );

  const tipPost = useCallback(
    async ({ postId }: SpecificTipPostDialogOptions) => {
      setPostTippingInProgress(true);

      await runAfterWalletAvailable({
        onSuccess: () =>
          openDialog(DIALOG_NAME.tipPost, {
            postId
          }),
        reason: WALLET_DIALOG_CALL_REASON.postTipping
      });

      setPostTippingInProgress(false);
    },
    [openDialog, runAfterWalletAvailable]
  );

  const warnAboutIncorrectPin = useCallback(async () => {
    const INCORRECT_PIN_INPUTS = 2;

    const result = await openDialog(DIALOG_NAME.walletConfirmation, {
      variant: WALLET_CONFIRMATION_VARIANT.pinIncorrect,
      specific: INCORRECT_PIN_INPUTS
    });

    if (result.status !== COMMON_DIALOG_STATUS.success) {
      return result;
    }

    return resetPin();
  }, [openDialog, resetPin]);

  useBuyCoinsResult({
    readyToCheck: dialogProviderInitialized
  });

  useBankAccountSetupResult({
    readyToCheck: dialogProviderInitialized
  });

  const selectWalletSynchronizationMethod =
    useCallback(async (): Promise<void> => {
      const currentUserId = currentUser?.id;

      if (!currentUserId) {
        return;
      }

      setWalletSynchronizationMethodSelectionInProgress(true);

      await runAfterWalletSet({
        onSuccess: async () => {
          const result = await openDialog(
            DIALOG_NAME.walletSynchronizationMethodSelector
          );

          if (result.status !== COMMON_DIALOG_STATUS.success) {
            return;
          }

          switch (result.data.method) {
            case WALLET_SYNCHRONIZATION_METHOD.mnemonic: {
              await openDialog(DIALOG_NAME.walletMnemonic);
              break;
            }
            case WALLET_SYNCHRONIZATION_METHOD.qrCode: {
              const decryptionRecord =
                getWalletDecryptionRecordFromLocalStore(currentUserId);

              if (!decryptionRecord) {
                throw new Error(
                  'There is no wallet record after "runIfWalletSet" checks'
                );
              }

              await openDialog(DIALOG_NAME.walletQRCode, {
                decryptionRecord
              });

              break;
            }
            default: {
              throw new Error("Unknown type of synchronization method");
            }
          }
        },
        reason: WALLET_DIALOG_CALL_REASON.walletSynchronization,
        userId: currentUserId
      });

      setWalletSynchronizationMethodSelectionInProgress(false);
    }, [currentUser?.id, openDialog, runAfterWalletSet]);

  const startNoWalletFlow = useCallback(
    async (reason: WALLET_DIALOG_CALL_REASON) =>
      noWalletFlow({
        openDialog,
        reason,
        router,
        sendEvent,
        startCreateNewWalletFlow,
        startNoWalletKeyFlow
      }),
    [
      openDialog,
      router,
      sendEvent,
      startCreateNewWalletFlow,
      startNoWalletKeyFlow
    ]
  );

  return (
    <WalletContext.Provider
      value={{
        runAfterWalletAvailable,
        startNoWalletFlow,
        startNoWalletKeyFlow,
        warnAboutIncorrectPin,
        warnAboutReceiverHasNoWallet,
        warnAboutNotEnoughMoney,
        warnAboutWalletMaintenance,
        warnAboutTransactionInProgress,
        warnAboutWalletKeyDrop,
        addTransactionProgress,
        getTransactionProgress,
        transactionInProgress,
        postPaymentInProgress,
        postTippingInProgress,
        coinsBuyingInProgress,
        walletSynchronizationMethodSelectionInProgress,
        addTransactionDoneHandler,
        removeTransactionDoneHandler,
        hasPostTransactionInProgress,
        addPostPurchaseLinkClickHandler,
        removePostPurchaseLinkClickHandler,
        fetchWallet,
        fetchWalletStatus,
        resetPin,
        payForPost,
        tipPost,
        wallet,
        fetched,
        buyCoins,
        showNFTMaintenancePlaceholders,
        allowedToMintNftFor1Hype,
        checkWalletInMaintenence,
        selectWalletSynchronizationMethod
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
