import { isApolloError } from "@apollo/client";
import debounce from "lodash.debounce";

import {
  INCORRECT_RE_CAPTCHA_ERROR_CODE,
  REGISTRATION_TEMPORARY_UNAVAILABLE_ERROR_CODE
} from "~/components/auth/constants";
import {
  ValidateDisplayName,
  ValidateDisplayNameVariables
} from "~/declarations/apollo/ValidateDisplayName";
import {
  ValidateEmail,
  ValidateEmailVariables
} from "~/declarations/apollo/ValidateEmail";
import {
  ValidatePassword,
  ValidatePasswordVariables
} from "~/declarations/apollo/ValidatePassword";
import {
  ValidateUsername,
  ValidateUsernameVariables
} from "~/declarations/apollo/ValidateUsername";
import {
  getCustomApolloErrorMessage,
  getCustomGraphQLError
} from "~/utils/errors";
import { ReCaptchaClientError } from "~/utils/useReCaptchaVerification/error";

import {
  FieldValidationAPIFunction,
  FieldValidationAPIFunctionsMap,
  FieldValidationFunctionOptions,
  ValidationFunctionsMap
} from "./declarations";
import {
  VALIDATE_DISPLAY_NAME,
  VALIDATE_EMAIL,
  VALIDATE_PASSWORD,
  VALIDATE_USERNAME
} from "./graphql";

type GetValidateEmailFunctionOptions = {
  checkIfExists?: boolean;
  checkReservation?: boolean;
};

export const getValidateEmailFunction =
  ({
    checkIfExists = true,
    checkReservation = false
  }: GetValidateEmailFunctionOptions = {}): FieldValidationAPIFunction =>
  (apolloClient, email) =>
    apolloClient.query<ValidateEmail, ValidateEmailVariables>({
      query: VALIDATE_EMAIL,
      variables: {
        email,
        checkIfExists,
        checkReservation
      },
      fetchPolicy: "no-cache"
    });

export const validatePassword: FieldValidationAPIFunction = (
  apolloClient,
  password
) =>
  apolloClient.query<ValidatePassword, ValidatePasswordVariables>({
    query: VALIDATE_PASSWORD,
    variables: {
      password
    },
    fetchPolicy: "no-cache"
  });

export const validateDisplayName: FieldValidationAPIFunction = async (
  apolloClient,
  displayName
) =>
  apolloClient.query<ValidateDisplayName, ValidateDisplayNameVariables>({
    query: VALIDATE_DISPLAY_NAME,
    variables: {
      displayName
    },
    fetchPolicy: "no-cache"
  });

type GetValidateUsernameFunctionOptions = {
  checkIfExists?: boolean;
  checkReservation?: boolean;
};

export const getValidateUsernameFunction =
  ({
    checkIfExists = true,
    checkReservation = false
  }: GetValidateUsernameFunctionOptions = {}): FieldValidationAPIFunction =>
  async (apolloClient, username) =>
    apolloClient.query<ValidateUsername, ValidateUsernameVariables>({
      query: VALIDATE_USERNAME,
      variables: {
        username,
        checkIfExists,
        checkReservation
      },
      fetchPolicy: "no-cache"
    });

export const getValidationFieldFunction =
  <Form>(validationFunction: FieldValidationAPIFunction) =>
  async ({
    value,
    fieldName,
    setValidating,
    setValidated,
    setApiErrors,
    setFieldTouched,
    apolloClient
  }: FieldValidationFunctionOptions<Form>): Promise<void> => {
    try {
      await validationFunction(apolloClient, value);

      setApiErrors(apiErrors => ({
        ...apiErrors,
        [fieldName]: null
      }));
    } catch (error) {
      const errorMessage = getCustomApolloErrorMessage(error);

      setApiErrors(apiErrors => ({
        ...apiErrors,
        [fieldName]: errorMessage
      }));

      setFieldTouched(fieldName as string, true);
    } finally {
      setValidating(validating => ({
        ...validating,
        [fieldName]: false
      }));
      setValidated(validated => ({
        ...validated,
        [fieldName]: true
      }));
    }
  };

export const getValidationFunctionsMap = <Form>(
  map: FieldValidationAPIFunctionsMap<Form>,
  timeout: number
): ValidationFunctionsMap<Form> =>
  Object.entries(map).reduce((map, [key, validationFunction]) => {
    const fieldName = key as keyof Form;

    map[fieldName] = debounce(
      getValidationFieldFunction<Form>(
        validationFunction as FieldValidationAPIFunction
      ),
      timeout
    );

    return map;
  }, {} as ValidationFunctionsMap<Form>);

export const isReCaptchaError = (error: unknown): boolean => {
  const isClientReCaptchaError = error instanceof ReCaptchaClientError;

  if (isClientReCaptchaError) {
    return true;
  }

  if (!(error instanceof Error) || !isApolloError(error)) {
    return false;
  }

  const graphQLError = getCustomGraphQLError(error);
  const errorType = graphQLError?.extensions?.status;
  const isServerReCaptchaError = errorType === INCORRECT_RE_CAPTCHA_ERROR_CODE;

  return isServerReCaptchaError;
};

export const isRegistrationTemporaryUnavailableError = (
  error: unknown
): boolean => {
  if (!(error instanceof Error) || !isApolloError(error)) {
    return false;
  }

  const graphQLError = getCustomGraphQLError(error);
  const errorType = graphQLError?.extensions?.status;
  const isServerReCaptchaError =
    errorType === REGISTRATION_TEMPORARY_UNAVAILABLE_ERROR_CODE;

  return isServerReCaptchaError;
};
