import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

import { PostDeletionDialogPayload } from "./declarations";

export interface PostReportProps {
  openDialog: (props: PostDeletionDialogPayload) => void;
}

const PostDeletionContext = createContext<PostReportProps | undefined>(
  undefined
);

const usePostDeletionContext = createUseContext(
  PostDeletionContext,
  "PostDeletionContext"
);

export default PostDeletionContext.Provider;
export { usePostDeletionContext };
