import { useCallback, useRef } from "react";

import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { MessageCreatedHandler } from "~/components/messaging/providers/MessagingProvider/useRealTimeMessagingUpdateViaSubscription/declarations";
import {
  updateConversationCanMessage,
  updateGroupConversationPictureInCache,
  updateGroupConversationTopicInCache
} from "~/components/messaging/utils/apollo/conversations";
import {
  MessengerEvents,
  MessengerEventsVariables
} from "~/declarations/apollo/MessengerEvents";
import useSubscriptionWithRecovery, {
  OnSubscriptionDataHandler
} from "~/utils/apollo/useSubscriptionWithRecovery";

import {
  addReactionToMessage,
  createGroupConversation,
  createMessage,
  createOneToOneConversation,
  deleteMessage,
  deleteReactionFromMessage,
  editMessage,
  makeConversationRead,
  makeMessagePublished,
  updateGroupConversationSettings,
  updateMessageQuote,
  updateOneToOneConversationSettings
} from "./apollo";
import { MESSAGING_EVENT_TYPE } from "./constants";
import { MESSENGER_EVENTS_SUBSCRIPTION } from "./graphql";

const useRealTimeMessagingUpdateViaSubscription = (
  currentUserId?: string,
  onMessageCreated?: MessageCreatedHandler
): void => {
  const { authorized, authorizationStatusFetched } = useAccountLayoutContext();

  const handleSubscriptionData: OnSubscriptionDataHandler<
    MessengerEvents,
    "messengerEvents"
  > = useCallback(
    ({ client, data }) => {
      data.events.map(event => {
        switch (event.__typename) {
          case MESSAGING_EVENT_TYPE.conversationFrozen: {
            updateConversationCanMessage(client, event.conversationId, false);
            break;
          }
          case MESSAGING_EVENT_TYPE.conversationUnfrozen: {
            updateConversationCanMessage(client, event.conversationId, true);
            break;
          }
          case MESSAGING_EVENT_TYPE.conversationWasRead: {
            if (currentUserId) {
              makeConversationRead(client, event, currentUserId);
            }
            break;
          }
          case MESSAGING_EVENT_TYPE.oneToOneConversationCreated: {
            createOneToOneConversation(client, event);
            break;
          }
          case MESSAGING_EVENT_TYPE.groupCreated: {
            createGroupConversation(client, event);
            break;
          }
          case MESSAGING_EVENT_TYPE.participantInvited:
          case MESSAGING_EVENT_TYPE.participantJoined:
          case MESSAGING_EVENT_TYPE.participantJoinRequestSent:
          case MESSAGING_EVENT_TYPE.participantKicked:
          case MESSAGING_EVENT_TYPE.participantLeft:
          case MESSAGING_EVENT_TYPE.participantBanned:
          case MESSAGING_EVENT_TYPE.participantUnbanned:
          case MESSAGING_EVENT_TYPE.participantPromoted:
          case MESSAGING_EVENT_TYPE.participantDemoted:
          case MESSAGING_EVENT_TYPE.groupOwnershipTransferred:
          case MESSAGING_EVENT_TYPE.messageCreated: {
            createMessage(client, currentUserId, event, handler.current);
            break;
          }
          case MESSAGING_EVENT_TYPE.groupPictureChanged: {
            createMessage(client, currentUserId, event, handler.current);
            updateGroupConversationPictureInCache(
              client,
              event.conversationId,
              event.picture
            );
            break;
          }
          case MESSAGING_EVENT_TYPE.groupTopicChanged: {
            createMessage(client, currentUserId, event, handler.current);
            updateGroupConversationTopicInCache(
              client,
              event.conversationId,
              event.topic
            );
            break;
          }
          case MESSAGING_EVENT_TYPE.messageDeleted: {
            deleteMessage(client, event, currentUserId);
            break;
          }
          case MESSAGING_EVENT_TYPE.messageEdited: {
            editMessage(client, event.messageId, event);
            break;
          }
          case MESSAGING_EVENT_TYPE.messageQuoteChanged: {
            updateMessageQuote(client, event);
            break;
          }
          case MESSAGING_EVENT_TYPE.participantBlockingMessagesChanged: {
            updateOneToOneConversationSettings(client, event.conversationId, {
              blockingMessages: event.value
            });
            break;
          }
          case MESSAGING_EVENT_TYPE.participantIgnoringMessagesChanged: {
            updateOneToOneConversationSettings(client, event.conversationId, {
              ignoringMessages: event.value
            });
            break;
          }
          case MESSAGING_EVENT_TYPE.groupInvitePolicyChanged: {
            updateGroupConversationSettings(client, event.conversationId, {
              invitePolicy: event.invitePolicy
            });
            break;
          }
          case MESSAGING_EVENT_TYPE.groupPrivacyChanged: {
            updateGroupConversationSettings(client, event.conversationId, {
              privacy: event.privacy
            });
            break;
          }
          case MESSAGING_EVENT_TYPE.groupKindChanged: {
            updateGroupConversationSettings(client, event.conversationId, {
              kind: event.kind
            });
            break;
          }
          case MESSAGING_EVENT_TYPE.reactionCreated: {
            addReactionToMessage(client, event, currentUserId);
            break;
          }
          case MESSAGING_EVENT_TYPE.reactionDeleted: {
            deleteReactionFromMessage(client, event, currentUserId);
            break;
          }
          case MESSAGING_EVENT_TYPE.messagePublished: {
            makeMessagePublished(client, event);
            break;
          }
        }
      });
    },
    [currentUserId]
  );

  useSubscriptionWithRecovery<
    MessengerEvents,
    "messengerEvents",
    MessengerEventsVariables
  >({
    subscription: MESSENGER_EVENTS_SUBSCRIPTION,
    key: "messengerEvents",
    skip: !authorizationStatusFetched || !authorized || !currentUserId,
    onSubscriptionData: handleSubscriptionData
  });

  const handler = useRef<MessageCreatedHandler | undefined>();
  handler.current = onMessageCreated;
};

export default useRealTimeMessagingUpdateViaSubscription;
