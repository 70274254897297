import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { PREVIEW_VIDEO_FRAGMENT } from "~/components/post/manage/LongContentManage/graphql";
import { POST_AUTHOR_FRAGMENT } from "~/components/post/postAuthorFragment";

export const VIDEO_POST_VIDEO_FRAGMENT = gql`
  fragment VideoPostVideo on AttachmentVideo {
    __typename
    id
    name
    videoId
    publicUrl
    previewUrl
    views
    duration
    cover {
      url
      blurredUrl
      thumbnails {
        little {
          url
          sizes {
            width
            height
          }
        }
        middle {
          url
        }
        big {
          url
        }
        w200h200 {
          url
        }
        w300h300 {
          url
        }
        w400h400 {
          url
        }
        w600h600 {
          url
        }
      }
      sizes {
        width
        height
      }
    }
  }
`;

export const VIDEO_POST_QUERY = gql`
  query VideoPost($postId: String!) {
    getLongPost(postId: $postId) {
      id
      confidentiality
      sensitiveShown @client
      __typename
      title
      publishedAt
      status
      type
      reactions {
        likes
        myReaction {
          id
          type
        }
      }
      counters {
        comments
        tips {
          sum
        }
      }
      settings {
        enabledTipping
        isAdult
      }
      previewInfo {
        addToMyPosts
        previewType
        video {
          ...VideoPreviewFields
        }
        videoCustomSegment {
          ...VideoPreviewFields
        }
        videoStartSegment {
          ...VideoPreviewFields
        }
      }
      owner {
        ...shortPostAuthor
      }
      paywall {
        cost
        preview
        status
      }
      video {
        ...VideoPostVideo
      }
      richText {
        ...richText
      }
    }
  }
  ${POST_AUTHOR_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
  ${PREVIEW_VIDEO_FRAGMENT}
  ${VIDEO_POST_VIDEO_FRAGMENT}
`;
