import UAParser from "ua-parser-js";

import { isNotNull, isNullable, isServerSide } from "~/utils/common";

const SAFARI_BROWSER_NAMES = ["Safari", "Mobile Safari"];
const CHROMIUM_ENGINE_NAME = "Blink";
const CHROMIUM_BASED_BROWSER_NAMES = ["Chrome", "Chromium"];
const IOS_OS_NAME = "iOS";

export const isSafari = (): boolean => {
  if (isServerSide()) {
    return false;
  }

  const parser = new UAParser(window.navigator.userAgent);
  const browserName = parser.getBrowser().name;

  return isNotNull(browserName) && SAFARI_BROWSER_NAMES.includes(browserName);
};

export const isIOS = (): boolean => {
  if (isServerSide()) {
    return false;
  }

  const parser = new UAParser(window.navigator.userAgent);
  const osName = parser.getOS().name;

  return osName === IOS_OS_NAME;
};

export const isChromiumBasedBrowser = (): boolean => {
  if (isServerSide()) {
    return false;
  }

  const browserHasGlobalChromeObject = isNotNull(
    window.chrome
  ); /* hack for most browsers */
  const parser = new UAParser(window.navigator.userAgent);
  const browserEngine = parser.getEngine();
  const userAgentHasChromiumEngine =
    CHROMIUM_ENGINE_NAME === browserEngine.name;
  const browserName = parser.getBrowser().name;
  const userAgentHasChromiumBasedBrowserName =
    isNotNull(browserName) &&
    CHROMIUM_BASED_BROWSER_NAMES.includes(browserName);

  return (
    browserHasGlobalChromeObject ||
    userAgentHasChromiumEngine ||
    userAgentHasChromiumBasedBrowserName
  );
};

const isDesktopUserAgent = (): boolean => {
  const disabledModernMobileFlag =
    window.navigator.userAgentData?.mobile === false;
  const parser = new UAParser(window.navigator.userAgent);
  const deviceType = parser.getDevice().type;
  const desktopDeviceTypeInUserAgent = isNullable(deviceType);

  return disabledModernMobileFlag || desktopDeviceTypeInUserAgent;
};

export const isDesktopBrowser = (): boolean =>
  !isServerSide() && isDesktopUserAgent();
