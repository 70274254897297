import { NftAsset } from "~/components/nft/declarations";

export enum XDB_NFT_IMPORT_PROCESS_STEP {
  confirmationFromAstraX,
  importingNftFromXdb,
  completed
}

export type SpecificXdbNftImportProcessDialogOptions = {
  xdbNftId: string;
  xdbWalletAddress: string;
  nftId: NftAsset["id"];
  walletKey: string;
  editionsToImport: number;
};
