import { useCallback } from "react";

import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { WALLET_TRANSACTION_CONTENT_TYPENAME } from "~/components/wallet/constants";
import { WALLET_EVENTS_SUBSCRIPTION } from "~/components/wallet/providers/WalletProvider/useRealTimeTransactionsUpdate/graphql";
import { updateNftAndEditionInCache as updateNftAndEditionInCache } from "~/components/wallet/providers/WalletProvider/useRealTimeTransactionsUpdate/utils";
import { PostType, TransferReason } from "~/declarations/apollo/globalTypes";
import { MyWallet_myWallet } from "~/declarations/apollo/MyWallet";
import {
  WalletEvents,
  WalletEvents_myWalletEvents_events_EventTransactionConfirmed_nft,
  WalletEventsVariables
} from "~/declarations/apollo/WalletEvents";
import { ProgressOfTransaction, PURCHASE_TYPE } from "~/declarations/purchases";
import useSubscriptionWithRecovery, {
  OnSubscriptionDataHandler
} from "~/utils/apollo/useSubscriptionWithRecovery";

interface UseRealTimeTransactionsUpdate {
  onTransactionDone: (transactionProgress: ProgressOfTransaction) => void;
  onBuyNftEdition: (
    nft: WalletEvents_myWalletEvents_events_EventTransactionConfirmed_nft
  ) => void;
  onUpdateWallet: (wallet: MyWallet_myWallet) => void;
  onUpdateRecentTransactions: () => void;
}

const TRANSACTION_REASON_TO_POST_TYPE = {
  [TransferReason.tipLongPost]: PostType.long,
  [TransferReason.tipShortPost]: PostType.short,
  [TransferReason.donateForStream]: PostType.longStream,
  [TransferReason.buyLongPost]: PostType.long,
  [TransferReason.buyShortPost]: PostType.short,
  [TransferReason.buyLongStream]: PostType.longStream
};

enum WALLET_EVENT_TYPE {
  nftMinted = "EventNftMinted",
  postWasBought = "EventPostWasBought",
  transactionConfirmed = "EventTransactionConfirmed",
  replenishmentDone = "EventReplenishmentDone",
  payoutProcessed = "EventPayoutProcessed"
}

const useRealTimeTransactionsUpdate = ({
  onTransactionDone,
  onBuyNftEdition,
  onUpdateWallet,
  onUpdateRecentTransactions
}: UseRealTimeTransactionsUpdate): void => {
  const { currentUser, authorized, authorizationStatusFetched } =
    useAccountLayoutContext();

  const handleSubscriptionData: OnSubscriptionDataHandler<
    WalletEvents,
    "myWalletEvents"
  > = useCallback(
    async ({ data, client }): Promise<void> => {
      data.events.forEach(event => {
        switch (event.__typename) {
          case WALLET_EVENT_TYPE.nftMinted: {
            onTransactionDone({
              type: PURCHASE_TYPE.nftMinting,
              nftId: event.nftId
            });
            break;
          }
          case WALLET_EVENT_TYPE.postWasBought: {
            onTransactionDone({
              type: PURCHASE_TYPE.postPurchase,
              postId: event.postId,
              postType: event.postType,
              balance: event.cost
            });
            break;
          }
          case WALLET_EVENT_TYPE.transactionConfirmed: {
            onUpdateWallet(event.wallet);
            onUpdateRecentTransactions();

            switch (event.transaction.reason) {
              case TransferReason.own: {
                onTransactionDone({
                  type: PURCHASE_TYPE.sendOwnMoney,
                  balance: event.transaction.balance
                });
                break;
              }
              case TransferReason.sendMoney: {
                if (event.transaction.reasonId) {
                  onTransactionDone({
                    type: PURCHASE_TYPE.sendMoneyByMessage,
                    conversationId: event.transaction.reasonId,
                    balance: event.transaction.balance
                  });
                }
                break;
              }
              case TransferReason.tipLongPost:
              case TransferReason.tipShortPost:
              case TransferReason.donateForStream: {
                const postId =
                  event.transaction.content?.__typename ===
                    WALLET_TRANSACTION_CONTENT_TYPENAME.longStream ||
                  event.transaction.content?.__typename ===
                    WALLET_TRANSACTION_CONTENT_TYPENAME.shortStream
                    ? event.transaction.content.postId
                    : event.transaction.reasonId;

                if (postId) {
                  onTransactionDone({
                    type: PURCHASE_TYPE.postTipping,
                    postId,
                    postType:
                      TRANSACTION_REASON_TO_POST_TYPE[event.transaction.reason],
                    balance: event.transaction.balance
                  });
                }
                break;
              }
              case TransferReason.buyEdition:
              case TransferReason.startSellingEditions:
              case TransferReason.stopSellingEditions: {
                if (!event.nft) {
                  return;
                }

                updateNftAndEditionInCache({
                  apolloClient: client,
                  reason: event.transaction.reason,
                  nftId: event.nft.id,
                  editionId: event.nft.editionId,
                  onTransactionDone
                });

                if (event.transaction.reason === TransferReason.buyEdition) {
                  onBuyNftEdition(event.nft);
                }

                break;
              }
            }

            break;
          }
        }
      });
    },
    [
      onBuyNftEdition,
      onTransactionDone,
      onUpdateRecentTransactions,
      onUpdateWallet
    ]
  );

  useSubscriptionWithRecovery<
    WalletEvents,
    "myWalletEvents",
    WalletEventsVariables
  >({
    subscription: WALLET_EVENTS_SUBSCRIPTION,
    key: "myWalletEvents",
    skip: !authorizationStatusFetched || !authorized || !currentUser.data,
    onSubscriptionData: handleSubscriptionData
  });
};

export default useRealTimeTransactionsUpdate;
