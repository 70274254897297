import {
  PaywallProtectionStatus,
  PostType
} from "~/declarations/apollo/globalTypes";
import {
  LongPost,
  LongStream,
  ShortPost,
  ShortStream
} from "~/declarations/post";

import ROUTES from "./routes";

export const SHORT_POST_TYPENAME: ShortPost["__typename"] = "ShortPost";
export const LONG_POST_TYPENAME: LongPost["__typename"] = "LongPost";
export const LONG_STREAM_TYPENAME: LongStream["__typename"] = "LongStream";
export const SHORT_STREAM_TYPENAME: ShortStream["__typename"] = "ShortStream";

export const PATH_TO_POST_INDEX_BY_TYPE = {
  [PostType.short]: ROUTES.posts.index,
  [PostType.long]: ROUTES.hypetv.index,
  [PostType.longStream]: ROUTES.live.web.index,
  [PostType.shortStream]: ROUTES.live.mobile.index
};

export const PATH_TO_POST_BY_TYPE = {
  [PostType.short]: ROUTES.posts.instance,
  [PostType.long]: ROUTES.hypetv.instance,
  [PostType.longStream]: ROUTES.live.web.instance,
  [PostType.shortStream]: ROUTES.live.mobile.instance
};

export const PATH_TO_POST_EDIT_PAGE_BY_TYPENAME = {
  [SHORT_POST_TYPENAME]: ROUTES.posts.edit,
  [LONG_POST_TYPENAME]: ROUTES.hypetv.edit,
  [LONG_STREAM_TYPENAME]: ROUTES.live.edit
};

export const PATH_TO_POST_EDIT_PAGE_BY_TYPE = {
  [PostType.short]: ROUTES.posts.edit,
  [PostType.long]: ROUTES.hypetv.edit,
  [PostType.longStream]: ROUTES.live.edit
};

export const PAYWALL_STATUS_THAT_ALLOW_SEND_POST_VIEW = {
  [PaywallProtectionStatus.bought]: true,
  [PaywallProtectionStatus.free]: true,
  [PaywallProtectionStatus.selling]: true
};

export const POST_TYPE_TO_TYPENAME = {
  [PostType.short]: SHORT_POST_TYPENAME,
  [PostType.long]: LONG_POST_TYPENAME,
  [PostType.longStream]: LONG_STREAM_TYPENAME,
  [PostType.shortStream]: SHORT_STREAM_TYPENAME
};
