import { gql } from "@apollo/client";

import { RICH_TEXT_FRAGMENT } from "~/components/core/RichTextViewer/graphql";
import { SHORT_POST_NODE_FILE_FRAGMENT } from "~/components/post/graphql";
import { PREVIEW_VIDEO_FRAGMENT } from "~/components/post/manage/LongContentManage/graphql";
import { POST_AUTHOR_FRAGMENT } from "~/components/post/postAuthorFragment";

export const POST_FRAGMENT_NAME = "PostFragment";
export const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    __typename
    id
    settings {
      enabledTipping
      categories {
        id
        nameI18N
      }
      location {
        id
        name
      }
      isAdult
    }
    paywall {
      preview
      status
      cost
    }
    type
    confidentiality
    status
    publishedAt
    counters {
      comments
      tips {
        sum
        count
      }
    }
    reactions {
      likes
      myReaction {
        id
        type
      }
    }
    owner {
      ...shortPostAuthor
    }
    richText {
      ...richText
    }

    ... on ShortPost {
      sensitiveShown @client
      parentPost {
        id
        type
      }
      files {
        ...ShortPostNodeFile
      }
    }

    ... on LongPost {
      sensitiveShown @client
      title
      previewInfo {
        addToMyPosts
        previewType
        video {
          ...VideoPreviewFields
        }
        videoCustomSegment {
          ...VideoPreviewFields
        }
        videoStartSegment {
          ...VideoPreviewFields
        }
      }
      video {
        id
        videoId
        name
        title
        views
        publicUrl
        cover {
          url
          blurredUrl
          sizes {
            width
            height
          }
          thumbnails {
            little {
              url
              sizes {
                width
                height
              }
            }
            middle {
              url
            }
            big {
              url
            }
            w200h200 {
              url
            }
            w300h300 {
              url
            }
            w400h400 {
              url
            }
            w600h600 {
              url
            }
          }
        }
        duration
        width
        height
        videoType
        previewUrl
      }
    }

    ... on LongStream {
      sensitiveShown @client
      name
      stream {
        id
        status
        broadcastUrl
        startDate
        finishDate
        watchers
        notifySubscribers
        video {
          id
          url
          duration
        }
        cover {
          id
          url
          blurredUrl
          thumbnails {
            s295 {
              url
              sizes {
                width
                height
              }
            }
            m600 {
              url
            }
            b960 {
              url
            }
            w1200 {
              url
            }
            f1920 {
              url
            }
            w200h200 {
              url
            }
            w300h300 {
              url
            }
            w400h400 {
              url
            }
            w600h600 {
              url
            }
          }
          sizes {
            width
            height
          }
        }
        credentials {
          id
          url
          key
        }
      }
    }
  }

  ${POST_AUTHOR_FRAGMENT}
  ${RICH_TEXT_FRAGMENT}
  ${SHORT_POST_NODE_FILE_FRAGMENT}
  ${PREVIEW_VIDEO_FRAGMENT}
`;

export const POST_QUERY = gql`
  query Post($postId: String!) {
    getPost(postId: $postId) {
      ...PostFragment
    }
  }
  ${POST_FRAGMENT}
`;
