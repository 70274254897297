import { ApolloClient, defaultDataIdFromObject } from "@apollo/client";

import { GeneralPostVideoFragment } from "~/declarations/apollo/GeneralPostVideoFragment";
import { AttachmentType, PostStatus } from "~/declarations/apollo/globalTypes";
import { PostStatusFragment } from "~/declarations/apollo/PostStatusFragment";

import {
  GENERAL_POST_VIDEO_FRAGMENT,
  GENERAL_POST_VIDEO_FRAGMENT_NAME,
  POST_STATUS_FRAGMENT,
  POST_STATUS_FRAGMENT_NAME
} from "../graphql";

export const updatePostStatusInApolloCache = (
  client: ApolloClient<unknown>,
  postId: string,
  __typename: PostStatusFragment["__typename"],
  status: PostStatus
): void => {
  client.writeFragment<PostStatusFragment>({
    id: defaultDataIdFromObject({
      id: postId,
      __typename
    }),
    fragment: POST_STATUS_FRAGMENT,
    fragmentName: POST_STATUS_FRAGMENT_NAME,
    data: {
      __typename,
      status
    }
  });
};

export const updateVideoAttachmentInApolloCache = (
  client: ApolloClient<unknown>,
  video: GeneralPostVideoFragment
): void => {
  client.writeFragment<GeneralPostVideoFragment>({
    id: defaultDataIdFromObject({
      id: video.id,
      __typename: AttachmentType.AttachmentVideo
    }),
    fragment: GENERAL_POST_VIDEO_FRAGMENT,
    fragmentName: GENERAL_POST_VIDEO_FRAGMENT_NAME,
    data: video
  });
};
