import dynamic, { DynamicOptions, LoaderComponent } from "next/dynamic";

import { isServerSide } from "../common";

const MODULE_NOT_FOUND_ERROR_CODE = "MODULE_NOT_FOUND";

export const safeDynamicImport = <P,>(
  loader: () => LoaderComponent<P>,
  options?: DynamicOptions<P>
): React.ComponentType<P> =>
  dynamic<P>(
    // @ts-expect-error No need to return component
    () =>
      loader().catch(error => {
        if (!isServerSide()) {
          location.reload();
        }

        console.error(error);
      }),
    { ...options }
  );

type ModuleNotFound = Error & {
  code: string;
};

export const isDynamicModuleNotFoundError = (error: unknown): boolean =>
  error instanceof Error &&
  (error as ModuleNotFound).code === MODULE_NOT_FOUND_ERROR_CODE;

export const logAndReloadIfDynamicModuleNotFound = (
  error: unknown
): boolean => {
  if (!isDynamicModuleNotFoundError(error)) {
    return false;
  }

  console.error(error);

  if (!isServerSide()) {
    window.location.reload();
  }

  return true;
};
