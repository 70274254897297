import useTranslation from "next-translate/useTranslation";
import { SnackbarKey } from "notistack";

import { CustomSnackbarMessage } from "~/components/providers/CustomSnackbarProvider/CustomSnackbarMessage";
import { formatCoinsAmount } from "~/utils/formatters/financialResources";

interface Props {
  id: SnackbarKey;
  cost: number;
  className?: string;
}

const PaymentResultSnackMessage = ({
  id,
  cost,
  className
}: Props): JSX.Element => {
  const { t } = useTranslation("payments");
  const formattedCost = formatCoinsAmount(cost);

  return (
    <CustomSnackbarMessage
      className={className}
      id={id}
      title={t("send-coins-succeeded-prefix", {
        cost: formattedCost,
        coinName: t("common:coin-name", { count: cost })
      })}
    />
  );
};

export default PaymentResultSnackMessage;
