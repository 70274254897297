import { isEmpty, isServerSide } from "~/utils/common";

type AcceptedPersonIds = string[];

const readAcceptedPeopleFromLSByKey = (key: string): AcceptedPersonIds => {
  if (isServerSide()) {
    return [];
  }

  try {
    const acceptedPersonsLS = localStorage.getItem(key);

    if (acceptedPersonsLS && !isEmpty(acceptedPersonsLS)) {
      const acceptedPersons = JSON.parse(
        acceptedPersonsLS
      ) as AcceptedPersonIds;

      if (Array.isArray(acceptedPersons)) {
        return acceptedPersons;
      }
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const isPersonAcceptedLS = (
  personId: string | undefined,
  key: string
): boolean => {
  if (isServerSide() || !personId) {
    return false;
  }
  const acceptedPeople = readAcceptedPeopleFromLSByKey(key);

  return acceptedPeople?.includes(personId) ?? false;
};

export const setAcceptedPersonToLS = (
  personId: string | undefined,
  key: string
): void => {
  if (isServerSide() || !personId) {
    return;
  }

  const acceptedPeople = readAcceptedPeopleFromLSByKey(key);

  try {
    if (!acceptedPeople.includes(personId)) {
      const acceptedPeopleAsString = JSON.stringify([
        ...acceptedPeople,
        personId
      ]);
      localStorage.setItem(key, acceptedPeopleAsString);
    }
  } catch (error) {
    console.error(error);
    return;
  }
};
