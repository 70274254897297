const ROUTES = {
  index: "/",
  search: "/search",
  settings: {
    index: "/settings",
    security: "/settings/security",
    privacy: "/settings/privacy",
    personalization: "/settings/personalization",
    notifications: "/settings/notifications",
    wallet: "/settings/wallet",
    help: "/settings/help",
    about: "/settings/about"
  },
  persons: "/persons",
  wallet: {
    index: "/wallet",
    transactions: "/wallet/transactions",
    premium: "/wallet/premium",
    tipping: "/wallet/tips",
    nft: "/wallet/nft"
  },
  page404: "/404",
  legal: {
    privacyPolicy: "/legal/privacy-policy",
    termsOfService: "/legal/terms-of-service",
    contentPolicy: "/legal/content-policy",
    nftTerms: "/legal/nft-terms"
  },
  artCompetitionTerms: "/art-competition-terms",
  confirmEmail: "/confirm_email",
  messaging: "/messaging",
  discover: {
    index: "/discover"
  },
  auth: {
    signup: {
      index: "/signup",
      social: {
        google: "/signup/google",
        apple: "/signup/apple"
      }
    },
    login: "/login",
    resetPassword: "/reset-password"
  },
  profile: {
    index: "/profile",
    instance: "/profile/[id]"
  },
  hypetv: {
    index: "/hypetv",
    discover: "/discover/hypetv",
    followed: "/hypetv/followed",
    instance: "/hypetv/[id]",
    create: "/hypetv/create",
    edit: "/hypetv/edit/[id]"
  },
  live: {
    index: "/live",
    discover: "/discover/live",
    create: "/live/create",
    edit: "/live/edit/[id]",
    web: {
      index: "/live/web", // using for path comparison reason only
      instance: "/live/web/[id]"
    },
    mobile: {
      index: "/live/mobile", // using for path comparison reason only
      instance: "/live/mobile/[id]"
    }
  },
  nft: {
    index: "/nft",
    discover: "/discover/nft",
    create: "/nft/create",
    instance: "/nft/[id]",
    editions: "/nft/[id]/editions"
  },
  posts: {
    index: "/posts",
    instance: "/posts/[id]",
    discover: "/discover/posts",
    create: "/posts/create",
    edit: "/posts/edit/[id]" // TODO: try to modify
  },
  payment: {
    success: "/payment/success",
    error: "/payment/error"
  },
  experiments: {
    components: "/experiments/components",
    notifications: "/experiments/notifications",
    sentry: {
      client: {
        test1: "/experiments/sentry/client/test1",
        test2: "/experiments/sentry/client/test2",
        test3: "/experiments/sentry/client/test3",
        test4: "/experiments/sentry/client/test4"
      },
      ssr: {
        test1: "/experiments/sentry/ssr/test1",
        test2: "/experiments/sentry/ssr/test2",
        test3: "/experiments/sentry/ssr/test3",
        test4: "/experiments/sentry/ssr/test4"
      },
      index: "/experiments/sentry"
    }
  },
  blog: {
    index: "/blog",
    instance: "/blog/[slug]"
  },
  helpCenter: {
    index: "/help",
    web: {
      index: "/help/web",
      fees: "/help/web/fees",
      instance: "/help/web/[slug]"
    },
    mobile: {
      index: "/help/mobile",
      instance: "/help/mobile/[slug]"
    }
  }
};

module.exports = ROUTES;
