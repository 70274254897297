import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import {
  WALLET_SYNCHRONIZATION_METHOD,
  WalletsynchronizationMethodMeta
} from "~/components/wallet/WalletSynchronizationMethodSelectorDialog/declarations";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_SYNCHRONIZATION_METHODS_META: WalletsynchronizationMethodMeta[] =
  [
    {
      method: WALLET_SYNCHRONIZATION_METHOD.mnemonic,
      labelTranslationKey: "sync-wallet-method-selector_option_mnemonic"
    },
    {
      method: WALLET_SYNCHRONIZATION_METHOD.qrCode,
      labelTranslationKey: "sync-wallet-method-selector_option_qr-code"
    }
  ];

export const WALLET_SYNCHRONIZATION_METHOD_SELECTOR_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.walletSynchronizationMethodSelector> =
  {
    Component: safeDynamicImport(
      () =>
        import("~/components/wallet/WalletSynchronizationMethodSelectorDialog"),
      {
        ssr: false
      }
    )
  };
