import { createContext } from "react";

import { PROBLEM_REPORT_TYPE } from "~/components/core/ProblemReportDialog/constants";
import { Me_me } from "~/declarations/apollo/Me";
import createUseContext from "~/utils/createUseContext";
import { UseDialogHookResult } from "~/utils/useDialog";

export type ProblemReportDialogPayload = {
  type: PROBLEM_REPORT_TYPE;
  order?: number;
  sentryEventId?: string | null;
};

type ProblemReportDialogContextProps =
  UseDialogHookResult<ProblemReportDialogPayload> & {
    handleSetReportUser: (user: Me_me) => void;
  };

export const ProblemReportDialogContext = createContext<
  ProblemReportDialogContextProps | undefined
>(undefined);

export const useProblemReportDialogContext = createUseContext(
  ProblemReportDialogContext,
  "ProblemReportDialogContext"
);
