import {
  COIN_CURRENCY_FORMAT,
  COIN_LOCALE_FORMAT,
  USD_CURRENCY_FORMAT,
  USD_LOCALE_FORMAT
} from "~/constants/currencies";

const COIN_MAXIMUM_FRACTION_DIGITS = 18;
const FIAT_MAXIMUM_FRACTION_DIGITS = 2;

const STANDARD_FIAT_FORMATTER = new Intl.NumberFormat(USD_LOCALE_FORMAT, {
  style: "decimal",
  currency: USD_CURRENCY_FORMAT,
  maximumFractionDigits: FIAT_MAXIMUM_FRACTION_DIGITS
});

export const formatCoinsAmount = (
  hype = 0,
  maximumFractionDigits = COIN_MAXIMUM_FRACTION_DIGITS
): string => {
  const formatter = new Intl.NumberFormat(COIN_LOCALE_FORMAT, {
    style: "decimal",
    currency: COIN_CURRENCY_FORMAT,
    maximumFractionDigits
  });

  return formatter.format(hype);
};

export const formatFiatAmount = (amount = 0): string =>
  STANDARD_FIAT_FORMATTER.format(amount);

const getNumberSign = (value: number): string => {
  if (value === 0) {
    return "";
  }

  return value > 0 ? "+" : "-";
};

export const EXCHANGE_RATE_DIFFERENCE_PRECISION = 2;
export const PERCENTS_IN_ABSOLUTE = 100;
export const formatExchangeRateDifference = (
  absoluteDifference: number
): string => {
  const sign = getNumberSign(absoluteDifference);
  const percents = (absoluteDifference / PERCENTS_IN_ABSOLUTE).toFixed(
    EXCHANGE_RATE_DIFFERENCE_PRECISION
  );

  return `${sign}${percents}%`;
};
