import { ApolloQueryResult } from "@apollo/client";
import { createContext } from "react";

import { AuthDialogPayload } from "~/components/auth/declarations";
import { Me, Me_me } from "~/declarations/apollo/Me";
import createUseContext from "~/utils/createUseContext";
import { OpenDialogHandler } from "~/utils/useDialog";

export interface CurrentUser {
  data?: Me_me;
  refetch?: () => Promise<ApolloQueryResult<Me>>;
  loading?: boolean;
  called?: boolean;
}

export interface AccountLayoutContextProps {
  mobileView: boolean;
  authorized: boolean;
  authorizationStatusFetched: boolean;
  onCloseSideMenu?: () => void;
  fetchAuthorizationStatus: () => void;
  loginDialog: {
    open: boolean;
    closeDialog: () => void;
    openDialog: OpenDialogHandler<AuthDialogPayload>;
  };
  signUpDialog: {
    open: boolean;
    closeDialog: () => void;
    openDialog: () => void;
  };
  currentUser: CurrentUser;
}

const AccountLayoutProvider = createContext<
  AccountLayoutContextProps | undefined
>(undefined);

const useAccountLayoutContext = createUseContext(
  AccountLayoutProvider,
  "AccountLayoutContext"
);

export default AccountLayoutProvider.Provider;
export { useAccountLayoutContext };
