import { mixed, object, SchemaOf, string } from "yup";

import {
  PIN_VALIDATION_SCHEMA,
  VERIFICATION_CODE_VALIDATION_SCHEMA
} from "~/components/payment/validation";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { ResetPinForm } from "~/components/wallet/WalletResetPinDialog/declarations";
import { REQUIRED_SCHEMA } from "~/constants/validation/common";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const RESET_PIN_DIALOG_FORM_ID = "reset-pin-dialog-form";

export const INITIAL_VALUES: ResetPinForm = {
  pin: "",
  password: "",
  verificationCode: ""
};

export const generateValidationSchema = (
  hasPassword: boolean
): SchemaOf<ResetPinForm> =>
  object({
    pin: REQUIRED_SCHEMA.concat(PIN_VALIDATION_SCHEMA),
    password: mixed().when([], {
      is: () => hasPassword,
      then: REQUIRED_SCHEMA.concat(string()),
      otherwise: object()
    }),
    verificationCode: mixed().when([], {
      is: () => !hasPassword,
      then: REQUIRED_SCHEMA.concat(VERIFICATION_CODE_VALIDATION_SCHEMA),
      otherwise: object()
    })
  });

export const WALLET_RESET_PIN_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.resetWalletPin> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletResetPinDialog"),
      {
        ssr: false
      }
    )
  };
