import { createContext } from "react";

import { RE_CAPTCHA_ACTION } from "~/constants/reCaptcha";
import createUseContext from "~/utils/createUseContext";

type ReCaptchaExecuteHandler = (action: RE_CAPTCHA_ACTION) => Promise<string>;

export type ReCaptchaContextProps = {
  executeReCaptcha: ReCaptchaExecuteHandler | null;
};

export const ReCaptchaContext = createContext<
  ReCaptchaContextProps | undefined
>(undefined);

export const useReCaptchaContext = createUseContext(
  ReCaptchaContext,
  "ReCaptcha"
);
