export enum DIALOG_NAME {
  walletMaintenance = "walletMaintenance",
  postPurchase = "postPurchaseDialog",
  buyCoins = "buyCoinsDialog",
  buyCoinsByXdbPendingDialog = "buyCoinsByXdbPendingDialog",
  buyCoinsByXdbSuccessDialog = "buyCoinsByXdbSuccessDialog",
  walletTransactionInProgress = "walletTransactionInProgress",
  walletConfirmation = "walletConfirmation",
  resetWalletPin = "resetWalletPin",
  dropWallet = "dropWallet",
  setUpWalletSuccess = "setUpWalletSuccess",
  walletKeyDroppedWarning = "walletKeyDroppedWarning",
  createWallet = "createWallet",
  createWalletKey = "createWalletKey",
  tipPost = "tipPost",
  tipStream = "tipStream",
  verifyEmailToCashOut = "verityEmailToCashOut",
  notEnoughFundsToCashOut = "notEnoughFundsToCashOut",
  payoutRequirements = "payoutRequirements",
  cashOutLimitExceeded = "cashOutLimitExceeded",
  payoutSuccess = "payoutSuccess",
  payout = "payout",
  payoutSetupPending = "payoutSetupPending",
  payoutAccountSetup = "payoutAccountSetup",
  payoutAccountSetupFailed = "payoutAccountSetupFailed",
  purchaseNft = "purchaseNft",
  purchaseNftSuccess = "purchaseNftSuccess",
  purchaseNftFailure = "purchaseNftFailure",
  nftCharities = "nftCharities",
  nftRoyaltyDetails = "nftRoyaltyDetails",
  walletInfo = "walletInfo",
  walletMnemonic = "walletMnemonic",
  walletQRCode = "walletQRCode",
  walletSynchronizationMnemonicInput = "walletSynchronizationMnemonicInput",
  walletSynchronizationPin = "walletSynchronizationPin",
  walletSynchronizationSuccess = "walletSynchronizationSuccess",
  walletSynchronizationWarning = "walletSynchronizationWarning",
  walletSynchronizationError = "walletSynchronizationError",
  walletSynchronizationMethodSelector = "walletSynchronizationMethodSelector",
  payoutBankAccountSetup = "payoutBankAccountSetup",
  astraXInstallInstructions = "astraXInstallInstructions",
  xdbConnectToAstraX = "xdbConnectToAstraX",
  xdbWalletConnected = "xdbWalletConnected",
  xdbNftImport = "xdbNftImport",
  xdbNftImportPin = "xdbNftImportPin",
  xdbNftImportError = "xdbNftImportError",
  xdbNftImportSuccess = "xdbNftImportSuccess",
  xdbNftImportProcess = "xdbNftImportProcess",
  xdbNftExport = "xdbNftExport",
  xdbNftExportAddressInput = "xdbNftExportAddressInput",
  xdbNftExportCreateTrust = "xdbNftExportCreateTrust",
  xdbNftExportSuccess = "xdbNftExportSuccess",
  xdbNftExportWarning = "xdbNftExportWarning",
  freeNftPurchaseLimitExceeded = "freeNftPurchaseLimitExceeded",
  canNotExportNftToXdbWarning = "canNotExportNftToXdbWarning",
  walletPayoutDisabled = "walletPayoutDisabled"
}

export enum COMMON_DIALOG_CLOSE_REASON {
  cancelButton = "cancelButton",
  closeButton = "closeButton",
  escapeButton = "escapeButton",
  backdrop = "backdrop",
  error = "error",
  openingAnotherView = "openingAnotherView",
  noLongerRelevant = "noLongerRelevant"
}

export enum COMMON_DIALOG_STATUS {
  closed = "closed",
  success = "success"
}

export enum COMMON_DIALOG_ERROR_CODE {
  alreadyOpen = "alreadyOpen"
}
