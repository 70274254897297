import { createContext } from "react";

import createUseContext from "~/utils/createUseContext";

import { NftReportDialogPayload } from "./declarations";

export interface NftReportContextProps {
  openDialog: (props: NftReportDialogPayload) => void;
}

const NftReportContext = createContext<NftReportContextProps | undefined>(
  undefined
);

const useNftReportContext = createUseContext(
  NftReportContext,
  "NftReportContext"
);

export default NftReportContext.Provider;
export { useNftReportContext };
