import { useCallback, useState } from "react";

import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogState,
  InMemoryDialogsState
} from "~/components/providers/DialogProvider/declarations/state";

type UseDialogProviderInMemoryStateResult = {
  state: InMemoryDialogsState<DIALOG_NAME>;
  setDialogClosed: (dialogName: DIALOG_NAME) => void;
  addDialog: (dialogState: DialogState<DIALOG_NAME>) => void;
  deleteDialog: (dialogName: DIALOG_NAME) => void;
  resetState: () => void;
};

const useDialogProviderInMemoryState =
  (): UseDialogProviderInMemoryStateResult => {
    const [state, setState] = useState<InMemoryDialogsState<DIALOG_NAME>>([]);

    const resetState = useCallback(() => {
      setState([]);
    }, []);

    const setDialogClosed = useCallback((dialogName: DIALOG_NAME) => {
      setState(dialogs =>
        dialogs.map(dialog => {
          if (dialog.name === dialogName) {
            return { ...dialog, open: false };
          }

          return dialog;
        })
      );
    }, []);

    const addDialog = useCallback((dialogState: DialogState<DIALOG_NAME>) => {
      setState(state => [...state, dialogState]);
    }, []);

    const deleteDialog = useCallback((dialogName: DIALOG_NAME) => {
      setState(state => state.filter(({ name }) => name !== dialogName));
    }, []);

    return {
      state,
      setDialogClosed,
      addDialog,
      deleteDialog,
      resetState
    };
  };

export default useDialogProviderInMemoryState;
