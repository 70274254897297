import { ReactNode, useState } from "react";

import { Me_me } from "~/declarations/apollo/Me";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

import {
  ProblemReportDialogContext,
  ProblemReportDialogPayload
} from "./ProblemReportDialogContext";

const ProblemReportDialog = safeDynamicImport(
  () => import("~/components/core/ProblemReportDialog"),
  {
    ssr: false
  }
);

type Props = {
  children: ReactNode;
};

const ProblemReportDialogProvider = ({ children }: Props): JSX.Element => {
  const [person, setPerson] = useState<Me_me | undefined>();
  const dialog = useDialog<ProblemReportDialogPayload>();

  const handleSetReportUser = (user: Me_me): void => {
    setPerson(user);
  };

  return (
    <ProblemReportDialogContext.Provider
      value={{ ...dialog, handleSetReportUser }}
    >
      {children}
      {dialog.hasBeenOpened && dialog.payload && (
        <ProblemReportDialog
          open={dialog.open}
          order={dialog.payload.order}
          onClose={dialog.closeDialog}
          type={dialog.payload.type}
          person={person}
          sentryEventId={dialog.payload?.sentryEventId}
        />
      )}
    </ProblemReportDialogContext.Provider>
  );
};

export default ProblemReportDialogProvider;
