import { string } from "yup";

import { REQUIRED_SCHEMA } from "~/constants/validation/common";

import {
  NUMBER_OF_VERIFICATION_CODE_DIGITS,
  NUMBER_OF_WALLET_PIN_DIGITS
} from "../wallet/constants";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const getAmountAsStringValidationSchema = (min: number, max?: number) =>
  REQUIRED_SCHEMA.concat(
    string()
      .test(
        "amountAsString",
        () => ({
          key: "validation_number_min",
          options: {
            value: min
          }
        }),
        amountAsString =>
          Boolean(amountAsString && parseFloat(amountAsString) >= min)
      )
      .test(
        "amountAsString",
        () => ({
          key: "validation_number_max",
          options: {
            value: max
          }
        }),
        amountAsString =>
          Boolean(!max || (amountAsString && parseFloat(amountAsString) <= max))
      )
  );

export const PIN_VALIDATION_SCHEMA = string().length(
  NUMBER_OF_WALLET_PIN_DIGITS,
  () => ({
    key: "pin_length",
    options: {
      value: NUMBER_OF_WALLET_PIN_DIGITS
    }
  })
);
export const VERIFICATION_CODE_VALIDATION_SCHEMA = string().length(
  NUMBER_OF_VERIFICATION_CODE_DIGITS,
  () => ({
    key: "verification-code_length",
    options: {
      value: NUMBER_OF_VERIFICATION_CODE_DIGITS
    }
  })
);

export const MAX_DONATION_MESSAGE_LENGTH = 300;
export const MIN_TIPPING_AMOUNT = 0.1;
export const MAX_TIPPING_AMOUNT = 9999;
export const MAX_TIPPING_AMOUNT_DECIMAL_DIGITS = 2;
