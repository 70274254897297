/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "ProfileGradient-5182c8ae--sprite",
  "use": "ProfileGradient-5182c8ae--sprite-usage",
  "viewBox": "0 0 48 48",
  "content": "<symbol fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 48 48\" id=\"ProfileGradient-5182c8ae--sprite\"><circle cx=\"24\" cy=\"24\" r=\"24\" fill=\"#171717\" /><mask id=\"ProfileGradient-5182c8ae--sprite_a\" mask-type=\"alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"48\" height=\"48\"><circle cx=\"24\" cy=\"24\" r=\"24\" fill=\"#171717\" /></mask><g mask=\"url(#ProfileGradient-5182c8ae--sprite_a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 33.312c1.717-4.976 6.442-8.55 12.001-8.55 5.56 0 10.284 3.575 12.001 8.55a19.369 19.369 0 0 1-12 4.143A19.369 19.369 0 0 1 12 33.312zm17.934-16.476a5.836 5.836 0 1 1-11.671 0 5.836 5.836 0 0 1 11.67 0z\" fill=\"url(#ProfileGradient-5182c8ae--sprite_b)\" /></g><defs><linearGradient id=\"ProfileGradient-5182c8ae--sprite_b\" x1=\"-9.771\" y1=\"2.903\" x2=\"7.139\" y2=\"51.605\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#36FDDE\" /><stop offset=\".561\" stop-color=\"#F4FDD0\" /><stop offset=\".975\" stop-color=\"#F5DAF8\" /><stop offset=\"1\" stop-color=\"#F5D8FA\" /></linearGradient></defs></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
