import { Theme } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import { DefaultTheme } from "styled-components";

import { THEME_MODE } from "~/constants/theme";
import { THEME_BY_TYPE } from "~/theme/theme";
import { isServerSide } from "~/utils/common";

import { ThemeModeToggleHandler } from "../AppThemeModeProvider/AppThemeModeContext";

type UseAppThemeResult = {
  muiTheme: Theme;
  styledComponentsTheme: DefaultTheme;
  toggleTheme: ThemeModeToggleHandler;
};

const THEME_MODE_LS_KEY = "themeMode";
const DEFAULT_THEME_MODE = THEME_MODE.light;

const getInitialThemeMode = (): THEME_MODE => {
  if (process.env.NEXT_PUBLIC_DARK_MODE_ENABLED !== "true" || isServerSide()) {
    return DEFAULT_THEME_MODE;
  }

  const fallbackTheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? THEME_MODE.dark
      : THEME_MODE.light;

  return (localStorage.getItem(THEME_MODE_LS_KEY) ??
    fallbackTheme) as THEME_MODE;
};

const useAppTheme = (): UseAppThemeResult => {
  const [themeMode, setThemeMode] = useState<THEME_MODE>(DEFAULT_THEME_MODE);

  useEffect(() => {
    setThemeMode(getInitialThemeMode());
  }, []);

  const toggleTheme = useCallback(() => {
    const nextTheme =
      themeMode === THEME_MODE.dark ? THEME_MODE.light : THEME_MODE.dark;

    setThemeMode(nextTheme);
    localStorage.setItem(THEME_MODE_LS_KEY, nextTheme);
  }, [themeMode]);

  const { muiTheme, styledComponentsTheme } = THEME_BY_TYPE[themeMode];

  return {
    muiTheme,
    styledComponentsTheme,
    toggleTheme
  };
};

export default useAppTheme;
