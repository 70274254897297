import { Client, ClientOptions, createClient } from "graphql-ws";

import { MILLISECONDS_IN_SECOND } from "~/constants/date";

export type RestartableClient = Client & {
  restart(): void;
};

export type RestartableClientOptions = ClientOptions & {
  onReconnected?: () => void;
  onDisconnected?: () => void;
};

const TIME_TO_WAIT_PONG_IN_MILLISECONDS = 7 * MILLISECONDS_IN_SECOND;

enum AdditionalCloseCodes {
  clientRestart = 4205,
  requestTimeout = 4400
}

export const createRestartableClient = ({
  onReconnected,
  onDisconnected,
  ...options
}: RestartableClientOptions): RestartableClient => {
  let restartRequested = false;
  let activeSocket: WebSocket | null = null;
  let pongWaitTimer = 0;
  let connectionCounter = 0;

  let restart = (): void => {
    restartRequested = true;
  };

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: (socket: unknown) => {
        options.on?.opened?.(socket);

        restart = () => {
          if ((socket as WebSocket).readyState === WebSocket.OPEN) {
            (socket as WebSocket).close(
              AdditionalCloseCodes.clientRestart,
              "Client Restart"
            );
          } else {
            restartRequested = true;
          }

          connectionCounter = 0;
        };

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false;
          restart();
        }
      },
      connected: (socket, payload) => {
        options.on?.connected?.(socket, payload);

        if (connectionCounter > 0) {
          onReconnected?.();
        }

        connectionCounter++;

        activeSocket = socket as WebSocket;
      },
      closed: event => {
        options.on?.closed?.(event);

        window.clearTimeout(pongWaitTimer);
        onDisconnected?.();
      },
      ping: received => {
        if (!received) {
          pongWaitTimer = window.setTimeout(() => {
            if (activeSocket && activeSocket.readyState === WebSocket.OPEN) {
              activeSocket.close(
                AdditionalCloseCodes.requestTimeout,
                "Request Timeout"
              );
            }
          }, TIME_TO_WAIT_PONG_IN_MILLISECONDS);
        }
      },
      pong: received => {
        if (received) {
          window.clearTimeout(pongWaitTimer);
        }
      }
    }
  });

  return {
    ...client,
    restart: () => restart()
  };
};
