const { Router } = require("next/router");
const ROUTES = require("./constants/routes");

const hoistNonReactStatics = require("hoist-non-react-statics");

// namespace shared between all pages
const COMMON_NAMESPACES = [
  "common",
  "user",
  "auth",
  "report",
  "routes",
  "meta",
  "form"
];

const ACCOUNT_LAYOUT_NAMESPACES = [
  "media",
  "files",
  "payments", // generic payment keys (tipping/buying content etc.)
  "notifications",
  "messaging",
  "post",
  "comment"
];

const POSTS_DISCOVER_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "postForm",
  "discover"
];

const HOME_NAMESPACES = [...POSTS_DISCOVER_NAMESPACES, "posts", "home"];

const PROFILE_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "profile",
  "files",
  "nft"
];

const NFT_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "nft"];

const POSTS_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "posts"];

const HYPETV_DISCOVER_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "discover"];

const POSTS_CREATE_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "postForm"];

const POSTS_EDIT_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "postForm"];

const HYPETV_CREATE_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "postForm"];

const HYPETV_EDIT_ID_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "postForm"];

const LIVE_CREATE_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "postForm"];

const LIVE_EDIT_ID_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "postForm"];

const LIVE_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "streaming"];

const LIVE_DISCOVER_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "discover",
  "streaming"
];

const NFT_DISCOVER_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "discover",
  "nft"
];

const LIVE_ID_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "chat",
  "streaming",
  "postForm"
];

const SETTINGS_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "settings"];

const MESSAGING_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "profile", "nft"];

const PRIVACY_POLICY_PASSWORD_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "privacyPolicy"
];

const TERMS_OF_SERVICE_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "termsOfService"
];

const CONTENT_POLICY_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "contentPolicy"
];

const NFT_TERMS_NAMESPACES = [...ACCOUNT_LAYOUT_NAMESPACES, "nftTerms"];

const WALLET_NAMESPACES = [
  ...ACCOUNT_LAYOUT_NAMESPACES,
  "wallet",
  "nft",
  "bankAccount"
];

const CHUNK_LOAD_ERROR_NAME = "ChunkLoadError";

module.exports = {
  logBuild: false,
  locales: ["en"],
  defaultLocale: "en",
  staticsHoc: hoistNonReactStatics,
  loadLocaleFrom: (language, namespace) =>
    // TODO: watch this issue because all namespaces are loaded now in _app.js
    // https://github.com/vercel/next.js/issues/33394
    import(`./public/locales/${language}/${namespace}.json`)
      .then(m => m.default)
      .catch(error => {
        if (error.name !== CHUNK_LOAD_ERROR_NAME) {
          throw error;
        }

        /* TODO: Find a way to send a sentry report to collect statistics about these errors.
         */
        console.error(
          `Absent translation. Namespace: ${namespace}. Language: ${language}`,
          error
        );

        if (typeof "window" !== undefined) {
          const handleRouteChangeComplete = () => {
            Router.events.off("routeChangeComplete", handleRouteChangeComplete);

            window.location.reload();
          };

          Router.events.on("routeChangeComplete", handleRouteChangeComplete);
        }

        return {};
      }),
  pages: {
    "*": COMMON_NAMESPACES,
    [ROUTES.index]: HOME_NAMESPACES,
    [ROUTES.auth.login]: [],
    [ROUTES.auth.signup.index]: [],
    [ROUTES.auth.resetPassword]: [],

    [`rgx:^${ROUTES.profile.index}*`]: PROFILE_NAMESPACES,

    [ROUTES.nft.index]: NFT_NAMESPACES,
    [ROUTES.nft.instance]: NFT_NAMESPACES,
    [ROUTES.nft.editions]: NFT_NAMESPACES,

    [ROUTES.posts.discover]: POSTS_DISCOVER_NAMESPACES,
    [ROUTES.posts.index]: POSTS_NAMESPACES,
    [ROUTES.posts.instance]: ACCOUNT_LAYOUT_NAMESPACES,
    [ROUTES.posts.create]: POSTS_CREATE_NAMESPACES,
    [ROUTES.posts.edit]: POSTS_EDIT_NAMESPACES,

    [ROUTES.hypetv.discover]: HYPETV_DISCOVER_NAMESPACES,
    [ROUTES.hypetv.index]: ACCOUNT_LAYOUT_NAMESPACES,
    [ROUTES.hypetv.instance]: ACCOUNT_LAYOUT_NAMESPACES,
    [ROUTES.hypetv.create]: HYPETV_CREATE_NAMESPACES,
    [ROUTES.hypetv.edit]: HYPETV_EDIT_ID_NAMESPACES,
    [ROUTES.hypetv.followed]: ACCOUNT_LAYOUT_NAMESPACES,

    [ROUTES.live.index]: LIVE_NAMESPACES,
    [ROUTES.live.discover]: LIVE_DISCOVER_NAMESPACES,
    [ROUTES.live.web.instance]: LIVE_ID_NAMESPACES,
    [ROUTES.live.create]: LIVE_CREATE_NAMESPACES,
    [ROUTES.live.edit]: LIVE_EDIT_ID_NAMESPACES,
    [ROUTES.live.mobile.instance]: LIVE_NAMESPACES,

    [ROUTES.nft.discover]: NFT_DISCOVER_NAMESPACES,
    [ROUTES.nft.create]: NFT_NAMESPACES,

    [`rgx:^${ROUTES.wallet.index}*`]: WALLET_NAMESPACES,

    [ROUTES.settings.index]: SETTINGS_NAMESPACES,
    [ROUTES.settings.security]: SETTINGS_NAMESPACES,
    [ROUTES.settings.privacy]: SETTINGS_NAMESPACES,
    [ROUTES.settings.personalization]: SETTINGS_NAMESPACES,
    [ROUTES.settings.notifications]: SETTINGS_NAMESPACES,
    [ROUTES.settings.wallet]: SETTINGS_NAMESPACES,
    [ROUTES.settings.help]: SETTINGS_NAMESPACES,
    [ROUTES.settings.about]: [...SETTINGS_NAMESPACES, "user"],
    [ROUTES.search]: ACCOUNT_LAYOUT_NAMESPACES,
    [ROUTES.persons]: ACCOUNT_LAYOUT_NAMESPACES,
    [ROUTES.messaging]: MESSAGING_NAMESPACES,

    [ROUTES.legal.termsOfService]: TERMS_OF_SERVICE_NAMESPACES,
    [ROUTES.legal.privacyPolicy]: PRIVACY_POLICY_PASSWORD_NAMESPACES,
    [ROUTES.legal.contentPolicy]: CONTENT_POLICY_NAMESPACES,
    [ROUTES.legal.nftTerms]: NFT_TERMS_NAMESPACES,

    [ROUTES.page404]: ACCOUNT_LAYOUT_NAMESPACES,

    [ROUTES.experiments.components]: [...ACCOUNT_LAYOUT_NAMESPACES],
    [ROUTES.experiments.notifications]: [...ACCOUNT_LAYOUT_NAMESPACES],

    [`rgx:^${ROUTES.blog.index}*`]: [...ACCOUNT_LAYOUT_NAMESPACES, "blog"],

    [`rgx:^${ROUTES.helpCenter.index}*`]: [
      ...ACCOUNT_LAYOUT_NAMESPACES,
      "helpCenter"
    ]
  }
};
