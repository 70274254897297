import { AuthenticationMethodButtonTranslations } from "~/components/auth/AuthMethodsButtonGroup/declarations";
import { SocialType } from "~/declarations/apollo/globalTypes";
import {
  AUTHENTICATION_METHOD,
  THIRD_PARTY_AUTHENTICATION_METHOD
} from "~/declarations/authentication";

export const THIRD_PARTY_AUTHENTICATION_METHOD_TO_SOCIAL_TYPE = {
  [AUTHENTICATION_METHOD.apple]: SocialType.apple,
  [AUTHENTICATION_METHOD.google]: SocialType.google
};

export const SOCIAL_TYPE_TO_THIRD_PARTY_AUTHENTICATION_METHOD: {
  [key in SocialType]: THIRD_PARTY_AUTHENTICATION_METHOD;
} = {
  [SocialType.apple]: AUTHENTICATION_METHOD.apple,
  [SocialType.google]: AUTHENTICATION_METHOD.google
};

export const LOGIN_METHOD_BUTTON_TRANSLATIONS: AuthenticationMethodButtonTranslations =
  {
    [AUTHENTICATION_METHOD.apple]: "auth_method-selector_login-by-apple_button",
    [AUTHENTICATION_METHOD.email]: "auth_method-selector_login-by-email_button",
    [AUTHENTICATION_METHOD.google]:
      "auth_method-selector_login-by-google_button"
  };

export const SIGNUP_METHOD_BUTTON_TRANSLATIONS: AuthenticationMethodButtonTranslations =
  {
    [AUTHENTICATION_METHOD.apple]:
      "auth_method-selector_signup-by-apple_button",
    [AUTHENTICATION_METHOD.email]:
      "auth_method-selector_signup-by-email_button",
    [AUTHENTICATION_METHOD.google]:
      "auth_method-selector_signup-by-google_button"
  };

export const INCORRECT_RE_CAPTCHA_ERROR_CODE =
  "validation.recaptcha.incorrectCaptcha";

export const REGISTRATION_TEMPORARY_UNAVAILABLE_ERROR_CODE =
  "person.exception.registration.disabled";
