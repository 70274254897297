import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const BUY_COINS_BY_XDB_SUCCESS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.buyCoinsByXdbSuccessDialog> =
  {
    Component: safeDynamicImport(
      () =>
        import(
          "~/components/payment/BuyCoinsDialog/BuyCoinsByXdbSuccessDialog"
        ),
      {
        ssr: false
      }
    )
  };
