import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($request: LoginRequest!) {
    login(request: $request) {
      accessToken {
        value
        expiresAt
      }
      refreshToken {
        value
        expiresAt
      }
      getStreamToken {
        value
      }
    }
  }
`;

export const RESET_PASSWORD_VIA_EMAIL_QUERY = gql`
  mutation ResetPasswordViaEmail($email: String!, $captcha: String!) {
    resetRequestViaEmailV2(email: $email, captcha: $captcha)
  }
`;

export const SET_PASSWORD_VIA_EMAIL_MUTATION = gql`
  mutation SetPasswordViaEmail(
    $request: NonAuthPasswordResetWithEmailRequest!
    $captcha: String!
  ) {
    resetPasswordWithEmailV2(request: $request, captcha: $captcha)
  }
`;

export const SIGNUP_BY_EMAIL_MUTATION = gql`
  mutation SignupByEmail(
    $request: RegistrationWithVerificationRequest!
    $captcha: String!
  ) {
    registerAsPersonV2(request: $request, captcha: $captcha) {
      id
    }
  }
`;

export const SIGNUP_BY_SOCIAL_NETWORK_MUTATION = gql`
  mutation SignupBySocialNetwork(
    $request: RegistrationSocialRequest!
    $captcha: String!
  ) {
    registerSocialV2(request: $request, captcha: $captcha) {
      person {
        id
      }
      token {
        accessToken {
          value
        }
        getStreamToken {
          value
        }
      }
    }
  }
`;

export const VALIDATE_EMAIL = gql`
  query ValidateEmail(
    $email: String!
    $checkIfExists: Boolean
    $checkReservation: Boolean
  ) {
    validateEmail(
      email: $email
      checkIfExists: $checkIfExists
      checkReservation: $checkReservation
    ) {
      status
    }
  }
`;

export const VALIDATE_PASSWORD = gql`
  query ValidatePassword($password: String!) {
    validatePassword(password: $password) {
      status
    }
  }
`;

export const VALIDATE_USERNAME = gql`
  query ValidateUsername(
    $username: String!
    $checkIfExists: Boolean
    $checkReservation: Boolean
  ) {
    validateNickname(
      nickname: $username
      checkIfExists: $checkIfExists
      checkReservation: $checkReservation
    ) {
      status
    }
  }
`;

export const VALIDATE_DISPLAY_NAME = gql`
  query ValidateDisplayName($displayName: String!) {
    validateName(name: $displayName) {
      status
    }
  }
`;
