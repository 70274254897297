import { css, FlattenSimpleInterpolation } from "styled-components";

import { SHADES_GRADE } from "~/constants/theme";
import { ShadesGrade } from "~/theme/declarations";
import { hexToRgba } from "~/utils/style";

export const generateShadesPalette = (baseColor: string): ShadesGrade =>
  Object.fromEntries(
    SHADES_GRADE.map(item => [item, hexToRgba(baseColor, item / 100)])
  ) as ShadesGrade;

export const gradientText = (
  gradient: string,
  fallback: string
): FlattenSimpleInterpolation => css`
  color: ${fallback};
  @supports (-webkit-text-fill-color: transparent) {
    // Order is mandatory here
    // eslint-disable-next-line better-styled-components/sort-declarations-alphabetically
    background: ${gradient};
    display: inline; // safari invisible background-clip fix
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
