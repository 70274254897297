import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";

import IconButton from "~/components/core/IconButton";
import MenuLogotype from "~/components/core/MenuLogotype";
import { safeDynamicImport } from "~/utils/import/dynamic";

const AccountHeaderMobileMenuItemsAuthorized = safeDynamicImport(
  () => import("./AccountHeaderMobileMenuItemsAuthorized"),
  {
    ssr: false
  }
);

const AccountHeaderMobileMenuItemsUnauthorized = safeDynamicImport(
  () => import("./AccountHeaderMobileMenuItemsUnauthorized"),
  {
    ssr: false
  }
);

export type Props = {
  open: boolean;
  onClose: () => void;
  authorized: boolean;
  authorizationStatusFetched: boolean;
  onClickLogin: () => void;
  onClickSignup: () => void;
  onClickReport?: () => void;
  onClickLeaveFeedback?: () => void;
  onClickContactUs?: () => void;
};

const AccountHeaderMobileMenu = ({
  open,
  authorized,
  authorizationStatusFetched,
  onClose,
  onClickReport,
  onClickLogin,
  onClickSignup,
  onClickLeaveFeedback,
  onClickContactUs
}: Props): JSX.Element => (
  <StyledDialog open={open} onClose={onClose} fullScreen>
    <MenuWrapper>
      <MenuHeader>
        <MenuLogotype />
        <IconButton icon="close" onClick={onClose} />
      </MenuHeader>
      {authorizationStatusFetched && (
        <>
          {authorized ? (
            <AccountHeaderMobileMenuItemsAuthorized
              onClickReport={onClickReport}
              onClickLeaveFeedback={onClickLeaveFeedback}
              onClickContactUs={onClickContactUs}
            />
          ) : (
            <AccountHeaderMobileMenuItemsUnauthorized
              onClickLogin={onClickLogin}
              onClickSignup={onClickSignup}
              onClickContactUs={onClickContactUs}
            />
          )}
        </>
      )}
    </MenuWrapper>
  </StyledDialog>
);

export default AccountHeaderMobileMenu;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.newTheme.background.octonary};
  }
`;

const MenuWrapper = styled.nav`
  padding: 0 4px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 0 12px;
  }
`;

const MenuHeader = styled.div`
  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.layouts.account.headerHeight}px;
  justify-content: space-between;
  padding: 0 12px;
`;
