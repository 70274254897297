import { createContext } from "react";

import { ApolloSubscriptionsConnectionEventsContextProps } from "~/components/layouts/ApolloSubscriptionsConnectionEventsProvider/declarations";
import createUseContext from "~/utils/createUseContext";

export const ApolloSubscriptionsConnectionEventsContext = createContext<
  ApolloSubscriptionsConnectionEventsContextProps | undefined
>(undefined);

export const useApolloSubscriptionsConnectionEventsContext = createUseContext(
  ApolloSubscriptionsConnectionEventsContext,
  "ApolloSubscriptionsConnectionEventsContext"
);
