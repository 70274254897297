/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionType {
  accepted = "accepted",
  declined = "declined",
  none = "none",
}

export enum ActivityType {
  channelInvites = "channelInvites",
  notifications = "notifications",
  personRequests = "personRequests",
}

export enum AttachmentMoneyStatus {
  draft = "draft",
  pending = "pending",
  transferred = "transferred",
}

export enum AttachmentType {
  AttachmentFile = "AttachmentFile",
  AttachmentGif = "AttachmentGif",
  AttachmentImage = "AttachmentImage",
  AttachmentMoney = "AttachmentMoney",
  AttachmentNft = "AttachmentNft",
  AttachmentPost = "AttachmentPost",
  AttachmentVideo = "AttachmentVideo",
}

export enum BalanceDetailType {
  gasFee = "gasFee",
  profitSplit = "profitSplit",
  received = "received",
  royalty = "royalty",
  sent = "sent",
  systemFeeCommission = "systemFeeCommission",
  systemFeeFixed = "systemFeeFixed",
}

export enum BuyHypesFeeType {
  bitpay = "bitpay",
  stripe = "stripe",
}

export enum CheckingStatus {
  allow = "allow",
  deny = "deny",
}

export enum CommentableType {
  post = "post",
  stream = "stream",
}

export enum ContentOrientation {
  horizontal = "horizontal",
  square = "square",
  vertical = "vertical",
}

export enum EditionFilter {
  all = "all",
  availableForSale = "availableForSale",
  ownedByMe = "ownedByMe",
}

export enum EditionStatus {
  blocked = "blocked",
  deleted = "deleted",
  deleting = "deleting",
  lost = "lost",
  minted = "minted",
  new = "new",
  selling = "selling",
  sold = "sold",
  startSelling = "startSelling",
  stopSelling = "stopSelling",
}

export enum EditionsOrder {
  cost = "cost",
  number = "number",
}

export enum FeeType {
  stripe = "stripe",
}

export enum GroupConversationInvitePolicy {
  admins = "admins",
  members = "members",
}

export enum GroupConversationKind {
  announcement = "announcement",
  community = "community",
}

export enum GroupConversationPrivacy {
  private = "private",
  public = "public",
}

export enum GroupParticipantFilterByRole {
  admin = "admin",
  all = "all",
  banned = "banned",
}

export enum GroupParticipantRole {
  admin = "admin",
  banned = "banned",
  candidate = "candidate",
  guest = "guest",
  member = "member",
  owner = "owner",
}

export enum InvoiceReason {
  buyHypes = "buyHypes",
}

export enum InvoiceStatus {
  cancelled = "cancelled",
  completed = "completed",
  pending = "pending",
}

export enum MessengerSendPrivacy {
  everyone = "everyone",
  myFollowings = "myFollowings",
  nobody = "nobody",
}

export enum MyInvoicesListOrder {
  createdAt = "createdAt",
}

export enum MyPremiumPostsOrder {
  datePosted = "datePosted",
  premiumPrice = "premiumPrice",
  totalEarned = "totalEarned",
}

export enum MyReceivedTipsOrder {
  datePosted = "datePosted",
  premiumPrice = "premiumPrice",
  totalEarned = "totalEarned",
}

export enum NftPlatform {
  hyprr = "hyprr",
  xdb = "xdb",
}

export enum NftRarity {
  rare = "rare",
  regular = "regular",
  superRare = "superRare",
}

export enum NftRarityCategory {
  all = "all",
  rare = "rare",
  rareOrSuperRare = "rareOrSuperRare",
  superRare = "superRare",
}

export enum NftStatus {
  deleted = "deleted",
  draft = "draft",
  minting = "minting",
  processing = "processing",
  published = "published",
  readyForMint = "readyForMint",
}

export enum NotificationSingleSwitcher {
  off = "off",
  on = "on",
}

export enum NotificationStatus {
  created = "created",
  read = "read",
  sent = "sent",
}

export enum OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum PasswordStrength {
  low = "low",
  middle = "middle",
  strong = "strong",
}

export enum PayoutType {
  skrill = "skrill",
  stripe = "stripe",
}

export enum PaywallProtectionStatus {
  bought = "bought",
  free = "free",
  protected = "protected",
  selling = "selling",
}

export enum PersonConfidentiality {
  private = "private",
  public = "public",
}

export enum PersonFollowingStatus {
  active = "active",
  blocked = "blocked",
  invited = "invited",
  pending = "pending",
  unsubscribed = "unsubscribed",
}

export enum PersonStatus {
  active = "active",
  deleted = "deleted",
}

export enum Platform {
  android = "android",
  ios = "ios",
  web = "web",
}

export enum PostAccessibilityType {
  free = "free",
  nftUnlock = "nftUnlock",
  premium = "premium",
}

export enum PostConfidentiality {
  private = "private",
  public = "public",
}

export enum PostPreviewType {
  image = "image",
  video = "video",
  videoCustomSegment = "videoCustomSegment",
  videoStartSegment = "videoStartSegment",
}

export enum PostStatus {
  deleted = "deleted",
  draft = "draft",
  hidden = "hidden",
  preModeration = "preModeration",
  published = "published",
  videoConvertFailed = "videoConvertFailed",
  waitForVideo = "waitForVideo",
}

export enum PostType {
  long = "long",
  longStream = "longStream",
  short = "short",
  shortStream = "shortStream",
}

export enum ProfileNftsOrderField {
  cost = "cost",
  published = "published",
}

export enum ProfileNftsOwningType {
  collected = "collected",
  created = "created",
}

export enum PurchasedFilterType {
  all = "all",
  hypetv = "hypetv",
  post = "post",
  stream = "stream",
}

export enum QuoteType {
  forward = "forward",
  reply = "reply",
}

export enum ReactableType {
  comment = "comment",
  message = "message",
  nft = "nft",
  post = "post",
}

export enum ReactionType {
  embarrassment = "embarrassment",
  glasses = "glasses",
  haha = "haha",
  like = "like",
  love = "love",
  rock = "rock",
  smile = "smile",
}

export enum SimpleSearchType {
  all = "all",
  hashtags = "hashtags",
  persons = "persons",
  videos = "videos",
}

export enum SocialType {
  apple = "apple",
  google = "google",
}

export enum StreamStatus {
  broadcastFinished = "broadcastFinished",
  conversionVideoFailed = "conversionVideoFailed",
  finished = "finished",
  live = "live",
  paused = "paused",
  pending = "pending",
  testing = "testing",
}

export enum StripeAccountStatus {
  failed = "failed",
  linked = "linked",
  new = "new",
  notCreated = "notCreated",
  pending = "pending",
  ready = "ready",
  verified = "verified",
  waiting = "waiting",
}

export enum StripeBankAccountUserFieldType {
  number = "number",
  string = "string",
}

export enum SupportCasePlatform {
  android = "android",
  ios = "ios",
  web = "web",
}

export enum TransactionCategory {
  all = "all",
  buyHypes = "buyHypes",
  buyNft = "buyNft",
  cashOut = "cashOut",
  mintNft = "mintNft",
  premium = "premium",
  returnHypes = "returnHypes",
  royaltyNft = "royaltyNft",
  sendHypes = "sendHypes",
  tip = "tip",
}

export enum TransactionType {
  income = "income",
  outcome = "outcome",
}

export enum TransactionsOrder {
  amount = "amount",
  date = "date",
}

export enum TransferReason {
  balanceReplenishment = "balanceReplenishment",
  buyEdition = "buyEdition",
  buyLongPost = "buyLongPost",
  buyLongStream = "buyLongStream",
  buyNft = "buyNft",
  buyShortPost = "buyShortPost",
  buyShortStream = "buyShortStream",
  createNftCollection = "createNftCollection",
  donateForStream = "donateForStream",
  mintEdition = "mintEdition",
  mintNft = "mintNft",
  own = "own",
  post = "post",
  return = "return",
  returnHype = "returnHype",
  sellHype = "sellHype",
  sendMoney = "sendMoney",
  sendProfit = "sendProfit",
  sendRoyalty = "sendRoyalty",
  startSellingEdition = "startSellingEdition",
  startSellingEditions = "startSellingEditions",
  stopSellingEdition = "stopSellingEdition",
  stopSellingEditions = "stopSellingEditions",
  tipForLongContent = "tipForLongContent",
  tipForShortContent = "tipForShortContent",
  tipLongPost = "tipLongPost",
  tipShortPost = "tipShortPost",
}

export enum UrlPartType {
  email = "email",
  external = "external",
  internal = "internal",
}

export enum ValidationStatus {
  allow = "allow",
  deny = "deny",
}

export enum VerificationAction {
  changePin = "changePin",
  deleteMe = "deleteMe",
  emailChange = "emailChange",
  removeWallet = "removeWallet",
  signUp = "signUp",
}

export enum VideoType {
  long = "long",
  nft = "nft",
  preview = "preview",
  short = "short",
}

export enum WalletStatus {
  absent = "absent",
  busy = "busy",
  ready = "ready",
}

export enum XdbNftType {
  image = "image",
  video = "video",
}

export interface AttachmentGifRequest {
  key?: string | null;
}

export interface AttachmentRequest {
  id: string;
  type: AttachmentType;
}

export interface AttachmentVideoRequest {
  name?: string | null;
  title?: string | null;
  publicUrl?: string | null;
  videoId?: string | null;
}

export interface AttachmentsRequest {
  images?: Upload[] | null;
  videos?: AttachmentVideoRequest[] | null;
  gifs?: AttachmentGifRequest[] | null;
}

export interface ClipFrames {
  start?: number | null;
  length?: number | null;
}

/**
 * Request with the data to complain about an incorrect content
 */
export interface ComplaintRequest {
  contentId: string;
  text?: string | null;
  complaintType?: string | null;
}

export interface CoordinatesInput {
  lat: number;
  lng: number;
}

/**
 * Comment
 */
export interface CreateCommentRequest {
  commentableId?: string | null;
  commentableType?: CommentableType | null;
  threadId?: string | null;
  body?: string | null;
  attachments?: AttachmentsRequest | null;
}

/**
 * Create reaction
 */
export interface CreateReactionRequest {
  reactableId: string;
  reactableType: ReactableType;
  type?: ReactionType | null;
}

export interface CreateSupportCaseRequest {
  email: string;
  platform: SupportCasePlatform;
  topicId?: number | null;
  topicAlias?: string | null;
  message: string;
  details: string;
}

/**
 * Edit comment
 */
export interface EditCommentRequest {
  commentId: string;
  body?: string | null;
  attachments?: UpdateAttachmentsRequest | null;
}

export interface GenerateNicknameRequest {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
}

export interface LoginRequest {
  identity: string;
  credential: string;
}

export interface MessengerSettingsRequest {
  sendPrivacy?: MessengerSendPrivacy | null;
}

export interface MyInvoicesFilter {
  status?: InvoiceStatus[] | null;
}

export interface MyReceivedTipsFilter {
  postType: PurchasedFilterType;
}

export interface MySentTipsFilter {
  postType: PurchasedFilterType;
}

export interface NftSettingsRequest {
  createdNftsAreHidden?: boolean | null;
  ownedNftsAreHidden?: boolean | null;
}

export interface NonAuthPasswordResetWithEmailRequest {
  password: string;
  token: string;
  repeatPassword: string;
}

export interface PaywallUpdateRequest {
  cost: number;
  contentPreview: string;
}

export interface PostAccessibilityUpdateRequest {
  type: PostAccessibilityType;
  preview?: string | null;
  nftRarity?: NftRarityCategory | null;
  cost?: number | null;
}

export interface PostCategoryRequest {
  id: number;
}

export interface PostCropParamsRequest {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface PostOrientationRequest {
  cropParams?: PostCropParamsRequest | null;
  contentOrientation: ContentOrientation;
}

export interface PostPreviewInfoRequest {
  previewType?: PostPreviewType | null;
  addToMyPosts?: boolean | null;
}

export interface PostSettingsRequest {
  categories?: PostCategoryRequest[] | null;
  taggedPersons?: PostTaggedPersonRequest[] | null;
  location?: string | null;
  isAdult?: boolean | null;
  enabledTipping: boolean;
  isEnabledComment?: boolean | null;
}

export interface PostTaggedPersonRequest {
  id: string;
}

export interface ProfileNftsFilter {
  owningType: ProfileNftsOwningType;
  forSale?: boolean | null;
  digitalbits?: boolean | null;
  personId: string;
  searchByTitle?: string | null;
}

export interface ProfileNftsOrder {
  field: ProfileNftsOrderField;
  direction: OrderDirection;
}

export interface PurchasedFilter {
  postType: PurchasedFilterType;
}

export interface QuoteRequest {
  id: string;
  type: QuoteType;
}

export interface RegistrationSocialRequest {
  socialId: string;
  nickname: string;
  name: string;
}

export interface RegistrationWithVerificationRequest {
  email: string;
  name: string;
  nickname: string;
  password: string;
  token?: string | null;
}

export interface ReserveUsernameRequest {
  email: string;
  username: string;
}

export interface ShareRequest {
  receiverId: string;
  rate: number;
}

export interface SocialLoginData {
  idToken: string;
  firstName?: string | null;
  lastName?: string | null;
}

export interface StartImageUploadingRequest {
  name: string;
  postId: string;
}

export interface StartVideoUploadingRequest {
  name: string;
  postId: string;
  size: number;
  mimeType: string;
  videoType: VideoType;
  clip?: ClipFrames | null;
  crop?: PostOrientationRequest | null;
}

export interface TransactionFilter {
  category: TransactionCategory;
  dateFrom?: string | null;
  dateTo?: string | null;
  personId?: string | null;
}

export interface UpdateAttachmentsRequest {
  images?: UpdateUploadImage[] | null;
  videos?: AttachmentVideoRequest[] | null;
  gifs?: AttachmentGifRequest[] | null;
}

export interface UpdateGroupConversationSettings {
  kind?: GroupConversationKind | null;
  privacy?: GroupConversationPrivacy | null;
  invitePolicy?: GroupConversationInvitePolicy | null;
}

export interface UpdateLongPostRequest {
  postId: string;
  title?: string | null;
  content?: string | null;
  settings?: PostSettingsRequest | null;
  previewInfo?: PostPreviewInfoRequest | null;
  paywall?: PaywallUpdateRequest | null;
}

export interface UpdateLongStreamRequest {
  postId: string;
  name?: string | null;
  description?: string | null;
  settings?: PostSettingsRequest | null;
  paywall?: PaywallUpdateRequest | null;
  accessibility?: PostAccessibilityUpdateRequest | null;
  notifySubscribers?: boolean | null;
}

export interface UpdateNftRequest {
  nftId: string;
  title: string;
  description?: string | null;
  uri?: string | null;
  royalty?: number | null;
  royaltyShares?: (ShareRequest | null)[] | null;
  numberOfEditions?: number | null;
  charitable?: boolean | null;
}

export interface UpdateParticipantSettings {
  ignoringMessages?: boolean | null;
  blockingMessages?: boolean | null;
}

export interface UpdatePersonRequest {
  name?: string | null;
  nickname?: string | null;
  avatar?: Upload | null;
  background?: Upload | null;
  birthday?: string | null;
  about?: string | null;
  location?: string | null;
  confidentiality?: PersonConfidentiality | null;
}

export interface UpdateShortPostRequest {
  postId: string;
  caption?: string | null;
  settings?: PostSettingsRequest | null;
  paywall?: PaywallUpdateRequest | null;
  orientation?: PostOrientationRequest | null;
}

export interface UpdateUploadImage {
  file?: Upload | null;
  name?: string | null;
  publicUrl?: string | null;
}

export interface UpdateXdbNftRequest {
  xdbNftId: string;
  title: string;
  description?: string | null;
  royalty?: number | null;
  royaltyShares?: (ShareRequest | null)[] | null;
}

export interface VerificationCodeInput {
  id: string;
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
