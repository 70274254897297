import qs from "query-string";

import {
  COMMON_DIALOG_CLOSE_REASON,
  DIALOG_NAME
} from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogComponentCloseResult,
  DialogComponentConfirmHandlerArguments,
  DialogComponentType
} from "~/components/providers/DialogProvider/declarations/config";
import {
  CloseDialogArguments,
  OpenDialogArguments
} from "~/components/providers/DialogProvider/declarations/context";
import { QUERY_SIGN } from "~/constants/url";
import { isBoolean, isNotNull, isObject, isString } from "~/utils/common";

import {
  DEFAULT_ROUTER_DIALOGS_STATE,
  DIALOG_CONFIG_MAP,
  DIALOGS_STATE_QUERY_PARAMETER
} from "./constants";
import {
  AlreadyOpenDialogsMap,
  DialogState,
  InMemoryDialogsState,
  RoutingDialogsState
} from "./declarations/state";

type ValidateRouterDialogStateOptions = {
  initial?: boolean;
};

export const validateRouterDialogState = (
  rawState: unknown,
  { initial = false }: ValidateRouterDialogStateOptions = {}
): rawState is RoutingDialogsState<DIALOG_NAME> => {
  if (!Array.isArray(rawState)) {
    return false;
  }

  const checkedDialogNames: Record<string, boolean> = {};

  const everyDialogStateValid = rawState.every(dialogState => {
    if (
      !dialogState ||
      !isObject(dialogState) ||
      !isBoolean(dialogState.open) ||
      (initial && !dialogState.open) ||
      !isString(dialogState.name) ||
      checkedDialogNames[dialogState.name]
    ) {
      return false;
    }

    const dialogsConfig = DIALOG_CONFIG_MAP[dialogState.name as DIALOG_NAME];

    if (!dialogsConfig || !dialogsConfig.saveInUrl) {
      return false;
    }

    checkedDialogNames[dialogState.name] = true;

    const validateOptions = dialogsConfig.validateOptions;
    const dialogWithNoOptions = !dialogState.options && !validateOptions;
    const dialogHasValidOptions =
      isNotNull(validateOptions) && validateOptions(dialogState.options);

    return dialogWithNoOptions || dialogHasValidOptions;
  });

  return everyDialogStateValid;
};

export const getValidRoutingDialogsState = (
  rawOptions: unknown
): RoutingDialogsState<DIALOG_NAME> => {
  if (validateRouterDialogState(rawOptions)) {
    return rawOptions;
  }

  return DEFAULT_ROUTER_DIALOGS_STATE;
};

const getDialogQueryStateItem = (
  ...dialogArguments: OpenDialogArguments<DIALOG_NAME>
): DialogState<DIALOG_NAME> => {
  const name = dialogArguments[0];
  const options = dialogArguments[1];

  const DialogState: DialogState<DIALOG_NAME> = {
    open: true,
    name,
    options
  };

  return DialogState;
};

type DialogQueryStateObject = {
  [key in typeof DIALOGS_STATE_QUERY_PARAMETER]: string;
};

export const getQueryObjectWithDialogParameterForSingleDialog = <
  Name extends DIALOG_NAME
>(
  ...openDialogArguments: OpenDialogArguments<Name>
): DialogQueryStateObject => {
  const state = [getDialogQueryStateItem(...openDialogArguments)];

  return getQueryObjectWithDialogParameter(state);
};

export const getQueryObjectWithDialogParameter = (
  state: RoutingDialogsState<DIALOG_NAME>
): DialogQueryStateObject => {
  const queryParameterValue = JSON.stringify(state);

  return {
    [DIALOGS_STATE_QUERY_PARAMETER]: queryParameterValue
  };
};

export const getRawDialogRoutingStateFromUrl = (url: string): unknown => {
  const queryParameters = qs.parseUrl(url).query[DIALOGS_STATE_QUERY_PARAMETER];
  const queryParameter = Array.isArray(queryParameters)
    ? queryParameters[0]
    : queryParameters;

  if (!queryParameter) {
    return [];
  }

  try {
    const dialogsState = JSON.parse(queryParameter);

    return dialogsState;
  } catch (error) {
    return null;
  }
};

export const getRawDialogRoutingStateFromLocation = (): unknown =>
  /* Parse directly from window.location because useQueryParam doesn't set the value instantly in old chrome and safari browsers in mac os */
  getRawDialogRoutingStateFromUrl(window.location.href);

type RenderDialogOptions = {
  state: DialogState<DIALOG_NAME>;
  order: number;
  closeDialog: <Name extends DIALOG_NAME>(
    ...closeDialogArguments: CloseDialogArguments<Name>
  ) => void;
  confirmDialog: <Name extends DIALOG_NAME>(
    dialogNameToClose: Name,
    ...confirmArguments: DialogComponentConfirmHandlerArguments<Name>
  ) => void;
  deleteDialogFromState: (dialogName: DIALOG_NAME) => void;
};

export const renderDialog = ({
  state,
  order,
  closeDialog,
  confirmDialog,
  deleteDialogFromState
}: RenderDialogOptions): JSX.Element => {
  const DialogComponent = DIALOG_CONFIG_MAP[state.name]
    .Component as DialogComponentType<DIALOG_NAME>;

  const handleCancel = (): void => {
    closeDialog(state.name, COMMON_DIALOG_CLOSE_REASON.cancelButton);
  };

  const handleClose = (
    result: DialogComponentCloseResult<DIALOG_NAME>
  ): void => {
    if (result.closeReason === COMMON_DIALOG_CLOSE_REASON.error) {
      closeDialog(
        state.name,
        COMMON_DIALOG_CLOSE_REASON.error,
        result.errorCode
      );
    } else {
      closeDialog(state.name, result.closeReason);
    }
  };

  const handleConfirm = (
    ...confirmArguments: DialogComponentConfirmHandlerArguments<DIALOG_NAME>
  ): void => {
    confirmDialog(state.name, ...confirmArguments);
  };

  const handleExited = (): void => {
    deleteDialogFromState(state.name);
  };

  return (
    <DialogComponent
      key={state.name}
      order={order}
      open={state.open}
      onCancel={handleCancel}
      onClose={handleClose}
      onConfirm={handleConfirm}
      onExited={handleExited}
      {...(state.options ? state.options : {})}
    />
  );
};

export const dialogPriorityComparator = (
  priority1: number,
  priority2: number
): number => {
  if (priority2 > priority1) {
    return 1;
  }

  if (priority2 < priority1) {
    return -1;
  }

  return 0;
};

export const getAlreadyOpenDialogsFromState = (
  routingState: RoutingDialogsState<DIALOG_NAME>,
  inMemoryState: InMemoryDialogsState<DIALOG_NAME>
): AlreadyOpenDialogsMap => {
  const openDialogs: AlreadyOpenDialogsMap = {};

  for (const dialog of routingState) {
    openDialogs[dialog.name] = true;
  }

  for (const dialog of inMemoryState) {
    openDialogs[dialog.name] = true;
  }

  return openDialogs;
};

export const isPathnameEqual = (url1: string, url2: string): boolean => {
  const pathname1 = url1.split(QUERY_SIGN)[0];
  const pathname2 = url2.split(QUERY_SIGN)[0];

  return pathname1 === pathname2;
};
