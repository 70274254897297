import Badge from "@material-ui/core/Badge";
import useTranslation from "next-translate/useTranslation";
import { useRef } from "react";

import IconButton from "~/components/core/IconButton/IconButton";
import { useMessagingContext } from "~/components/messaging/providers/MessagingProvider";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

const MessagingPopup = safeDynamicImport(
  () => import("~/components/activityPopups/MessagingPopup"),
  { ssr: false }
);

const AccountHeaderMessaging = (): JSX.Element => {
  const messagingPopup = useDialog();
  const balloonButtonRef = useRef(null);
  const { t } = useTranslation("common");
  const { unreadConversations } = useMessagingContext();

  return (
    <>
      <Badge
        overlap="circular"
        badgeContent=""
        variant="dot"
        invisible={!unreadConversations}
      >
        <IconButton
          onClick={messagingPopup.openDialog}
          aria-label={t("messaging")}
          ref={balloonButtonRef}
          icon={messagingPopup.open ? "sendFilled" : "send"}
        />
      </Badge>
      {messagingPopup.hasBeenOpened && (
        <MessagingPopup
          open={messagingPopup.open}
          anchorElement={balloonButtonRef.current}
          onClose={messagingPopup.closeDialog}
        />
      )}
    </>
  );
};

export default AccountHeaderMessaging;
