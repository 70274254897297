import { PROFILE_SECTION } from "~/components/profile/Profile/constants";
import { BuyHypesFeeType, PostType } from "~/declarations/apollo/globalTypes";

export enum ANALYTICS_EVENT {
  // User Registrations
  explore = "Explore",
  signUpStart = "Start Sign Up",
  signUpFinish = "Finish Sign Up",
  logIn = "Log In",

  // User Retention
  websiteVisit = "Website Visit",
  profileVisit = "Profile Visit",
  profileFollow = "Profile Follow",

  // Content
  contentView = "Content View",
  contentShare = "Content Share",
  contentLike = "Content Like",
  contentComment = "Content Comment",

  // Blog
  blogView = "Blog View",
  blogPostView = "Blog Post View",
  blogPostShare = "Blog Post Share",

  // NFT
  nftDiscoverView = "Discover NFT View",
  nftView = "NFT View",
  nftLike = "NFT Like",
  nftSelect = "Select NFT",
  nftPurchaseStart = "Start NFT Purchase",
  nftPurchaseFinish = "Finish NFT Purchase",

  // Payments
  walletSetupStart = "Start Wallet Setup",
  walletSetupFinish = "Finish Wallet Setup",
  buyCoinsStart = "Start Buy Hypes",
  buyCoinsFinish = "Finish Buy Hypes", // TBD after Stripe integration
  premiumPurchaseStart = "Start Premium Purchase",
  premiumPurchaseFinish = "Finish Premium Purchase",
  tipStart = "Start Tip",
  tipFinish = "Finish Tip"
}

export enum CUSTOM_USER_PROPERTY {
  email = "email",
  walletAddress = "walletId"
}

export const ANALYTICS_ERRORS = {
  initialization:
    "Event wasn't sent because client isn't properly initialized!",
  apiKey: "Please set up analytics API key!"
};

export enum CONTENT_TYPE {
  post = "Post",
  hypeTV = "HypeTV",
  stream = "Stream"
}

export enum SHARE_TARGET {
  copyLink = "Copy Link",
  hyprr = "Hyprr",
  facebook = "Facebook",
  twitter = "Twitter",
  whatsapp = "Whatsapp",
  telegram = "Telegram"
}

export const POST_TYPE_TO_CONTENT_TYPE: {
  [k in PostType]: CONTENT_TYPE;
} = {
  [PostType.short]: CONTENT_TYPE.post,
  [PostType.long]: CONTENT_TYPE.hypeTV,
  [PostType.longStream]: CONTENT_TYPE.stream,
  [PostType.shortStream]: CONTENT_TYPE.stream
};

export enum PROFILE_TAB_NAME {
  featured = "Featured",
  posts = "Posts",
  premium = "Premium",
  hypeTV = "HypeTV",
  nft = "NFT"
}

export const PROFILE_SECTION_TO_TAB_NAME: {
  [k in PROFILE_SECTION]: PROFILE_TAB_NAME;
} = {
  [PROFILE_SECTION.featured]: PROFILE_TAB_NAME.featured,
  [PROFILE_SECTION.posts]: PROFILE_TAB_NAME.posts,
  [PROFILE_SECTION.premium]: PROFILE_TAB_NAME.premium,
  [PROFILE_SECTION.hypetv]: PROFILE_TAB_NAME.hypeTV,
  [PROFILE_SECTION.nft]: PROFILE_TAB_NAME.nft
};

export enum AUTH_PROVIDER {
  email = "Email",
  google = "Google",
  apple = "Apple"
}

export enum PAYMENT_PROVIDER {
  bitpay = "Bitpay",
  stripe = "Stripe"
}

export const FEE_TYPE_TO_PAYMENT_PROVIDER: {
  [k in BuyHypesFeeType]: PAYMENT_PROVIDER;
} = {
  [BuyHypesFeeType.bitpay]: PAYMENT_PROVIDER.bitpay,
  [BuyHypesFeeType.stripe]: PAYMENT_PROVIDER.stripe
};

export enum AVAILABILITY_FLAG {
  yes = "Yes",
  no = "No"
}

export const OPT_OUT_COOKIE_NAME = "test-user";

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
