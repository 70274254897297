import { useCallback, useState } from "react";
import styled from "styled-components";

import IconButton from "~/components/core/IconButton";
import { PROBLEM_REPORT_TYPE } from "~/components/core/ProblemReportDialog/constants";
import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { useProblemReportDialogContext } from "~/components/providers/ProblemReportDialogProvider/ProblemReportDialogContext";
import { useRouteChangeCallback } from "~/utils/useRouteChangeCallback";

import AccountHeaderMobileMenu from "./AccountHeaderMobileMenu";

const AccountHeaderMobileMenuWithButton = (): JSX.Element => {
  const { authorized, authorizationStatusFetched, loginDialog, signUpDialog } =
    useAccountLayoutContext();
  const problemReportDialog = useProblemReportDialogContext();

  const [menuShown, setMenuShown] = useState(false);

  const handleClickMenuTrigger = useCallback((): void => {
    setMenuShown(shown => !shown);
  }, []);

  const handleCloseMenu = useCallback((): void => {
    setMenuShown(false);
  }, []);

  const handleClickReport = useCallback((): void => {
    problemReportDialog.openDialog({
      type: PROBLEM_REPORT_TYPE.reportProblem
    });
    handleCloseMenu();
  }, [handleCloseMenu, problemReportDialog]);

  const handleClickLeaveFeedback = useCallback((): void => {
    problemReportDialog.openDialog({
      type: PROBLEM_REPORT_TYPE.leaveFeedback
    });
    handleCloseMenu();
  }, [handleCloseMenu, problemReportDialog]);

  const handleClickContactUs = useCallback((): void => {
    problemReportDialog.openDialog({
      type: PROBLEM_REPORT_TYPE.contactSupport
    });
    handleCloseMenu();
  }, [handleCloseMenu, problemReportDialog]);

  const handleClickLogin = useCallback((): void => {
    loginDialog.openDialog();
    handleCloseMenu();
  }, [handleCloseMenu, loginDialog]);

  const handleClickSignup = useCallback((): void => {
    signUpDialog.openDialog();
    handleCloseMenu();
  }, [handleCloseMenu, signUpDialog]);

  useRouteChangeCallback(handleCloseMenu);

  return (
    <>
      <MobileMenuButton
        icon={menuShown ? "cancel" : "burger"}
        onClick={handleClickMenuTrigger}
      />

      <AccountHeaderMobileMenu
        open={menuShown}
        authorized={authorized}
        authorizationStatusFetched={authorizationStatusFetched}
        onClose={handleCloseMenu}
        onClickReport={handleClickReport}
        onClickLeaveFeedback={handleClickLeaveFeedback}
        onClickContactUs={handleClickContactUs}
        onClickLogin={handleClickLogin}
        onClickSignup={handleClickSignup}
      />
    </>
  );
};

export default AccountHeaderMobileMenuWithButton;

const MobileMenuButton = styled(IconButton)`
  margin-left: 8px;
`;
