import { FieldPolicy, Reference } from "@apollo/client";

import { APOLLO_PAGINATION_CLIENT_CURSOR } from "~/constants/pagination";

type KeyArgs = FieldPolicy<unknown>["keyArgs"];
export type NodeComparator<TNode> = (a: TNode, b: TNode) => number;

export interface CustomNode extends Reference {
  id?: string;
}

interface PageInfo {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string;
  endCursor: string;
}

export type InternalRelayEdge<TNode> = {
  cursor: string;
  position?: number;
  node: TNode;
};

type InternalRelay<TNode> = Readonly<{
  edges: InternalRelayEdge<TNode>[];
  clientEdges: InternalRelayEdge<TNode>[];
  pageInfo: Readonly<PageInfo>;
}>;

const cursorFromEdge = <TNode>(
  edges: InternalRelay<TNode>["edges"],
  index: number
): string => {
  if (index < 0) {
    index += edges.length;
  }

  const edge = edges[index];

  return (edge && edge.cursor) || "";
};

const updateCursor = <TNode>(
  edges: InternalRelay<TNode>["edges"],
  index: number,
  cursor: string | undefined
): void => {
  if (index < 0) {
    index += edges.length;
  }

  const edge = edges[index];

  if (cursor && edge && cursor !== edge.cursor) {
    edges[index] = { ...edge, cursor };
  }
};

const makeEmptyData = <TNode>(): InternalRelay<TNode> => ({
  edges: [],
  clientEdges: [],
  pageInfo: {
    hasPreviousPage: false,
    hasNextPage: true,
    startCursor: "",
    endCursor: ""
  }
});

const getUniqueNodeKey = <TNode extends CustomNode>(
  node: TNode
): string | null => node.id ?? node.__ref ?? null;

const filterUnique = <TNode extends CustomNode>(
  edges: InternalRelay<TNode>["edges"],
  uniqueKeySet: Set<string>
): InternalRelay<TNode>["edges"] => {
  const uniqueEdges: InternalRelay<TNode>["edges"] = [];

  edges.forEach(edge => {
    const nodeUniqueKey = getUniqueNodeKey<TNode>(edge.node);

    if (!nodeUniqueKey || !uniqueKeySet.has(nodeUniqueKey)) {
      uniqueEdges.push(edge);
    }

    if (nodeUniqueKey && !uniqueKeySet.has(nodeUniqueKey)) {
      uniqueKeySet.add(nodeUniqueKey);
    }
  });

  return uniqueEdges;
};

export const customStylePagination = <TNode extends CustomNode>(
  keyArgs: KeyArgs = false
): FieldPolicy<InternalRelay<TNode>> => ({
  keyArgs,

  read(existing, { canRead }): InternalRelay<TNode> | undefined {
    if (!existing) {
      return;
    }

    const validServerEdges = existing.edges.filter(edge => canRead(edge.node));
    const edges = [...validServerEdges];

    existing.clientEdges
      .filter(edge => canRead(edge.node))
      .forEach(({ position = 0, ...edge }) => {
        // preserve saved client edge position
        edges.splice(position, 0, edge);
      });

    return {
      ...existing,
      edges,
      pageInfo: {
        ...existing.pageInfo,
        startCursor: cursorFromEdge(validServerEdges, 0),
        endCursor: cursorFromEdge(validServerEdges, -1)
      }
    };
  },

  merge(existing = makeEmptyData(), incoming, { args }): InternalRelay<TNode> {
    if (!args) {
      return existing;
    } // TODO: Maybe throw?

    let prefix = existing.edges;
    let suffix: typeof prefix = [];

    if (args.after) {
      const index = prefix.findIndex(edge => edge.cursor === args.after);

      if (index >= 0) {
        prefix = prefix.slice(0, index + 1);
      }
    } else if (args.before) {
      const index = prefix.findIndex(edge => edge.cursor === args.before);
      suffix = index < 0 ? prefix : prefix.slice(index);
      prefix = [];
    } else {
      prefix = [];
    }

    const incomingClientEdges: InternalRelay<TNode>["edges"] = [];
    const incomingServerEdges: InternalRelay<TNode>["edges"] = [];

    incoming.edges.forEach((edge, index) => {
      if (edge.cursor === APOLLO_PAGINATION_CLIENT_CURSOR) {
        // save client edge position to use in read function
        edge = { ...edge, position: index };
        incomingClientEdges.push(edge);
      } else {
        incomingServerEdges.push(edge);
      }
    });

    const uniqueKeySet: Set<string> = new Set();
    filterUnique(prefix, uniqueKeySet);
    filterUnique(suffix, uniqueKeySet);
    const incomingEdges: InternalRelay<TNode>["edges"] = filterUnique(
      incomingServerEdges,
      uniqueKeySet
    );
    const clientEdges: InternalRelay<TNode>["edges"] = filterUnique(
      [...existing.clientEdges, ...incomingClientEdges],
      uniqueKeySet
    );

    if (incoming.pageInfo) {
      updateCursor(incomingEdges, 0, incoming.pageInfo.startCursor);
      updateCursor(incomingEdges, -1, incoming.pageInfo.endCursor);
    }

    const edges: InternalRelayEdge<TNode>[] = [
      ...prefix,
      ...incomingEdges,
      ...suffix
    ];

    const pageInfo = {
      ...incoming.pageInfo,
      ...existing.pageInfo,
      startCursor: cursorFromEdge(edges, 0),
      endCursor: cursorFromEdge(edges, -1)
    };

    const updatePageInfo = (
      name: keyof InternalRelay<TNode>["pageInfo"]
    ): void => {
      const value = incoming.pageInfo[name];

      if (value !== void 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (pageInfo as any)[name] = value;
      }
    };

    if (!prefix.length) {
      updatePageInfo("hasPreviousPage");
    }

    if (!suffix.length) {
      updatePageInfo("hasNextPage");
    }

    return {
      ...existing,
      ...incoming,
      edges,
      pageInfo,
      clientEdges
    };
  }
});
