export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;
export const MILLISECONDS_IN_DAY =
  HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
export const DATE_FORMAT_FULL = "MMM D, YYYY";
export const DATE_FULL_MONTH_FORMAT = "MMMM D, YYYY";
