export const PEOPLE_TO_SHOW_IN_NOTIFICATION_HEADER = 2;

export enum RAW_NOTIFICATION_TYPE {
  PersonFollowed = "PersonFollowed",
  PostLiked = "PostLiked",
  PostCommented = "PostCommented",
  PersonFollowRequest = "PersonFollowRequest",
  PersonFollowRequestAccepted = "PersonFollowRequestAccepted",
  PayoutFailed = "PayoutFailed",
  PayoutProcessed = "PayoutProcessed",
  StreamStarted = "StreamStarted",
  /**
   * @deprecated This event shouldn't be used
   */
  StreamCreated = "StreamCreated",
  NFTMinted = "NftMinted",
  NFTAvailableForSale = "NftAvailableForSale",
  NFTPurchaseCompleted = "NftPurchaseCompleted",
  NFTPurchased = "NftPurchased",
  PostSold = "PostSold",
  PostTipped = "PostTipped",
  PersonMentioned = "PersonMentioned",
  PersonReplied = "PersonReplied",
  ReplenishmentDone = "ReplenishmentDone",
  NftToXdbExported = "NftToXdbExported",
  NftToXdbExportFailed = "NftToXdbExportFailed"
}
