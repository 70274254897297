import { useRouter } from "next/router";

import ROUTES from "~/constants/routes";
import { safeDynamicImport } from "~/utils/import/dynamic";
import useDialog from "~/utils/useDialog";

import { PostDeletionDialogPayload } from "./declarations";
import PostDeletionContextProvider from "./PostDeletionContextProvider";

const PostDeletionDialog = safeDynamicImport(
  () => import("./PostDeletionDialog"),
  {
    ssr: false
  }
);

const SUCCESS_DELETE_REDIRECT_TIMEOUT = 1500;

type Props = {
  children: React.ReactNode;
};

const PostDeletionProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const { open, hasBeenOpened, payload, openDialog, closeDialog } =
    useDialog<PostDeletionDialogPayload>({
      options: {
        forceResetPayloadOnClose: true
      }
    });

  const handleDeleteDialogSuccessSubmit = (): void => {
    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    if (payload?.redirectToProfileOnSuccess) {
      setTimeout(() => {
        router.push(ROUTES.profile.index);
      }, SUCCESS_DELETE_REDIRECT_TIMEOUT);
    }
  };

  return (
    <PostDeletionContextProvider
      value={{
        openDialog
      }}
    >
      {children}
      {hasBeenOpened && payload && (
        <PostDeletionDialog
          postId={payload.postId}
          open={open}
          onClose={closeDialog}
          onSuccess={handleDeleteDialogSuccessSubmit}
        />
      )}
    </PostDeletionContextProvider>
  );
};

export default PostDeletionProvider;
