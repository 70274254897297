import { object } from "yup";

import { PIN_VALIDATION_SCHEMA } from "~/components/payment/validation";
import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { WalletShowMnemonicDialogForm } from "~/components/wallet/WalletShowMnemonicDialog/descriptions";
import { REQUIRED_SCHEMA } from "~/constants/validation/common";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const WALLET_SHOW_MNEMONIC_DIALOG_INITIAL_VALUES: WalletShowMnemonicDialogForm =
  {
    pin: ""
  };

export const WALLET_SHOW_MNEMONIC_DIALOG_VALIDATION_SCHEMA = object({
  pin: REQUIRED_SCHEMA.concat(PIN_VALIDATION_SCHEMA)
});

export enum WALLET_SHOW_MNEMONIC_DIALOG_ERROR_CODE {
  walletKeyNotExists = "walletKeyNotExists",
  walletNotExists = "walletNotExists"
}

export const WALLET_SHOW_MNEMONIC_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.walletMnemonic> =
  {
    Component: safeDynamicImport(
      () => import("~/components/wallet/WalletShowMnemonicDialog"),
      {
        ssr: false
      }
    )
  };
