export const IMAGE_SAVING_QUALITY = 0.8;
export const IMAGE_SAVING_MIN_DIMENSION = 300;
export const IMAGE_SAVING_MAX_DIMENSION = 2400;
export const IMAGE_SAVING_MAX_SIZE_MB = 2;
export const IMAGE_SAVING_FORMAT_MIME_TYPE = "image/jpeg";
export const CANVAS_FILL_COLOR = "#FFFFFF";
export const IMG_CROSS_ORIGIN_FORCE_VALUE = "anonymous";

export const MEDIA_ENDPOINTS = {
  startUploadVideo: "/v1/video/start-upload-video",
  uploadVideoCover: "/v1/video/upload-cover"
};
export const VIDEO_HLS_PLAYLIST_EXTENSION = ".m3u8";

export enum MEDIA_TYPE {
  image,
  video,
  audio
}

export enum VIDEO_MIME_TYPE {
  QUICKTIME = "video/quicktime",
  MP4 = "video/mp4"
}

export const MOV_VIDEO_FILE_EXTENSION = ".mov";
export const SUPPORTED_VIDEO_FORMATS: string[] = [VIDEO_MIME_TYPE.MP4];
export const VIDEO_HLS_MIME = "application/vnd.apple.mpegurl";

export enum IMAGE_TYPE {
  png = "image/png",
  gif = "image/gif",
  jpeg = "image/jpeg",
  webp = "image/webp"
}

export const IMAGE_FORMATS: string[] = [IMAGE_TYPE.png, IMAGE_TYPE.jpeg];

export const CANVAS_2D_CONTEXT = "2d";

export const PLAIN_TEXT_MEDIA_TYPE = "text/plain";
