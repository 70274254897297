import { SnackbarKey } from "notistack";
import styled from "styled-components";

import PaymentProgressSnackLoading from "./PaymentProgressSnackLoading";

type Props = {
  id: SnackbarKey;
  className?: string;
};

const PaymentProgressSnack = ({ id, className }: Props): JSX.Element => (
  <InnerWrapper className={className}>
    <PaymentProgressSnackLoading id={id} />
  </InnerWrapper>
);

export default PaymentProgressSnack;

const InnerWrapper = styled.div`
  animation: easeOutElastic 1s;
  display: flex;
  min-height: 104px;
  width: 420px;

  @keyframes easeOutElastic {
    0% {
      transform: translateY(0%);
    }

    16% {
      transform: translateY(-30%);
    }

    28% {
      transform: translateY(10%);
    }

    44% {
      transform: translateY(-5%);
    }

    59% {
      transform: translateY(2%);
    }

    73% {
      transform: translateY(-1%);
    }

    88% {
      transform: translateY(0.5%);
    }

    100% {
      transform: translateY(0%);
    }
  }
`;
