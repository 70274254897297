import { useCallback } from "react";

import useDialog from "~/utils/useDialog";

import { WhatsInsideDialogPayload } from "./declarations";
import WhatsInsideDialog from "./WhatsInsideDialog";
import WhatsInsideProvider from "./WhatsInsideProvider";

type Props = {
  children: React.ReactNode;
};

const WhatsInsideContextProvider = ({ children }: Props): JSX.Element => {
  const { open, openDialog, closeDialog, payload } =
    useDialog<WhatsInsideDialogPayload>({
      options: {
        forceResetPayloadOnClose: true
      }
    });

  const handleSubmit = useCallback(() => {
    if (!payload) {
      return;
    }

    payload.onSubmit();
    closeDialog();
  }, [closeDialog, payload]);

  return (
    <WhatsInsideProvider
      value={{
        openDialog
      }}
    >
      {children}
      {payload && (
        <WhatsInsideDialog
          open={open}
          price={payload.price}
          onClose={closeDialog}
          onSubmit={handleSubmit}
        >
          {payload.description}
        </WhatsInsideDialog>
      )}
    </WhatsInsideProvider>
  );
};

export default WhatsInsideContextProvider;
