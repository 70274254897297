import {
  ApolloClient,
  DataProxy,
  defaultDataIdFromObject
} from "@apollo/client";
import isMatch from "lodash.ismatch";

import {
  MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME,
  MESSAGING_DETAILED_GROUP_CONVERSATION_TYPENAME,
  MESSAGING_DETAILED_ONE_TO_ONE_CONVERSATION_TYPENAME,
  MESSAGING_GROUP_CONVERSATION_TYPENAME,
  MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME,
  MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_PIC_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_TOPIC_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_OWNERSHIP_TRANSFERRED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_ADDED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_BANNED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_DEMOTED_ADMIN_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOIN_REQUEST_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOINED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_KICKED_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_LEFT_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_PROMOTED_ADMIN_TYPENAME,
  MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_UNBANNED_TYPENAME
} from "~/components/messaging/constants";
import { MessagingConversationMessageReaction } from "~/components/messaging/declarations/common";
import {
  MessagingConversationGroup,
  MessagingConversationOneToOne
} from "~/components/messaging/declarations/conversations";
import { MessagingConversationMessageFromSystem } from "~/components/messaging/declarations/messages";
import {
  MessagingGroupSettings,
  MessagingParticipantSettings
} from "~/components/messaging/declarations/settings";
import {
  CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT,
  CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT_NAME,
  MESSAGING_CONVERSATION_GROUP_SETTINGS_FRAGMENT,
  MESSAGING_CONVERSATION_ONE_TO_ONE_SETTINGS_FRAGMENT
} from "~/components/messaging/graphql/conversations";
import {
  CONVERSATION_MESSAGE_EDIT_FRAGMENT,
  CONVERSATION_MESSAGE_EDIT_FRAGMENT_NAME,
  CONVERSATION_MESSAGE_QUOTE_EDIT_FRAGMENT,
  CONVERSATION_MESSAGE_QUOTE_EDIT_FRAGMENT_NAME,
  CONVERSATION_MESSAGES_QUERY,
  REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT,
  REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT_NAME,
  TYPENAME_CONVERSATION_MESSAGE_FRAGMENT
} from "~/components/messaging/graphql/messages";
import {
  addConversationToMyConversations,
  fetchAndAddConversationToMyConversations,
  makeConversationReadInCache
} from "~/components/messaging/utils/apollo/conversations";
import {
  addNewMessageToCache,
  deleteMessageFromCache,
  needToAddMessageCreatedNotification
} from "~/components/messaging/utils/apollo/messages";
import { ConverationMessageReactions } from "~/declarations/apollo/ConverationMessageReactions";
import { ConversationMessageEditFragment } from "~/declarations/apollo/ConversationMessageEditFragment";
import { ConversationMessageQuoteEditFragment } from "~/declarations/apollo/ConversationMessageQuoteEditFragment";
import {
  ConversationMessages,
  ConversationMessagesVariables
} from "~/declarations/apollo/ConversationMessages";
import { conversationSettingsFragment } from "~/declarations/apollo/conversationSettingsFragment";
import {
  AttachmentMoneyStatus,
  AttachmentType,
  ReactionType
} from "~/declarations/apollo/globalTypes";
import { groupConversationSettingsFragment } from "~/declarations/apollo/groupConversationSettingsFragment";
import { MyConversationWithShallowAttachment } from "~/declarations/apollo/MyConversationWithShallowAttachment";
import { isNotNull, isNullable } from "~/utils/common";

import { MESSAGING_EVENT_TYPE } from "./constants";
import {
  EventConversationWasRead,
  EventGroupCreated,
  EventMessageCreated,
  EventMessageDeleted,
  EventMessagePublished,
  EventMessageQuoteChanged,
  EventOneToOneConversationCreated,
  EventReactionCreated,
  EventReactionDeleted,
  MessageCreatedHandler,
  SystemMessageEvent
} from "./declarations";

const INITIAL_REACTIONS_COUNT = 1;

const getMessageTypeByMessageId = (
  apollo: DataProxy,
  id: string
): string | undefined | null => {
  const conversationMessageResponse = apollo.readFragment<{
    __typename: string;
  }>({
    id: getSimpleMessageCacheId(id),
    fragment: TYPENAME_CONVERSATION_MESSAGE_FRAGMENT
  });

  if (conversationMessageResponse) {
    return conversationMessageResponse?.__typename;
  }

  const conversationMessageWithQuote = apollo.readFragment<{
    __typename: string;
  }>({
    id: defaultDataIdFromObject({
      id,
      __typename: MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME
    }),
    fragment: TYPENAME_CONVERSATION_MESSAGE_FRAGMENT
  });

  return conversationMessageWithQuote?.__typename;
};

export const updateOneToOneConversationSettings = (
  apollo: DataProxy,
  conversationId: string,
  settings: Partial<MessagingParticipantSettings>
): void => {
  try {
    const apolloConversationId = defaultDataIdFromObject({
      id: conversationId,
      __typename: MESSAGING_DETAILED_ONE_TO_ONE_CONVERSATION_TYPENAME
    });
    const response = apollo.readFragment<conversationSettingsFragment>({
      id: apolloConversationId,
      fragment: MESSAGING_CONVERSATION_ONE_TO_ONE_SETTINGS_FRAGMENT
    });

    if (response && !isMatch(response.settings, settings)) {
      apollo.writeFragment<conversationSettingsFragment>({
        id: apolloConversationId,
        fragment: MESSAGING_CONVERSATION_ONE_TO_ONE_SETTINGS_FRAGMENT,
        data: {
          settings: {
            ...response.settings,
            ...settings
          }
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateGroupConversationSettings = (
  apollo: DataProxy,
  conversationId: string,
  settings: Partial<MessagingGroupSettings>
): void => {
  try {
    const apolloConversationId = defaultDataIdFromObject({
      id: conversationId,
      __typename: MESSAGING_DETAILED_GROUP_CONVERSATION_TYPENAME
    });
    const response = apollo.readFragment<groupConversationSettingsFragment>({
      id: apolloConversationId,
      fragment: MESSAGING_CONVERSATION_GROUP_SETTINGS_FRAGMENT
    });

    if (response && !isMatch(response.settings, settings)) {
      apollo.writeFragment({
        id: apolloConversationId,
        fragment: MESSAGING_CONVERSATION_GROUP_SETTINGS_FRAGMENT,
        data: {
          settings: {
            ...response.settings,
            ...settings
          }
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const createOneToOneConversation = (
  apollo: ApolloClient<unknown>,
  data: EventOneToOneConversationCreated
): void => {
  const oneToOneConversation: MessagingConversationOneToOne = {
    ...data.conversation,
    __typename: MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME
  };

  addConversationToMyConversations(apollo.cache, oneToOneConversation);
};

export const createGroupConversation = (
  apollo: ApolloClient<unknown>,
  data: EventGroupCreated
): void => {
  const groupConversation: MessagingConversationGroup = {
    ...data.conversation,
    __typename: MESSAGING_GROUP_CONVERSATION_TYPENAME
  };

  addConversationToMyConversations(apollo.cache, groupConversation);
};

export const makeConversationRead = (
  apollo: ApolloClient<unknown>,
  data: EventConversationWasRead,
  currentUserId: string
): void => {
  makeConversationReadInCache(
    apollo,
    data.conversationId,
    currentUserId,
    data.readerId,
    data.lastReadAt
  );
};

const systemEventToMessage = (
  event: SystemMessageEvent
): MessagingConversationMessageFromSystem | null => {
  const common = {
    conversationId: event.conversationId,
    createdAt: event.occurredOn.toString(),
    id: event.eventId
  };

  switch (event.__typename) {
    case MESSAGING_EVENT_TYPE.participantInvited:
      return {
        ...common,
        __typename: MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_ADDED_TYPENAME,
        inviter: {
          name: event.inviter.name,
          nickname: event.inviter.nickname,
          avatar: event.inviter.avatar
        },
        person: {
          name: event.person.name,
          nickname: event.person.nickname
        }
      };
    case MESSAGING_EVENT_TYPE.participantJoinRequestSent:
      return {
        ...common,
        __typename:
          MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOIN_REQUEST_TYPENAME,
        person: {
          id: event.person.id,
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantJoined:
      return {
        ...common,
        __typename: MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_JOINED_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantKicked:
      return {
        ...common,
        __typename: MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_KICKED_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantLeft:
      return {
        ...common,
        __typename: MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_LEFT_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantPromoted:
      return {
        ...common,
        __typename:
          MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_PROMOTED_ADMIN_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantDemoted:
      return {
        ...common,
        __typename:
          MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_DEMOTED_ADMIN_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.groupOwnershipTransferred:
      return {
        ...common,
        __typename:
          MESSAGING_SYSTEM_CONVERSATION_MESSAGE_OWNERSHIP_TRANSFERRED_TYPENAME,
        person: {
          name: event.newOwner.name,
          nickname: event.newOwner.nickname,
          avatar: event.newOwner.avatar
        },
        performer: {
          name: event.oldOwner.name,
          nickname: event.oldOwner.nickname,
          avatar: event.oldOwner.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantBanned:
      return {
        ...common,
        __typename: MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_BANNED_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.participantUnbanned:
      return {
        ...common,
        __typename:
          MESSAGING_SYSTEM_CONVERSATION_MESSAGE_USER_UNBANNED_TYPENAME,
        person: {
          name: event.person.name,
          nickname: event.person.nickname,
          avatar: event.person.avatar
        }
      };
    case MESSAGING_EVENT_TYPE.groupPictureChanged:
      return {
        ...common,
        __typename: MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_PIC_TYPENAME,
        performer: event.performer,
        picture: {
          little:
            event.picture.__typename === "GroupCustomPicture"
              ? event.picture.little
              : ""
        }
      };
    case MESSAGING_EVENT_TYPE.groupTopicChanged:
      return {
        ...common,
        __typename:
          MESSAGING_SYSTEM_CONVERSATION_MESSAGE_CHANGED_TOPIC_TYPENAME,
        performer: event.performer,
        topic: event.topic
      };
  }
};

const prepareApolloCacheToCreateMessage = async (
  client: ApolloClient<unknown>,
  conversationId: string
): Promise<{
  conversationCreated: boolean;
}> => {
  let conversationCreated = false;
  const conversationCacheId = defaultDataIdFromObject({
    __typename: MESSAGING_ONE_TO_ONE_CONVERSATION_TYPENAME,
    id: conversationId
  });
  const conversationResponse =
    client.readFragment<MyConversationWithShallowAttachment>({
      fragment: CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT,
      fragmentName: CONVERSATION_SHALLOW_ATTACHMENTS_FRAGMENT_NAME,
      id: conversationCacheId
    });

  if (!conversationResponse) {
    conversationCreated = await fetchAndAddConversationToMyConversations(
      client,
      conversationId
    );
  }

  let conversationMessagesFieldAlreadyExists = false;

  try {
    const conversationMessagesResponse = client.readQuery<
      ConversationMessages,
      ConversationMessagesVariables
    >({
      query: CONVERSATION_MESSAGES_QUERY,
      variables: {
        conversationId
      }
    });

    conversationMessagesFieldAlreadyExists = isNotNull(
      conversationMessagesResponse
    );
  } catch {}

  if (!conversationMessagesFieldAlreadyExists) {
    client.writeQuery<ConversationMessages, ConversationMessagesVariables>({
      query: CONVERSATION_MESSAGES_QUERY,
      variables: {
        conversationId
      },
      data: {
        conversationMessages: {
          pageInfo: {
            endCursor: "",
            hasNextPage: true
          },
          edges: [],
          totalCount: 0
        }
      }
    });
  }

  return {
    conversationCreated
  };
};

export const createMessage = async (
  client: ApolloClient<unknown>,
  currentUserId: string | undefined,
  event: EventMessageCreated | SystemMessageEvent,
  onMessageCreated?: MessageCreatedHandler
): Promise<void> => {
  let needToAddNotification = false;

  const conversationId = event.conversationId;
  if (event.__typename === MESSAGING_EVENT_TYPE.messageCreated) {
    needToAddNotification = needToAddMessageCreatedNotification(
      client,
      conversationId,
      event.message.id
    );
  }

  const { conversationCreated } = await prepareApolloCacheToCreateMessage(
    client,
    conversationId
  );

  const message =
    event.__typename === MESSAGING_EVENT_TYPE.messageCreated
      ? event.message
      : systemEventToMessage(event);

  if (!message) {
    return;
  }

  addNewMessageToCache(
    client,
    message,
    conversationId,
    currentUserId,
    !conversationCreated
  );

  if (needToAddNotification && onMessageCreated) {
    onMessageCreated(conversationId);
  }
};

export const deleteMessage = (
  apollo: ApolloClient<unknown>,
  data: EventMessageDeleted,
  currentUserId: string | undefined
): void => {
  deleteMessageFromCache(
    apollo,
    data.messageId,
    data.conversationId,
    currentUserId
  );
};

const editMessageForCacheInstance = (
  apollo: DataProxy,
  data: ConversationMessageEditFragment,
  messageCacheId: string | undefined
): void => {
  if (!messageCacheId) {
    return;
  }

  try {
    apollo.writeFragment<ConversationMessageEditFragment>({
      id: messageCacheId,
      fragment: CONVERSATION_MESSAGE_EDIT_FRAGMENT,
      fragmentName: CONVERSATION_MESSAGE_EDIT_FRAGMENT_NAME,
      data: {
        attachments: data.attachments,
        richText: data.richText
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const editMessage = (
  apollo: DataProxy,
  messageId: string,
  data: ConversationMessageEditFragment
): void => {
  editMessageForCacheInstance(apollo, data, getSimpleMessageCacheId(messageId));
  editMessageForCacheInstance(
    apollo,
    data,
    getMessageWithQuoteCacheId(messageId)
  );
};

export const updateMessageQuote = (
  apollo: DataProxy,
  data: EventMessageQuoteChanged
): void => {
  const messageCacheId = getMessageWithQuoteCacheId(data.messageId);

  if (isNullable(data.quote) || isNullable(data.quoteType)) {
    /* There is no mechanism to delete quote from message yet */
    return;
  }

  try {
    apollo.writeFragment<ConversationMessageQuoteEditFragment>({
      id: messageCacheId,
      fragment: CONVERSATION_MESSAGE_QUOTE_EDIT_FRAGMENT,
      fragmentName: CONVERSATION_MESSAGE_QUOTE_EDIT_FRAGMENT_NAME,
      data: {
        quoteType: data.quoteType,
        quote: data.quote
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const getMessageWithQuoteCacheId = (id: string): string | undefined =>
  defaultDataIdFromObject({
    id,
    __typename: MESSAGING_CONVERSATION_MESSAGE_WITH_QUOTE_TYPENAME
  });

export const getSimpleMessageCacheId = (id: string): string | undefined =>
  defaultDataIdFromObject({
    id,
    __typename: MESSAGING_SIMPLE_CONVERSATION_MESSAGE_TYPENAME
  });

const getMessageCacheId = (
  apollo: DataProxy,
  id: string
): string | undefined => {
  const __typename = getMessageTypeByMessageId(apollo, id);

  if (!__typename) {
    return;
  }

  return defaultDataIdFromObject({
    __typename,
    id
  });
};

export const addReactionToCache = (
  apollo: DataProxy,
  messageId: string,
  reactionType: ReactionType,
  ownId: string | null
): void => {
  addReactionToCacheInstance(
    apollo,
    ownId,
    reactionType,
    getSimpleMessageCacheId(messageId)
  );
  addReactionToCacheInstance(
    apollo,
    ownId,
    reactionType,
    getMessageWithQuoteCacheId(messageId)
  );
};

const addReactionToCacheInstance = (
  apollo: DataProxy,
  ownId: string | null,
  reactionType: ReactionType,
  messageCacheId?: string
): void => {
  if (!messageCacheId) {
    return;
  }

  const response = apollo.readFragment<ConverationMessageReactions>({
    fragment: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT,
    fragmentName: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT_NAME,
    id: messageCacheId
  });

  if (!response) {
    return;
  }

  const reactions: MessagingConversationMessageReaction[] = response.reactions;
  const reactionIndex = reactions.findIndex(
    reaction => reaction.type === reactionType
  );
  const reaction: MessagingConversationMessageReaction | undefined =
    reactions[reactionIndex];
  const newReactions = [...reactions];

  if (reaction && reaction.ownId && reaction.ownId === ownId) {
    return;
  }

  const score = reaction ? reaction.score + 1 : INITIAL_REACTIONS_COUNT;
  const newOwnId = reaction?.ownId ?? ownId;
  const newReaction: MessagingConversationMessageReaction = {
    __typename: "ConversationMessageReaction",
    type: reactionType,
    ownId: newOwnId,
    score
  };

  if (reaction) {
    newReactions.splice(reactionIndex, 1, newReaction);
  } else {
    newReactions.push(newReaction);
  }

  apollo.writeFragment<ConverationMessageReactions>({
    id: messageCacheId,
    fragment: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT,
    fragmentName: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT_NAME,
    data: {
      reactions: newReactions
    }
  });
};

export const addReactionToMessage = (
  apollo: DataProxy,
  data: EventReactionCreated,
  currentUserId: string | undefined
): void => {
  const ownId =
    data.reaction.reactorId === currentUserId ? data.reaction.id : null;

  addReactionToCache(
    apollo,
    data.reaction.reactableId,
    data.reaction.type,
    ownId
  );
};

export const deleteReactionFromCache = (
  apollo: DataProxy,
  messageId: string,
  reactionType: ReactionType,
  ownId: string | null
): void => {
  deleteReactionFromCacheInstance(
    apollo,
    ownId,
    reactionType,
    getSimpleMessageCacheId(messageId)
  );
  deleteReactionFromCacheInstance(
    apollo,
    ownId,
    reactionType,
    getMessageWithQuoteCacheId(messageId)
  );
};

export const deleteReactionFromCacheInstance = (
  apollo: DataProxy,
  ownId: string | null,
  reactionType: ReactionType,
  messageCacheId?: string
): void => {
  if (!messageCacheId) {
    return;
  }

  const response = apollo.readFragment<ConverationMessageReactions>({
    fragment: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT,
    fragmentName: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT_NAME,
    id: messageCacheId
  });

  if (!response) {
    return;
  }
  const reactions: MessagingConversationMessageReaction[] = response.reactions;
  const reactionIndex = reactions.findIndex(
    reaction => reaction.type === reactionType
  );
  const reaction = reactions[reactionIndex];
  const newReactions = [...reactions];

  if (!reaction) {
    return;
  }

  const canDeleteReaction =
    reaction.ownId === ownId || reaction.score > INITIAL_REACTIONS_COUNT;

  if (!canDeleteReaction) {
    return;
  }

  if (reaction.score === INITIAL_REACTIONS_COUNT) {
    newReactions.splice(reactionIndex, 1);
  } else {
    newReactions.splice(reactionIndex, 1, {
      ...reaction,
      ownId: isNotNull(ownId) ? null : reaction.ownId,
      score: reaction.score - 1
    });
  }

  apollo.writeFragment<ConverationMessageReactions>({
    id: messageCacheId,
    fragment: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT,
    fragmentName: REACTIONS_OF_CONVERSATION_MESSAGE_FRAGMENT_NAME,
    data: {
      reactions: newReactions
    }
  });
};

export const deleteReactionFromMessage = (
  apollo: DataProxy,
  data: EventReactionDeleted,
  currentUserId: string | undefined
): void => {
  const ownId =
    data.reaction.reactorId === currentUserId ? data.reaction.id : null;

  deleteReactionFromCache(
    apollo,
    data.reaction.reactableId,
    data.reaction.type,
    ownId
  );
};

export const makeMessagePublished = (
  apollo: DataProxy,
  data: EventMessagePublished
): void => {
  try {
    const messageCacheId = getMessageCacheId(apollo, data.messageId);

    if (!messageCacheId) {
      return;
    }

    const message = apollo.readFragment<ConversationMessageEditFragment>({
      id: messageCacheId,
      fragment: CONVERSATION_MESSAGE_EDIT_FRAGMENT,
      fragmentName: CONVERSATION_MESSAGE_EDIT_FRAGMENT_NAME
    });

    if (
      !message ||
      message.attachments.length === 0 ||
      message.attachments[0].__typename !== AttachmentType.AttachmentMoney
    ) {
      return;
    }

    const updatedMessage: ConversationMessageEditFragment = {
      ...message,
      attachments: message.attachments.map(message => {
        if (message.__typename === AttachmentType.AttachmentMoney) {
          return {
            ...message,
            status: AttachmentMoneyStatus.transferred
          };
        }

        return message;
      })
    };

    editMessage(apollo, data.messageId, updatedMessage);
  } catch (error) {
    console.error(error);
    return;
  }
};
