import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const NFT_ROYALTY_DETAILS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.nftRoyaltyDetails> =
  {
    Component: safeDynamicImport(
      () => import("~/components/nft/NftRoyaltyDetailsDialog"),
      { ssr: false }
    )
  };
