import { useCallback, useRef } from "react";

import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import {
  DialogPriority,
  DialogsPriorityMap,
  RoutingDialogsState
} from "~/components/providers/DialogProvider/declarations/state";
import { dialogPriorityComparator } from "~/components/providers/DialogProvider/utils";
import { isNotNull } from "~/utils/common";

type UseDialogPrioritiesResult = {
  setPriority: (name: DIALOG_NAME) => void;
  setPrioritiesByRoutingState: (
    state: RoutingDialogsState<DIALOG_NAME>
  ) => void;
  resetPriorities: () => void;
  deletePriority: (name: DIALOG_NAME) => void;
  hasPriority: (name: DIALOG_NAME) => boolean;
  getPriority: (name: DIALOG_NAME) => DialogPriority;
  getDialogNamesSortedByPriority: () => DIALOG_NAME[];
};

const INITIAL_PRIORITY: DialogPriority = 1;

const createPriorityMap = (): DialogsPriorityMap => ({});

const useDialogPriorities = (): UseDialogPrioritiesResult => {
  const nextPriority = useRef(INITIAL_PRIORITY);
  const priorities = useRef(createPriorityMap());

  const setPriority = useCallback((name: DIALOG_NAME) => {
    priorities.current[name] = nextPriority.current;
    nextPriority.current++;
  }, []);

  const resetPriorities = useCallback(() => {
    priorities.current = createPriorityMap();
  }, []);

  const setPrioritiesByRoutingState = useCallback(
    (state: RoutingDialogsState<DIALOG_NAME>) => {
      resetPriorities();

      state.forEach(({ name }) => {
        setPriority(name);
      });
    },
    [resetPriorities, setPriority]
  );

  const deletePriority = useCallback((name: DIALOG_NAME) => {
    delete priorities.current[name];
  }, []);

  const hasPriority = useCallback(
    (name: DIALOG_NAME) => isNotNull(priorities.current[name]),
    []
  );

  const getPriority = useCallback(
    (name: DIALOG_NAME) => priorities.current[name] ?? 0,
    []
  );

  const getDialogNamesSortedByPriority = useCallback(
    () =>
      Object.entries(priorities.current)
        .sort(([, priority1], [, priority2]) =>
          dialogPriorityComparator(priority1, priority2)
        )
        .map(([name]) => name as DIALOG_NAME),
    []
  );

  return {
    setPriority,
    setPrioritiesByRoutingState,
    resetPriorities,
    deletePriority,
    hasPriority,
    getPriority,
    getDialogNamesSortedByPriority
  };
};

export default useDialogPriorities;
