const Fonts = (): JSX.Element => (
  <>
    <link
      rel="preload"
      href="/assets/fonts/Aeonik-Regular.woff2"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/assets/fonts/Aeonik-Medium.woff2"
      as="font"
      crossOrigin=""
    />
    <link
      rel="preload"
      href="/assets/fonts/Aeonik-Bold.woff2"
      as="font"
      crossOrigin=""
    />
    <style jsx global>{`
      @font-face {
        font-family: "Aeonik";
        src: local("Aeonik Regular"), local("Aeonik-Regular"),
          url("/assets/fonts/Aeonik-Regular.woff2") format("woff2"),
          url("/assets/fonts/Aeonik-Regular.woff") format("woff"),
          url("/assets/fonts/Aeonik-Regular.ttf") format("truetype"),
          url("/assets/fonts/Aeonik-Regular.eot") format("embedded-opentype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Aeonik";
        src: local("Aeonik Medium"), local("Aeonik-Medium"),
          url("/assets/fonts/Aeonik-Medium.woff2") format("woff2"),
          url("/assets/fonts/Aeonik-Medium.woff") format("woff"),
          url("/assets/fonts/Aeonik-Medium.ttf") format("truetype"),
          url("/assets/fonts/Aeonik-Medium.eot") format("embedded-opentype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "Aeonik";
        src: local("Aeonik Bold"), local("Aeonik-Bold"),
          url("/assets/fonts/Aeonik-Bold.woff2") format("woff2"),
          url("/assets/fonts/Aeonik-Bold.woff") format("woff"),
          url("/assets/fonts/Aeonik-Bold.ttf") format("truetype"),
          url("/assets/fonts/Aeonik-Bold.eot") format("embedded-opentype");
        font-style: normal;
        font-weight: bold;
        font-display: swap;
      }
    `}</style>
  </>
);

export default Fonts;
