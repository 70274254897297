import { Dictionary } from "~/utils/structures/dictionary";

import { EditionStatus, PostType } from "./apollo/globalTypes";

export enum PURCHASE_TYPE {
  postPurchase,
  postTipping,
  sendMoneyByMessage,
  sendOwnMoney,
  nftMinting,
  nftStartSellingEditions,
  nftStopSellingEditions,
  nftPurchase,
  xdbNftExport,
  xdbNftImport
}

interface ProgressOfPostPurchase {
  type: PURCHASE_TYPE.postPurchase;
  postType: PostType;
  postId: string;
  balance: number;
}

interface ProgressOfPostTipping {
  type: PURCHASE_TYPE.postTipping;
  postType: PostType;
  postId: string;
  balance: number;
}
interface ProgressOfSendMoneyByMessage {
  type: PURCHASE_TYPE.sendMoneyByMessage;
  conversationId: string;
  balance: number;
}

interface ProgressOfSendOwnMoney {
  type: PURCHASE_TYPE.sendOwnMoney;
  balance: number;
}

interface ProgressOfNftMinting {
  type: PURCHASE_TYPE.nftMinting;
  nftId: string;
}

interface ProgressOfNftStartSellingEditions {
  type: PURCHASE_TYPE.nftStartSellingEditions;
  nftId: string;
  cost: number;
  status: EditionStatus;
}

interface ProgressOfNftStopSellingEditions {
  type: PURCHASE_TYPE.nftStopSellingEditions;
  nftId: string;
}

interface ProgressOfNftPurchase {
  type: PURCHASE_TYPE.nftPurchase;
  editionId: string;
}

interface ProgressOfXdbNftImport {
  type: PURCHASE_TYPE.xdbNftImport;
  nftId: string;
}

interface ProgressOfXdbNftExport {
  type: PURCHASE_TYPE.xdbNftExport;
  nftId: string;
}

export type ProgressOfTransaction =
  | ProgressOfPostPurchase
  | ProgressOfPostTipping
  | ProgressOfSendMoneyByMessage
  | ProgressOfSendOwnMoney
  | ProgressOfNftMinting
  | ProgressOfNftStartSellingEditions
  | ProgressOfNftStopSellingEditions
  | ProgressOfNftPurchase
  | ProgressOfXdbNftImport
  | ProgressOfXdbNftExport;

export type ProgressOfTransactionDictionary = Dictionary<
  string,
  ProgressOfTransaction
>;
