/* eslint-disable */
var React = require('react');
var SpriteSymbol = require("../../../../node_modules/svg-baker-runtime/browser-symbol.js");
var sprite = require("../../../../node_modules/svg-sprite-loader/runtime/browser-sprite.build.js");

var symbol = new SpriteSymbol({
  "id": "Anonymous-2ce05ee3--sprite",
  "use": "Anonymous-2ce05ee3--sprite-usage",
  "viewBox": "0 0 40 40",
  "content": "<symbol fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 40 40\" id=\"Anonymous-2ce05ee3--sprite\"><circle cx=\"20\" cy=\"20\" r=\"19\" fill=\"#171717\" stroke=\"url(#Anonymous-2ce05ee3--sprite_a)\" stroke-width=\"2\" /><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.668 41.365C6.004 34.593 12.434 29.728 20 29.728c7.564 0 13.993 4.864 16.33 11.634A26.357 26.357 0 0 1 19.997 47a26.357 26.357 0 0 1-16.329-5.635ZM28.074 18.94a7.941 7.941 0 1 1-15.883 0 7.941 7.941 0 0 1 15.883 0Z\" fill=\"url(#Anonymous-2ce05ee3--sprite_b)\" /><path d=\"M16.855 15.65c-.285.54-.42 1.125-.42 1.785h1.65c0-.615.165-1.11.51-1.5.345-.39.81-.585 1.395-.585.495 0 .9.135 1.215.405.3.27.465.63.465 1.05 0 .345-.09.645-.27.9-.18.27-.435.51-.795.75a8.11 8.11 0 0 0-.885.645c-.21.18-.39.405-.525.675s-.195.6-.195.975v.48h1.53v-.3c0-.33.09-.615.3-.855.195-.225.54-.51 1.02-.84.48-.33.84-.69 1.11-1.08.255-.39.39-.885.39-1.515 0-.51-.15-.975-.42-1.395-.27-.42-.66-.735-1.17-.975-.51-.24-1.11-.36-1.785-.36-.72 0-1.35.165-1.89.465-.54.315-.945.735-1.23 1.275Zm2.205 7.215c-.195.18-.285.42-.285.72s.09.555.285.735c.195.18.45.27.75.27s.54-.09.735-.27c.18-.18.285-.435.285-.735s-.105-.54-.285-.72a1.044 1.044 0 0 0-.735-.285c-.3 0-.555.105-.75.285Z\" fill=\"#000\" /><defs><linearGradient id=\"Anonymous-2ce05ee3--sprite_a\" x1=\"4.747\" y1=\"-34.15\" x2=\"58.057\" y2=\"-15.924\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#46FEDC\" /><stop offset=\".512\" stop-color=\"#EAFFCD\" /><stop offset=\"1\" stop-color=\"#F3D4FE\" /></linearGradient><linearGradient id=\"Anonymous-2ce05ee3--sprite_b\" x1=\"-25.958\" y1=\"-.019\" x2=\"-2.947\" y2=\"66.257\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#36FDDE\" /><stop offset=\".561\" stop-color=\"#F4FDD0\" /><stop offset=\".975\" stop-color=\"#F5DAF8\" /><stop offset=\"1\" stop-color=\"#F5D8FA\" /></linearGradient></defs></symbol>"
});
sprite.add(symbol);

var SvgSpriteIcon = function SvgSpriteIcon(props) {
  return React.createElement(
    'svg',
    Object.assign({
      viewBox: symbol.viewBox
    }, props),
    React.createElement(
      'use',
      {
        xlinkHref: '#' + symbol.id
      }
    )
  );
};

SvgSpriteIcon.viewBox = symbol.viewBox;
SvgSpriteIcon.id = symbol.id;
SvgSpriteIcon.content = symbol.content;
SvgSpriteIcon.url = symbol.url;
SvgSpriteIcon.toString = symbol.toString;

module.exports = SvgSpriteIcon;
module.exports.default = SvgSpriteIcon;
