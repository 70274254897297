import useTranslation from "next-translate/useTranslation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DEFAULT_RE_CAPTCHA_DYNAMIC_SCRIPT_ID } from "~/components/providers/ReCaptchaProvider/constants";
import {
  ReCaptchaProviderProps,
  ReCaptchaProviderState,
  ReCaptchaScriptProps
} from "~/components/providers/ReCaptchaProvider/declarations";
import { ReCaptchaContext } from "~/components/providers/ReCaptchaProvider/ReCaptchaContext";
import {
  cleanGoogleReCaptcha,
  createLayoutErrorObserver,
  createReCaptchaContainer,
  getActualReCaptchaInstance,
  injectGoogleReCaptchaScript,
  logReCaptchaError,
  mountReCaptchaContainer
} from "~/components/providers/ReCaptchaProvider/utils";
import { RE_CAPTCHA_ACTION } from "~/constants/reCaptcha";

const ReCaptchaProvider = ({
  reCaptchaKey,
  children,
  scriptProps,
  useReCaptchaNet = false,
  useEnterprise = false
}: ReCaptchaProviderProps): JSX.Element => {
  const { lang } = useTranslation();
  const [state, setState] = useState<ReCaptchaProviderState>(null);
  const layoutErrorObserverRef = useRef<MutationObserver | null>(null);

  /* Save to a separate object to remove dependency on scriptProps object pointer */
  const scriptPropsWithRequiredId: ReCaptchaScriptProps = useMemo(
    () => ({
      nonce: scriptProps?.nonce,
      defer: scriptProps?.defer,
      async: scriptProps?.async,
      appendTo: scriptProps?.appendTo,
      id: scriptProps?.id ?? DEFAULT_RE_CAPTCHA_DYNAMIC_SCRIPT_ID
    }),
    [
      scriptProps?.appendTo,
      scriptProps?.async,
      scriptProps?.defer,
      scriptProps?.id,
      scriptProps?.nonce
    ]
  );

  useEffect(() => {
    if (!reCaptchaKey) {
      throw new Error(
        "You should provide NEXT_PUBLIC_RECAPTCHA_SITE_KEY env to use ReCaptchaProvider"
      );
    }

    const onLoad = (): void => {
      const reCaptchaInstance = getActualReCaptchaInstance({
        useEnterprise
      });

      if (!reCaptchaInstance) {
        logReCaptchaError("These is no global Google reCAPTCHA instance");
        return;
      }

      reCaptchaInstance.ready(() => {
        const reCaptchaContainer = createReCaptchaContainer();
        layoutErrorObserverRef.current =
          createLayoutErrorObserver(reCaptchaContainer);
        mountReCaptchaContainer(reCaptchaContainer);

        const renderId = reCaptchaInstance.render(reCaptchaContainer, {
          sitekey: reCaptchaKey,
          size: "invisible"
        });

        setState({
          reCaptchaInstance,
          renderId
        });
      });
    };

    const onError = (): void => {
      logReCaptchaError("Can't load Google reCAPTCHA script");
    };

    injectGoogleReCaptchaScript({
      useEnterprise,
      useReCaptchaNet,
      scriptProps: scriptPropsWithRequiredId,
      language: lang,
      onLoad,
      onError
    });

    return () => {
      if (layoutErrorObserverRef.current) {
        layoutErrorObserverRef.current.disconnect();
        layoutErrorObserverRef.current = null;
      }

      cleanGoogleReCaptcha(scriptPropsWithRequiredId.id);
    };
  }, [
    lang,
    reCaptchaKey,
    scriptPropsWithRequiredId,
    useEnterprise,
    useReCaptchaNet
  ]);

  const executeReCaptcha = useCallback(
    async (action: RE_CAPTCHA_ACTION): Promise<string> => {
      if (!state) {
        throw new Error("ReCaptchaProvider state is not initialized");
      }

      const result = await state.reCaptchaInstance.execute(state.renderId, {
        action
      });

      return result;
    },
    [state]
  );

  const contextValue = useMemo(
    () => ({ executeReCaptcha: state ? executeReCaptcha : null }),
    [executeReCaptcha, state]
  );

  return (
    <ReCaptchaContext.Provider value={contextValue}>
      {children}
    </ReCaptchaContext.Provider>
  );
};

export default ReCaptchaProvider;
