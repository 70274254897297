import useTranslation from "next-translate/useTranslation";
import { SnackbarKey } from "notistack";

import { CustomSnackbarMessage } from "~/components/providers/CustomSnackbarProvider/CustomSnackbarMessage";

interface Props {
  id: SnackbarKey;
  className?: string;
}

const PaymentResultSnackEditionUpdated = ({
  id,
  className
}: Props): JSX.Element => {
  const { t } = useTranslation("payments");

  return (
    <CustomSnackbarMessage
      className={className}
      id={id}
      title={t("editions-update-succeeded")}
    />
  );
};

export default PaymentResultSnackEditionUpdated;
