import { generateShadesPalette } from "~/theme/utils";

export const BLACK = "#000000";
export const WHITE = "#FFFFFF";

export const BACKGROUNDS = {
  lightPrimary: "#171717",
  lightSecondary: "#222222",
  lightTertiary: "#373535",
  lightQuaternary: "#868686",
  lightSenary: "#F3F3F3",
  lightSeptenary: "#F9F9F9",
  lightOctonary: "#FFFFFF",

  darkPrimary: "#FFFFFF",
  darkSecondary: "#F9F9F9",
  darkTertiary: "#F3F3F3",
  darkQuaternary: "#868686",
  darkSenary: "#373535",
  darkSeptenary: "#222222",
  darkOctonary: "#171717"
};

export const TEXT_COLOR = {
  lightPrimary: "#171717",
  lightSecondary: "#84858B",
  lightTertiary: "#BFC0C2",
  lightQuaternary: "#FFFFFF",
  darkPrimary: "#FFFFFF",
  darkSecondary: "#BFC0C2",
  darkTertiary: "#88888A",
  darkQuaternary: "#171717"
};

export const PALE_COLORS = {
  lightPalePink: "#FBF4FC",
  lightPalePurple: "#EDE9FB",
  lightPaleYellow: "#FFFEF0",
  lightPaleGreen: "#EFFFF8",
  lightPaleRed: "#FFECF0",
  darkPalePink: "#2E292F",
  darkPalePurple: "#2D2B34",
  darkPaleYellow: "#32312B",
  darkPaleGreen: "#212D28",
  darkPaleRed: "#342B2C"
};

export const THIRD_PARTY_BACKGROUND = {
  xdb: "#00B3F0"
};

export const TRANSPARENCY_WHITE = generateShadesPalette(WHITE);
export const TRANSPARENCY_BLACK = generateShadesPalette(BLACK);

export const SNACKBAR_BOX_SHADOW_COLOR = "#0B0B0B";
