import { useCallback } from "react";

import { RAW_NOTIFICATION_TYPE } from "~/components/notifications/constants";
import { ActivityType } from "~/declarations/apollo/globalTypes";
import {
  NotificationEvents,
  NotificationEventsVariables
} from "~/declarations/apollo/NotificationEvents";
import useSubscriptionWithRecovery, {
  OnSubscriptionDataHandler
} from "~/utils/apollo/useSubscriptionWithRecovery";

import { NOTIFICATION_EVENTS_SUBSCRIPTION } from "../graphql";
import { NOTIFICATION_EVENT_TYPE } from "./declarations";
import {
  addNotificationToCache,
  deleteNotificationFromCache,
  unstackNotificationInCache,
  updateNotificationActionTypeInCache,
  updateOrAddNotificationToCache
} from "./utils";

const useRealTimeNotifications = (currentUserId: string | undefined): void => {
  const handleSubscriptionData: OnSubscriptionDataHandler<
    NotificationEvents,
    "activityEvents"
  > = useCallback(({ client, data }): void => {
    const { events } = data;

    events.forEach(event => {
      switch (event.__typename) {
        case NOTIFICATION_EVENT_TYPE.created: {
          addNotificationToCache(
            client,
            event.activity,
            ActivityType.notifications
          );

          break;
        }
        case NOTIFICATION_EVENT_TYPE.stacked: {
          const notification = event.activity;

          if (
            notification.__typename === RAW_NOTIFICATION_TYPE.PostLiked ||
            notification.__typename === RAW_NOTIFICATION_TYPE.PostCommented ||
            notification.__typename === RAW_NOTIFICATION_TYPE.PersonFollowed
          ) {
            updateOrAddNotificationToCache(client, notification);
          }

          break;
        }
        case NOTIFICATION_EVENT_TYPE.unstacked: {
          unstackNotificationInCache(
            client,
            event.activityId,
            event.unstackedPersonId,
            event.otherPerson
          );
          break;
        }
        case NOTIFICATION_EVENT_TYPE.actionPerformed: {
          updateNotificationActionTypeInCache(
            client,
            event.activityId,
            event.actionType
          );
          break;
        }
        case NOTIFICATION_EVENT_TYPE.deleted: {
          deleteNotificationFromCache(client, event.activityId);
          break;
        }
      }
    });
  }, []);

  useSubscriptionWithRecovery<
    NotificationEvents,
    "activityEvents",
    NotificationEventsVariables
  >({
    subscription: NOTIFICATION_EVENTS_SUBSCRIPTION,
    onSubscriptionData: handleSubscriptionData,
    key: "activityEvents",
    skip: !currentUserId
  });
};

export default useRealTimeNotifications;
