import { DIALOG_NAME } from "~/components/providers/DialogProvider/declarations/common";
import { DialogConfig } from "~/components/providers/DialogProvider/declarations/config";
import { safeDynamicImport } from "~/utils/import/dynamic";

export const NFT_PURCHASING_SUCCESS_DIALOG_CONFIG: DialogConfig<DIALOG_NAME.purchaseNftSuccess> =
  {
    Component: safeDynamicImport(
      () =>
        import(
          "~/components/nft/NftAsset/NftPurchaseDialog/NftPurchasingSuccessDialog"
        ),

      { ssr: false }
    )
  };
